import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Box, styled } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { useProposalInquiryStore } from 'providers/proposal-inquiry';

const BorderedContainer = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    border: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
}));

const SmallBorderedContainer = styled(BorderedContainer)(({ theme: { spacing } }) => ({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 0,
    padding: spacing(1.25),
}));

const AttachIcon = styled(AttachFileIcon)(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.bizlyOSPrimary),
}));

const Title = styled(Body1)(({ theme: { getColor, EColors, spacing } }) => ({
    paddingBottom: spacing(2.5),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    fontWeight: 600,
}));

const AttachTitle = styled(Body2)(({ theme: { getColor, EColors, spacing } }) => ({
    flex: 1,
    borderRight: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    paddingLeft: spacing(1.25),
}));

function ImportantDetails() {
    const { proposal, inquiry } = useProposalInquiryStore(state => state.proposalInquiryForm);

    return (
        <Box display="flex" flexDirection="column" gap={2.5}>
            {inquiry?.notes && (
                <BorderedContainer>
                    <Title>Client Notes & Requests</Title>
                    <Body1>{inquiry?.notes || '-'}</Body1>
                </BorderedContainer>
            )}

            <BorderedContainer>
                <Title>Important Client Document</Title>
                <Body2>Please review the attached document.</Body2>
                <SmallBorderedContainer>
                    <AttachIcon fontSize="small" />
                    <AttachTitle>{proposal?.msaDocumentUrl?.split('/').at(-1) || '-'}</AttachTitle>
                    <a href={proposal?.msaDocumentUrl} download>
                        <Button variant="text">Download</Button>
                    </a>
                </SmallBorderedContainer>
            </BorderedContainer>
        </Box>
    );
}

export default ImportantDetails;
