import { fields, schema, schemaWithoutGuestrooms } from 'components/VenueAccepted/uploadFormSchema';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Copy, ExternalLink, Spacer } from '../../ui';
import pluckFileName from '../../utils/pluckFileName';
import Form from '../Form';
import Button from '../ui/Button';
import { H2Headline } from '../ui/Headline';

export type TEstimateForm = {
    cateringCost?: string | number;
    guestroomCost?: string | number;
    guestroomNights?: string | number;
    guests?: string | number;
    roomCost?: string | number;
    total?: string | number;
    existingDocs: {
        contract1?: string;
        contract2?: string;
    };
    stagedDocs?: {
        contract1?: File;
        contract2?: File;
    };
    currency?: string | null;
};

const Headline = styled(H2Headline)`
    margin-bottom: 32px;
`;

const HighlightedCopy = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.highlightedText)};
`;

const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.listItemBackground)};
    height: 60px;
    margin: 12px 0;
    padding: 0 16.9px;
`;

const PlaceholderText = styled.p`
    font-size: 15px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
`;

type UploadBoxProps = {
    fileName?: string;
    target: string;
    onUpload: (files: File[], target: string) => void;
    link?: string;
    readOnly?: boolean;
};

const UploadBox = ({ fileName, target, onUpload, link, readOnly }: UploadBoxProps) => {
    const onDrop = (files: File[]) => onUpload(files, target);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    const { color: inputColor, ...inputProps } = getInputProps();
    const { color: rootColor, ...rootProps } = getRootProps();

    return (
        <Box>
            {link ? (
                <ExternalLink href={link} openInNewTab>
                    {fileName || link}
                </ExternalLink>
            ) : fileName ? (
                <p>{fileName}</p>
            ) : (
                <PlaceholderText>{i18n.venue.proposal.noFileSelected}</PlaceholderText>
            )}
            <input {...inputProps} />
            {!readOnly && (
                <div {...rootProps}>
                    <Button onClick={() => null} small secondary width={92}>
                        {fileName ? i18n.button.replace : i18n.button.selectFile}
                    </Button>
                </div>
            )}
        </Box>
    );
};

const SmallCopy = styled.p`
    font-size: 13px;
`;

export default function EstimateForm({
    canUpdate,
    onFormUpdate,
    formValues,
    teamCompliance,
    hideHeader,
    readonly,
    disabled,
}: Readonly<{
    canUpdate: boolean;
    onFormUpdate: (estimatesForm: TEstimateForm) => void;
    formValues: TEstimateForm;
    teamCompliance: Bizly.TeamCompliance;
    readonly?: boolean;
    disabled?: boolean;
    hideHeader?: boolean;
}>) {
    const { enqueueSnackbar } = useSnackbar();
    const { existingDocs, currency, stagedDocs = {} } = formValues;

    const handleStagingDoc = async (files: File[], target: string) => {
        const maxMB = 41943040; // 40mb
        const file = files[0] || {};
        if (file.size > maxMB) {
            enqueueSnackbar(i18n.error.tooLargeFile(file.name), {
                variant: 'error',
            });
        } else {
            onFormUpdate({ ...formValues, stagedDocs: { ...stagedDocs, [target]: file } });
        }
    };

    return (
        <>
            {!hideHeader && <Headline>{i18n.venue.proposal.uploadSignedDocs}</Headline>}
            {canUpdate ? (
                <>
                    <UploadBox
                        onUpload={handleStagingDoc}
                        fileName={stagedDocs?.contract1?.name || pluckFileName(existingDocs.contract1)}
                        target={'contract1'}
                        link={stagedDocs?.contract1?.name ? undefined : existingDocs.contract1}
                        readOnly={readonly || disabled}
                    />
                    <UploadBox
                        onUpload={handleStagingDoc}
                        fileName={stagedDocs?.contract2?.name || pluckFileName(existingDocs.contract2)}
                        target={'contract2'}
                        link={stagedDocs?.contract2?.name ? undefined : existingDocs.contract2}
                        readOnly={readonly || disabled}
                    />

                    <SmallCopy>{i18n.venue.proposal.maxFileUploadSize}</SmallCopy>

                    <Spacer />

                    <Form
                        fields={fields(currency ?? 'USD')}
                        schema={teamCompliance?.guestroomsDisabled ? schemaWithoutGuestrooms : schema}
                        value={{ ...formValues, currencyCode: currency ?? 'USD' }}
                        onChange={(newValue: { value: TEstimateForm }) => onFormUpdate(newValue.value)}
                        isNested={false}
                        readonly={readonly}
                        densePadding={false}
                        disabled={disabled}
                    />
                </>
            ) : (
                <HighlightedCopy>{i18n.venue.proposal.estimatesHelper}</HighlightedCopy>
            )}
        </>
    );
}
