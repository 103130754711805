import { Box } from '@mui/material';
import styled from 'styled-components';
import { AlignedRow } from 'ui';
import { HEADER_HEIGHT } from 'utils/header';
import { Notifications } from './Notifications';

const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: -24px -32px;
    padding: 24px 32px;
    box-sizing: border-box;
    height: ${HEADER_HEIGHT}px;

    transition: 0.3s all ease-in-out;

    ${({ theme: { getColor, EColors } }) =>
        `
        background: ${getColor(EColors.pureWhite)};
    `}
`;

const Right = styled(AlignedRow)`
    justify-content: flex-end;
`;

const NotificationHeader = () => {
    return (
        <Box>
            <Header>
                <Right itemSpacing="small">
                    <Notifications />
                </Right>
            </Header>
        </Box>
    );
};

export default NotificationHeader;
