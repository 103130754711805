import { alpha, Box, Paper, Stack, Typography } from '@mui/material';
import LoadingAnimation from 'components/Venues/InsightsAndKeyDetails/LoadingAnimation';
import { useVenueInsightsQuery } from 'hooks/queries/useVenueInsightsQuery';
import AtmosphereIcon from 'images/icons/bizly-insights/atmosphere.svg?react';
import InsightsIcon from 'images/icons/bizly-insights/insight.svg?react';
import PricingIcon from 'images/icons/bizly-insights/pricing.svg?react';
import ReviewsIcon from 'images/icons/bizly-insights/reviews.svg?react';
import { ReactElement, useMemo } from 'react';
import { EColors, getColor } from 'theme';
import { VenueData } from 'types/stream';

type StreamingSection = {
    header: string;
    section: string;
    content: string;
    isLoading: boolean;
};

type BizlyInsightsProps = {
    venue: Bizly.Venue;
};

function getSectionIcon(section: string): ReactElement | null {
    switch (section) {
        case 'atmosphere':
            return <AtmosphereIcon />;
        case 'pricing':
            return <PricingIcon />;
        case 'reviews':
            return <ReviewsIcon />;
        default:
            return null;
    }
}

const InsightsItem = ({ header, section, isLoading, content }: StreamingSection) => {
    if (isLoading) {
        return <LoadingAnimation />;
    }

    const icon = getSectionIcon(section || '');

    return (
        <Stack direction="row" gap={1}>
            <Box>{icon}</Box>
            <Box sx={{ width: '100%' }}>
                <Typography component="span" fontSize={16} fontWeight={600}>
                    {header}:{' '}
                </Typography>
                <Typography fontSize={16} lineHeight={'24px'} component="span">
                    {content}
                </Typography>
            </Box>
        </Stack>
    );
};

export const BizlyInsights = ({ venue }: BizlyInsightsProps) => {
    // Memoize venue data to prevent unnecessary recreations
    const venueData = useMemo<VenueData>(
        () => ({
            name: venue.name,
            address: venue.address,
            type: venue.type?.name,
        }),
        [venue]
    );

    // Insights queries
    const atmosphereQuery = useVenueInsightsQuery(venueData, 'atmosphere');
    const pricingQuery = useVenueInsightsQuery(venueData, 'pricing');
    const reviewsQuery = useVenueInsightsQuery(venueData, 'reviews');

    return (
        <Paper
            elevation={0}
            sx={{ p: 2, backgroundColor: alpha(getColor(EColors.bizlyInsightsBlue), 0.1), borderRadius: 2, mb: 2 }}
        >
            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                <Box>
                    <InsightsIcon />
                </Box>
                <Box>
                    <Typography fontSize={22} fontWeight={600} mb={'15px'}>
                        Bizly Insights
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        {atmosphereQuery && (
                            <InsightsItem
                                header="Atmosphere"
                                section="atmosphere"
                                isLoading={atmosphereQuery.isLoading}
                                content={atmosphereQuery?.data?.words.join(' ') || ''}
                            />
                        )}
                        {pricingQuery && (
                            <InsightsItem
                                header="Pricing Estimate"
                                section="pricing"
                                isLoading={pricingQuery.isLoading}
                                content={pricingQuery?.data?.words.join(' ') || ''}
                            />
                        )}
                        {reviewsQuery && (
                            <InsightsItem
                                header="Reviews from Event Planners"
                                section="reviews"
                                isLoading={reviewsQuery.isLoading}
                                content={reviewsQuery?.data?.words.join(' ') || ''}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
};
