import groupBy from 'lodash/groupBy';
import { tzMoment } from 'utils/moment';

export const STATUS_TO_RANK: { [status: string]: number } = {
    Booked: 3,
    'Contract Pending': 2,
    'Proposal Received': 1,
    'Extension Pending': 1,
    'Proposal Expired': 1,
    Submitted: 0,
    'Extension Rejected': -1,
    'Proposal Rejected': -1,
    'Proposal Cancelled': -1,
    'Inquiry Rejected': -1,
    'Inquiry Cancelled': -1,
};

export function sortGalleryInquiries(venues: Bizly.Venue[]) {
    return venues.sort((a, b) => (STATUS_TO_RANK[b.status as string] || 0) - (STATUS_TO_RANK[a.status as string] || 0));
}

export function inquiryToSchedule({ eventSpaces, guestrooms }: BizlyAPI.Inquiry) {
    const NO_DATE = '';

    const spacesWithDates = eventSpaces.map(eventSpace => {
        const isoDate = tzMoment(eventSpace.start);
        return {
            ...eventSpace,
            isoDate: isoDate ? isoDate.format('YYYY-MM-DD') : NO_DATE,
        };
    });
    const roomsWithDate = guestrooms.map(guestroom => {
        const isoDate = tzMoment(guestroom.date);
        return {
            ...guestroom,
            isoDate: isoDate ? isoDate.format('YYYY-MM-DD') : NO_DATE,
        };
    });

    const spacesByDate = groupBy(spacesWithDates, space => space.isoDate);
    const roomsByDate = groupBy(roomsWithDate, room => room.isoDate);

    const allIsoDates = [...new Set([...Object.keys(spacesByDate), ...Object.keys(roomsByDate)])];

    type ScheduleAgenda = {
        id: string;
        name?: string;
        start?: string;
        end?: string;
        roomSetup?: number;
        amenities: {
            av: number[];
            foodAndBeverage: { optionId?: number }[];
        };
    };

    type ScheduleGuestroom = { count?: number };

    type Day = {
        start?: string;
        agenda: ScheduleAgenda[];
        guestrooms: ScheduleGuestroom[];
    };

    type Schedule = Day[];

    const ZERO_ISO_TIME = 'T00:00:00';

    const schedule: Schedule = allIsoDates.map(isoDate => ({
        start: isoDate === NO_DATE ? undefined : isoDate + ZERO_ISO_TIME,
        agenda: (spacesByDate[isoDate] || []).map(item => ({
            ...item,
            roomSetup: item.setup?.id,
            amenities: {
                av: item.avNeeds.map(need => need.id),
                foodAndBeverage: item.fbNeeds.map(need => ({ optionId: need.id })),
            },
        })),
        guestrooms: roomsByDate[isoDate] || [],
    }));

    return schedule;
}

export function hasProposal(venue: Bizly.Venue) {
    return (
        [
            'Booked',
            'Contract Pending',
            'Proposal Received',
            'Proposal Expired',
            'Extension Pending',
            'Extension Rejected',
            'Proposal Rejected',
            'Proposal Cancelled',
            'Booking Cancelled',
        ].includes(venue.status) || venue.booking
    );
}

export function proposalActive(venue: Bizly.Venue) {
    return ['Proposal Received', 'Proposal Expired', 'Extension Pending', 'Extension Rejected'].includes(venue.status);
}

export function proposalExpired(venue: Bizly.Venue) {
    return ['Proposal Expired', 'Extension Pending', 'Extension Rejected'].includes(venue.status);
}
