import InfoIcon from '@mui/icons-material/Info';
import TextField from '@mui/material/TextField';
import { Spacer } from 'components/Ui-V2/Spacer/Spacer';
import { i18n } from 'translation';
import { Link } from 'ui';
import { EventCreateForm, EventCreateFormErrors } from '../formSchema';
import { getNextMonthAndYear } from '../utils';
import { FieldLabel, FieldTitle, StepContainer, Title, VerbiageAlert } from './styled';

type PropType = {
    values: EventCreateForm;
    onChange: (key: keyof EventCreateForm) => (value: unknown) => void;
    errors: EventCreateFormErrors;
};

const Step1 = ({ values, onChange, errors }: PropType) => {
    const eventNameChange = onChange('eventName');
    const objectiveDescriptionChange = onChange('eventDescription');

    const handleEventName = (event: React.ChangeEvent<HTMLInputElement>) => {
        eventNameChange(event.target.value);
    };

    const handleObjectiveDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        objectiveDescriptionChange(event.target.value);
    };

    const { nextMonthName, year } = getNextMonthAndYear();

    return (
        <StepContainer>
            <Title>{i18n.homepage.createMeetingModal.objetiveTitle}</Title>
            <VerbiageAlert severity="info" icon={<InfoIcon fontSize="inherit" />}>
                {i18n.homepage.createMeetingModal.eventInfoDescription}{' '}
                <Link href="/playbooks">{i18n.homepage.createMeetingModal.clickHere}</Link>
            </VerbiageAlert>
            <TextField
                multiline
                id="event-name"
                label={`${i18n.homepage.createMeetingModal.eventName} (required)`}
                variant="outlined"
                sx={{ width: '100%' }}
                value={values.eventName}
                onChange={handleEventName}
                error={errors.eventName}
            />
            <Spacer />
            <FieldTitle>{i18n.homepage.createMeetingModal.objectiveDescription}</FieldTitle>
            <TextField
                multiline
                id="objective-description"
                placeholder="Event Description"
                variant="outlined"
                sx={{ width: '100%', height: '120px' }}
                rows={4}
                value={values.eventDescription}
                onChange={handleObjectiveDescription}
                error={errors.eventDescription}
            />
            <FieldLabel>{i18n.homepage.createMeetingModal.sampleDescription(nextMonthName, year)}</FieldLabel>
        </StepContainer>
    );
};

export default Step1;
