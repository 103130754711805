import { Box } from '@mui/material';
import GooglePlacesAutocomplete from 'components/BizlyOS/InputFields/PlaceInput';
import ClaimStepContainer from 'components/BizlyOS/ListingsClaim/ClaimStepContainer';
import StepItemsContainer from 'components/BizlyOS/ListingsClaim/StepItemsContainer';
import { Body2 } from 'components/BizlyOS/Typography/Typography';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EColors } from 'theme';
import { ListingsCreateStepProps } from './ListingsCreate';

export default function VenueAddress(props: ListingsCreateStepProps) {
    const [searchParams] = useSearchParams();

    const [venue, setVenue] = useState<string>(props.listingsCreateData.venueAddress?.name || '');
    const [placeId, setPlaceId] = useState<string>(props.listingsCreateData.venueAddress?.placeId || '');

    const locationLat = searchParams.get('location_lat');
    const locationLng = searchParams.get('location_lng');

    const onSubmit = () => {
        props.onNextStepClicked({ venueAddress: { placeId, name: venue } });
    };

    const onChange = (venue: string, placeId: string | undefined) => {
        setVenue(venue);
        setPlaceId(placeId || '');
    };

    const locationBias =
        locationLat && locationLng
            ? {
                  lat: Number(locationLat),
                  lng: Number(locationLng),
              }
            : undefined;

    return (
        <ClaimStepContainer {...props} onSave={onSubmit} disabled={!placeId} contentCentered>
            <StepItemsContainer
                title="What’s your venue’s address?"
                description="Enter your venue’s physical location where event planners can find you. We also use Google Maps to double-check address accuracy. "
            >
                <Box display="flex" gap={0.5} marginBottom={1}>
                    <Body2 fontWeight={500}>Venue Address</Body2>
                    <Body2 color={EColors.bizlyOSPrimary} fontWeight={500}>
                        *
                    </Body2>
                </Box>
                <GooglePlacesAutocomplete
                    locationValue={venue}
                    placeId={placeId}
                    onChange={onChange}
                    locationBias={locationBias}
                    noOptionIcon
                    hideSearchIcon
                />
            </StepItemsContainer>
        </ClaimStepContainer>
    );
}
