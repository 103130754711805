import { Box, Skeleton, Stack, styled } from '@mui/material';
import { EColors, getColor } from 'theme';

const ModalContainer = styled(Box)({
    backgroundColor: getColor(EColors.pureWhite),
    overflow: 'hidden',
});

const Gallery = styled(Box)({
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    gap: '8px',
    height: '450px',
    width: '100%',
    '& .main-image': {
        gridRow: '1 / span 2',
    },
});

const ContentSection = styled(Box)({
    padding: '24px 0',
    width: '100%',
});

const ActionButtons = styled(Stack)({
    padding: '16px 24px',
    borderTop: '1px solid ' + getColor(EColors.lighterGrey),
    width: '100%',
});

export const VenueListingSkeleton = () => {
    return (
        <ModalContainer>
            {/* Image Gallery */}
            <Gallery>
                <Skeleton className="main-image" variant="rectangular" width="100%" height="100%" />
                <Skeleton variant="rectangular" width="100%" height="100%" />
                <Skeleton variant="rectangular" width="100%" height="100%" />
                <Skeleton variant="rectangular" width="100%" height="100%" />
                <Skeleton variant="rectangular" width="100%" height="100%" />
            </Gallery>

            {/* Venue Content */}
            <ContentSection>
                <Stack spacing={3} width="100%">
                    {/* Venue Name and Subtitle */}
                    <Stack spacing={1} width="100%">
                        <Skeleton variant="text" width="100%" height={40} />
                        <Skeleton variant="text" width="100%" height={24} />
                    </Stack>

                    {/* Restaurant Tag */}
                    <Skeleton variant="rectangular" width={100} height={28} sx={{ borderRadius: '14px' }} />

                    {/* Description */}
                    <Stack spacing={1} width="100%">
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                    </Stack>
                </Stack>
            </ContentSection>

            {/* Action Buttons */}
            <ActionButtons direction="row" spacing={2}>
                <Skeleton variant="rectangular" width="100%" height={40} sx={{ borderRadius: '4px' }} />
                <Skeleton variant="rectangular" width="100%" height={40} sx={{ borderRadius: '4px' }} />
            </ActionButtons>
        </ModalContainer>
    );
};
