import { SingleFieldForm } from 'components/Form';
import { H2Headline } from 'components/ui/Headline';
import styled from 'styled-components';

const Headline = styled(H2Headline)`
    margin-bottom: 32px;
`;

export default function BudgetForm({
    value,
    onChange,
    readonly,
    disabled,
    hideHeader,
    isApple,
}: {
    value: string;
    onChange: (value: string) => void;
    readonly?: boolean;
    disabled?: boolean;
    hideHeader?: boolean;
    isApple: boolean;
}) {
    // Only apply currency formatting for Apple teams
    // This prevents NaN display issues for non-Apple teams who need to enter text
    const shouldFormatAsCurrency = isApple && !isNaN(Number(value));
    const currencyOptions = shouldFormatAsCurrency
        ? {
              numberType: 'currency',
              currency: 'USD',
          }
        : undefined;

    return (
        <>
            {!hideHeader && <Headline>Budget</Headline>}
            <SingleFieldForm
                field={{
                    type: 'text',
                    prompt: 'Your Budget',

                    options: currencyOptions,
                }}
                value={value}
                onChange={onChange}
                readonly={readonly}
                disabled={disabled}
            />
        </>
    );
}
