import { Box, BoxProps, styled } from '@mui/material';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Toolbar from './Toolbar';

const DEFAULT_MAX_CHAR_LIMIT = 5000;

export const Container = styled(Box)(({ theme: { getColor, EColors, shape, spacing }, error }) => ({
    height: 'auto',
    border: `1px solid ${error ? getColor(EColors.red) : getColor(EColors.bizlyOSBorder)}`,
    borderRadius: shape.borderRadius,
    boxSizing: 'border-box',
    padding: spacing(1.25),
    '&:focus-within': {
        borderColor: getColor(EColors.bizlyOSPrimary),
    },
}));

/**
 * use this comeponent to render the content of the tiptap editor
 * Note: style of the this component is corresponded to the tiptap-input p classes
 * @param {BoxProps} props
 */
export const TiptapContentRender = styled(Box)<BoxProps>({
    fontSize: '1rem',
    lineHeight: '21px',
    '& > p': { margin: 0, paddingBottom: '1rem' },
});

const extensions = [StarterKit, Underline];

type TiptapProps = {
    description: string;
    editable: boolean;
    onChange: (_: string) => void;
    onBlur: (_: string) => void;
    limit?: number;
    error?: boolean;
};

export default function Tiptap({
    description,
    editable,
    limit = DEFAULT_MAX_CHAR_LIMIT,
    error = false,
    onChange,
    onBlur: _onBlur,
}: TiptapProps) {
    const editor = useEditor({
        extensions,
        content: description,
        editorProps: {
            attributes: {
                class: 'tiptap-input',
            },
        },
        onUpdate({ editor }) {
            const text = editor.getText();
            // Rollback changes if limit exceeded
            if (text.length > limit) {
                const truncated = text.slice(0, limit);
                editor.commands.setContent(truncated); // Reset content
            } else {
                onChange(editor.getHTML());
            }
        },
        onBlur({ editor }) {
            _onBlur(editor.getHTML());
        },
        editable,
    });

    return (
        <Container error={error}>
            <Toolbar editor={editor} />
            <EditorContent editor={editor} />
        </Container>
    );
}
