import { useFeatureFlagEnabled } from 'posthog-js/react';
import { PostHogFeatureFlags } from 'shared';

const LEGACY_MAX_INQUIRY_VENUE_SLOTS = 4;
const NEW_MAX_INQUIRY_VENUE_SLOTS = 8;

export const useMaxInquiryVenueSlots = () => {
    const newVenueTileLayout = useFeatureFlagEnabled(PostHogFeatureFlags.toggleNewVenueTileLayout);
    return newVenueTileLayout ? NEW_MAX_INQUIRY_VENUE_SLOTS : LEGACY_MAX_INQUIRY_VENUE_SLOTS;
};
