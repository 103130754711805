import Dialog from '@material-ui/core/Dialog';
import { eventOptionsAPIDict, EventOptionsDictAPI } from 'api/eventOptionsApi';
import RejectionReasonForm from 'components/ProposalForm/RejectionReasonForm';
import fontFns from 'fontFns';
import useKey from 'hooks/useKey';
import CloseIcon from 'images/icons/close.svg?react';
import { EventOptionsContext } from 'providers/event-options';
import React from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { EColors } from 'types/themenum';
import { Column, Copy, Row, Spacer } from 'ui';
import { enableTurnDown } from 'utils';
import { tzMoment } from 'utils/moment';
import { SpinnerOverlay } from './Spinner';
import TextButton from './ui/Button/TextButton';

const AccentedHeadline = styled.h3`
    font-size: 18px;
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.accentedHeadline)};
    margin-top: 0;
`;

const ITEM_MARGIN = 12;

const DayHeader = styled(Row)`
    height: 60px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.agendaDayPane)};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.agendaDayPaneText)};

    box-sizing: border-box;
    padding: 0 ${ITEM_MARGIN}px;
    & > * {
        margin: 0 ${ITEM_MARGIN}px;
    }
`;

const DayContent = styled.div`
    & > * {
        border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};

        &:last-child {
            border: none;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
`;

const GRRow = styled(Row)`
    display: flex;
    align-items: center;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};

    box-sizing: border-box;
    padding: 28px ${ITEM_MARGIN}px;
    & > * {
        margin: 0 ${ITEM_MARGIN}px;
    }
`;

const HEADER_ICON_WIDTH = 40;

const HeaderIconImg = styled.img`
    width: ${HEADER_ICON_WIDTH}px;
`;

const ESContent = styled.div`
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    padding: 28px 0;
`;

const ESHeader = styled(Row)`
    flex-wrap: wrap;

    box-sizing: border-box;
    padding: 0 ${ITEM_MARGIN}px;
    padding-bottom: 12px;
    & > * {
        margin: 0 ${ITEM_MARGIN}px;
    }
`;

const AmenitiesRow = styled(Row)`
    width: unset;
    flex-wrap: wrap;

    box-sizing: border-box;
    padding: 0 ${ITEM_MARGIN}px;
    padding-left: ${ITEM_MARGIN + ITEM_MARGIN + HEADER_ICON_WIDTH + ITEM_MARGIN}px;
    & > * {
        margin: ${ITEM_MARGIN}px;
    }
`;

const DialogContainer = styled.div`
    width: 480px;

    max-height: 571px;

    flex: 1;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackground)};

    height: 100%;
    box-sizing: border-box;

    overflow: auto;

    position: relative;

    & > *:not(:first-child) {
        padding: 32px;
    }
`;

const StyledCloseIcon = styled(CloseIcon)`
    position: absolute;
    top: 30px;
    right: 30px;

    cursor: pointer;
`;

const WhiteSection = styled.section`
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    padding: 30px;
`;

const NotesLabel = styled.div`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}
`;

const AMENITY_ICON_WIDTH = 28;

const AmenityImg = styled.img`
    max-width: ${AMENITY_ICON_WIDTH}px;
    max-height: ${AMENITY_ICON_WIDTH}px;
`;

export type InquiryDetailsType = {
    schedule: any[];
    submittedAt?: string;
    notes?: string;
    datesFlexible?: boolean;
    rejectedAt?: string;
    rejectionReason?: string;
    rejectionNotes?: string;
    venueId: number;
    venueInquiryId: number;
    status: Bizly.Venue['status'];
};

const InquiryDetailsModal = ({
    inquiryDetails,
    open,
    closeModal,
    onCancelInquiry,
}: {
    inquiryDetails?: InquiryDetailsType;
    open: boolean;
    closeModal: () => void;
    onCancelInquiry: (venueId: number, venueInquiryId: number) => void;
}) => {
    useKey('Escape', closeModal);

    // this filtering allows us to use the labels from the eventOptionsAPIDict while filtering out options that potentially
    // could have been removed from the database
    const {
        avOptions: avOptionsAPI,
        fbOptions: fbOptionsAPI,
        setupOptions: setupOptionsAPI,
    } = React.useContext(EventOptionsContext);
    const avOptions: Record<number, EventOptionsDictAPI['avOptions'][number]> = eventOptionsAPIDict()
        .avOptions.filter(val => avOptionsAPI.some(avOption => avOption.id === val.id))
        .reduce(
            (dict, opt) => ({
                ...dict,
                [opt.id]: opt,
            }),
            {}
        );
    const fbOptions: Record<number, EventOptionsDictAPI['fbOptions'][number]> = eventOptionsAPIDict()
        .fbOptions.filter(val => fbOptionsAPI.some(avOption => avOption.id === val.id))
        .reduce(
            (dict, opt) => ({
                ...dict,
                [opt.id]: opt,
            }),
            {}
        );
    const setupOptions: Record<number, EventOptionsDictAPI['setupOptions'][number]> = eventOptionsAPIDict()
        .setupOptions.filter(val => setupOptionsAPI.some(avOption => avOption.id === val.id))
        .reduce(
            (dict, opt) => ({
                ...dict,
                [opt.id]: opt,
            }),
            {}
        );

    const renderDay = (day: any, idx: number) => (
        <div key={`${day.id}-${idx}`}>
            <DayHeader alignItems="center">
                <div>{i18n.common.dayIndex(idx + 1)}</div>
                {day.start && <div>{tzMoment(day.start).format('ddd, ll')}</div>}
            </DayHeader>
            <DayContent>
                {!!day.guestrooms.length && (
                    <GRRow>
                        <HeaderIconImg
                            src="https://res.cloudinary.com/hdd626jg7/image/upload/v1562953405/stencil/icons/guest-room.svg"
                            alt="guestroom-icon"
                        />
                        {i18n.common.guestNumberFormatter(day.guestrooms[0].count)}
                    </GRRow>
                )}
                {day.agenda.map((agendaItem: any, idx: number) => (
                    <ESContent key={agendaItem.id}>
                        <ESHeader alignItems="center">
                            <HeaderIconImg
                                src={setupOptions[agendaItem.roomSetup]?.iconUrl ?? ''}
                                alt="meetingspace-icon"
                            />
                            <Column>
                                <Row itemSpacing="smallish">
                                    <div>{agendaItem.name || i18n.common.meetingSpaceIndex(idx + 1)}</div>
                                    <div>
                                        {tzMoment(agendaItem.start).format('LT')} -{' '}
                                        {tzMoment(agendaItem.end).format('LT')}
                                    </div>
                                </Row>
                                <div>{i18n.venue.inquiry.guestsNumber(agendaItem.attendees)}</div>
                            </Column>
                        </ESHeader>

                        <AmenitiesRow alignItems="center">
                            {agendaItem.amenities.av.map((avId: number) => (
                                <AmenityImg src={avOptions[avId]?.iconUrl ?? ''} alt="meetingspace-icon" key={avId} />
                            ))}
                            {agendaItem.amenities.foodAndBeverage.map((foodAndBeverageItem: { optionId: number }) => (
                                <AmenityImg
                                    src={fbOptions[foodAndBeverageItem.optionId]?.iconUrl ?? ''}
                                    alt="meetingspace-icon"
                                    key={foodAndBeverageItem.optionId}
                                />
                            ))}
                        </AmenitiesRow>
                    </ESContent>
                ))}
            </DayContent>
        </div>
    );

    return (
        <Dialog open={open} maxWidth="md" onClose={closeModal}>
            <DialogContainer>
                <StyledCloseIcon onClick={closeModal} />
                <WhiteSection>
                    <AccentedHeadline>{i18n.venue.inquiry.inquiryDetails}</AccentedHeadline>
                    {inquiryDetails && inquiryDetails.submittedAt && (
                        <Copy>
                            {i18n.venue.inquiry.submittedOn(tzMoment(inquiryDetails.submittedAt).format('dddd, lll'))}
                        </Copy>
                    )}
                    {inquiryDetails?.rejectedAt && (
                        <>
                            <Spacer small />
                            <Column fillWidth itemSpacing="small">
                                <Copy $color={EColors.warningText}>
                                    {i18n.venue.inquiry.rejectedOn(
                                        tzMoment(inquiryDetails.rejectedAt).format('ddd, ll')
                                    )}
                                </Copy>
                                {enableTurnDown && (
                                    <RejectionReasonForm
                                        value={{
                                            reason: inquiryDetails.rejectionReason || '',
                                            note: inquiryDetails.rejectionNotes || '',
                                        }}
                                        readonly
                                    />
                                )}
                            </Column>
                        </>
                    )}
                </WhiteSection>
                <section>
                    <Column fillWidth itemSpacing="medium">
                        {inquiryDetails && inquiryDetails.datesFlexible && (
                            <Copy>{i18n.proposalForm.header.datesAreFlexible}</Copy>
                        )}

                        {inquiryDetails && inquiryDetails.schedule && inquiryDetails.schedule.map(renderDay)}

                        {inquiryDetails && inquiryDetails.notes && (
                            <Column fillWidth>
                                <NotesLabel>{i18n.proposalForm.modal.notes}</NotesLabel>
                                <div>{inquiryDetails.notes}</div>
                            </Column>
                        )}
                    </Column>
                </section>
            </DialogContainer>
            {inquiryDetails?.status === 'Submitted' && (
                <WhiteSection>
                    <Row justifyContent="flex-end">
                        <TextButton
                            secondary
                            onClick={() => onCancelInquiry(inquiryDetails?.venueId, inquiryDetails?.venueInquiryId)}
                        >
                            {i18n.venue.inquiry.cancelInquiry}
                        </TextButton>
                    </Row>
                </WhiteSection>
            )}
            {!inquiryDetails && <SpinnerOverlay />}
        </Dialog>
    );
};

export default InquiryDetailsModal;
