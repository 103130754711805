import { zodResolver } from '@hookform/resolvers/zod';
import { Box, styled } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { DEFAULT_CURRENCY_CODE } from 'components/BizlyOS/Proposals/utils';
import { SideDrawer } from 'components/BizlyOS/SideDrawer/SideDrawer';
import { TESBooking, TProposal } from 'components/ProposalForm/types';
import { useGetEventSpaceQuery } from 'hooks/queries/BizlyOS/useListingQuery';
import { useGetProposalInquiry, useUpdateProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { SelectVenueSpaceDrawer, VenueSpaceDrawer } from '../VenueSpaceDrawers';
import { EventSpaceForm } from './EventSpaceForm';
import { ProposedVenueSpace } from './ProposedVenueSpace';
import { EventSpaceFormData, EventSpaceSchema } from './utils';

const ActionButtonContainer = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(2),
}));

const getDefaultValues = (proposal: Partial<TProposal> | undefined, eventSpace: TESBooking) => ({
    currencyCode: proposal?.currency?.code || DEFAULT_CURRENCY_CODE,
    proposedRoomRate: eventSpace.proposedRoomRate || 0,
    proposedFbMinimum: eventSpace.proposedFbMinimum || 0,
    proposedRatePerPerson: eventSpace.proposedRatePerPerson || 0,
    proposedMinGuests: eventSpace.proposedMinGuests || 0,
    proposedSetupId: eventSpace.proposedSetupId || 0,
    useDdr: Boolean(proposal?.useDdr),
    proposedAvIds: eventSpace.proposedAvIds || [],
    proposedFb: eventSpace.proposedFb || [],
});

type AddSpaceSideDrawerProps = {
    drawerOpen: boolean;
    eventSpace: TESBooking;
    proposedVenueSpace?: Bizly.OsEventSpace;
    onClose: () => void;
};

export function AddSpaceDetailsDrawer({
    drawerOpen: isSpaceDetailsDrawerOpen,
    proposedVenueSpace,
    eventSpace,
    onClose: onSpaceDetailsDrawerClose,
}: AddSpaceSideDrawerProps) {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: proposalInquiry, isLoading } = useGetProposalInquiry(venueId, proposalId);

    const proposal = proposalInquiry?.proposal;
    const [editingVenueSpaceId, setEditingVenueSpaceId] = useState(proposedVenueSpace?.id);
    const [newProposedVenueSpace, setNewProposedVenueSpace] = useState<Bizly.OsEventSpace | undefined>(
        proposedVenueSpace
    );
    const [isSelectSpaceDrawerOpen, setIsSelectSpaceDrawerOpen] = useState(false);
    const [isAddVenueSpaceDrawerOpen, setIsAddVenueSpaceDrawerOpen] = useState(false);

    const updateProposalInquiry = useUpdateProposalInquiry(venueId, proposalId);
    const { data: venueEventSpaces } = useGetEventSpaceQuery(venueId);

    const defaultValues = useMemo(() => getDefaultValues(proposal || {}, eventSpace), [proposal, eventSpace]);

    const { control, handleSubmit, setValue, watch, reset, formState } = useForm<EventSpaceFormData>({
        resolver: zodResolver(EventSpaceSchema),
        mode: 'onChange',
        defaultValues,
    });

    const [currencyCode, useDdr] = watch(['currencyCode', 'useDdr']);

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const onSave = (data: EventSpaceFormData) => {
        const eventSpaces = [
            {
                ...eventSpace,
                proposedVenueSpaceId: newProposedVenueSpace?.id,
                proposedSetupId: data.proposedSetupId,
                proposedAvIds: data.proposedAvIds || [],
                proposedFb: data.proposedFb || [],
                ...(data.useDdr
                    ? {
                          proposedRatePerPerson: data.proposedRatePerPerson,
                          proposedMinGuests: data.proposedMinGuests,
                      }
                    : {
                          proposedRoomRate: data.proposedRoomRate,
                          proposedFbMinimum: data.proposedFbMinimum,
                      }),
            },
        ];

        updateProposalInquiry.mutate(
            {
                eventSpaces,
                proposal: {
                    ...proposal,
                    useDdr: data.useDdr,
                },
            },
            {
                onSuccess: handleOnClose,
            }
        );
    };

    const onEditVenueSpace = (id: number | undefined) => {
        setEditingVenueSpaceId(id);
        setIsAddVenueSpaceDrawerOpen(true);
    };

    const handleOnClose = () => {
        setNewProposedVenueSpace(proposedVenueSpace);
        reset(defaultValues);
        onSpaceDetailsDrawerClose();
    };

    if (isLoading || !proposalInquiry) return null;

    const isProposedVenueSpaceDirty = newProposedVenueSpace?.id !== proposedVenueSpace?.id;
    const isProposedVenueSpaceValid = Boolean(newProposedVenueSpace);
    const isSaveDisabled = !(
        formState.isValid &&
        isProposedVenueSpaceValid &&
        (formState.isDirty || isProposedVenueSpaceDirty)
    );

    return (
        <>
            <SideDrawer
                drawerOpen={isSpaceDetailsDrawerOpen}
                onClose={handleOnClose}
                title={`${proposedVenueSpace ? 'Edit' : 'Add'} Details & Pricing`}
                footer={
                    <ActionButtonContainer>
                        <Button variant="outline" onClick={handleOnClose}>
                            Cancel
                        </Button>
                        <Button
                            disabled={isSaveDisabled}
                            onClick={handleSubmit(onSave)}
                            loading={updateProposalInquiry.isLoading}
                        >
                            Save
                        </Button>
                    </ActionButtonContainer>
                }
            >
                <Box display="flex" flexDirection="column" gap={2.5}>
                    <ProposedVenueSpace
                        editable
                        proposedVenueSpace={newProposedVenueSpace}
                        onSelectVenueSpace={() => setIsSelectSpaceDrawerOpen(true)}
                        onEditVenueSpace={() => onEditVenueSpace(newProposedVenueSpace?.id)}
                    />
                    <EventSpaceForm
                        control={control}
                        errors={formState.errors}
                        currencyCode={currencyCode}
                        useDdr={useDdr}
                        eventSpace={eventSpace}
                        proposalInquiry={proposalInquiry}
                        setValue={setValue}
                    />
                </Box>
            </SideDrawer>

            <SelectVenueSpaceDrawer
                // (isSpaceDetailsDrawerOpen && !newProposedVenueSpace) is used to open the SelectVenueSpaceDrawer by default
                // when the SpaceDetailsDrawer is open and no ProposedVenueSpace has been selected.
                drawerOpen={isSelectSpaceDrawerOpen || (isSpaceDetailsDrawerOpen && !newProposedVenueSpace)}
                venueEventSpaces={venueEventSpaces}
                proposedVenueSpace={newProposedVenueSpace}
                setProposedVenueSpace={setNewProposedVenueSpace}
                onEditVenueSpace={onEditVenueSpace}
                onClose={(select = false) => {
                    // When closing the SelectVenueSpaceDrawer, if no ProposedVenueSpace is selected, we also close the SpaceDetailsDrawer.
                    if (!select && !newProposedVenueSpace) {
                        onSpaceDetailsDrawerClose();
                    }
                    setIsSelectSpaceDrawerOpen(false);
                }}
                onAddVenueSpace={() => setIsAddVenueSpaceDrawerOpen(true)}
            />

            <VenueSpaceDrawer
                drawerOpen={isAddVenueSpaceDrawerOpen}
                venueEventSpaces={venueEventSpaces}
                proposedVenueSpace={newProposedVenueSpace}
                editingVenueSpaceId={editingVenueSpaceId}
                setProposedVenueSpace={setNewProposedVenueSpace}
                onClose={closeSelectDrawer => {
                    if (closeSelectDrawer) setIsSelectSpaceDrawerOpen(false);
                    setIsAddVenueSpaceDrawerOpen(false);
                    setEditingVenueSpaceId(undefined);
                }}
            />
        </>
    );
}
