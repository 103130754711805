import { Box, Skeleton, styled, Typography } from '@mui/material';
import { QUESTION_TYPES } from 'components/Rsvp-V2/utills';
import CheckboxGroup from 'components/Ui-V2/InputFields/CheckboxGroup';
import { OnOffoSwitch } from 'components/Ui-V2/InputFields/Switch';
import { TabPlanelProps } from 'components/WebRegistration/WebRegistrationContent';
import { WEBREGISTRATION_PREVIEW_IDS } from 'constants/event';
import {
    useCreateParcelsQuestionsMutation,
    useGetParcelsQuestionsQuery,
    useRemoveParcelsQuestionsMutation,
} from 'hooks/queries/useEventsQuery';
import { useLayoutEffect, useState } from 'react';

const OPTIONS = [
    { value: 'vegan', label: 'Vegan' },
    { value: 'vegetarian', label: 'Vegetarian' },
    { value: 'kosher', label: 'Kosher' },
    { value: 'halal', label: 'Halal' },
    { value: 'pescatarian', label: 'Pescatarian' },
    { value: 'gluten_free', label: 'Gluten Free' },
    { value: 'dairy_allergy', label: 'Dairy Allergy' },
    { value: 'egg_allergy', label: 'Egg Allergy' },
    { value: 'peanut_allergy', label: 'Peanut Allergy' },
    { value: 'tree_nut_allergy', label: 'Tree Nut Allergy' },
    { value: 'soy_allergy', label: 'Soy Allergy' },
    { value: 'wheat_allergy', label: 'Wheat Allergy' },
    { value: 'fish_allergy', label: 'Fish Allergy' },
    { value: 'shellfish_allergy', label: 'Shellfish Allergy' },
];

export const BorderedContainer = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    height: '100%',
    border: `1px solid ${getColor(EColors.inactiveAction)}`,
    borderRadius: shape.borderRadius,
    boxSizing: 'border-box',
    padding: spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    transition: 'height 1s ease',
}));
export const BorderedBottom = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    borderBottom: `1px solid ${getColor(EColors.inactiveAction)}`,
    paddingBottom: spacing(2.5),
}));

function DietaryPreferences({ parcel, isDisabled, scrollToElement }: TabPlanelProps) {
    const { data: questions, isLoading } = useGetParcelsQuestionsQuery(parcel.id as number);
    const createParcelsQuestionsMutation = useCreateParcelsQuestionsMutation(parcel.id || 0, () => {});
    const removeParcelsQuestionsMutation = useRemoveParcelsQuestionsMutation(parcel.id || 0);

    const [hasDietaryPreferences, setHasDietaryPreferences] = useState(false);
    const [questionField, setQuestionFeild] = useState<Bizly.ParcelRsvpQuestion>();

    const onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (createParcelsQuestionsMutation.isLoading || removeParcelsQuestionsMutation.isLoading) return;
        scrollToElement(WEBREGISTRATION_PREVIEW_IDS.rsvp);

        setHasDietaryPreferences(event.target.checked);
        if (event.target.checked) {
            createParcelsQuestionsMutation.mutate({
                type: QUESTION_TYPES.dietaryRestrictions,
                value: '',
                required: true,
            });
        } else {
            if (questionField?.id) {
                removeParcelsQuestionsMutation.mutate(questionField.id);
            }
        }
    };

    useLayoutEffect(() => {
        if (questions) {
            const question = questions.find(
                (question: Bizly.ParcelRsvpQuestion) => question.type === QUESTION_TYPES.dietaryRestrictions
            );

            setHasDietaryPreferences(!!question);
            setQuestionFeild(question);
        } else {
            setHasDietaryPreferences(false);
        }
    }, [questions]);

    if (isLoading) {
        return <Skeleton variant="rounded" width="100%" height={126} />;
    }

    return (
        <BorderedContainer>
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={0.5}>
                    <Typography fontWeight={600}>Dietary Preferences & Restrictions</Typography>
                    <OnOffoSwitch checked={hasDietaryPreferences} onChange={onSwitchChange} disabled={isDisabled} />
                </Box>
                <Typography>
                    Collect your guests dietary needs and preferences to ensure everyone has an enjoyable experience.
                </Typography>
            </Box>
            {hasDietaryPreferences && (
                <CheckboxGroup
                    columns={2}
                    error={undefined}
                    options={OPTIONS}
                    onChange={() => {}}
                    disabled
                    ref={null}
                />
            )}
        </BorderedContainer>
    );
}

export default DietaryPreferences;
