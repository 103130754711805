import { useFeatureFlagEnabled } from 'posthog-js/react';
import { PostHogFeatureFlags } from 'shared';
import LegacyEventOverview from './LegacyOverview';
import NewEventOverview from './Overview';
import { TEventDashboard } from './utils';

const EventOverview = (props: TEventDashboard) => {
    const newEventNavEnabled = useFeatureFlagEnabled(PostHogFeatureFlags.toggleNewEventOverview);

    return newEventNavEnabled ? <NewEventOverview {...props} /> : <LegacyEventOverview {...props} />;
};

export default EventOverview;
