import isDate from 'lodash/isDate';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import keyBy from 'lodash/keyBy';

import { isEmptyString } from 'components/ProposalForm/utils';

const isNoResponse = (response?: number | string | string[] | Date) =>
    typeof response === 'string'
        ? isEmptyString(response)
        : !isNumber(response) && isEmpty(response) && !isDate(response);

export const getSurveyQuestionError = (surveyQuestions: Bizly.SurveyQuestion[], responses?: Bizly.SurveyQuestion[]) => {
    const getError = (surveyQuestion?: Bizly.SurveyQuestion) =>
        surveyQuestion ? (
            <span>
                A response is required for the question:
                <br />
                <br />
                {surveyQuestion.prompt}
            </span>
        ) : (
            false
        );

    const responsesById = keyBy(responses || surveyQuestions, 'id');

    return getError(
        surveyQuestions.find(
            ({ id, responseRequired }) => responseRequired && isNoResponse(responsesById[id]?.response)
        )
    );
};

export const getSurveyQuestionErrorMessage = (
    surveyQuestions: Bizly.SurveyQuestion[],
    responses?: Bizly.SurveyQuestion[]
) => {
    const getError = (surveyQuestion?: Bizly.SurveyQuestion) =>
        surveyQuestion ? `A response is required for the question: ${surveyQuestion.prompt}` : false;

    const responsesById = keyBy(responses || surveyQuestions, 'id');

    return getError(
        surveyQuestions.find(
            ({ id, responseRequired }) => responseRequired && isNoResponse(responsesById[id]?.response)
        )
    );
};
