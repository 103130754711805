import { zodResolver } from '@hookform/resolvers/zod';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, Typography, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { submitEventRegistrationInfo } from 'api';
import { Spinner } from 'components/Spinner';
import { LoadingButton } from 'components/Ui-V2/Button/Button';
import CheckboxGroup from 'components/Ui-V2/InputFields/CheckboxGroup';
import DatePicker from 'components/Ui-V2/InputFields/DatePicker';
import RadioGroup from 'components/Ui-V2/InputFields/RadioGroup';
import TextArea from 'components/Ui-V2/InputFields/TextArea';
import { useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { EColors, getColor } from 'theme';
import { tzMoment } from 'utils/moment';
import * as z from 'zod';
import GuestDetails from './GuestDetails';
import LinearScale from './LinearScale';
import RoomBlocksQuestion from './RoomBlocksQuestion';
import { QUESTION_TYPES, generateFormSchema, getTypeById } from './utills';

const DIETARY_PREFIX = 'dietary';
const ALLERGY_PREFIX = 'allergy';

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    zIndex: theme.zIndex.modal + 1, // Ensures the Drawer appears above the Dialog
}));

const DrawerContainer = styled(Box)(({ theme }) => ({
    width: '500px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(1020)]: {
        width: '100vw',
    },
}));

const DrawerHeader = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(2.5),
    borderBottom: '0.5px solid ' + getColor(EColors.inactiveAction),
    boxSizing: 'border-box',
    transition: '0.3s all ease-in-out',
    backgroundColor: getColor(EColors.pureWhite),
}));

const StyledLoadingButton = styled(LoadingButton)(({ theme: { getColor, EColors } }) => ({
    '&.MuiButton-root.MuiButton-contained': {
        backgroundColor: getColor(EColors.pureBlack),
        color: getColor(EColors.pureWhite),
    },

    '&.MuiButton-root.MuiButton-contained:hover': {
        backgroundColor: getColor(EColors.pureBlack, 0.8),
    },
    '&.MuiButton-root.MuiButton-contained.Mui-disabled': {
        backgroundColor: getColor(EColors.pureBlack, 0.12),
        color: getColor(EColors.pureBlack, 0.26),
    },
}));

const DrawerBody = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    position: 'relative',
    padding: theme.spacing(2.5),
    gap: theme.spacing(2),
}));

const DrawerFooter = styled(Box)(({ theme: { spacing, getColor, EColors } }) => ({
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: spacing(2.5),
    gap: spacing(1),
    borderTop: '0.5px solid ' + getColor(EColors.inactiveAction),
}));

const QuestionContainer = styled(Box)(({ theme: { spacing, shape, getColor, EColors } }) => ({
    border: `0.5px solid ${getColor(EColors.inactiveAction)}`,
    borderRadius: shape.borderRadius,
    boxSizing: 'border-box',
    padding: spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1),
}));

type RsvpModalProps = {
    open: boolean;
    isLoading: boolean;
    event: Bizly.Event;
    attendee: Bizly.EventAttendee;
    formSettings: Bizly.FormSetting;
    eventId: string;
    slugId: string;
    preview?: boolean;
    isSubmitted?: boolean;
    onClose: () => void;
    handleSuccessfulSubmit?: () => void;
};

export default function RsvpDrawer({
    open,
    formSettings,
    attendee,
    event,
    isLoading,
    eventId,
    slugId,
    preview = false,
    isSubmitted = false,
    onClose,
    handleSuccessfulSubmit,
}: RsvpModalProps) {
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const dietaryRestrictionsEnable = Boolean(
        formSettings && formSettings?.defaultFieldsEnabled?.find(f => f.name === 'dietary_restrictions')
    );
    let dietaryRestrictionsOptions: { value: number | string; label: string }[] = [];
    if (dietaryRestrictionsEnable) {
        const restrictions = formSettings.dietaryRestrictionOptions.map(option => ({
            value: `${DIETARY_PREFIX}-${option.id}`,
            label: option.name,
        }));

        const allergies = formSettings.foodAllergyOptions.map(option => ({
            value: `${ALLERGY_PREFIX}-${option.id}`,
            label: option.name,
        }));
        dietaryRestrictionsOptions = [...restrictions, ...allergies];
    }
    const roomBlockEnabled = Boolean(attendee?.roomBlockEnabled);

    //TODO: check why customQuestions can either be an array or an object
    const customQuestions: Bizly.SurveyQuestion[] = formSettings
        ? Array.isArray(formSettings?.customQuestions)
            ? formSettings?.customQuestions
            : Object.values(formSettings?.customQuestions)
        : [];

    const { FormSchema, defaultValues } = generateFormSchema({
        dietaryRestrictionsEnable,
        roomBlockEnabled,
        customQuestions,
    });
    type FormData = z.infer<typeof FormSchema>;

    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<FormData>({
        resolver: zodResolver(FormSchema),
        defaultValues,
    });

    const onSubmit = async (data: FormData) => {
        try {
            setLoading(true);
            const dietary_restrictions: number[] = [];
            const food_allergies: number[] = [];

            if (data.dietaryRestrictions) {
                data.dietaryRestrictions.map((id: string) => {
                    if (id.includes(DIETARY_PREFIX)) {
                        dietary_restrictions.push(parseInt(id.split('-')[1]));
                    }
                    if (id.includes(ALLERGY_PREFIX)) {
                        food_allergies.push(parseInt(id.split('-')[1]));
                    }
                });
            }

            const newData: { [key: string]: unknown } = {
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                check_in_date: data.checkInDate,
                check_out_date: data.checkOutDate,
                status: 'attending',
                is_eu: false,
                gdpr_optin: false,
                rsvp_comments: data.rsvpComments || '',
                attendance_type: 'In-person',
                dietary_restrictions: dietary_restrictions,
                food_allergies: food_allergies,
                preferred_room_type: data.preferredRoomType || 'single',
            };

            const custom_question_responses: { id: number; response: string }[] = [];

            customQuestions.forEach(q => {
                custom_question_responses.push({
                    id: q.id,
                    response: data[q.id.toString()],
                });
            });

            newData.custom_question_responses = custom_question_responses;

            const result = await submitEventRegistrationInfo(eventId, slugId, newData);
            if (!result.success) {
                throw new Error(result.message);
            }
            handleSuccessfulSubmit?.();
        } catch (error) {
            setErrorMessage(error as string);
            setTimeout(() => {
                setErrorMessage('');
            }, 5000);
        } finally {
            setLoading(false);
        }
    };

    const getQuestionBody = (questionField: Bizly.SurveyQuestion) => {
        const type = getTypeById(questionField.definition.id);
        switch (type) {
            case QUESTION_TYPES.text:
                return (
                    <QuestionContainer key={questionField.id}>
                        <Controller
                            name={questionField.id.toString()}
                            control={control}
                            render={({ field, fieldState }) => {
                                return (
                                    <TextArea
                                        {...field}
                                        label={questionField.prompt}
                                        placeholder="Answer"
                                        error={fieldState.error as FieldError}
                                        required={questionField.responseRequired}
                                        minRows={2}
                                        maxRows={2}
                                        disabled={isSubmitted || preview}
                                    />
                                );
                            }}
                        />
                    </QuestionContainer>
                );
            case QUESTION_TYPES.checkBox: {
                const options = (questionField.options || []) as string[];
                return (
                    <QuestionContainer key={questionField.id}>
                        <Controller
                            name={questionField.id.toString()}
                            control={control}
                            render={({ field, fieldState }) => {
                                return (
                                    <CheckboxGroup
                                        {...field}
                                        values={field.value}
                                        label={questionField.prompt}
                                        error={fieldState.error?.message}
                                        options={options.map(option => ({ value: option, label: option }))}
                                        columns={1}
                                        required={questionField.responseRequired}
                                        disabled={isSubmitted || preview}
                                    />
                                );
                            }}
                        />
                    </QuestionContainer>
                );
            }
            case QUESTION_TYPES.radio: {
                const options = (questionField.options || []) as string[];
                return (
                    <QuestionContainer key={questionField.id}>
                        <Controller
                            name={questionField.id.toString()}
                            control={control}
                            render={({ field, fieldState }) => {
                                return (
                                    <RadioGroup
                                        {...field}
                                        label={questionField.prompt}
                                        error={fieldState.error?.message}
                                        options={options.map(option => ({ value: option, label: option }))}
                                        required={questionField.responseRequired}
                                        disabled={isSubmitted || preview}
                                    />
                                );
                            }}
                        />
                    </QuestionContainer>
                );
            }
            case QUESTION_TYPES.linearScale:
                return (
                    <QuestionContainer key={questionField.id}>
                        <Controller
                            name={questionField.id.toString()}
                            control={control}
                            render={({ field, fieldState }) => {
                                return (
                                    <LinearScale
                                        {...field}
                                        error={fieldState.error?.message}
                                        questionField={questionField}
                                        disabled={isSubmitted || preview}
                                    />
                                );
                            }}
                        />
                    </QuestionContainer>
                );
            case QUESTION_TYPES.date:
                return (
                    <QuestionContainer key={questionField.id}>
                        <Controller
                            name={questionField.id.toString()}
                            control={control}
                            render={({ field, fieldState }) => {
                                return (
                                    <DatePicker
                                        {...field}
                                        value={field.value || ''}
                                        label={questionField.prompt}
                                        error={fieldState.error}
                                        required={questionField.responseRequired}
                                        disabled={isSubmitted || preview}
                                        timeZone={event?.timeZone}
                                    />
                                );
                            }}
                        />
                    </QuestionContainer>
                );
            default:
                return null;
        }
    };

    const watchedFields = watch();

    return (
        <StyledDrawer anchor="right" open={open} onClose={onClose}>
            <DrawerContainer>
                <DrawerHeader>
                    <Typography fontSize={18} lineHeight="100%" fontWeight={600}>
                        RSVP
                    </Typography>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            color: getColor(EColors.primaryAction),
                            backgroundColor: getColor(EColors.primaryAction, 0.1),
                            width: '30px',
                            height: '30px',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DrawerHeader>

                {!isLoading ? (
                    <DrawerBody>
                        <QuestionContainer>
                            <GuestDetails control={control} disabled={isSubmitted || preview} />
                        </QuestionContainer>

                        {dietaryRestrictionsEnable && (
                            <QuestionContainer>
                                <Box display="flex" gap={0.5}>
                                    <Typography fontWeight={600}>Dietary Preferences & Restrictions</Typography>
                                    <Typography color={getColor(EColors.pureBlack, 0.5)}>(required)</Typography>
                                </Box>
                                <Controller
                                    name="dietaryRestrictions"
                                    control={control}
                                    render={props => {
                                        const { field } = props;
                                        return (
                                            <CheckboxGroup
                                                {...field}
                                                values={field.value}
                                                error={errors.dietaryRestrictions?.message as string}
                                                columns={2}
                                                label="Select your dietary needs and preferences."
                                                options={dietaryRestrictionsOptions}
                                                disabled={isSubmitted || preview}
                                            />
                                        );
                                    }}
                                />
                            </QuestionContainer>
                        )}

                        {roomBlockEnabled && (
                            <QuestionContainer>
                                <RoomBlocksQuestion
                                    control={control}
                                    dueDate={tzMoment(attendee.rsvpDueAt, event?.timeZone)}
                                    disabled={isSubmitted || preview}
                                    timeZone={event?.timeZone}
                                    checkInDate={watchedFields.checkInDate}
                                />
                            </QuestionContainer>
                        )}

                        {customQuestions.map(getQuestionBody)}
                    </DrawerBody>
                ) : (
                    <DrawerBody>
                        <Spinner />
                    </DrawerBody>
                )}

                {!isSubmitted && (
                    <DrawerFooter>
                        {errorMessage && (
                            <Typography variant="body2" color={getColor(EColors.red)}>
                                {errorMessage}
                            </Typography>
                        )}
                        <StyledLoadingButton
                            loading={loading}
                            disabled={preview}
                            fullWidth
                            variant="contained"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Register for Event
                        </StyledLoadingButton>
                    </DrawerFooter>
                )}
            </DrawerContainer>
        </StyledDrawer>
    );
}
