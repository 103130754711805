import HowToPopper from 'components/HowToPopper/HowToPopper';
import EmptyVisual from 'components/Ui-V2/EmptyVisual';
import { Spacer } from 'components/Ui-V2/Spacer/Spacer';
import { PageHeadline } from 'components/ui/Headline';
import EmptyPageImage from 'images/empty-pages-assets/empty_contracts.png';
import { i18n } from 'translation';
import { Row } from 'ui';

export const EmptyState = () => (
    <>
        <Row alignItems="center">
            <PageHeadline withDescription>{i18n.venue.proposal.contracting}</PageHeadline>
            <HowToPopper sectionId="contracting" />
        </Row>
        <Spacer largest />
        <EmptyVisual
            image={<img src={EmptyPageImage} alt="Empty Contracts" />}
            title={i18n.venue.proposal.emptyContractTitle}
            description={i18n.venue.proposal.emptyContractDescription}
        />
    </>
);
