import { InquiryProposalBody } from './InquiryProposalBody/InquiryProposalBody';
import { ProposalAction } from './ProposalAction/ProposalAction';

export function InquiryProposal() {
    return (
        <>
            <InquiryProposalBody />
            <ProposalAction />
        </>
    );
}
