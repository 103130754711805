import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, styled } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { ImageWithSkeleton } from 'components/BizlyOS/Image/ImageWithSkeleton';
import { IMPERIAL_UNIT, METRIC_UNIT } from 'components/BizlyOS/Proposals/utils';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import VenuePlaceholderImage from 'images/bizlyOS/empty/empty-image-placeholder.png';

const Text = styled(Body2)(({ theme: { getColor, EColors } }) => ({
    fontWeight: 500,
    color: getColor(EColors.pureBlack),
    '& > span': {
        fontWeight: 400,
    },
}));

const ContainerWithBottomBorder = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    gap: spacing(2.5),
    paddingBottom: spacing(2.5),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

const SelectButton = styled(Button)({
    height: 30,
    '& .MuiButton-endIcon': { marginLeft: '2px' },
});
type ProposedVenueSpaceProps = {
    proposedVenueSpace?: Bizly.OsEventSpace;
} & (
    | { editable: true; onSelectVenueSpace: () => void; onEditVenueSpace: () => void }
    | { editable?: false; onSelectVenueSpace?: () => void; onEditVenueSpace?: () => void }
);

export const ProposedVenueSpace = ({
    editable = false,
    proposedVenueSpace,
    onSelectVenueSpace,
    onEditVenueSpace,
}: ProposedVenueSpaceProps) => {
    if (!proposedVenueSpace) {
        return (
            <ContainerWithBottomBorder sx={{ alignItems: 'center' }}>
                <Body1 flex={1} fontWeight={500}>
                    Select your venue space
                </Body1>
                <SelectButton variant="outline" onClick={onSelectVenueSpace} endIcon={<ArrowForwardIcon />}>
                    Select
                </SelectButton>
            </ContainerWithBottomBorder>
        );
    }

    return (
        <ContainerWithBottomBorder>
            <ImageWithSkeleton
                src={proposedVenueSpace?.images[0]?.srcUrl || VenuePlaceholderImage}
                alt={proposedVenueSpace.name}
                width="130"
                height="100"
            />
            <Box flex={1} height={100} display="flex" flexDirection="column" gap={0.5} overflow="hidden">
                <Body1 fontWeight={600} lineHeight="21px" noWrap>
                    {proposedVenueSpace.name}
                </Body1>
                {!editable && (
                    <Text>
                        Type: <span>{proposedVenueSpace.type.name || '-'}</span>
                    </Text>
                )}
                <Text>
                    Size:{' '}
                    <span>
                        {proposedVenueSpace.size} {proposedVenueSpace.metric ? METRIC_UNIT : IMPERIAL_UNIT}
                    </span>
                </Text>
                <Text>
                    Max Capacity: <span>{proposedVenueSpace.maxCapacity || '-'}</span>
                </Text>
                {editable && (
                    <Button variant="text" sx={{ padding: 0, width: 80 }} onClick={onEditVenueSpace}>
                        Edit Space
                    </Button>
                )}
            </Box>
            {editable && (
                <Button variant="outline" onClick={onSelectVenueSpace}>
                    Change
                </Button>
            )}
        </ContainerWithBottomBorder>
    );
};
