import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { Box, styled, Typography } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers-pro';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { FieldError } from 'react-hook-form';
import { EColors, getColor } from 'theme';
import { tzMoment } from 'utils/moment';

const StyledDatePicker = styled(MuiDatePicker, {
    shouldForwardProp: prop => prop !== 'iconColor' && prop !== 'showDefaultError' && prop !== 'showEmptyError',
})(({ iconColor, disabled, showDefaultError, showEmptyError, error, theme: { getColor, EColors } }) => ({
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: '4px',
    '& .MuiInputBase-root > input': {
        fontSize: '0.875rem',
        padding: '13.5px',
    },
    '& .MuiInputBase-root > .MuiOutlinedInput-notchedOutline': {
        borderRadius: '4px',
        borderColor:
            error || (showDefaultError && showEmptyError)
                ? getColor(EColors.red)
                : !showDefaultError
                  ? getColor(EColors.bizlyOSBorder) // Disabling the default error indicator
                  : undefined,
    },
    '& .MuiInputBase-root .MuiIconButton-root': {
        color: disabled ? getColor(EColors.bizlyOSBorder) : iconColor,
    },
    '& .MuiInputBase-root .MuiIconButton-root > .MuiSvgIcon-root': {
        fontSize: '1.25rem',
    },
}));

type DatePickerProps = {
    label?: string;
    error: FieldError | undefined;
    showDefaultError?: boolean;
    showEmptyError?: boolean;
    value: string | undefined;
    timeZone?: string;
    disabled?: boolean | undefined;
    required?: boolean;
    iconColor?: string;
    disablePast?: boolean;
    onChange?: (dateText: string) => void;
    onAccept?: (dateText: string) => void;
    shouldDisableDate?: (date: Moment) => boolean;
};

const DatePicker = React.forwardRef<HTMLInputElement, DatePickerProps>(
    (
        {
            label,
            error,
            disabled = false,
            required = false,
            disablePast = false,
            value: date,
            iconColor = getColor(EColors.bizlyOSPrimary),
            timeZone = 'UTC',
            showDefaultError = false,
            showEmptyError = false,
            onChange,
            onAccept,
            shouldDisableDate,
        },
        ref
    ) => {
        const [openPicker, setOpenPicker] = useState(false);

        const onClosePicker = () => setOpenPicker(false);

        const onOpenPicker = () => setOpenPicker(true);

        return (
            <Box>
                {label && (
                    <Box display="flex" gap={0.5} marginBottom={1}>
                        <Typography fontWeight={500}>{label}</Typography>

                        <Typography color={getColor(EColors.pureBlack, 0.5)} fontWeight={500}>
                            {required ? '(required)' : ''}
                        </Typography>
                    </Box>
                )}
                <StyledDatePicker
                    inputRef={ref}
                    iconColor={iconColor}
                    value={date ? tzMoment(date, timeZone) : null}
                    error={!!error}
                    disabled={disabled}
                    disablePast={disablePast}
                    showDefaultError={showDefaultError}
                    showEmptyError={showEmptyError && !date}
                    onChange={(value: Moment) => {
                        onChange?.(value.format('YYYY-MM-DD'));
                    }}
                    onAccept={(value: Moment) => onAccept?.(value.format('YYYY-MM-DD'))}
                    shouldDisableDate={shouldDisableDate}
                    open={openPicker}
                    onClose={onClosePicker}
                    onOpen={onOpenPicker}
                    slots={{
                        openPickerIcon: CalendarMonthOutlinedIcon,
                    }}
                    slotProps={{
                        textField: {
                            onClick: onOpenPicker,
                        },
                    }}
                />

                {!!error && (
                    <Typography variant="body2" color={getColor(EColors.red)} fontWeight={500}>
                        {error.message}
                    </Typography>
                )}
            </Box>
        );
    }
);

export default DatePicker;
