import * as Sentry from '@sentry/react';
import { putProposalForm } from 'api';
import { getAllProposals, getProposalForVenue } from 'api/bizlyOS';
import { TProposalForm } from 'components/ProposalForm/types';
import { sortBy } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { z } from 'zod';

const ProposalSchema = z.object({
    id: z.number(),
    venue: z.object({
        id: z.number(),
        name: z.string(),
        type: z.string(),
    }),
    assignedTo: z
        .object({
            id: z.number().nullable(),
            firstName: z.string().nullable(),
            lastName: z.string().nullable(),
            title: z.string().nullable(),
        })
        .nullable(),
    client: z.object({
        id: z.number(),
        firstName: z.string(),
        lastName: z.string(),
    }),
    company: z.object({
        id: z.number(),
        name: z.string(),
    }),
    event: z.object({
        id: z.number(),
        name: z.string(),
        startsAt: z.string().nullable(),
        endsAt: z.string().nullable(),
    }),
    inquiry: z.object({
        id: z.number(),
        startsAt: z.string(),
        endsAt: z.string(),
        submittedAt: z.string(),
    }),
    status: z.object({
        friendlyName: z.enum([
            'All',
            'New Inquiry',
            'Proposal Sent',
            'Won',
            'Lost',
            'Inquiry Rejected',
            'Inquiry Withdrawn',
            'Proposal Withdrawn',
            'Proposal Expired',
            'Inquiry Expired',
        ]),
    }),
    expiryDate: z.string().nullable(),
    createdAt: z.string(),
    updatedAt: z.string(),
    inquiredAt: z.string().nullable(),
    sentAt: z.string().nullable(),
    totals: z.object({
        guestRoomNights: z.number(),
        eventSpaces: z.number(),
    }),
});

const ProposalApiResponseSchema = z.object({
    proposals: z.array(ProposalSchema),
});

export interface ProposalRow {
    id: string;
    inquiryId: string;
    dateReceived: string;
    lastContact: string;
    customer: string;
    company: string;
    event: string;
    eventStartDate: string | null;
    eventEndDate: string | null;
    guestsRoomNights: number;
    eventSpaces: number;
    status: StatusType;
    venueId: number;
    venueName: string;
}

type ProposalResponse = z.infer<typeof ProposalApiResponseSchema>;

export const PROPOSAL_QUERIES = {
    PROPOSALS: 'proposals',
    PROPOSAL_INQURY: 'proposal_inqury',
} as const;

const getProposals = async (): Promise<ProposalResponse> => {
    try {
        const response = await getAllProposals();
        const parsedResponse = ProposalApiResponseSchema.safeParse(response);

        if (!parsedResponse.success) {
            console.error('Validation Errors:', parsedResponse.error.errors);
            throw new Error('Invalid response format');
        }

        return parsedResponse.data;
    } catch (error) {
        console.error('Error in getProposals:', error);
        throw error;
    }
};

const formatProposalRows = (proposals: ProposalResponse): ProposalRow[] => {
    return proposals.proposals.map(proposal => ({
        id: proposal.id.toString(),
        inquiryId: proposal.inquiry.id.toString(),
        dateReceived: proposal.inquiry.submittedAt,
        lastContact: proposal.updatedAt,
        customer: `${proposal.client.firstName} ${proposal.client.lastName}`,
        company: proposal.company.name,
        event: proposal.event.name,
        eventStartDate: proposal.event.startsAt,
        eventEndDate: proposal.event.endsAt,
        guestsRoomNights: proposal.totals.guestRoomNights,
        eventSpaces: proposal.totals.eventSpaces,
        status: proposal.status.friendlyName,
        venueId: proposal.venue.id,
        venueName: proposal.venue.name,
    }));
};

export const useProposalsQuery = () => {
    const result = useQuery<ProposalResponse, Error>([PROPOSAL_QUERIES.PROPOSALS], getProposals, {
        onError: (error: unknown) => {
            console.error('Error fetching proposals:', error);

            Sentry.captureException(error, {
                tags: {
                    component: 'useProposalsQuery',
                },
            });
        },
    });

    return {
        ...result,
        rows: result.data ? formatProposalRows(result.data) : [],
        isLoading: result.isLoading,
    };
};

export const useGetProposalInquiry = (
    venueId: string,
    proposalId: string,
    onSuccess?: (data: TProposalForm) => void
) => {
    const result = useQuery({
        queryKey: [PROPOSAL_QUERIES.PROPOSAL_INQURY, venueId, proposalId],
        queryFn: () => getProposalForVenue(venueId, proposalId),
        onSettled(data) {
            if (!data) return {};
            data.options.currencies = data.options.currencies && sortBy(data.options.currencies, 'code');
            return data;
        },
        onSuccess: data => {
            if (onSuccess) onSuccess(data);
        },
        onError: (error: unknown) => {
            console.error('Error fetching ProposalInquiry:', error);

            Sentry.captureException(error, {
                tags: { component: 'useGetProposalInquiry' },
                extra: { venueId, proposalId },
            });
        },
        enabled: Boolean(venueId && proposalId),
        staleTime: Infinity,
    });

    return result;
};

export const useUpdateProposalInquiry = (venueId: string, proposalId: string, onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    return useMutation<TProposalForm & { success?: boolean }, Error, Partial<TProposalForm>>(
        listing => putProposalForm(venueId, proposalId, listing),
        {
            onSuccess: response => {
                if (response.success) {
                    queryClient.invalidateQueries([PROPOSAL_QUERIES.PROPOSAL_INQURY, venueId, proposalId]);
                    onSuccess?.();
                }
            },
            onError: (error: Error) => {
                console.error('Error updating ProposalInquiry:', error);

                Sentry.captureException(error, {
                    tags: {
                        component: 'useUpdateProposalInquiry',
                        venueId,
                        proposalId,
                    },
                    extra: {
                        venueId,
                        proposalId,
                    },
                });
            },
        }
    );
};
