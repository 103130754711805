import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, ButtonProps, Dialog, DialogContent, styled, Typography } from '@mui/material';
import { publishParcel } from 'api';
import { Button, LoadingButton } from 'components/Ui-V2/Button/Button';
import MirageLoader from 'components/Ui-V2/Loaders/MirageLoader';
import { WebRegistrationPreviewIdsType } from 'constants/event';
import { useSnackbar } from 'notistack';
import { EditParcelState, ParcelFormState, TEventParcelContext } from 'pages/EditParcel/EditParcel';
import { useEffect, useRef, useState } from 'react';
import { validateRichText } from 'utils/validation';
import { z } from 'zod';
import PublishedConfirmationModal from './PublishedConfirmationModal';
import UnpublishedConfirmationModal from './UnpublishedConfirmationModal';
import WebRegistrationContent from './WebRegistrationContent';
import WebRegistrationPreview from './WebRegistrationPreview';

const WideDialogContent = styled(DialogContent)(({ theme: { getColor, EColors } }) => ({
    backgroundColor: getColor(EColors.pureWhite),
    width: '100%',
    height: '100%',
    padding: '0px',
    display: 'grid',
    gridTemplateColumns: '500px 1fr',
    gridTemplateRows: '60px 1fr',
}));

const BackIcon = styled(KeyboardBackspaceIcon)(({ theme: { getColor, EColors } }) => ({
    fontSize: '1rem',
    backgroundColor: getColor(EColors.primaryAction, 0.1),
    borderRadius: '50%',
    padding: '2px',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    '&:hover': {
        backgroundColor: getColor(EColors.primaryAction, 0.2),
    },
}));

const Header = styled(Box)(({ theme: { spacing } }) => ({
    gridColumn: 'span 2',
    boxSizing: 'border-box',
    padding: '0px ' + spacing(2.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0px 4px 4px 0px #DBDBDB40',
}));

const PublishButton = styled(LoadingButton)({
    width: '120px',
    height: '40px',
    '&.MuiButton-containedPrimary': { marginRight: 0 },
});

const CopyLinkButton = styled((props: ButtonProps) => (
    <Button variant="text" startIcon={<ContentCopyIcon />} {...props} />
))({
    height: '40px',
});

const ContentFormSchema = z.object({
    subject: z.string().min(1, 'Required'),
    content: z.string().min(11, 'Required'), // 11 is the minimum length of the content because it's the minimum length of the content in the rich text editor with html eg: <p>txt</p>.
    customImage: z.string().optional(),
    customLogo: z.string().optional(),
    startDate: z.string().min(1, 'Required'),
    startTime: z.string().min(1, 'Required'),
    endDate: z.string().min(1, 'Required'),
    endTime: z.string().min(1, 'Required'),
    locationName: z.string().min(1, 'Required'),
    roomBlockEnabled: z.boolean(),
    rsvpDueDate: z.string().min(1, 'Required'),
    rsvpDueTime: z.string().min(1, 'Required'),
    postCutoffContact: z.string().email().optional(),
});

type ContentFormData = z.infer<typeof ContentFormSchema>;

export type ErrorType = { [key in keyof ContentFormData]?: { message: string; type: string } };

export type HandleSaveType = (fieldName: keyof ContentFormData, values?: Partial<ParcelFormState>) => void;

type WebRegistrationModalType = {
    open: boolean;
    loading: boolean;
    state: EditParcelState;
    context: TEventParcelContext;
    isPublished: boolean;
    onClose: () => void;
    onParcelDelete: () => void;
    onSave: (values?: Partial<ParcelFormState>) => void;
    onPublish: () => void;
    onCopyLink: () => void;
    handleChange: <K extends keyof ParcelFormState>(value: ParcelFormState[K], field: K) => void;
};

function WebRegistrationModal({
    open,
    state,
    context,
    loading,
    isPublished: _isPublished,
    onSave,
    onClose,
    handleChange,
    onCopyLink,
    onParcelDelete,
}: WebRegistrationModalType) {
    const { parcel } = state;
    const [isPublishing, setIsPublishing] = useState<boolean>(false);
    const [publishedConfirmation, setPublishedConfirmation] = useState<boolean>(false);
    const [isPublished, setIsPublished] = useState<boolean>(_isPublished);
    const [unpublishedConfirmation, setUnpublishedConfirmation] = useState<boolean>(false);

    const [errors, setErrors] = useState<ErrorType>({});

    const { enqueueSnackbar } = useSnackbar();

    const previewRef = useRef<HTMLElement>(null);

    useEffect(() => {
        setIsPublished(_isPublished);
    }, [_isPublished, setIsPublished]);

    const handleSave: HandleSaveType = (fieldName, values) => {
        // using the provided value if it's available, since there might be a delay in updating the parcel.
        let value = values ? values[fieldName] : parcel[fieldName];

        if (fieldName === 'customImage' || fieldName === 'customLogo') {
            value = values ? values[fieldName]?.url : parcel[fieldName]?.url;
        }

        if (fieldName === 'content') {
            if (!validateRichText(parcel.content || '')) value = '';
        }
        try {
            // Validate single field
            ContentFormSchema.shape[fieldName].parse(value);
            onSave(values);
            setErrors(prev => ({ ...prev, [fieldName]: undefined })); // Clear error if valid
        } catch (e) {
            if (e instanceof z.ZodError) {
                setErrors(prev => ({ ...prev, [fieldName]: { message: e.errors[0].message, type: 'required' } })); // Set error message
            }
        }
    };

    const validateAllFields = (values: Partial<ContentFormData>) => {
        try {
            // Validate selected fields
            const Fields = ContentFormSchema.pick({
                subject: true,
                content: true,
                startDate: true,
                startTime: true,
                endDate: true,
                endTime: true,
                locationName: true,
                ...(parcel.roomBlockEnabled ? { rsvpDueDate: true, rsvpDueTime: true } : {}),
            });

            Fields.parse(values);
            setErrors({});
        } catch (e) {
            if (e instanceof z.ZodError) {
                const newErrors = e.errors.reduce((acc: Record<string, { message: string; type: string }>, error) => {
                    acc[error.path[0]] = { message: error.message, type: 'required' };
                    return acc;
                }, {});
                setErrors(newErrors);
                throw Error('Please ensure all required fields are filled out!');
            }
        }
    };

    const scrollToElement = (elementId: WebRegistrationPreviewIdsType) => {
        if (previewRef.current) {
            const previewSection = previewRef.current.querySelector(`#${elementId}`);

            if (previewSection) {
                previewSection.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
    };

    const onPublish = async () => {
        try {
            validateAllFields({
                subject: parcel.subject || '',
                content: parcel.content || '',
                startDate: parcel.startDate || '',
                startTime: parcel.startTime || '',
                endDate: parcel.endDate || '',
                endTime: parcel.endTime || '',
                locationName: parcel.locationName || '',
                customImage: parcel.customImageUrl || '',
                customLogo: parcel.customLogoUrl || '',
                rsvpDueDate: parcel.rsvpDueDate || '',
                rsvpDueTime: parcel.rsvpDueTime || '',
            });
            setIsPublishing(true);
            const { id: eventId } = context;
            await publishParcel(eventId, parcel.id);
            setPublishedConfirmation(true);
            setIsPublished(true);
        } catch (error) {
            if (error instanceof Error) {
                enqueueSnackbar(error.message, {
                    variant: 'error',
                });
            }
        } finally {
            setIsPublishing(false);
        }
    };

    const onUnpublishConfirmation = async () => {
        setUnpublishedConfirmation(true);
    };

    return (
        <Dialog open={open} fullScreen transitionDuration={0}>
            {loading ? (
                <MirageLoader size="5rem" isfullScreen />
            ) : (
                <WideDialogContent>
                    <Header>
                        <Box display="flex" alignItems="center" gap={2.5}>
                            <BackIcon onClick={onClose} />
                            <Typography variant="h6" fontWeight={600}>
                                {parcel.name || ''}
                            </Typography>
                        </Box>
                        {isPublished ? (
                            <Box display="flex" alignItems="center" gap={0.5}>
                                <CopyLinkButton onClick={onCopyLink}>Copy Link</CopyLinkButton>
                                <PublishButton onClick={onUnpublishConfirmation} variant="outlined" color="error">
                                    Delete
                                </PublishButton>
                            </Box>
                        ) : (
                            <Box display="flex" alignItems="center" gap={0.5}>
                                <PublishButton variant="contained" onClick={onPublish} loading={isPublishing}>
                                    Publish
                                </PublishButton>
                            </Box>
                        )}
                    </Header>

                    <WebRegistrationContent
                        state={state}
                        context={context}
                        isPublished={isPublished}
                        errors={errors}
                        onSave={handleSave}
                        handleChange={handleChange}
                        scrollToElement={scrollToElement}
                    />
                    <WebRegistrationPreview state={state} context={context} previewRef={previewRef} />
                </WideDialogContent>
            )}

            <PublishedConfirmationModal
                open={publishedConfirmation}
                onClose={() => setPublishedConfirmation(false)}
                onCopyLink={onCopyLink}
            />

            <UnpublishedConfirmationModal
                open={unpublishedConfirmation}
                onClose={() => setUnpublishedConfirmation(false)}
                onUnpublish={onParcelDelete}
                onDone={onClose} //onClose is used here to navigate back to the main web registration page
            />
        </Dialog>
    );
}

export default WebRegistrationModal;
