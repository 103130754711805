import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import { Box } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { useNavigate } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { INQUIRY_PROPOSAL_PATH } from '../../InquirySubNavigation';

export const ProposalSent = ({
    startWithdrawProcess,
    venueId,
    proposalId,
}: {
    startWithdrawProcess: () => void;
    venueId: string;
    proposalId: string;
}) => {
    const navigate = useNavigate();

    const navigateToProposal = () => {
        if (venueId && proposalId) {
            navigate(`/os/venues/${venueId}/proposals/${proposalId}/${INQUIRY_PROPOSAL_PATH}`);
        }
    };

    return (
        <>
            <Box display="flex" flexDirection="row" gap={1.25} marginBottom={1.25}>
                <RequestQuoteOutlinedIcon sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                <Body1 fontWeight={600}>Proposal Sent!</Body1>
            </Box>
            <Body2 marginBottom={2.5}>
                Your proposal is in the client's hands. We'll keep you updated on their decision.
            </Body2>
            <Button
                fullWidth
                size="small"
                sx={{ marginBottom: '.625rem', height: '2.5rem' }}
                onClick={navigateToProposal}
            >
                View Proposal
            </Button>
            <Button fullWidth variant="outline" color="error" sx={{ height: '2.5rem' }} onClick={startWithdrawProcess}>
                Withdraw Proposal
            </Button>
        </>
    );
};
