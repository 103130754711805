import { Box } from '@mui/material';
import SelectField from 'components/BizlyOS/InputFields/SelectField';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { Controller } from 'react-hook-form';
import { BorderedContainer, Description, EventSpaceFormProps, Row, Title } from './utils';

export function SetupRequest({ control, errors, eventSpace, proposalInquiry }: EventSpaceFormProps) {
    const proposedSetup = proposalInquiry.options.spaceSetups.find(setup => setup.id === eventSpace.requestedSetupId);
    return (
        <BorderedContainer>
            <Title>Setup Request(s)</Title>
            <Description>The client is requesting the setup below. Select what you can accommodate.</Description>
            <Row sx={{ marginTop: 2.5 }}>
                <Box>
                    <Body2 fontWeight={500} marginBottom={1}>
                        Client Request
                    </Body2>
                    <Box display="flex" alignItems="center" gap={1.25} height="50px">
                        <img src={proposedSetup?.iconUrl} width="20px" />
                        <Body1>{proposedSetup?.name}</Body1>
                    </Box>
                </Box>
                <Controller
                    name="proposedSetupId"
                    control={control}
                    render={({ field }) => {
                        return (
                            <SelectField
                                {...field}
                                required
                                value={field.value || ''}
                                error={errors.proposedSetupId}
                                label="Your Selection"
                                size="large"
                                options={proposalInquiry.options.spaceSetups.map(option => ({
                                    label: option.name,
                                    value: option.id,
                                    icon: <img src={option.iconUrl} width="20px" />,
                                }))}
                            />
                        );
                    }}
                />
            </Row>
        </BorderedContainer>
    );
}
