import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { Body2 } from 'components/BizlyOS/Typography/Typography';
import { useGetProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { EColors, getColor } from 'theme';
import { DEFAULT_CURRENCY_SYMBOL, calculateTotalRoomCost } from '../../utils';

export const Container = styled(Box)({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});

export const Body = styled(Box)(({ theme: { spacing } }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    overflowY: 'auto',
    padding: spacing(2.5),
}));

export const Footer = styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    padding: spacing(2.5),
}));

const Card = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    padding: spacing(1.25),
    borderRadius: shape.borderRadius,
    border: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1.25),
}));

const CardRow = styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    gap: spacing(1.25),
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const TotalCard = styled(Card)(({ theme: { getColor, EColors } }) => ({
    backgroundColor: getColor(EColors.pureBlack),
}));

const Title = styled(Body2)(({ theme: { getColor, EColors, spacing } }) => ({
    paddingBottom: spacing(1.25),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    fontWeight: 500,
}));

export const Devider = styled(Box)(({ theme: { getColor, EColors } }) => ({
    width: 'calc(100% + 40px)',
    transform: 'translateX(-20px)',
    height: '0.5px',
    backgroundColor: getColor(EColors.bizlyOSBorder),
}));

const InfoContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    display: 'flex',
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    backgroundColor: getColor(EColors.bizlyOSWarning, 0.1),
    gap: spacing(1.25),
}));

export function ProposalSummary() {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: proposalInquiry, isLoading } = useGetProposalInquiry(venueId, proposalId);

    const { roomRateTotal, occupancyTax, totalResortFee, roomblockTotal } = useMemo(() => {
        const guestRooms = proposalInquiry?.guestRooms || [];
        const proposal = proposalInquiry?.proposal;

        const roomRateTotal = calculateTotalRoomCost(proposalInquiry?.guestRooms || []);

        const occupancyTaxValue = proposal?.occupancyTax || 0;
        const occupancyTax = (roomRateTotal / 100) * occupancyTaxValue;

        const totalResortFee = (proposal?.resortFee || 0) * guestRooms.length;

        const roomblockTotal = roomRateTotal + occupancyTax + totalResortFee;

        return { roomRateTotal, occupancyTax, totalResortFee, roomblockTotal };
    }, [proposalInquiry?.guestRooms, proposalInquiry?.proposal]);

    const guestRooms = proposalInquiry?.guestRooms || [];
    const proposal = proposalInquiry?.proposal;
    const currencySymbol = proposal?.currency?.symbol || DEFAULT_CURRENCY_SYMBOL;

    if (isLoading || !proposalInquiry) return null;

    return (
        <Container>
            <Body>
                {guestRooms.length > 0 && (
                    <Card>
                        <Title>Room Block</Title>
                        <CardRow>
                            <Body2>Rate</Body2>
                            <Body2>
                                {currencySymbol}
                                {roomRateTotal.toFixed(2)}
                            </Body2>
                        </CardRow>
                        <CardRow>
                            <Body2>Occupancy Tax ({proposal?.occupancyTax || 0}%)</Body2>
                            <Body2>
                                {currencySymbol}
                                {occupancyTax.toFixed(2)}
                            </Body2>
                        </CardRow>
                        <CardRow>
                            <Body2>Resort Fee</Body2>
                            <Body2>
                                {currencySymbol}
                                {totalResortFee.toFixed(2)}
                            </Body2>
                        </CardRow>
                        <CardRow>
                            <Body2 fontWeight={500}>Room Block Total</Body2>
                            <Body2 fontWeight={500}>
                                {currencySymbol}
                                {roomblockTotal.toFixed(2)}
                            </Body2>
                        </CardRow>
                    </Card>
                )}

                <Card>
                    <Title>Spaces</Title>
                    <CardRow>
                        <Body2>Rate</Body2>
                        <Body2>$0.00</Body2>
                    </CardRow>
                    <CardRow>
                        <Body2>F&B Minimum</Body2>
                        <Body2>$0.00</Body2>
                    </CardRow>
                    <CardRow>
                        <Body2>Sales Tax (0%)</Body2>
                        <Body2>$0.00</Body2>
                    </CardRow>
                    <CardRow>
                        <Body2>Service Charge (0%)</Body2>
                        <Body2>$0.00</Body2>
                    </CardRow>
                    <CardRow>
                        <Body2>Gratuity (0%)</Body2>
                        <Body2>$0.00</Body2>
                    </CardRow>
                    <CardRow>
                        <Body2 fontWeight={500}>Space Total</Body2>
                        <Body2 fontWeight={500}>$0.00</Body2>
                    </CardRow>
                </Card>
            </Body>
            <Footer>
                <TotalCard>
                    <CardRow>
                        <Body2 color={EColors.pureWhite} fontWeight={500}>
                            Proposal Total
                        </Body2>
                        <Body2 color={EColors.pureWhite} fontWeight={500}>
                            $0.00
                        </Body2>
                    </CardRow>

                    {/* 
                    NOTE: Commented for now until the API is ready to handel deposit.
                    <CardRow>
                        <Body2 color={EColors.pureWhite} fontWeight={500}>
                            Deposit (0%)
                        </Body2>
                        <Body2 color={EColors.pureWhite} fontWeight={500}>
                            $0.00
                        </Body2>
                    </CardRow> 
                    */}
                </TotalCard>
                <InfoContainer>
                    <InfoOutlinedIcon fontSize="small" sx={{ color: getColor(EColors.bizlyOSWarning) }} />
                    <Body2>Review and complete all required fields to send your proposal.</Body2>
                </InfoContainer>
                <Devider />
                <Button fullWidth disabled>
                    Send Proposal
                </Button>
            </Footer>
        </Container>
    );
}
