import * as Sentry from '@sentry/react';
import { getVenueDetails } from 'api/venues';
import { useQuery, UseQueryOptions } from 'react-query';
import { StreamResponse, VenueData } from 'types/stream';

const VENUE_DETAILS_QUERY_KEY = 'venue-details';

export const useVenueDetailsQuery = (
    venue: VenueData,
    section: string,
    options?: UseQueryOptions<StreamResponse, Error>
) => {
    return useQuery<StreamResponse, Error>(
        [VENUE_DETAILS_QUERY_KEY, section, venue.name],
        () => getVenueDetails(venue, section),
        {
            onError: (error: Error) => {
                console.error('Error fetching venue details:', error);
                Sentry.captureException(error, {
                    tags: {
                        component: 'useVenueDetailsQuery',
                        venueName: venue.name,
                        section,
                    },
                });
            },
            enabled: !!venue.name,
            refetchOnWindowFocus: false,
            ...options,
        }
    );
};
