import { TFBRequest } from 'components/ProposalForm/EventSpacesForm/utils';
import { TESBooking, TGRBooking, TProposalOptions } from 'components/ProposalForm/types';
import { z } from 'zod';

export const InquiryStatus = {
    SUBMITTED: 'submitted',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
    DECLINING: 'declining', // intermediate state for the decline flow
};

export const DATE_FORMAT = 'MMM D, YYYY';
export const DEFAULT_ROOM_TYPE = 'Single';
export const DEFAULT_CURRENCY_CODE = 'USD';
export const DEFAULT_CURRENCY_SYMBOL = '$';

export const IMPERIAL_UNIT = 'Sq. Ft';
export const METRIC_UNIT = 'Sq. Mt';
export const FIRST_INDEX = 0;
export const SECOND_INDEX = 1;
export const THIRD_INDEX = 2;

export const CURRENCY_MODAL_DESCRIPTION =
    'Update the currency for the entire proposal. Previously entered prices values will not be converted.';

export type TypeOfRoomTypes = 'Single' | 'Double' | 'Suite';

export const hasAllRoomBlockBeenFilled = (guestRooms: TGRBooking[] = []): boolean => {
    // Check if all rooms have required fields filled
    return guestRooms.every(room => room.proposedRoomCount && room.proposedRoomRate);
};

export const hasEventSpacesBeenFilled = (eventSpaces: TESBooking, useDdr = false): boolean => {
    const {
        proposedVenueSpaceId,
        proposedSetupId,
        proposedRoomRate,
        proposedFbMinimum,
        proposedRatePerPerson,
        proposedMinGuests,
    } = eventSpaces;

    // Mandatory fields for all cases
    if (!proposedVenueSpaceId || !proposedSetupId) {
        return false;
    }

    // DDR-specific checks
    if (useDdr) {
        return Boolean(proposedRatePerPerson) && Boolean(proposedMinGuests);
    }

    // Non-DDR checks
    return Boolean(proposedRoomRate) && Boolean(proposedFbMinimum);
};

export const calculateRoomSpaceTotal = (eventSpaces: TESBooking[] = []): number => {
    if (!eventSpaces || eventSpaces.length === 0) return 0;
    return eventSpaces.reduce((accumulator, eventSpace) => accumulator + (eventSpace?.proposedRoomRate || 0), 0);
};

export const calculateFandBMinimum = (eventSpaces: TESBooking[] = []): number => {
    if (!eventSpaces || eventSpaces.length === 0) return 0;
    return eventSpaces.reduce((accumulator, eventSpace) => accumulator + (eventSpace?.proposedFbMinimum || 0), 0);
};

export const calculateTotalRoomCost = (proposals: TGRBooking[]): number => {
    return proposals.reduce((total, proposal) => {
        if (!proposal.proposedRoomCount || !proposal.proposedRoomRate) {
            return total;
        }
        const dailyCost = proposal.proposedRoomCount * proposal.proposedRoomRate;
        return total + dailyCost;
    }, 0);
};

export const getRoomSetup = (options: TProposalOptions | undefined, id: number | undefined | null) => {
    if (!id || !options?.spaceSetups) return '-';

    const roomSetup = options?.spaceSetups.find(rs => rs.id === id);

    if (roomSetup) return roomSetup.name;

    return '-';
};

export const getAvRequests = (options: TProposalOptions | undefined, ids: number[] | undefined | null): string[] => {
    if (!ids || ids.length === 0) return [];

    return ids.map(id => options?.avOptions.find(av => av.id === id)?.name).filter(Boolean) as string[];
};

export const getFbRequests = (
    options: TProposalOptions | undefined,
    fbRequests: TFBRequest[] | undefined
): string[] => {
    if (!fbRequests || fbRequests.length === 0) return [];

    return fbRequests
        .map(request => options?.fbOptions.find(fb => fb.id === request.fbOptionId)?.name)
        .filter(Boolean) as string[];
};

type NumericSchemaOPTionType = {
    min?: number;
    max?: number;
    minMessage?: string;
    maxMessage?: string;
    optional?: boolean;
};

export const numericSchema = (options: NumericSchemaOPTionType = {}) => {
    const {
        min = 0,
        max = Infinity,
        minMessage = 'Must be a positive number',
        maxMessage = 'Exceeds the maximum allowed limit',
        optional = false,
    } = options;

    const schema = z
        .union([
            // Handle strings (allow trimmed non-empty numeric strings)
            optional ? z.string().trim().optional() : z.string().trim().min(1, { message: 'Required' }),
            // Handle numbers directly
            z.number(),
        ])
        .transform(val => {
            // Clean strings (remove commas, currency symbols, spaces) before conversion
            return typeof val === 'string' ? Number(val.replace(/[,$\s]/g, '')) : val;
        })
        .pipe(
            z.coerce
                .number()
                .refine(v => !isNaN(v), { message: 'Must be a valid number' })
                .refine(v => isFinite(v), { message: 'Must be a finite number' })
                .refine(v => v >= min, { message: minMessage })
                .refine(v => v <= max, { message: maxMessage })
        );

    if (optional) return schema.optional();

    return schema;
};
