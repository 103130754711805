import { DATE_FORMAT } from 'components/Planner/utils';
import { addDays, addSeconds, differenceInSeconds, parse, parseISO, startOfDay } from 'date-fns';
import { maxBy, minBy } from 'lodash';
import moment from 'moment';
import { tz as momentTz } from 'moment-timezone';
import { i18n } from 'translation';
import { tzMoment, userTimeZone } from './moment';

export const DATE_USED_TO_FORMAT_TIME = '2020-01-01';
const dayFormat = 'yyyy-MM-dd';
const timeFormat = `${dayFormat} HH:mm`; // zero indexed 24 hour clock

export const API_DATE_FORMAT = 'yyyy-MM-dd';
export const API_TIME_FORMAT = 'HH:mm:ss';
const ISO_DATETIME_FORMAT = `${API_DATE_FORMAT} ${API_TIME_FORMAT}`;

export const serializeDate = (dateStr?: string) => (dateStr ? parse(dateStr, timeFormat, new Date()) : null);

export const moveTimeToDay = (newDate: Date, dateWithWantedTime: Date) =>
    !dateWithWantedTime
        ? newDate
        : addSeconds(startOfDay(newDate), differenceInSeconds(dateWithWantedTime, startOfDay(dateWithWantedTime)));

export const moveDays = (date: Date, quantity?: number) => addDays(date, quantity || 0);

export const timeValue = (timeString: string) => timeString && Number(timeString.split(':').join(''));

export const mergeDateTime = (date: string, time: string) => `${date} ${time}`;

export const moveDayBy = (date: string, delta: number = 1) => {
    const currentDate = mergeDateTime(date, '00:00');
    const newDay = addDays(parseISO(currentDate), delta);

    return tzMoment(newDay).format('L');
};

export const adjustTime = (timeString: string) => {
    const [hours, minutes, seconds] = timeString.split(':');
    const halfHour = Number(minutes) > 0;
    const adjustedMinutes = halfHour ? '00' : '30';
    const adjustedHours = halfHour ? Number(hours) + 1 : hours;
    const resetHours = Number(adjustedHours) > 23;
    return `${resetHours ? 0 : adjustedHours}:${adjustedMinutes}:${seconds}`;
};

export const fixedTime = (baseTime: string, minTime: string) =>
    baseTime && minTime && timeValue(baseTime) <= timeValue(minTime) ? adjustTime(minTime) : baseTime;

const TIME_CHECK_FORMAT = 'H:mm:ss';
export const roundEndOfDayto12AM = (date: Date) =>
    tzMoment(date).format(TIME_CHECK_FORMAT) === '23:59:59' ? addDays(startOfDay(date), 1) : date;

export const is12AM = (date: Date) => moment(date).format(TIME_CHECK_FORMAT) === '0:00:00';

export const datetimeStrToLocalDate = (date: string, time: string) =>
    parse(`${date} ${time}`, ISO_DATETIME_FORMAT, new Date());

export const apiDateToDateObj = (date: string) => parse(date, API_DATE_FORMAT, new Date());

//ISO Dates are string sortable
export const getDateRange = (list: string[]): [Date | undefined, Date | undefined] => {
    const start = minBy(list);
    const end = maxBy(list);

    const startDate = start ? apiDateToDateObj(start) : undefined;
    const endDate = end ? apiDateToDateObj(end) : undefined;

    return [startDate, endDate];
};

export const getDateTimeStrings = (baseDate?: string | Date, zone?: string) =>
    baseDate && zone
        ? {
              date: momentTz(baseDate, zone).format(DATE_FORMAT),
              time: momentTz(baseDate, zone).format('HH:mm:ss'),
          }
        : {};

export const getShortTimeZone = (baseDate: string | Date, zone: string) => momentTz(baseDate, zone).format('z');

const timeslots = [
    '00:00:00',
    '00:30:00',
    '01:00:00',
    '01:30:00',
    '02:00:00',
    '02:30:00',
    '03:00:00',
    '03:30:00',
    '04:00:00',
    '04:30:00',
    '05:00:00',
    '05:30:00',
    '06:00:00',
    '06:30:00',
    '07:00:00',
    '07:30:00',
    '08:00:00',
    '08:30:00',
    '09:00:00',
    '09:30:00',
    '10:00:00',
    '10:30:00',
    '11:00:00',
    '11:30:00',
    '12:00:00',
    '12:30:00',
    '13:00:00',
    '13:30:00',
    '14:00:00',
    '14:30:00',
    '15:00:00',
    '15:30:00',
    '16:00:00',
    '16:30:00',
    '17:00:00',
    '17:30:00',
    '18:00:00',
    '18:30:00',
    '19:00:00',
    '19:30:00',
    '20:00:00',
    '20:30:00',
    '21:00:00',
    '21:30:00',
    '22:00:00',
    '22:30:00',
    '23:00:00',
    '23:30:00',
];
export const timeOptions = timeslots.map(slot => ({
    id: slot,
    value: slot,
    name: tzMoment(`2023-01-01T${slot}`).format('LT'),
}));

export const isBefore = (option: string, threshold: string | undefined) => {
    if (threshold === undefined) return false;
    return option <= threshold;
};

export const durationOptions = [
    { id: 0, name: '-' },
    { id: 5, name: '5 mins' },
    { id: 10, name: '10 mins' },
    { id: 15, name: '15 mins' },
    { id: 20, name: '20 mins' },
    { id: 25, name: '25 mins' },
    { id: 30, name: '30 mins' },
    { id: 35, name: '35 mins' },
    { id: 40, name: '40 mins' },
    { id: 45, name: '45 mins' },
    { id: 50, name: '50 mins' },
    { id: 55, name: '55 mins' },
    { id: 60, name: '1 hour' },
] as const;

export const shortTimestamp = (lastActiveAt: string) => {
    const lastSeen = tzMoment(lastActiveAt, 'UTC').tz(userTimeZone);
    const now = tzMoment('', userTimeZone);

    if (now.diff(lastSeen, 'days') < 1) {
        return lastSeen.fromNow();
    }

    if (lastSeen.month() === now.month() && lastSeen.year() === now.year()) {
        return lastSeen.format('MMM D, LT');
    }
    return lastSeen.isValid() ? lastSeen.format('ll') : '';
};

export const fullTimestamp = (isoTimestamp: string, timeZone?: string) =>
    tzMoment(isoTimestamp, timeZone).tz(userTimeZone).format('ll LT z');

export const cancelledAtTime = (meeting: BizlyAPI.Meeting) =>
    meeting.cancelledAt &&
    i18n.meetingsPage.cancelledOn(fullTimestamp(meeting.cancelledAt.date, meeting.cancelledAt.timezone));

export const timeElapsed = (date?: string | Date | null | number, timeZone = 'UTC') => {
    return tzMoment(null, timeZone).isAfter(tzMoment(date, timeZone));
};

export const formatDateRange = (_startDate: string | Date, _endDate: string | Date, time = true) => {
    if (!_startDate || !_endDate) return '';

    const startDate = tzMoment(_startDate);
    const endDate = tzMoment(_endDate);

    // If the dates are the same day
    if (startDate.isSame(endDate, 'day')) {
        if (time) {
            return `${startDate.format('MMM D, YYYY h:mm A')} - ${endDate.format('h:mm A')}`;
        } else {
            return startDate.format('MMM D, YYYY');
        }
    }

    // If the dates are in the same month and year
    if (startDate.isSame(endDate, 'month') && startDate.isSame(endDate, 'year')) {
        return `${startDate.format('MMM D')}-${endDate.format('D')}, ${startDate.format('YYYY')}`;
    }

    // If the dates are in the same year but different months
    if (startDate.isSame(endDate, 'year')) {
        return `${startDate.format('MMM D')} - ${endDate.format('MMM D')}, ${startDate.format('YYYY')}`;
    }

    // If the dates are in different years
    return `${startDate.format('MMM D, YYYY')} - ${endDate.format('MMM D, YYYY')}`;
};

export const formatTimeOnly = (time: string, timeZone = 'UTC') => {
    const today = new Date().toISOString().split('T')[0];
    const dateTimeString = `${today}T${time}`; // Combine today's date with the given time
    return tzMoment(dateTimeString, timeZone).format('hh:mm A');
};
