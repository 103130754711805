import { Box, Typography } from '@mui/material';
import { FieldError } from 'react-hook-form';
import { EColors, getColor } from 'theme';
import Tiptap from './TipTap';

type TextAreaFieldProps = {
    label?: string;
    error: FieldError | undefined;
    onChange: (text: string) => void;
    onBlur?: () => void;
    value: string | undefined;
    disabled?: boolean | undefined;
    required?: boolean;
    name: string;
};

const RichTextEditorField = ({
    label,
    error,
    required = false,
    value,
    disabled,
    onChange,
    onBlur,
}: TextAreaFieldProps) => {
    return (
        <Box>
            {label && (
                <Box display="flex" gap={0.5} marginBottom={1}>
                    <Typography fontWeight={500}>{label}</Typography>

                    <Typography color={getColor(EColors.pureBlack, 0.5)} fontWeight={500}>
                        {required ? '(required)' : '(optional)'}
                    </Typography>
                </Box>
            )}

            <Tiptap
                editable={!disabled}
                description={value || ''}
                onChange={onChange}
                onBlur={() => onBlur?.()}
                error={!!error}
            />

            {!!error && (
                <Typography variant="body2" color={getColor(EColors.red)} fontWeight={500}>
                    {error.message}
                </Typography>
            )}
        </Box>
    );
};

export default RichTextEditorField;
