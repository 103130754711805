import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { requestBudgetApproval } from 'api/inquiry';
import Form from 'components/Form';
import Button from 'components/ui/Button';
import { Headline } from 'components/ui/Headline';
import { FixedRatio } from 'components/VenueTile/VenueTile';
import useShowModal from 'hooks/useShowModal';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { EColors, getColor } from 'theme';
import { i18n } from 'translation';
import { Spacer } from 'ui';

const Line = styled('div')(() => ({
    width: '100%',
    height: '1px',
    backgroundColor: getColor(EColors.lightGrey),
}));

type RoundedImageProps = {
    url?: string;
    width: string | number;
    height: string | number;
    fixedRatio: string;
};

const RoundedImage = styled(FixedRatio)<Partial<RoundedImageProps>>(({ url }) => ({
    backgroundImage: `url(${url})`,
    backgroundSize: 'cover',
}));

type TRequestNotes = {
    notes?: string;
};

const FormContainer = styled('div')(() => ({
    height: '100vh',
    width: '31.25rem',
    padding: '1rem',
}));

const VenueModalRoundedImage = styled(RoundedImage)(() => ({
    height: '50%',
    padding: 'unset',
    backgroundPosition: 'center center',
}));

export const useProposalRequest = (proposal?: Bizly.VenueProposal) => {
    const { enqueueSnackbar } = useSnackbar();
    const { modalShown, showModal, hideModal } = useShowModal(false);

    const [requestNotes, setRequestNotes] = useState<TRequestNotes>({});
    const [hasRequested, setHasRequested] = useState(Boolean(proposal?.approvalRequests.length) || false);

    useEffect(() => {
        if (proposal?.approvalRequests.length) {
            setHasRequested(true);
        }
    }, [proposal?.approvalRequests.length]);

    const handleApprovalRequest = async () => {
        if (!requestNotes.notes?.length) {
            enqueueSnackbar(i18n.venue.inquiry.error.noNotes, { variant: 'error' });
            return;
        }

        if (!proposal) {
            enqueueSnackbar(i18n.venue.inquiry.error.serverError, { variant: 'error' });
            return;
        }

        const { success, message } = await requestBudgetApproval(proposal.id, requestNotes.notes);

        if (!success) {
            enqueueSnackbar(message, { variant: 'error' });
            return;
        }

        enqueueSnackbar(message, { variant: 'success' });
        setHasRequested(true);
        hideModal();
    };

    const ProposalRequestModal = (
        <Dialog
            open={modalShown}
            onClose={(e: React.KeyboardEvent<HTMLDivElement>) => {
                e.stopPropagation();
                hideModal();
                return false;
            }}
        >
            <FormContainer>
                <Headline style={{ margin: 'unset', paddingBottom: '1rem' }}>
                    {i18n.venue.inquiry.needsApproval}
                </Headline>
                <VenueModalRoundedImage url={proposal?.venue.imageUrl} fixedRatio="calc(160 / 208 * 100%)" />

                <div>
                    <p>{proposal?.venue.name}</p>
                    <p>
                        {proposal?.venue.venue?.qualityLevel} {i18n.venue.types.hotel}
                    </p>
                </div>

                <Line />
                <Spacer small />

                <Form
                    fields={{
                        notes: {
                            prompt: `${i18n.venue.inquiry.addANote}`,
                            type: 'textarea',
                            options: {
                                placeholder: i18n.venue.inquiry.addNotePlaceholder,
                                rows: 6,
                                smallPlaceholder: true,
                            },
                        },
                    }}
                    schema={[{ key: 'notes', fields: ['notes'], spacing: false }]}
                    onChange={({ value }: { value: TRequestNotes }) => {
                        setRequestNotes(value);
                    }}
                    value={requestNotes}
                />

                <p>{i18n.venue.inquiry.notesPlaceholder3}</p>

                <div style={{ textAlign: 'right', paddingBottom: '1rem' }}>
                    <Button onClick={handleApprovalRequest} width={160}>
                        {i18n.venue.inquiry.submitForApproval}
                    </Button>
                </div>
            </FormContainer>
        </Dialog>
    );

    return {
        hasRequested,
        setHasRequested,
        ProposalRequestModal,
        modalShown,
        showModal,
    };
};
