import { Box, Input, InputProps, styled } from '@mui/material';
import { Body2, Caption } from 'components/BizlyOS/Typography/Typography';
import { TCurrency } from 'components/ProposalForm/types';
import React, { HTMLInputTypeAttribute, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { EColors, getColor } from 'theme';
import { CurrencyModal } from './CurrencyModal';

const StyledInputField = styled(Input)<InputProps & { error: boolean }>(({ theme: { getColor, EColors }, error }) => ({
    flex: 1,
    boxSizing: 'border-box',
    height: '100%',
    fontSize: '0.875rem',
    border: 'none',
    borderRadius: '4px',
    padding: 0,
    color: getColor(EColors.pureBlack),
    '& .MuiInput-input': {
        padding: 0,
    },
    '& .MuiInput-input:focus': {
        outline: 'none',
        WebkitBoxShadow: 'none',
        boxShadow: 'none',
    },
    '& .MuiInput-input:focus::placeholder': {
        color: getColor(EColors.pureBlack),
        opacity: 0.5,
    },
    '& .MuiInput-input::placeholder': {
        color: error ? getColor(EColors.red) : getColor(EColors.pureBlack),
    },
}));

const InputFieldWrapper = styled(Box, {
    shouldForwardProp: prop => prop !== 'error',
})(({ theme, error }) => ({
    color: getColor(EColors.pureBlack),
    padding: '14.5px 10px',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.25),
    boxSizing: 'border-box',
    width: '100%',
    height: '50px',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid',
    borderColor: error ? getColor(EColors.red) : getColor(EColors.bizlyOSBorder),
    '&:focus-within': {
        border: `1px solid ${getColor(EColors.bizlyOSPrimary, 0.6)}`,
    },
}));

const OptionButton = styled(Body2)(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.bizlyOSPrimary),
    fontWeight: '500',
    borderLeft: '1px solid ' + getColor(EColors.bizlyOSBorder),
    paddingLeft: '10px',
    cursor: 'pointer',
}));

function formatCurrency(currency: number | string | undefined) {
    if (currency === undefined) return currency;
    if (typeof currency === 'string') {
        if (!currency) return '';
        const convertedToNubmer = Number(currency);
        if (isNaN(convertedToNubmer)) return '';
        return convertedToNubmer.toFixed(2);
    }

    return currency.toFixed(2);
}

type InputFieldProps = {
    label: string;
    placeholder?: string;
    error: FieldError | undefined;
    value: number | undefined;
    currencyCode: string;
    currencyOptions: TCurrency[];
    currencyModalDescription?: string;
    disabled?: boolean | undefined;
    required?: boolean;
    name: string;
    ref?: React.LegacyRef<HTMLInputElement> | undefined;
    type?: HTMLInputTypeAttribute;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onCurrencyCodeChange: (value: string) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const CurrencyField = React.forwardRef<HTMLInputElement, InputFieldProps>(
    (
        {
            label,
            error,
            type = 'text',
            placeholder = '0.00',
            required = false,
            currencyCode,
            currencyOptions = [],
            currencyModalDescription,
            onCurrencyCodeChange,
            ...field
        },
        ref
    ) => {
        const [openModal, setOpenModal] = useState(false);
        const [isFocused, setIsFocused] = useState(false);

        const selectedCurrency: TCurrency | undefined = useMemo(
            () => currencyOptions.find(currency => currency.code === currencyCode) ?? currencyOptions[0],
            [currencyCode, currencyOptions]
        );

        const handleFocus = () => {
            setIsFocused(true);
        };

        const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
            setIsFocused(false);
            field.onBlur?.(e);
        };

        const onClickToggle = () => {
            setOpenModal(true);
        };

        const isError = !!error;

        const inputValue = isFocused ? field.value : formatCurrency(field.value);

        return (
            <>
                <Box>
                    <Box display="flex" gap={0.5} marginBottom={1}>
                        <Body2 fontWeight={500}>{label}</Body2>
                        {required && (
                            <Body2 color={EColors.bizlyOSPrimary} fontWeight={500}>
                                *
                            </Body2>
                        )}
                    </Box>
                    <InputFieldWrapper error={isError}>
                        <Body2
                            color={isError && !isFocused ? EColors.red : EColors.pureBlack}
                            sx={{ opacity: field.value ? 1 : 0.5 }}
                        >
                            {selectedCurrency?.symbol}
                        </Body2>
                        <StyledInputField
                            {...field}
                            value={inputValue}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            type={type}
                            placeholder={placeholder}
                            error={isError}
                            disableUnderline
                            ref={ref}
                        />
                        <OptionButton onClick={onClickToggle}>{currencyCode || ''}</OptionButton>
                    </InputFieldWrapper>
                    {isError && (
                        <Caption size="large" color={EColors.red} fontWeight={500}>
                            {error.message}
                        </Caption>
                    )}
                </Box>
                <CurrencyModal
                    open={openModal}
                    value={currencyCode}
                    currencyOptions={currencyOptions}
                    descpription={currencyModalDescription}
                    handleClose={() => setOpenModal(false)}
                    onCurrencyCodeChange={onCurrencyCodeChange}
                />
            </>
        );
    }
);

export default CurrencyField;
