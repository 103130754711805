import { OpenInFull } from '@mui/icons-material';
import { alpha, Box, Stack, styled, Switch, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { Card } from 'components/Ui-V2/Card/Card';
import Image from 'components/Ui-V2/Image/Image';
import { EColors, getColor } from 'theme';
import CheckmarkIcon from '../../images/icons/checkmark.svg';
import { Copy } from '../../ui';
import { DEFAULT_PREFERENCE_COLOR, ERankColors } from './VenuePill';

type TTileCardProps = {
    width?: string;
    padding?: string;
    clickable?: boolean;
    centered?: boolean;
    highlighted?: boolean;
};

export const TileCard = styled(Card, {
    shouldForwardProp: prop => prop !== 'clickable' && prop !== 'centered' && prop !== 'highlighted',
})<TTileCardProps>(({ theme, width, clickable, centered, padding, highlighted }) => {
    const baseStyles = {
        gap: theme.spacing(1.25),
        '&.MuiCard-root': {
            boxShadow: 'none',
            '&:hover': {
                boxShadow: `0px 2px 2px 0px ${getColor(EColors.pureBlack, 0.05)}`,
            },
        },
    };

    const conditionalStyles = {
        centered: {
            alignItems: 'center',
            justifyContent: 'center',
        },
        clickable: {
            cursor: 'pointer',
        },
        width: {
            width,
        },
        padding: {
            padding,
        },
        highlighted: {
            border: `4px solid ${getColor(EColors.primaryAction)}`,
            cursor: 'pointer',
            transform: 'scale(1.05)',
        },
    };

    return {
        ...baseStyles,
        ...(centered && conditionalStyles.centered),
        ...(clickable && conditionalStyles.clickable),
        ...(width && conditionalStyles.width),
        ...(padding && conditionalStyles.padding),
        ...(highlighted && conditionalStyles.highlighted),
    };
});

export const VenueName = styled('div')(({ theme: { spacing } }) => ({
    fontWeight: '500',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '0.875rem',
    lineHeight: spacing(2.5),
}));

export const VenueHeadlineWrapper = styled(Stack)(({ theme }) => ({
    minHeight: theme.spacing(5),
}));

export const VenueHeadline = styled('div')(({ theme: { spacing } }) => ({
    fontSize: '0.875rem',
    lineHeight: spacing(2.5),
    color: getColor(EColors.grey3),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
}));

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
    padding: 0,
    gap: theme.spacing(0.5),
    '&:last-child': {
        paddingBottom: 0,
    },
}));

export const Chip = styled(Copy)`
    font-size: 0.563rem;
    font-weight: 500;
    line-height: 1.18;
    text-transform: uppercase;
    color: ${getColor(EColors.pureWhite)};
    padding: 0.25rem 0.5rem;
    border-radius: 0.875rem;
`;

export const PreferencePill = styled(Chip, {
    shouldForwardProp: prop => prop !== 'rank',
})<{ rank: number }>`
    background-color: ${({ rank }) =>
        getColor(ERankColors[('r' + rank) as keyof typeof ERankColors] || DEFAULT_PREFERENCE_COLOR)};
    text-transform: capitalize;
    font-size: 0.625rem;
    font-weight: 500;
`;

export const TempClosedPill = styled(Chip)`
    background-color: ${getColor(EColors.cancelledOrRejectedStatus)};
`;

export const PillContainer = styled(Box)`
    position: absolute;
    top: 0.625rem;
    left: 0.625rem;
    display: flex;
    gap: 0.5rem;
    z-index: 1;
`;

export const ImageContainer = styled(Box)({
    width: '160px',
    height: '160px',
    position: 'relative',
});

export const StyledImage = styled(Image)({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 1,
});

export const PreferredBadge = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 8,
    left: 8,
    backgroundColor: 'white',
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    fontSize: '0.75rem',
}));

export const VenueRightStack = styled(Stack)({
    flex: 1,
    justifyContent: 'flex-start',
    gap: '4px',
});

export const VenueHeading = styled(Typography)({
    fontSize: '1rem',
    fontWeight: '600',
    lineHeight: '1.5rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '365px',
});

export const VenueSubtitle = styled(Typography)({
    fontSize: '0.875rem',
    fontWeight: '400',
    lineHeight: '1.313rem',
    color: getColor(EColors.tagColor),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '365px',
});

export const VenueDetailsContainer = styled(Stack)({
    flex: 1,
    justifyContent: 'space-between',
});

export const VenueInfoRow = styled(Stack)({
    borderRadius: '4px',
    padding: '10px',
    marginTop: '6px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '1rem',
    backgroundColor: alpha(getColor(EColors.bizlyInsightsBlue), 0.1),
});

export const VenueMetaItem = styled(Stack)({
    flexDirection: 'row',
    gap: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.875rem',
});

export const VenueMetaItemLabel = styled(Typography)({
    fontSize: '0.875rem',
    fontWeight: '400',
    color: getColor(EColors.black),
});

export const InquiryRow = styled(Stack)({
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '15px',
});

export const InquiryText = styled(Typography)({
    fontSize: '0.875rem',
    fontWeight: '500',
    color: getColor(EColors.black),
});

export const StyledSwitch = styled(Switch)({
    width: 50,
    height: 28,
    padding: 0,
    overflow: 'visible',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        bottom: 0,
        top: 2,
        left: 5,
        '&.Mui-disabled': {
            '& + .MuiSwitch-track': {
                backgroundColor: alpha(getColor(EColors.grey), 0.8),
                borderColor: getColor(EColors.grey),
                opacity: 0.5,
            },
            '& .MuiSwitch-thumb': {
                backgroundColor: alpha(getColor(EColors.grey), 0.8),
            },
        },
        '&:hover': {
            backgroundColor: 'transparent',
            '& + .MuiSwitch-track': {
                backgroundColor: getColor(EColors.pureWhite),
                borderColor: alpha(getColor(EColors.grey), 0.8),
            },
            '& .MuiSwitch-thumb': {
                backgroundColor: alpha(getColor(EColors.grey), 0.8),
            },
        },
        '&.Mui-checked': {
            left: 1,
            transform: 'translateX(25px)',
            '&:hover': {
                '& + .MuiSwitch-track': {
                    borderColor: alpha(getColor(EColors.bizlyInsightsBlue), 0.8),
                },
                '& .MuiSwitch-thumb': {
                    backgroundColor: alpha(getColor(EColors.bizlyInsightsBlue), 0.8),
                },
            },
            '& + .MuiSwitch-track': {
                backgroundColor: getColor(EColors.pureWhite),
                border: `1px solid ${getColor(EColors.bizlyInsightsBlue)}`,
                opacity: 1,
            },
            '& .MuiSwitch-thumb': {
                backgroundColor: getColor(EColors.bizlyInsightsBlue),
                backgroundImage: `url(${CheckmarkIcon})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        width: 20,
        height: 20,
        boxShadow: 'none',
        backgroundColor: getColor(EColors.inactiveAction),
        '&:hover': {
            boxShadow: 'none',
        },
    },
    '& .MuiSwitch-track': {
        borderRadius: 54,
        backgroundColor: getColor(EColors.pureWhite),
        border: `1px solid ${getColor(EColors.inactiveAction)}`,
        opacity: 1,
    },
});

export const VenueHiddenActionsContainer = styled(Stack)({
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: 'auto',
});

export const VenueDetailsTextHiddenAction = styled(Typography)({
    color: getColor(EColors.bizlyInsightsBlue),
    textAlign: 'right',
    fontSize: '0.875rem',
    fontWeight: '500',
    '&:hover': {
        color: alpha(getColor(EColors.bizlyInsightsBlue), 0.8),
    },
});

export const VenueExpandIcon = styled(OpenInFull)({
    color: getColor(EColors.bizlyInsightsBlue),
    height: 20,
    width: 20,
    '&:hover': {
        transform: 'scale(1.1)',
        transition: 'transform 0.2s ease-in-out',
    },
});
