import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { IconButton } from '@mui/material';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { uploadFile } from '../cloudinary';
import { Column, ExternalLink, Spacer } from '../ui';

interface UploadedFile {
    url: string;
    title: string;
}

interface DragAndDropUploadProps {
    field?: string;
    value?: UploadedFile[];
    onChange?: (value: { field: string; value: UploadedFile[]; errors: null }) => void;
    prompt?: string;
    maxFiles?: number;
    disabled?: boolean;
}

interface DropzoneContainerProps {
    disabled?: boolean;
}

const DropzoneContainer = styled.div<DropzoneContainerProps>`
    border: 2px dashed ${({ theme }) => theme.getColor('grey')};
    background-color: ${({ theme }) => theme.getColor('lighterGrey', 0.4)};
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    transition: border-color 0.3s;

    &:hover {
        border-color: ${({ theme, disabled }) => (disabled ? theme.getColor('grey') : theme.getColor('darkerGrey'))};
    }
`;

const FileList = styled.ul`
    list-style: none;
    padding: 0;
    margin-top: 16px;
`;

const FileItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    padding: 8px;
    border: 1px solid ${({ theme }) => theme.getColor('lightGrey')};
    border-radius: 4px;
    background-color: ${({ theme }) => theme.getColor('lighterGrey', 0.3)};
`;

const PlaceholderText = styled.div`
    font-size: 1rem;
    color: ${({ theme }) => theme.getColor('pureBlack')};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const UploadIcon = styled(UploadFileOutlinedIcon)`
    color: ${({ theme }) => theme.getColor('primaryAction')};
`;

export const DragAndDropUpload = ({
    field = 'files',
    value = [],
    onChange,
    prompt = 'Drag and drop files here, or click to select files',
    maxFiles = 5,
    disabled = false,
}: DragAndDropUploadProps) => {
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleUploadStart = useCallback(() => {
        setUploading(true);
        setError(null);
    }, []);

    const handleUploadError = useCallback((err: Error) => {
        setError(err.message);
    }, []);

    const handleDelete = useCallback(
        (index: number) => {
            const updatedFiles = value.filter((_, i) => i !== index);
            onChange?.({ field, value: updatedFiles, errors: null });
        },
        [field, onChange, value]
    );

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            if (disabled || uploading) return;

            handleUploadStart();

            const availableSlots = maxFiles - value.length;
            if (availableSlots <= 0) {
                setUploading(false);
                return;
            }

            const filesToUpload = acceptedFiles.slice(0, availableSlots);
            let uploadedFiles: UploadedFile[] = [];

            try {
                for (const file of filesToUpload) {
                    const uploadResult = await uploadFile(file);
                    uploadedFiles = [
                        ...uploadedFiles,
                        {
                            url: uploadResult.url,
                            title: uploadResult.title,
                        },
                    ];
                }

                const newFiles = [...value, ...uploadedFiles];
                const limitedFiles = newFiles.slice(0, maxFiles);
                onChange?.({ field, value: limitedFiles, errors: null });
            } catch (err) {
                handleUploadError(err as Error);
            } finally {
                setUploading(false);
            }
        },
        [disabled, uploading, value.length, maxFiles, handleUploadStart, handleUploadError, field, onChange, value]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: maxFiles > 1,
        disabled: disabled || uploading || value.length >= maxFiles,
    });

    const limitReached = value.length >= maxFiles;

    return (
        <Column>
            <DropzoneContainer {...getRootProps()} disabled={limitReached || disabled || uploading}>
                <input {...getInputProps()} />
                <PlaceholderText>
                    <UploadIcon />
                    <Spacer />
                    {uploading ? 'Uploading. Please wait.' : limitReached ? 'File limit reached.' : prompt}
                </PlaceholderText>
            </DropzoneContainer>

            {error && <PlaceholderText>Error: {error}</PlaceholderText>}
            <Spacer />

            {value.length > 0 && (
                <FileList>
                    {value.map((file, index) => (
                        <FileItem key={file.url || file.title}>
                            <ExternalLink href={file.url} openInNewTab>
                                {file.title}
                            </ExternalLink>
                            <IconButton
                                onClick={() => handleDelete(index)}
                                aria-label="delete"
                                color="error"
                                sx={{ fontSize: '0.75rem', padding: 0 }}
                            >
                                <DeleteOutlinedIcon />
                            </IconButton>
                        </FileItem>
                    ))}
                </FileList>
            )}
        </Column>
    );
};
