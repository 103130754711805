import { Alert, Box, Snackbar } from '@mui/material';
import EventRegistrationWebPageBody from 'components/EventRegistrationWebPageBody';
import RsvpDrawer from 'components/Rsvp-V2/RsvpDrawer';
import { SpinnerOverlay } from 'components/Spinner';
import { useGetRegistrationInfoQuery } from 'hooks/queries/useEventsQuery';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { formatDateRange, formatTimeOnly, getShortTimeZone } from 'utils/date_util';

type MatchParams = {
    eventId: string;
    slugId: string;
};

function EventRegistrationV2() {
    const { eventId, slugId } = useParams<MatchParams>();
    const { data, isLoading } = useGetRegistrationInfoQuery(Number(eventId), slugId);

    const event = data && data.event;

    const [openRsvpModal, setOpenRsvpModal] = useState(false);
    const [rsvpSubmitted, setRsvpSubmitted] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleRsvpModalOpen = () => {
        setOpenRsvpModal(true);
    };

    const handleSuccessfulSubmit = () => {
        setRsvpSubmitted(true);
        setOpenSnackbar(true);
        handleRsvpModalClose();
    };

    const handleRsvpModalClose = () => {
        setOpenRsvpModal(false);
    };

    if (!data || isLoading) {
        return <SpinnerOverlay />;
    }

    return (
        <Box bgcolor={getColor(EColors.pureBlack)} minHeight="100vh">
            <EventRegistrationWebPageBody
                isSubmitted={rsvpSubmitted}
                previewRef={null}
                coverImageUrl={event.imageUrl}
                logoUrl={event.logoUrl}
                eventTitle={event.name}
                dateRange={formatDateRange(event.startDate || '', event.endDate || '')}
                startTime={formatTimeOnly(event.startTime || '', event.timeZone)}
                endTime={formatTimeOnly(event.endTime || '', event.timeZone)}
                timeZone={getShortTimeZone(event.startDate || '', event.timeZone || '-')}
                locationName={event.location}
                locationAddress={event.address || '-'}
                eventOverview={event.description}
                googleLocationId={event.locationGooglePlaceId}
                onClickCountMeIn={handleRsvpModalOpen}
            />
            <RsvpDrawer
                isSubmitted={rsvpSubmitted}
                open={openRsvpModal}
                isLoading={isLoading}
                event={data.event}
                attendee={data.attendee}
                formSettings={data.formSettings}
                eventId={eventId as string}
                slugId={slugId as string}
                onClose={handleRsvpModalClose}
                handleSuccessfulSubmit={handleSuccessfulSubmit}
            />
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                <Alert
                    icon={false}
                    variant="filled"
                    sx={{
                        width: '100%',
                        backgroundColor: getColor(EColors.primaryAction),
                    }}
                    onClose={() => setOpenSnackbar(false)}
                >
                    Successfully registered!
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default EventRegistrationV2;
