import { alpha, styled } from '@mui/material';
import Chip, { ChipProps } from '@mui/material/Chip';
import { shouldForwardProp } from '@mui/system';
import { EColors, getColor } from 'theme';

const getStatusColor = (status: string) => {
    const pureBlack = getColor(EColors.pureBlack);

    switch (status) {
        case 'New Inquiry':
            return { bg: getColor(EColors.lemonChiffon), text: pureBlack };
        case 'Proposal Sent':
            return { bg: getColor(EColors.wizardWhite), text: pureBlack };
        case 'Confirmed':
            return { bg: getColor(EColors.aquaSpring), text: pureBlack };
        case 'Won':
            return { bg: getColor(EColors.aquaSpring), text: pureBlack };
        case 'Lost':
            return { bg: getColor(EColors.blushMist), text: pureBlack };
        case 'Payment Due':
            return { bg: getColor(EColors.minuteMauve), text: pureBlack };
        case 'Temp Closed':
            return { bg: alpha(getColor(EColors.bizlyOSYellow), 0.2), text: pureBlack };
        case 'Active':
            return { bg: alpha(getColor(EColors.green), 0.2), text: pureBlack };
        default:
            return { bg: getColor(EColors.softAccentedBackground), text: pureBlack };
    }
};

interface StatusChipProps extends ChipProps {
    status: string;
    bold?: boolean;
}

export const StatusChip = styled(Chip, {
    shouldForwardProp: prop => !['status', 'bold'].includes(prop.toString()) && shouldForwardProp(prop),
})<StatusChipProps>(({ status, bold }) => {
    const { bg, text } = getStatusColor(status);
    return {
        backgroundColor: bg,
        color: text,
        borderRadius: '12px',
        fontSize: '0.75rem',
        height: '24px',
        fontWeight: bold ? '500' : 'normal',
        '& .MuiChip-label': {
            padding: '0 8px',
        },
    };
});

StatusChip.defaultProps = {
    variant: 'filled',
    size: 'small',
} as Partial<StatusChipProps>;
