import AddIcon from '@mui/icons-material/Add';
import { Box, Stack } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import NotificationHeader from 'components/NotificationHeader';
import SideNav from 'components/SideNav';
import { ButtonWithIcon } from 'components/Ui-V2/Button/Button';
import { PageDescription, PageHeadline } from 'components/Ui-V2/Headline/Headline';
import { Spacer } from 'components/Ui-V2/Spacer/Spacer';
import SearchDrawer from 'components/Workspace/SearchDrawer';
import WorkspacesTable from 'components/Workspace/WorkspaceTable';
import { useState } from 'react';
import { i18n } from 'translation';

const StyledAddIcon = muiStyled(AddIcon)`
    font-size: 1.125rem;
`;

const StyledButtonWithIcon = muiStyled(ButtonWithIcon)({
    '&.MuiButton-containedPrimary': {
        width: 'fit-content',
        height: '36.5px',
    },
});

export default function Workspaces() {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const bookWorkspace = () => {
        setDrawerOpen(true);
    };

    return (
        <SideNav>
            <NotificationHeader />
            <Stack gap={2}>
                <PageHeadline withDescription>{i18n.workspaces.title}</PageHeadline>
                <PageDescription large>{i18n.workspaces.mainDescription}</PageDescription>

                <Box display="flex" flexDirection="row">
                    <StyledButtonWithIcon leftIcon={StyledAddIcon} onClick={() => bookWorkspace()}>
                        {i18n.workspaces.bookWorkspace}
                    </StyledButtonWithIcon>
                </Box>
            </Stack>

            <Spacer />

            <Stack flex={1}>
                <WorkspacesTable />
            </Stack>

            <SearchDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
        </SideNav>
    );
}
