import { Box, styled, Typography } from '@mui/material';
import TextField from 'components/Ui-V2/InputFields/TextField';

export const BorderedContainer = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    height: '100%',
    border: `1px solid ${getColor(EColors.inactiveAction)}`,
    borderRadius: shape.borderRadius,
    boxSizing: 'border-box',
    padding: spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    transition: 'height 1s ease',
}));
export const BorderedBottom = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    borderBottom: `1px solid ${getColor(EColors.inactiveAction)}`,
    paddingBottom: spacing(2.5),
}));

function GuestDetails() {
    return (
        <BorderedContainer>
            <Typography fontWeight={600}>Your Details</Typography>
            <TextField
                name="FirstName"
                label="First Name"
                onChange={() => {}}
                placeholder="Your First Name"
                value=""
                error={undefined}
                required
                disabled
            />
            <TextField
                name="LastName"
                label="Last Name"
                onChange={() => {}}
                placeholder="Your Last Name"
                value=""
                error={undefined}
                required
                disabled
            />
            <TextField
                name="Email"
                label="Email"
                onChange={() => {}}
                placeholder="Your Email"
                value=""
                error={undefined}
                required
                disabled
            />
        </BorderedContainer>
    );
}

export default GuestDetails;
