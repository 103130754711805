import { Box } from '@mui/material';
import ConfirmationModal from 'components/ConfirmationModal';
import { EventHeaderIconsSlot } from 'components/EventHeader';
import HowToPopper from 'components/HowToPopper/HowToPopper';
import RightDrawerVenues from 'components/RightDrawerVenues';
import { Button } from 'components/Ui-V2/Button/Button';
import { REJECTED_STATUSES } from 'components/VenueCard';
import VenueInquiry from 'components/VenueInquiry';
import { PageHeadline } from 'components/ui/Headline';
import { useMaxInquiryVenueSlots } from 'hooks/useMaxInquiryVenueSlots';
import useVenueInquiry from 'hooks/useVenueInquiry';
import keyBy from 'lodash/keyBy';
import React, { useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { LoadCurrentInquiry, NEW_INQUIRY_ID, currentInquiryActions, useCurrentInquiry } from 'stores/current-inquiry';
import styled from 'styled-components';
import { i18n } from 'translation';
import { HEADER_HEIGHT } from 'utils/header';
import { Column, Row, Spacer } from '../ui';
import BuildInquiry, { PageDescription } from './BuildInquiry';
import Contracting from './Contracting';
import Proposal from './Proposal';
import VenueListingV2 from './VenueListingV2';
import VenueSearch from './VenueSearch';
import { EventFormValues } from './Venues/Venues';

const TitleWrapper = styled('div')`
    padding: 32px 32px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const PaddedContent = styled(Column)<{ sideMargin: number }>`
    height: 100%;
    padding: 0 ${({ sideMargin }) => sideMargin}px;
    box-sizing: border-box;
    scroll-margin: ${HEADER_HEIGHT}px;
`;

const FullWidthColumn = styled(Column)`
    height: 100%;
    width: 100%;
`;

const StyledLink = styled(Link)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
`;

const RightDrawerFooter = styled(Box)`
    display: flex;
`;

const RightDrawerActionButton = styled(Button)`
    &.MuiButton-root {
        margin: 0;
        height: 36.5px;
    }
`;

const getSelectedVenueInqs = (venues: Bizly.Venue[]) => venues.filter(venue => !REJECTED_STATUSES.has(venue.status));

const Venue = ({
    event,
    viewVenueListing,
    viewProposal,
    sideMargin = 32,
    pageOffset = 0,
}: {
    event: Bizly.Event;
    viewVenueListing: (venueId: number) => void;
    viewProposal: (venue?: Bizly.Venue) => void;
    suggestedVenues: Bizly.Venue[];
    venuesRefreshKey?: number;
    onUpdateVenues?: () => void;
    sideMargin?: number;
    pageOffset?: number;
}) => {
    const navigate = useNavigate();

    const [submitKey] = React.useState(0);
    const [venueBooked, setVenueBooked] = React.useState(false);

    const setBookedVenue = (booked: boolean) => {
        setVenueBooked(booked);
        currentInquiryActions.load(event.id);
    };

    const methods = useForm<EventFormValues>();

    const { inquiry: currentInquiry, venues: currentInquiryVenues, loading: loadingCurrent } = useCurrentInquiry();

    const MAX_INQUIRY_VENUE_SLOTS = useMaxInquiryVenueSlots();

    const submitInquiryHook = (venues?: Bizly.Venue[]) => {
        const validVenues = venues?.filter(venue => !REJECTED_STATUSES.has(venue.status));
        const isFirstVenueAdded = validVenues && validVenues.length === 0;
        const isLastAvailableSlotAdded = validVenues && validVenues.length === MAX_INQUIRY_VENUE_SLOTS - 1;
        if (isFirstVenueAdded || isLastAvailableSlotAdded) {
            setShowRightDrawer?.(true);
        }
    };

    const {
        openConfirmationDialog,
        selectedVenueInquiry,
        modalNonPreferredContent,
        addVenueIfCan,
        removeVenueIfCan,
        modalContent,
        stagedVenue,
        setStagedVenue,
    } = useVenueInquiry(event as Bizly.Event, { postInquiryHook: submitInquiryHook });

    React.useEffect(() => {
        if (currentInquiry && currentInquiryVenues) {
            const bookedVenue = !!currentInquiryVenues.find(venue => venue.booked);
            if (bookedVenue) setVenueBooked(bookedVenue);
        }
    }, [currentInquiry, currentInquiryVenues]);

    const selectedVenues = useMemo(() => {
        if (venueBooked) return {};
        return keyBy(
            getSelectedVenueInqs(currentInquiryVenues || []).map(venueInq => venueInq.venue),
            'id'
        );
    }, [venueBooked, currentInquiryVenues]);

    const [hasInquiryData, setHasInquiryData] = useState(false);

    const contentRef = useRef<HTMLDivElement>(null);

    const onClose = () => setShowRightDrawer(false);

    const goToInquiry = () => {
        onClose();
        navigate(`/event/${event.id}/venue/inquiries/${currentInquiry?.id ?? NEW_INQUIRY_ID}`, {
            replace: true,
        });
    };

    const renderRightDrawerFooter = () => (
        <RightDrawerFooter>
            {!loadingCurrent && (
                <RightDrawerActionButton fullWidth disabled={loadingCurrent || !event?.editable} onClick={goToInquiry}>
                    {hasInquiryData ? i18n.venue.inquiry.reviewInquiry : i18n.venue.inquiry.buildInquiry}
                </RightDrawerActionButton>
            )}
        </RightDrawerFooter>
    );

    const [showRightDrawer, setShowRightDrawer] = useState(false);

    return (
        <FullWidthColumn>
            <LoadCurrentInquiry />

            <RightDrawerVenues
                isOpen={showRightDrawer}
                onRemoveVenue={removeVenueIfCan}
                footer={renderRightDrawerFooter()}
                onClose={onClose}
            />

            {stagedVenue && (
                <ConfirmationModal
                    isActive
                    headline={i18n.venue.addToInquiry}
                    ctaLabel={i18n.button.add}
                    warning
                    onDismiss={() => setStagedVenue(undefined)}
                    {...modalContent(stagedVenue)}
                />
            )}

            {selectedVenueInquiry && (
                <ConfirmationModal
                    isActive={openConfirmationDialog}
                    headline={i18n.communication.confirmation}
                    {...modalNonPreferredContent()}
                />
            )}

            {/* Title */}
            <TitleWrapper>
                <Box>
                    <Routes>
                        <Route
                            path={'inquiries'}
                            element={
                                <>
                                    <Row alignItems="center">
                                        <PageHeadline noMarginBlock withDescription>
                                            {i18n.meetingsPage.sideNav.proposals}
                                        </PageHeadline>
                                        <HowToPopper sectionId="proposals" />
                                    </Row>
                                    <Spacer medium />
                                </>
                            }
                        />
                        {['inquiries/*', 'listing/*', 'proposal/*', 'contracting/*'].map(route => (
                            <Route path={route} key={route} element={null} />
                        ))}
                        <Route
                            path={'inquiries/:inquiryId'}
                            element={
                                <>
                                    <Row alignItems="center">
                                        <PageHeadline noMarginBlock withDescription>
                                            {i18n.venue.inquiry.buildYourInquiry}
                                        </PageHeadline>
                                        <HowToPopper sectionId="inquiry" />
                                    </Row>
                                    <Spacer small />
                                    <PageDescription large>
                                        Enter your requirements for your event below. Add your{' '}
                                        <StyledLink to={`/event/${event.id}/venue`}>venues</StyledLink> before
                                        submitting inquiry.
                                    </PageDescription>
                                    <Spacer large />
                                </>
                            }
                        />
                    </Routes>
                </Box>
                <Box>
                    <EventHeaderIconsSlot />
                </Box>
            </TitleWrapper>

            <PaddedContent sideMargin={sideMargin} ref={contentRef}>
                <Routes>
                    <Route
                        path={'listing/:venueId'}
                        element={
                            <FormProvider {...methods}>
                                <VenueListingV2
                                    addToInquiry={addVenueIfCan}
                                    removeFromInquiry={removeVenueIfCan}
                                    selectedVenues={selectedVenues}
                                    pageOffset={pageOffset}
                                />
                            </FormProvider>
                        }
                    />
                    <Route
                        path={'search'}
                        element={
                            <VenueSearch
                                event={event}
                                onSelect={addVenueIfCan}
                                onDeselect={removeVenueIfCan}
                                viewVenueListing={viewVenueListing}
                                selectedVenues={selectedVenues}
                                hideActions={false}
                            />
                        }
                    />
                    <Route path={'inquiries/:inquiryId'} element={<BuildInquiry />} />
                    <Route
                        path={'inquiries'}
                        element={
                            <VenueInquiry
                                viewVenueListing={viewVenueListing}
                                viewProposal={viewProposal}
                                submitKey={submitKey}
                                setVenueBooked={setBookedVenue}
                                readonly={!event.editable || !!event.cancelledAt}
                            />
                        }
                    />

                    <Route
                        path={'proposal/:proposalId/*'}
                        element={
                            <Proposal
                                venueBooked={venueBooked}
                                setVenueBooked={setBookedVenue}
                                viewVenueListing={viewVenueListing}
                            />
                        }
                    />
                    <Route path={'contracting/*'} element={<Contracting viewVenueListing={viewVenueListing} />} />
                    <Route
                        path="*"
                        element={
                            <VenueSearch<false>
                                event={event}
                                onSelect={addVenueIfCan}
                                onDeselect={removeVenueIfCan}
                                viewVenueListing={viewVenueListing}
                                selectedVenues={selectedVenues}
                                hideActions={false}
                                setShowRightDrawer={setShowRightDrawer}
                                setHasInquiryData={setHasInquiryData}
                            />
                        }
                    />
                </Routes>
            </PaddedContent>
        </FullWidthColumn>
    );
};

export default Venue;
