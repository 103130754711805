export const STATUS_LABELS: { [key in BizlyAPI.MeetingStatusCode]: string } = {
    new: 'Plan Event',
    inquiry_draft: 'Submit Inquiry',
    inquiry_submitted: 'Inquiry Submitted',
    inquiry_sent: 'Inquiry Submitted',
    inquiry_response: 'Review Responses',
    proposal_accepted: 'Upload Contract',
    booked: 'Booked',
    booking_over: 'Update Final Spend',
    booking_tracked: 'Meeting Complete',
    cancelled: 'Cancelled',
};

export enum EventType {
    SCHEDULED = 'Scheduled',
    UNSCHEDULED = 'Unscheduled',
}

export const WEBREGISTRATION_PREVIEW_IDS = {
    banner: 'banner',
    logo: 'banner',
    eventTitle: 'banner',
    dates: 'dates',
    rsvp: 'rsvp',
    eventOverview: 'event-overview',
    eventLocation: 'event-location',
    eventLocationMap: 'event-location-map',
};

export type WebRegistrationPreviewIdsType =
    (typeof WEBREGISTRATION_PREVIEW_IDS)[keyof typeof WEBREGISTRATION_PREVIEW_IDS];
