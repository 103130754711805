import { Box, FormControlLabel, RadioGroup as MuiRadioGroup, Radio, Typography, styled } from '@mui/material';
import React from 'react';
import { EColors, getColor } from 'theme';

const StyledFormControlLabel = styled(FormControlLabel)(({ theme: { getColor, EColors, spacing } }) => ({
    padding: 0,
    marginLeft: 0,
    marginRight: 0,
    '& .MuiRadio-root': {
        padding: 0,
        marginRight: spacing(1),
    },
    '& .MuiSvgIcon-root': {
        fontSize: spacing(2.25),
    },
    '& > .MuiFormControlLabel-label': {
        fontSize: '1rem',
        color: getColor(EColors.pureBlack),
    },
}));

const Grid = styled(Box, {
    shouldForwardProp: prop => prop !== 'columns',
})(({ theme: { spacing }, columns }) => ({
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gridAutoRows: 'auto',
    gap: spacing(1.25),
}));

type BaseProps = {
    options: { value: number | string; label: number | string }[];
    label?: string;
    placeholder?: string;
    error: string | undefined;
    onChange: (values: number | string) => void;
    onBlur?: () => void;
    value?: number | string;
    disabled?: boolean | undefined;
    required?: boolean;
};

type RowProps = { row: true; columns?: never };
type ColumnProps = { row?: false; columns?: number };

type RadioGroupProps = BaseProps & (RowProps | ColumnProps);

const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>(
    (
        { label, error = '', required = false, options, disabled, value = '', row = false, columns = 1, onChange },
        ref
    ) => {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange((event.target as HTMLInputElement).value);
        };

        const Options = options.map(option => {
            return (
                <StyledFormControlLabel
                    key={option.value}
                    label={option.label}
                    value={option.value}
                    disabled={disabled}
                    control={<Radio />}
                />
            );
        });

        return (
            <Box>
                {label && (
                    <Box display="flex" gap={0.5} marginBottom={1}>
                        <Typography fontWeight={500}>{label}</Typography>

                        <Typography color={getColor(EColors.pureBlack, 0.5)} fontWeight={500}>
                            {required ? '(required)' : ''}
                        </Typography>
                    </Box>
                )}
                <MuiRadioGroup
                    value={value}
                    onChange={handleChange}
                    sx={{ justifyContent: 'space-between' }}
                    row={row}
                    ref={ref}
                >
                    {row ? Options : <Grid columns={columns}>{Options}</Grid>}
                </MuiRadioGroup>
                {!!error && (
                    <Typography variant="body2" color={getColor(EColors.red)} fontWeight={500}>
                        {error}
                    </Typography>
                )}
            </Box>
        );
    }
);

export default RadioGroup;
