import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { CircularProgress } from '@mui/material';
import PhotosUploadIcon from 'images/bizlyOS/icons/photos-upload-icon.svg?react';
import { EColors, getColor } from 'theme';
import { Body2, Caption } from '../../Typography/Typography';

type UploadContent = {
    isDragActive: boolean;
    isUploading?: boolean;
    onUploadClick?: () => void;
};

export function UploadContent({ isDragActive, onUploadClick }: UploadContent) {
    return isDragActive ? (
        <>
            <CloudUploadOutlinedIcon fontSize="large" sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
            <Body2>Drop the files here</Body2>
        </>
    ) : (
        <>
            <PhotosUploadIcon />
            <Body2>
                <span
                    style={{
                        color: getColor(EColors.bizlyOSPrimary),
                        fontWeight: '500',
                        cursor: 'pointer',
                    }}
                    onClick={onUploadClick}
                >
                    Click to upload
                </span>{' '}
                or drag and drop
            </Body2>
            <Body2>SVG, PNG, JPG or GIF (max. 3MB)</Body2>
        </>
    );
}

export function SmallUploadContent({ isDragActive, isUploading = false, onUploadClick }: UploadContent) {
    return isDragActive ? (
        <>
            <CloudUploadOutlinedIcon fontSize="large" sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
            <Caption>Drop the files here</Caption>
        </>
    ) : isUploading ? (
        <CircularProgress size={24} sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
    ) : (
        <>
            <PhotosUploadIcon width={24} height={24} />
            <Body2
                textAlign="center"
                color={EColors.bizlyOSPrimary}
                fontWeight="500"
                sx={{ cursor: 'pointer' }}
                onClick={onUploadClick}
            >
                Click to upload
            </Body2>
            <Body2 textAlign="center">or drag and drop</Body2>
            <Caption textAlign="center" width={100}>
                SVG, PNG, JPG or GIF (max. 3MB)
            </Caption>
        </>
    );
}
