import { Box, styled } from '@mui/material';
import { numericSchema } from 'components/BizlyOS/Proposals/utils';
import { Body2 } from 'components/BizlyOS/Typography/Typography';
import { TESBooking, TProposalForm } from 'components/ProposalForm/types';
import { Control, FieldErrors, UseFormSetValue } from 'react-hook-form';
import { z } from 'zod';

export const Row = styled(Box)(({ theme: { spacing } }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: spacing(1.25),
}));

export const BorderedContainer = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    border: `0.5px solid ${getColor(EColors.bizlyOSBorder)}`,
    borderRadius: spacing(1),
    boxSizing: 'border-box',
    padding: spacing(2.5),
}));

export const Title = styled(Body2)(({ theme: { getColor, EColors } }) => ({
    lineHeight: 'normal',
    fontWeight: 500,
    '& > span': {
        color: getColor(EColors.bizlyOSPrimary),
    },
}));

export const Description = styled(Body2)(({ theme: { getColor, EColors, spacing } }) => ({
    lineHeight: 'normal',
    marginTop: spacing(1.25),
    marginBottom: spacing(2.5),
    paddingBottom: spacing(2.5),
    borderBottom: `0.5px solid ${getColor(EColors.bizlyOSBorder)}`,
}));

export const EventSpaceSchema = z
    .object({
        proposedRoomRate: numericSchema({ optional: true }),
        proposedFbMinimum: numericSchema({ optional: true }),
        proposedRatePerPerson: numericSchema({ optional: true }),
        proposedMinGuests: numericSchema({ optional: true }),
        proposedSetupId: z.number(),
        currencyCode: z.string().min(1, 'Required'),
        useDdr: z.boolean(), // useDdr=true: per person rate, useDdr=false:daily room rate pricing
        proposedAvIds: z.array(z.number()),
        proposedFb: z.array(
            z.object({
                fbOptionId: z.number(),
                diningStyleId: z.number().nullable(),
            })
        ),
    })
    .refine(data => {
        if (data.useDdr) {
            return ((data.proposedRatePerPerson || 0 > 0) && data.proposedMinGuests) || 0 > 0;
        } else {
            return (data.proposedRoomRate || 0 > 0) && (data.proposedFbMinimum || data.proposedFbMinimum === 0);
        }
    });

export type EventSpaceFormData = z.infer<typeof EventSpaceSchema>;

export type EventSpaceFormProps = {
    control: Control<EventSpaceFormData>;
    errors: FieldErrors<EventSpaceFormData>;
    currencyCode: string;
    eventSpace: TESBooking;
    useDdr: boolean;
    proposalInquiry: TProposalForm;
    setValue: UseFormSetValue<EventSpaceFormData>;
};
