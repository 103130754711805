import * as Sentry from '@sentry/react';
import { authProposalForm } from 'api';
import ErrorFallback from 'components/Error/ErrorFallback';
import { MobileSplashBlocker } from 'components/MobileSplashBlocker/MobileSplashBlocker';
import { SpinnerOverlay } from 'components/Spinner';
import { createLocation } from 'history';
import EventRegistration from 'pages/EventRegistration';
import EventRegistrationV2 from 'pages/EventRegistrationV2';
import { PostHogProvider, useFeatureFlagEnabled } from 'posthog-js/react';
import { AppConfigProvider } from 'providers/app-config';
import { UserProvider } from 'providers/user';
import { parse } from 'query-string';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ReactGA from 'react-ga';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
    Route,
    RouterProvider,
    Routes,
    ScrollRestoration,
    createBrowserRouter,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { ProposalRedirect } from 'routes/BizlyOS';
import { AppWithUser } from 'routes/UserRoutes';
import { LoadMeasurementUnits } from 'stores/measurement-units';
import { setCookie } from 'utils/tokens';
import { takeFirstIfArray } from './util';

const POSTHOG_API_KEY = import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY;
const POSTHOG_API_HOST = import.meta.env.VITE_APP_PUBLIC_POSTHOG_HOST;

const POSTHOG_OPTIONS = {
    api_host: POSTHOG_API_HOST,
} as const;

const queryClient = new QueryClient();

const GOOGLE_ANALYTICS_ID =
    import.meta.env.VITE_APP_ENV === 'prod'
        ? import.meta.env.VITE_APP_GOOGLE_ANALYTICS_ID_PRODUCTION
        : import.meta.env.VITE_APP_GOOGLE_ANALYTICS_ID;

export type TResult = { code: number; message: string };
const isNotNetworkError = (e: unknown): e is TResult => {
    return !!e && typeof (e as TResult).code === 'number';
};

const Auth = () => {
    const navigate = useNavigate();
    const queryParams = takeFirstIfArray(parse(useLocation().search));

    useEffect(() => {
        const intendedPage = decodeURI(queryParams.redirect ?? '');
        const intendedLocation = createLocation(intendedPage);
        const newLocationSearch = takeFirstIfArray(parse(intendedLocation.search));
        const token = queryParams.token ?? '';

        const pathRegex = /\/proposals\/(\d+)/;
        const match = RegExp(pathRegex).exec(intendedLocation.pathname);
        if (!match) {
            return;
        }
        const proposalId = match[1];

        const performAuth = async () => {
            try {
                const response = await authProposalForm(newLocationSearch.venueId ?? '', proposalId, token);
                if (response.success && response.user) setCookie(response.user.authToken);
                navigate(intendedLocation);
            } catch (e) {
                Sentry.captureException(e);

                if (isNotNetworkError(e)) {
                    navigate(intendedLocation, {
                        state: {
                            error: e,
                        },
                    });
                }
            }
        };

        performAuth();
    }, [queryParams, navigate]);

    return <SpinnerOverlay />;
};

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const webRegistrationRedesignFlagEnabled = useFeatureFlagEnabled('AiWebRegistrationRedesign');

    useEffect(() => {
        // Track a pageview in Google Analytics
        if (GOOGLE_ANALYTICS_ID) {
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [location]);

    return (
        <PostHogProvider apiKey={POSTHOG_API_KEY} options={POSTHOG_OPTIONS}>
            <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => navigate(0)}>
                <QueryClientProvider client={queryClient}>
                    <AppConfigProvider>
                        <LoadMeasurementUnits />
                        <MobileSplashBlocker>
                            <SentryRoutes>
                                <Route path="/auth" element={<Auth />} />
                                <Route
                                    path="/events/:eventId/register/:slugId"
                                    element={
                                        webRegistrationRedesignFlagEnabled ? (
                                            <EventRegistrationV2 />
                                        ) : (
                                            <EventRegistration />
                                        )
                                    }
                                />
                                <Route path="/proposals/:proposalId" element={<ProposalRedirect />} />
                                <Route
                                    path="*"
                                    element={
                                        <UserProvider>
                                            <AppWithUser />
                                        </UserProvider>
                                    }
                                />
                            </SentryRoutes>
                        </MobileSplashBlocker>
                        <ScrollRestoration getKey={location => location.pathname} />
                    </AppConfigProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </ErrorBoundary>
        </PostHogProvider>
    );
};

const router = createBrowserRouter([{ path: '*', element: <App /> }]);

const AppRouter = () => {
    return <RouterProvider router={router} />;
};

export default AppRouter;
