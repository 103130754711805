import Form from 'components/Form';

export function CustomQuestionsForm({
    field: formField,
    value,
    errors = {},
    onChange: onChangeProp,
    readonly = false,
    isNested = false,
    questions,
    placeholder = '',
    header = '',
}) {
    const originalValues = value ? value.reduce((agg, { id, response }) => ({ ...agg, [id]: response }), {}) : {};
    const onChange = ({ value: newValue, errors: newErrors, field }) => {
        onChangeProp({
            value: valueToQuestionsAPI({
                field,
                value: newValue[field],
                questions,
                originalValues,
            }),
            errors: newErrors[field],
            field: `${formField}`,
        });
    };

    const props = {
        fields: questionsToFields(questions, readonly, placeholder),
        schema: questionsToSchema({
            questions,
            header,
            bottomSpacing: !isNested,
        }),
        value: questionsAPItoValue(value),
        errors,
        onChange,
    };

    return <Form {...props} />;
}

const customQuestionToField = {
    5: {
        type: 'text',
        perRow: 2,
        options: {},
    },
    6: {
        type: 'radioselect',
        perRow: 1,
        options: {
            perRow: 4,
        },
    },
    7: {
        type: 'multiselect',
        perRow: 1,
        options: {
            perRow: 4,
        },
    },
    8: {
        type: 'date',
        perRow: 2,
        options: {},
    },
    9: {
        type: 'linear_scale',
        perRow: 1,
        options: {},
    },
};

const questionsToFields = (questions = [], readonly, placeholder) =>
    questions.reduce((agg, { id, definition, name, prompt, options, responseRequired }) => {
        const { type, perRow, options: customOptions } = customQuestionToField[definition.id];
        agg[id] = {
            prompt: prompt || name,
            type,
            perRow,
            optional: !responseRequired,
            readonly,
            options: {
                options,
                placeholder,
                ...customOptions,
            },
        };
        return agg;
    }, {});

const questionsAPItoValue = (APIVal = []) =>
    APIVal.reduce((agg, { id, response }) => {
        agg[id] = response;
        return agg;
    }, {});

const questionsToSchema = ({ questions, header, bottomSpacing }) =>
    questions.map(({ id }, idx) => ({
        key: id,
        header: idx === 0 ? header : undefined,
        fields: [id],
        ...(!bottomSpacing && idx === questions.length - 1 ? { spacing: false } : {}),
    }));

const valueToQuestionsAPI = ({ field, value, questions, originalValues }) =>
    questions.map(q => ({
        ...q,
        response: field === q.id ? value : originalValues ? originalValues[q.id] : undefined,
    }));
