import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, styled } from '@mui/material';
import React, { ReactNode } from 'react';
import { EColors, getColor } from 'theme';
import { Body2 } from './Typography/Typography';

const InfoContainer = styled(Box)(({ theme: { spacing, shape } }) => ({
    display: 'flex',
    padding: spacing(1.25),
    borderRadius: shape.borderRadius,
    gap: spacing(1.25),
}));

type BasicInfoBoxProps = {
    message: string | ReactNode;
    icon?: React.JSX.Element;
    color?: string;
    backgroundColor?: string;
    alignItems?: 'center' | 'flex-start' | 'flex-end';
};

export const BasicInfoBox = ({
    icon = <InfoOutlinedIcon fontSize="small" />,
    message,
    alignItems = 'center',
    color = getColor(EColors.bizlyOSPrimary),
    backgroundColor = getColor(EColors.bizlyOSPrimary, 0.1),
}: BasicInfoBoxProps) => {
    const Icon = icon ? React.cloneElement(icon, { sx: { color } }) : null;

    return (
        <InfoContainer sx={{ alignItems, backgroundColor }}>
            {Icon}
            {React.isValidElement(message) ? message : <Body2>{message}</Body2>}
        </InfoContainer>
    );
};
