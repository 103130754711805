import { Box, styled } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { Switch } from 'components/BizlyOS/InputFields/SwitchField';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { TFBRequest } from 'components/ProposalForm/EventSpacesForm/utils';
import { TESBooking, TProposal } from 'components/ProposalForm/types';
import { useUpdateProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

const BorderedContainer = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    border: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

const TitleContainer = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    paddingBottom: spacing(2.5),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

function copyEventSpaceValues(targetSpace: TESBooking, sourceSpace: TESBooking) {
    const proposedAvIds: number[] = [];

    sourceSpace.proposedAvIds?.forEach(avId => {
        if (targetSpace.requestedAvIds?.includes(avId)) proposedAvIds.push(avId);
    });

    const proposedFb: TFBRequest[] = [];
    sourceSpace?.proposedFb?.forEach(fb => {
        const requestFb = targetSpace.requestedFb?.find(rfb => rfb.fbOptionId === fb.fbOptionId);
        if (requestFb) proposedFb.push(fb);
    });

    return {
        ...targetSpace,
        proposedVenueSpaceId: sourceSpace.proposedVenueSpaceId,
        proposedSetupId: sourceSpace.proposedSetupId,
        proposedAvIds: proposedAvIds || [],
        proposedFb: proposedFb || [],
        proposedRatePerPerson: sourceSpace.proposedRatePerPerson,
        proposedMinGuests: sourceSpace.proposedMinGuests,
        proposedRoomRate: sourceSpace.proposedRoomRate,
        proposedFbMinimum: sourceSpace.proposedFbMinimum,
    };
}

type ReuseDetailsProps = {
    currentEventSpace: TESBooking;
    eventSpaces: TESBooking[];
    eventSpaceIndex: number;
    proposal?: Partial<TProposal> & {
        currencyCode?: string;
        currencyId?: number;
    };
    isLastSpace: boolean;
};

export function ReuseDetails({
    eventSpaces,
    eventSpaceIndex,
    currentEventSpace,
    proposal,
    isLastSpace,
}: ReuseDetailsProps) {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const updateProposalInquiry = useUpdateProposalInquiry(venueId, proposalId);

    const { enqueueSnackbar } = useSnackbar();

    const [isNext, setIsNext] = useState(false);
    const [isAll, setIsAll] = useState(false);

    const onSubmit = () => {
        let newEventSpaces: TESBooking[] = [];

        if (isAll) {
            newEventSpaces = eventSpaces.map(space => copyEventSpaceValues(space, currentEventSpace));
        } else if (isNext) {
            const newEventSpace = copyEventSpaceValues(eventSpaces[eventSpaceIndex + 1], currentEventSpace);
            newEventSpaces = [newEventSpace];
        }

        if (newEventSpaces.length === 0) return;

        updateProposalInquiry.mutate(
            {
                eventSpaces: newEventSpaces,
                proposal,
            },
            {
                onSuccess: () => {
                    enqueueSnackbar('Successfully updated! ', { variant: 'success' });
                },
                onError: error => {
                    enqueueSnackbar('Something went wrong! ' + error, { variant: 'error' });
                },
            }
        );
    };

    const onCancel = () => {
        setIsNext(false);
        setIsAll(false);
    };

    return (
        <>
            <BorderedContainer>
                <TitleContainer>
                    <Body1 fontWeight={600}>Reuse Request Details</Body1>
                </TitleContainer>

                {!isLastSpace && (
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Body2 fontWeight={500}>Copy to next request</Body2>
                        <Switch
                            disabled={isAll}
                            checked={isNext || isAll}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setIsNext(e.target.checked);
                            }}
                        />
                    </Box>
                )}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Body2 fontWeight={500}>Copy to all requests</Body2>
                    <Switch
                        checked={isAll}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setIsAll(e.target.checked);
                        }}
                    />
                </Box>
            </BorderedContainer>
            {(isNext || isAll) && (
                <Box display="flex" justifyContent="flex-end" gap={1.25}>
                    <Button variant="outline" disableElevation onClick={onCancel} sx={{ width: 100, height: 37 }}>
                        Cancel
                    </Button>
                    <Button
                        loading={updateProposalInquiry.isLoading}
                        type="submit"
                        onClick={onSubmit}
                        sx={{ width: 100, height: 37 }}
                    >
                        {updateProposalInquiry.isLoading ? 'Saving...' : 'Save'}
                    </Button>
                </Box>
            )}
        </>
    );
}
