import { zodResolver } from '@hookform/resolvers/zod';
import { Box, styled } from '@mui/material';
import { TwoColumnsRow } from 'components/BizlyOS/GridContainer';
import HeaderFormActions from 'components/BizlyOS/HeaderFormActions';
import { BasicInfoBox } from 'components/BizlyOS/InfoBoxes';
import DatePicker from 'components/BizlyOS/InputFields/DatePicker';
import { FileInputField } from 'components/BizlyOS/InputFields/FileInputField';
import TextArea from 'components/BizlyOS/InputFields/TextArea';
import TimePicker from 'components/BizlyOS/InputFields/TimePicker';
import { Body2 } from 'components/BizlyOS/Typography/Typography';
import { useGetProposalInquiry, useUpdateProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { z } from 'zod';

const DEFAULT_TIMEZONE = 'UTC';
const HARDCODED_TIME = '23:59:59';

const BorderedContainer = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    border: `0.5px solid ${getColor(EColors.bizlyOSBorder)}`,
    borderRadius: spacing(1),
    boxSizing: 'border-box',
    padding: spacing(2.5),
}));

const Title = styled(Body2)(({ theme: { getColor, EColors } }) => ({
    lineHeight: 'normal',
    fontWeight: 500,
    '& > span': {
        color: getColor(EColors.bizlyOSPrimary),
    },
}));

const Description = styled(Body2)(({ theme: { getColor, EColors, spacing } }) => ({
    lineHeight: 'normal',
    marginTop: spacing(1.25),
    marginBottom: spacing(2.5),
    paddingBottom: spacing(2.5),
    borderBottom: `0.5px solid ${getColor(EColors.bizlyOSBorder)}`,
}));

const FileSchema = z.object({
    id: z.string(),
    url: z.string().url({ message: 'Must be a valid URL' }),
    name: z.string().min(1, { message: 'Image name is required' }),
    size: z.number(),
});

const FinalDetailsSchema = z.object({
    expirationDate: z.string().min(1, 'Required'),
    additionalDetails: z.string().optional(),
    expirationTime: z.string().optional(),
    files: z.array(FileSchema).optional(),
});

type FinalDetailsFormData = z.infer<typeof FinalDetailsSchema>;

export function FinalDetails() {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: proposalInquiry, isLoading } = useGetProposalInquiry(venueId, proposalId);
    const proposal = proposalInquiry?.proposal || {};

    const updateProposalInquiry = useUpdateProposalInquiry(venueId, proposalId);

    const {
        control,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting, isValid },
        reset,
    } = useForm<FinalDetailsFormData>({
        resolver: zodResolver(FinalDetailsSchema),
        defaultValues: {
            additionalDetails: proposal.additionalDetails || '',
            expirationDate: proposal.expiryDate || '',
            expirationTime: HARDCODED_TIME,
            files: proposal?.attachments
                ? proposal.attachments.map(file => ({
                      id: crypto.randomUUID() as string,
                      name: file.name || '',
                      url: file.url,
                      size: file.bytes || 0,
                  }))
                : [],
        },
        mode: 'onChange',
    });

    const onSubmit = (data: FinalDetailsFormData) => {
        const newProposal = {
            ...proposal,
            additionalDetails: data.additionalDetails,
            expiryDate: `${data.expirationDate} ${data.expirationTime}`,
            attachments:
                data?.files?.map(file => ({ name: file.name, url: file.url, bytes: file.size, format: '' })) || null,
        };

        updateProposalInquiry.mutate({ proposal: newProposal }, { onSuccess: () => reset(data) });
    };

    if (isLoading || !proposalInquiry) return null;

    const venue = proposalInquiry.venue;

    return (
        <>
            <Box display="flex" flexDirection="column" gap={2.5}>
                <BasicInfoBox
                    alignItems="flex-start"
                    color={getColor(EColors.primaryAction)}
                    message="Double check that your proposal is complete and accurate. This is your chance to share any additional information before submitting. "
                />
                <BorderedContainer>
                    <Title>
                        Proposal Expiration <span>*</span>
                    </Title>
                    <Description>Provide the latest date you can hold this space.</Description>
                    <TwoColumnsRow>
                        <Controller
                            name="expirationDate"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <DatePicker
                                        {...field}
                                        required
                                        error={errors.expirationDate}
                                        value={field.value}
                                        disablePast
                                        iconColor={getColor(EColors.pureBlack)}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="expirationTime"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <TimePicker
                                        value={field.value}
                                        error={errors.expirationTime}
                                        onAccept={field.onChange}
                                        iconColor={getColor(EColors.pureBlack)}
                                        showTimeZone
                                        timeZone={venue.timeZone || DEFAULT_TIMEZONE}
                                        disabled
                                    />
                                );
                            }}
                        />
                    </TwoColumnsRow>
                </BorderedContainer>
                <BorderedContainer>
                    <Title>Notes to Client</Title>
                    <Description>
                        Use this space to answer client questions or to address any additional concessions.
                    </Description>
                    <Controller
                        name="additionalDetails"
                        control={control}
                        render={({ field }) => {
                            return (
                                <TextArea
                                    {...field}
                                    error={errors.additionalDetails}
                                    placeholder="Write..."
                                    minRows={6}
                                />
                            );
                        }}
                    />
                </BorderedContainer>
                <BorderedContainer>
                    <Title>Files</Title>
                    <Description>
                        Upload and share important files like menus, floor plans, and A/V pricing with the client.
                    </Description>
                    <Controller
                        name="files"
                        control={control}
                        render={({ field }) => {
                            return (
                                <FileInputField
                                    required
                                    label="Room Image"
                                    values={field?.value || []}
                                    onChange={field.onChange}
                                    error={!!errors.files}
                                />
                            );
                        }}
                    />
                </BorderedContainer>
            </Box>
            <HeaderFormActions
                state="edit"
                isSaving={isSubmitting || updateProposalInquiry.isLoading}
                isChanges={isDirty}
                isValid={isValid}
                onSave={handleSubmit(onSubmit)}
                onCancel={() => reset()}
            />
        </>
    );
}
