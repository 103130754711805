import React from 'react';
import { loadEventOptions } from '../api';
import { useAsync } from '../hooks/useAsync';

const initialState: Bizly.EventOptions = {
    attendeeQuestionDefinitions: [],
    avOptions: [],
    dietaryRestrictionOptions: [],
    fbOptions: [],
    foodAllergyOptions: [],
    setupOptions: [],
};

const optionsCache = new Map<string, Promise<Bizly.EventOptions>>();

export const EventOptionsContext = React.createContext(initialState);

export function useEventOptions() {
    const context = React.useContext(EventOptionsContext);
    if (context === undefined) {
        throw new Error('useEventOptions must be used within a EventOptionsProvider');
    }
    return context;
}

type EventOptionsProviderProps = {
    eventId: string;
    children: React.ReactNode;
};

function EventOptionsProvider({ eventId, children }: EventOptionsProviderProps) {
    const loadOptions = React.useCallback(() => {
        // Return cached promise if it exists
        if (optionsCache.has(eventId)) {
            return optionsCache.get(eventId)!;
        }

        // Create new promise and cache it
        const eventOptionsPromise = loadEventOptions(eventId);
        optionsCache.set(eventId, eventOptionsPromise);

        eventOptionsPromise.catch(error => {
            console.error('Error loading event options', error);
            optionsCache.delete(eventId);
        });

        return eventOptionsPromise;
    }, [eventId]);

    const { data: eventOptions } = useAsync(loadOptions, initialState);

    return (
        <EventOptionsContext.Provider value={eventOptions as Bizly.EventOptions}>
            {children}
        </EventOptionsContext.Provider>
    );
}

export default EventOptionsProvider;
