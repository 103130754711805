import { Box, Step, Stepper, styled } from '@mui/material';
import { StepConnector, StepIcon, StepLabel } from 'components/BizlyOS/ListingsClaim/Stepper';
import { Body1, Heading1 } from 'components/BizlyOS/Typography/Typography';
import BizlyLogo from 'images/bizlyOS/bizly-logo.svg?react';
import React from 'react';
import { EColors, getColor } from 'theme';
import ContactDetails from './ContactDetails';
import SearchVenue from './SearchVenue';
import Successful from './Successful';
import VenueLocation from './VenueLocation';

const ClaimSteps = [
    { label: 'Venue Location', Element: VenueLocation },
    { label: 'Search Venue', Element: SearchVenue },
    { label: 'Contact Details', Element: ContactDetails },
];

const LoginInfo = styled(Body1)({
    marginBottom: '70px',
    '& span': { fontWeight: 600, textDecoration: 'underline', cursor: 'pointer' },
});

type ListingsClaimDataType = {
    venue?: {
        name: string;
        placeId: string;
    };
    venueLocation?: {
        name: string;
        placeId: string;
    };
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    jobTitle?: string;
    department?: string;
};

export type ClaimStepProps = {
    onNextStepClicked: (data: ListingsClaimDataType) => void;
    onBackClicked: () => void;
    isLastStep: boolean;
    isFirstStep: boolean;
    listingsClaimData: ListingsClaimDataType;
};

export default function ListingsClaim() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [listingsClaimData, setListingsClaimData] = React.useState<ListingsClaimDataType>({});

    const onNextStepClicked = (data: ListingsClaimDataType) => {
        const newData = { ...listingsClaimData, ...data };
        setListingsClaimData(newData);
        if (activeStep < ClaimSteps.length) {
            setActiveStep(activeStep + 1);
        } else {
            // TODO: handel claim submit here..
        }
    };

    const onBackClicked = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    };

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <Box
                width="540px"
                boxSizing="border-box"
                height="100%"
                bgcolor={getColor(EColors.pureBlack)}
                padding={6.25}
            >
                <BizlyLogo />
                <Heading1 color={EColors.pureWhite} marginTop="40px" marginBottom="10px">
                    Claim your venue’s listing
                </Heading1>
                <Body1 color={EColors.pureWhite} marginBottom="20px">
                    <span style={{ fontWeight: 700 }}>We’re excited that you want to claim your Bizly listing.</span> We
                    just need to confirm some information to get started
                </Body1>
                <LoginInfo color={EColors.pureWhite}>
                    If you already have a merchant account, <span>Log In</span>.
                </LoginInfo>
                <Stepper activeStep={activeStep} connector={<StepConnector />} orientation="vertical">
                    {ClaimSteps.map(({ label }, index) => (
                        <Step key={label} completed={index < activeStep}>
                            <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            {activeStep < ClaimSteps.length ? (
                React.createElement(ClaimSteps[activeStep].Element, {
                    onNextStepClicked,
                    onBackClicked,
                    isLastStep: activeStep + 1 === ClaimSteps.length,
                    isFirstStep: activeStep === 0,
                    listingsClaimData,
                })
            ) : (
                <Successful />
            )}
        </Box>
    );
}
