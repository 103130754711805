import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useFeatureFlagEnabled } from 'posthog-js/react';
import { PostHogFeatureFlags } from 'shared';
import styled from 'styled-components';
import { i18n } from 'translation';
import RightDrawer from './Drawer/RightDrawer';
import InquiryCartRightDrawer from './InquiryCartRightDrawer';
import InquiryCartRightDrawerV2 from './InquiryCartRightDrawerV2';

const RightDrawerContent = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 1em;
    height: 100%;
`;

type RightDrawerVenuesProps = {
    isOpen: boolean;
    footer?: React.ReactNode;
    onClose?: () => void;
    onRemoveVenue?: (venue: Bizly.Venue) => void;
};

export default function RightDrawerVenues(props: RightDrawerVenuesProps) {
    const newVenueTileLayout = useFeatureFlagEnabled(PostHogFeatureFlags.toggleNewVenueTileLayout);
    const { isOpen, footer, onClose, onRemoveVenue } = props;

    const handleClose = () => {
        onClose?.();
        return false;
    };

    return (
        <RightDrawer drawerOpen={isOpen} onClose={handleClose} title={i18n.venue.selectedVenues} footer={footer}>
            <RightDrawerContent>
                {!newVenueTileLayout ? (
                    <>
                        <Typography>{i18n.meetingsPage.venueDescription}</Typography>
                        <Box>
                            <InquiryCartRightDrawer onClose={handleClose} onRemoveVenue={onRemoveVenue} />
                        </Box>
                    </>
                ) : (
                    <InquiryCartRightDrawerV2 onClose={handleClose} onRemoveVenue={onRemoveVenue} />
                )}
            </RightDrawerContent>
        </RightDrawer>
    );
}
