import { tzMoment } from 'utils/moment';

// Type-safe query param helpers
export const getStringParam = (value: unknown): string | undefined => (typeof value === 'string' ? value : undefined);

export const getMomentParam = (value: unknown): moment.Moment | undefined => {
    if (typeof value !== 'string') return undefined;
    const m = tzMoment(value);
    return m.isValid() ? m : undefined;
};

export const getNumberParam = (value: unknown): number | undefined => {
    if (typeof value !== 'string') return undefined;
    const num = Number(value);
    return Number.isFinite(num) ? num : undefined;
};
