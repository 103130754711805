import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, styled } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { getAvRequests, getFbRequests, getRoomSetup } from 'components/BizlyOS/Proposals/utils';
import { Body1 } from 'components/BizlyOS/Typography/Typography';
import { TESBooking, TProposalOptions } from 'components/ProposalForm/types';
import React from 'react';
import { EColors, getColor } from 'theme';
import { tzMoment } from 'utils/moment';
import { EventSpaceProposedDetails } from './EventSpaceProposedDetails';
import { EventSpaceRequestedDetails } from './EventSpaceRequestedDetails';

const DATE_FORMAT = 'MMM DD, YYYY';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)(
    ({ theme }) => ({
        border: `0.5px solid ${theme.palette.divider}`,
        '&::before': {
            display: 'none',
        },
        borderRadius: theme.shape.borderRadius,
    })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: getColor(EColors.pureBlack), fontSize: 22 }} />}
        {...props}
    />
))(({ theme }) => ({
    height: '64px',
    padding: theme.spacing(2.5),
    '& .MuiAccordionSummary-content': {
        margin: 0,
    },
    '& .MuiAccordionSummary-content > p': {
        fontSize: '1rem',
        fontWeight: '600',
        lineHeight: '24px',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2.5),
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
}));

type EventSpacesAccordionProps = {
    index: number;
    expanded: boolean;
    eventSpace: TESBooking;
    options?: TProposalOptions;
    isFilled?: boolean;
    requestedDetailsOnly?: boolean;
    onExpandChange: (panel: number) => (_: React.SyntheticEvent, newExpanded: boolean) => void;
};

function EventSpacesAccordion({
    index,
    expanded,
    eventSpace,
    options,
    isFilled = false,
    requestedDetailsOnly = false,
    onExpandChange,
}: EventSpacesAccordionProps) {
    const avRequests = getAvRequests(options, eventSpace.requestedAvIds);
    const fbRequests = getFbRequests(options, eventSpace.requestedFb);
    const spaceConfiguration = getRoomSetup(options, eventSpace.requestedSetupId);
    const requestedDate = tzMoment(eventSpace.requestedDate).format(DATE_FORMAT);
    const requestedTime = `${eventSpace?.requestedStartTime} - ${eventSpace?.requestedEndTime}`;

    return (
        <Accordion key={index} expanded={expanded} onChange={onExpandChange(index)}>
            <AccordionSummary>
                <Box display="flex" alignItems="center" gap={1.25}>
                    {!requestedDetailsOnly && (
                        <CheckCircleIcon
                            fontSize="medium"
                            color="disabled"
                            sx={{
                                color: isFilled ? getColor(EColors.bizlyOSPrimary) : getColor(EColors.bizlyOSBorder),
                            }}
                        />
                    )}
                    <Body1 fontWeight={500}>{eventSpace.requestedSpaceName || '-'}</Body1>
                    <CircleIcon sx={{ fontSize: 5 }} />
                    <Body1>{`${requestedDate} (${requestedTime})`}</Body1>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box borderBottom={`0.5px solid ${getColor(EColors.bizlyOSBorder)}`} />
                <EventSpaceRequestedDetails
                    spaceName={eventSpace.requestedSpaceName || '-'}
                    headcount={eventSpace?.requestedGuests?.toString() || ''}
                    avRequests={avRequests}
                    fbRequests={fbRequests}
                    spaceConfiguration={spaceConfiguration}
                    time={requestedTime}
                    requestedDate={requestedDate}
                />
                {!requestedDetailsOnly && <EventSpaceProposedDetails eventSpace={eventSpace} eventSpaceIndex={index} />}
            </AccordionDetails>
        </Accordion>
    );
}

export default EventSpacesAccordion;
