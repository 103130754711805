import { Box, BoxProps } from '@mui/material';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { EColors } from 'theme';

type TextCardProps = {
    title: string;
    description?: string;
    descriptions?: string[];
    fallbackText?: string;
} & BoxProps;

export function TextCard({ title, description, descriptions = [], fallbackText = '-', ...boxProps }: TextCardProps) {
    const showDescriptions = descriptions.length > 0;
    const hasSingleDescription = !!description;

    return (
        <Box flex={1} display="flex" flexDirection="column" gap={0.75} {...boxProps}>
            <Body2 lineHeight="normal" color={EColors.pureBlack} sx={{ opacity: 0.5 }} fontWeight={500}>
                {title}
            </Body2>
            {hasSingleDescription ? (
                <Body1 lineHeight="normal">{description || fallbackText}</Body1>
            ) : showDescriptions ? (
                descriptions.map((desc, index) => (
                    <Body1 key={index} lineHeight="normal">
                        {desc || fallbackText}
                    </Body1>
                ))
            ) : (
                <Body1 lineHeight="normal">-</Body1>
            )}
        </Box>
    );
}
