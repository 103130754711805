import {
    CalendarMonthOutlined,
    CelebrationOutlined,
    CottageOutlined,
    DirectionsBusOutlined,
    EmojiEventsOutlined,
    EnergySavingsLeafOutlined,
    ExploreOutlined,
    RestaurantOutlined,
    StarOutlineOutlined,
    SvgIconComponent,
} from '@mui/icons-material';
import FlightIcon from '@mui/icons-material/Flight';
import { Box, Collapse, Typography, styled } from '@mui/material';
import { Button } from 'components/Ui-V2/Button/Button';
import LoadingAnimation from 'components/Venues/InsightsAndKeyDetails/LoadingAnimation';
import { useMemo, useState } from 'react';
import { EColors, getColor } from 'theme';
import { VenueData } from 'types/stream';
import { getVenueFeatures } from 'utils/getVenueFeatures';
import { useVenueDetailsQuery } from '../../../hooks/queries/useVenueDetailsQuery';

interface StreamingDetail {
    key: string;
    section: string;
}

interface KeyDetailsSectionProps {
    venue: Bizly.Venue;
    initialVisibleItems?: number;
}

const IconMap: Record<string, SvgIconComponent> = {
    property_overview: CottageOutlined,
    meeting_spaces: CelebrationOutlined,
    dining_options: RestaurantOutlined,
    seasonality: CalendarMonthOutlined,
    airports: FlightIcon,
    transit: DirectionsBusOutlined,
    nearby: ExploreOutlined,
    ratings: StarOutlineOutlined,
    awards: EmojiEventsOutlined,
    sustainability: EnergySavingsLeafOutlined,
};

const StyledContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px 24px',
    background: getColor(EColors.pureWhite),
    border: `1px solid ${getColor(EColors.placeholderBackground)}`,
    borderRadius: '12px',
    flex: 'none',
    order: 1,
    alignSelf: 'stretch',
    flexGrow: 0,
    overflowY: 'auto',
});

const TitleTypography = styled(Typography)({
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '25px',
    paddingBottom: '16px',
    borderBottom: `1px solid ${getColor(EColors.greyBorder)}`,
    width: '100%',
    marginBottom: '15px',
});

const IconContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
});

const SectionTitle = styled(Typography)({
    fontWeight: 600,
    fontSize: '1rem',
    color: getColor(EColors.bizlyInsightsHeaderBlack),
    lineHeight: '1.4rem',
});

const KeyDetailsItem = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px 0',
    borderBottom: `1px solid ${getColor(EColors.greyBorder)}`,
});

const ExpandMoreButton = styled(Button)({
    border: `1px solid ${getColor(EColors.bizlyInsightsBlue)}`,
    color: getColor(EColors.bizlyInsightsBlue),
    fontWeight: 500,
    fontSize: '14px',
    cursor: 'pointer',
});

export const KeyDetailsSection = ({ venue, initialVisibleItems = 1 }: KeyDetailsSectionProps) => {
    const [showAll, setShowAll] = useState(false);

    // Memoize venue data to prevent unnecessary recreations
    const venueData = useMemo<VenueData>(
        () => ({
            name: venue.name,
            address: venue.address,
            type: venue.type?.name,
            city: venue.neighborhood?.split(',')[0],
            state: venue.neighborhood?.split(',')[1]?.trim(),
            country: 'United States',
            description: venue.summary,
            amenities: (venue.spaceTypes || []).map(type => String(type)),
            features: getVenueFeatures(venue),
            rating: venue.qualityLevel,
            priceLevel: venue.maxCapacity ? `$${venue.maxCapacity}` : undefined,
        }),
        [venue]
    );

    const details: StreamingDetail[] = [
        { key: 'property_overview', section: 'Property Overview' },
        { key: 'meeting_spaces', section: 'Meeting Spaces' },
        { key: 'dining_options', section: 'Dining Options' },
        { key: 'seasonality', section: 'Seasonality' },
        { key: 'airports', section: 'Nearest Airports' },
        { key: 'transit', section: 'Major Transportation' },
        { key: 'nearby', section: 'Nearby Activities & Attractions' },
        { key: 'ratings', section: 'Star Ratings & Reviews' },
        { key: 'awards', section: 'Awards & Accolades' },
        { key: 'sustainability', section: 'Sustainability Practices' },
    ];

    // Create queries for each detail section
    const queries = {
        property_overview: useVenueDetailsQuery(venueData, 'property_overview'),
        meeting_spaces: useVenueDetailsQuery(venueData, 'meeting_spaces'),
        dining_options: useVenueDetailsQuery(venueData, 'dining_options'),
        seasonality: useVenueDetailsQuery(venueData, 'seasonality'),
        airports: useVenueDetailsQuery(venueData, 'airports'),
        transit: useVenueDetailsQuery(venueData, 'transit'),
        nearby: useVenueDetailsQuery(venueData, 'nearby'),
        ratings: useVenueDetailsQuery(venueData, 'ratings'),
        awards: useVenueDetailsQuery(venueData, 'awards'),
        sustainability: useVenueDetailsQuery(venueData, 'sustainability'),
    };

    const renderDetailItem = (detail: StreamingDetail, index: number) => {
        const Icon = IconMap[detail.key];
        const query = queries[detail.key as keyof typeof queries];
        const queryData = query.data;

        if (query.isLoading) {
            return <LoadingAnimation key={index} />;
        }

        return (
            <KeyDetailsItem key={index}>
                <Box>
                    <IconContainer>
                        <Icon sx={{ color: getColor(EColors.primaryAction), fontSize: 20 }} />
                        <SectionTitle>{detail.section}</SectionTitle>
                    </IconContainer>
                    <Box sx={{ pl: '30px', mt: '8px' }}>
                        <Typography lineHeight="24px" fontWeight={400} fontSize={16}>
                            {queryData?.words?.join(' ')}
                        </Typography>
                    </Box>
                </Box>
            </KeyDetailsItem>
        );
    };

    return (
        <StyledContainer>
            <TitleTypography variant="h5" component="h2">
                Key Details
            </TitleTypography>

            <Box sx={{ width: '100%' }}>
                {/* Always visible items */}
                {details.slice(0, initialVisibleItems).map((detail, index) => renderDetailItem(detail, index))}

                {/* Collapsible items */}
                <Collapse in={showAll} timeout="auto">
                    {details
                        .slice(initialVisibleItems)
                        .map((detail, index) => renderDetailItem(detail, index + initialVisibleItems))}
                </Collapse>

                {details.length > initialVisibleItems && (
                    <ExpandMoreButton
                        variant="outlined"
                        size="small"
                        fullWidth
                        onClick={() => setShowAll(!showAll)}
                        sx={{ mt: '15px', border: `1px solid ${getColor(EColors.greyBorder)}` }}
                    >
                        {showAll ? 'Show Less' : 'View More'}
                    </ExpandMoreButton>
                )}
            </Box>
        </StyledContainer>
    );
};
