import { Box } from '@mui/material';
import DietaryPreferences from 'components/Rsvp-V2/RsvpForm/DietaryPreferences ';
import GuestDetails from 'components/Rsvp-V2/RsvpForm/GuestDetails';
import AddQuestions from 'components/Rsvp-V2/RsvpForm/Questions/AddQuestions';
import RoomBlocksQuestion from 'components/Rsvp-V2/RsvpForm/RoomBlocksQuestion';
import { TabPlanelProps } from './WebRegistrationContent';

function RsvpForm(props: TabPlanelProps) {
    if (!props.parcel.id) {
        return null;
    }

    return (
        <Box display="flex" flexDirection="column" gap={2} marginTop={2.5}>
            <GuestDetails />
            <DietaryPreferences {...props} />
            <RoomBlocksQuestion {...props} />
            <AddQuestions
                title="Add Question"
                parcelId={props.parcel.id}
                disabled={props.isDisabled}
                scrollToElement={props.scrollToElement}
            />
        </Box>
    );
}

export default RsvpForm;
