import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { SpinnerOverlay } from 'components/Spinner';
import TextButton from 'components/ui/Button/TextButton';
import { H2Headline as UIH2Headline } from 'components/ui/Headline';
import fontFns from 'fontFns';
import React from 'react';
import { getColor } from 'theme';
import { i18n } from 'translation';
import { EColors } from 'types/themenum';
import { Column, Copy, Row, Spacer } from 'ui';

const Modal = styled(Column)({
    width: '400px',
    padding: '24px',
});

const H2Headline = styled(UIH2Headline, {
    shouldForwardProp: prop => prop !== 'warning',
})<{ warning?: boolean }>(({ warning }) => ({
    color: warning ? getColor(EColors.warningText) : getColor(EColors.accentedHeadline),
    textTransform: 'none',
    ...fontFns.heading,
}));

const EndRow = styled(Row)({
    justifyContent: 'flex-end',
});

const PreCopy = styled(Copy)({
    whiteSpace: 'pre-line',
});

type TConfirmationModal = {
    isActive?: boolean;
    warning?: boolean;

    onDismiss?: (
        event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>,
        reason?: 'backdropClick' | 'escapeKeyDown'
    ) => void;
    onProceed?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

    headline?: React.ReactNode;
    prompt?: React.ReactNode;
    ctaLabel?: React.ReactNode;
    dismissLabel?: React.ReactNode;

    loading?: boolean;
    children?: React.ReactNode;
};

const ConfirmationModal = ({
    isActive,
    warning,
    onDismiss,
    onProceed,
    headline = warning ? i18n.modal.areYouSure : null,
    prompt,
    ctaLabel = warning ? i18n.button.delete : i18n.button.continue,
    dismissLabel = i18n.button.cancel,
    loading,
    children,
}: TConfirmationModal) => {
    const handleClose = () => {
        onDismiss?.();
        return false;
    };

    return (
        <Dialog open={!!isActive} onClose={handleClose}>
            <Modal>
                {headline && (
                    <>
                        <H2Headline warning={warning}>{headline}</H2Headline>
                        <Spacer />
                    </>
                )}
                {prompt && (
                    <>
                        <PreCopy>{prompt}</PreCopy>
                        <Spacer />
                    </>
                )}
                {children ? (
                    <>{children}</>
                ) : (
                    <EndRow itemSpacing="smallish">
                        {onDismiss && (
                            <TextButton secondary onClick={handleClose}>
                                {dismissLabel}
                            </TextButton>
                        )}
                        {onProceed && (
                            <TextButton warning={warning} onClick={onProceed}>
                                {ctaLabel}
                            </TextButton>
                        )}
                    </EndRow>
                )}
                {loading && <SpinnerOverlay />}
            </Modal>
        </Dialog>
    );
};

export default ConfirmationModal;
