import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, styled } from '@mui/material';
import { EColors, getColor } from 'theme';
import { Button } from './Button/Button';
import { Body2 } from './Typography/Typography';

const Container = styled(Box)(({ theme: { spacing, shape } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(0.5),
    borderRadius: shape.borderRadius,
    height: 30,
    paddingRight: spacing(2.5),
    position: 'absolute',
    right: 0,
    top: 75,
}));

const Badge = styled(Box)(({ theme: { spacing, shape } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(0.5),
    borderRadius: shape.borderRadius,
    height: 30,
    padding: '0 10px',
}));

const UnSaveBadge = styled(Badge)(({ theme: { getColor, EColors } }) => ({
    background: getColor(EColors.bizlyOSWarning, 0.2),
    color: getColor(EColors.pureBlack),
}));

const LastUpdatedBadge = styled(Badge)(({ theme: { getColor, EColors } }) => ({
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.2),
    color: getColor(EColors.pureBlack),
}));

type ListingActionsProps = {
    state: 'create' | 'edit' | 'view';
    propertyTitle?: string;
    lastModified?: string;
    isChanges?: boolean;
    isSaving?: boolean;
    isValid?: boolean;
    onSave?: () => void;
    onCancel?: () => void;
};

export default function HeaderFormActions({
    state,
    isChanges,
    onSave,
    onCancel,
    lastModified,
    isValid = true,
    isSaving = false,
}: ListingActionsProps) {
    return (
        <Container>
            <Box display="flex" alignItems="center" gap={1.25}>
                {state !== 'view' ? (
                    <>
                        {isChanges && (
                            <>
                                <UnSaveBadge>
                                    <InfoOutlinedIcon fontSize="small" />
                                    <Body2 fontWeight={500}>Unsaved Changes</Body2>
                                </UnSaveBadge>
                                <Button variant="outline" sx={{ height: 30 }} onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button disabled={isSaving || !isValid} sx={{ height: 30 }} onClick={onSave}>
                                    {isSaving ? 'Saving...' : 'Save'}
                                </Button>
                            </>
                        )}
                    </>
                ) : (
                    <LastUpdatedBadge>
                        <HistoryOutlinedIcon fontSize="small" sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                        <Body2 fontWeight={500}>Last Updated:</Body2>
                        <Body2>{lastModified}</Body2>
                    </LastUpdatedBadge>
                )}
            </Box>
        </Container>
    );
}
