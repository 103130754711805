import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, ButtonProps, Dialog, DialogContent, DialogProps, Slide, styled, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import publishedAnimationData from 'animations/published.json';
import { Button } from 'components/Ui-V2/Button/Button';
import Lottie from 'lottie-react';
import React, { JSXElementConstructor } from 'react';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<unknown, JSXElementConstructor<unknown>>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialog = styled((props: DialogProps) => (
    <Dialog TransitionComponent={Transition} transitionDuration={300} {...props} />
))(({ theme }) => ({ '& .MuiPaper-root': { borderRadius: theme.spacing(1.5) } }));

const StyledDialogContent = styled(DialogContent)(({ theme: { spacing } }) => ({
    boxSizing: 'border-box',
    padding: spacing(5),
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
}));

const CopyLinkButton = styled((props: ButtonProps) => (
    <Button variant="text" startIcon={<ContentCopyIcon />} {...props} />
))({
    height: '40px',
});

const AnimationContainer = styled(Box)({
    width: '60px',
    height: '60px',
});

type PublishedConfirmationModalType = {
    open: boolean;
    onClose: () => void;
    onCopyLink: () => void;
};

function PublishedConfirmationModal({ open, onClose, onCopyLink }: PublishedConfirmationModalType) {
    return (
        <StyledDialog open={open} onClose={onClose}>
            <StyledDialogContent>
                <AnimationContainer>
                    <Lottie animationData={publishedAnimationData} loop />
                </AnimationContainer>
                <Box display="flex" flexDirection="column" gap={1}>
                    <Typography variant="h6" fontWeight={600}>
                        Published!
                    </Typography>
                    <Typography variant="body1">
                        Your registration page is now live! Click the button below to copy the link and share it with
                        your network to gather registrations.
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap={1.25}>
                    <CopyLinkButton fullWidth onClick={onCopyLink} variant="contained">
                        Copy Link
                    </CopyLinkButton>
                    <Button fullWidth onClick={onClose} variant="outlined" sx={{ height: 40 }}>
                        Close
                    </Button>
                </Box>
            </StyledDialogContent>
        </StyledDialog>
    );
}

export default PublishedConfirmationModal;
