import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Button } from 'components/Ui-V2/Button/Button';
import { i18n } from 'translation';
import { emailIsValid } from 'utils';

interface PlannerLoginProps {
    email: string;
    isLoading: boolean;
    onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onSendLink: () => void;
}

export const PlannerLogin = ({ email, isLoading, onEmailChange, onKeyPress, onSendLink }: PlannerLoginProps) => {
    return (
        <>
            <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 500 }}>
                {i18n.signIn.welcome}
            </Typography>
            <Typography variant="body1" paragraph>
                Enter the email associated with your account, and we'll send you a magic link for instant access.
            </Typography>

            <TextField
                fullWidth
                variant="outlined"
                type="email"
                value={email || ''}
                onChange={onEmailChange}
                onKeyUp={onKeyPress}
                placeholder="Enter your email"
                margin="normal"
                autoFocus
                disabled={isLoading}
                sx={{ marginTop: '20px', marginBottom: '10px' }}
            />

            <Button
                fullWidth
                variant="contained"
                onClick={onSendLink}
                disabled={!email || !emailIsValid(email) || isLoading}
                sx={{ mb: 2, height: '50px' }}
            >
                Send Link
            </Button>
        </>
    );
};
