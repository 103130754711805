import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button } from '../Button/Button';

const ActionButtonContainer = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(2),
}));

interface DrawerActionButtonsProps {
    onCancel: () => void;
    onSubmit: () => void;
    isSubmitting: boolean;
    isValid: boolean;
    goBackEnabled?: boolean;
}

export const DrawerActionButtons = ({
    onCancel,
    onSubmit,
    isSubmitting,
    goBackEnabled = false,
    isValid,
}: DrawerActionButtonsProps) => {
    return goBackEnabled ? (
        <ActionButtonContainer>
            <Button variant="outline" disableElevation onClick={onCancel}>
                Go back
            </Button>
            {/* temporally disable if the goBackEnabled is true, remove it after API confirmation */}
            <Button type="submit" disabled={isSubmitting || !isValid || goBackEnabled} onClick={onSubmit}>
                {isSubmitting ? 'Inviting...' : 'Invite'}
            </Button>
        </ActionButtonContainer>
    ) : (
        <Box display="flex" justifyContent="flex-end" gap={1.25}>
            <Button variant="outline" disableElevation onClick={onCancel}>
                Cancel
            </Button>
            <Button
                type="submit"
                disabled={isSubmitting || !isValid}
                onClick={onSubmit}
                sx={{ width: 150, height: 37 }}
            >
                {isSubmitting ? 'Saving...' : 'Save'}
            </Button>
        </Box>
    );
};
