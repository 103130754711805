import { Box, DialogContent, Typography, alpha, styled } from '@mui/material';
import TileInfo, { InfoColumn } from 'components/VenueTile/TileInfo';

const HEADER_HEIGHT = 224;
const HEADER_HEIGHT_STICKY = 92;
const GAP = 30;
const IMAGE_HEIGHT = 150;
const IMAGE_HEIGHT_STICKY = 40;

export const DayHeader = styled(Box)`
    display: flex;
    gap: 12px;
    font-weight: 500;
    font-size: 0.875rem;
`;

export const StyledVenueImageDetails = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 10px;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    border-radius: 8px;
    width: 100%;
    position: relative;
`;

export const StyledSectionHeader = styled(Box)`
    padding-inline: 15px;
    background-color: white;
    & > .MuiTypography-root {
        border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
        padding-block: 15px;
        font-weight: 600;
        font-size: 0.875rem;
    }
`;

export const StyledGridContentTableRow = styled(Box)<{ open?: boolean }>`
    & {
        display: grid;
        grid-template-columns: 5fr repeat(4, 4fr);

        &:nth-of-type(odd) {
            background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackground)};
        }

        & .MuiTableCell-root {
            padding: ${({ open }) => (open ? '10px 15px' : '0px')};
            border-bottom: none;
        }
    }
`;

export const StyledHeaderTableRowContent = styled(Box)`
    & {
        display: grid;
    }
`;

export const StyledDaysContainer = styled(Box)`
    & {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
`;

export const StyledGridContent = styled(Box)`
    & {
        display: grid;
        grid-template-columns: 1fr repeat(5, 4fr);
        align-items: center;
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackground)};
        border-radius: 5px;
        padding: 18px;
        border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
        cursor: pointer;

        & .MuiTableCell-root {
            border-bottom: none;
        }
    }
`;

export const StyledAccordionSummaryContainer = styled(Box)<{ open?: boolean }>`
    & {
        border-radius: 5px;
        outline: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
        height: ${({ open }) => (open ? 'auto' : '0px')};
        margin-block: ${({ open }) => (open ? '5px' : '0px')};
        opacity: ${({ open }) => (open ? '1' : '0')};
    }
`;

export const StyledGridCell = styled(Box)`
    & {
        font-size: 0.875rem;
        padding-inline: 18px;
        padding-block: 10px;
    }
`;

export const StyledGridCellTitle = styled(Typography)`
    & {
        font-size: 0.875rem;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.black)};
    }
`;

export const StyledGridCellText = styled(Typography)`
    & {
        font-size: 0.875rem;
    }
`;

export const StyledFooterContainer = styled(Box)<{ open?: boolean }>`
    & {
        margin-top: 10px;
        border-radius: 5px;
        border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
        padding-inline: 15px;
        padding-block: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
`;

export const StyledFooterDetails = styled(Box)`
    & {
        display: grid;
        grid-template-columns: 5fr repeat(4, 4fr);
        padding-inline: 10px;
    }
`;

export const StyledGridHeader = styled(Box)`
    & {
        display: grid;
        grid-template-columns: 1fr repeat(5, 4fr);
        column-gap: 7px;
    }
`;

export const StyledVenueBudgetLabel = styled('label')<{ aboveBudget?: boolean }>`
    font-size: 0.875rem;
    font-weight: 600;
    color: ${({ aboveBudget, theme: { getColor, EColors } }) =>
        aboveBudget ? getColor(EColors.red) : getColor(EColors.green)};
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const StyledRemoveButtonWrapper = styled(Box)`
    & {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: ${({ theme: { getColor, EColors } }) => alpha(getColor(EColors.lightGrey), 0.6)};
        color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        cursor: pointer;
        padding: 5px;
    }
`;

export const StyledTileInfo = styled(TileInfo)`
    position: absolute;
    top: 15px;
    left: 15px;
`;

export const StyledGridHeaderContainer = styled(Box)``;

export const StyledDialogContent = styled(DialogContent)<{ isSticky?: boolean }>`
    & {
        padding-top: ${({ isSticky }) => (isSticky ? '0' : `${HEADER_HEIGHT + GAP}px`)};
        position: relative;

        & ${StyledGridHeader} {
            top: ${({ isSticky }) => (isSticky ? '-142px' : `${GAP / 2}px`)};
            position: ${({ isSticky }) => (isSticky ? 'sticky' : 'absolute')};
            outline: 1px solid white;
            z-index: 3;
            padding-bottom: 15px;
            margin-inline: ${({ isSticky }) => (isSticky ? '-2px' : '0px')};
            height: 219px;
            background-color: white;

            > ${StyledGridHeaderContainer} {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                max-width: 225px;
            }

            ${StyledVenueImageDetails} {
                display: ${({ isSticky }) => (isSticky ? 'grid' : 'flex')};
                grid-template-columns: 4fr 11fr;
                align-items: center;
                position: relative;

                & img {
                    height: ${({ isSticky }) => (isSticky ? `${IMAGE_HEIGHT_STICKY}px` : `${IMAGE_HEIGHT}px`)};
                    width: 100%;
                    object-fit: cover;
                }

                & p {
                    font-size: 0.813rem;
                    font-weight: 500;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;
                }

                & > ${InfoColumn}:first-child {
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    display: ${({ isSticky }) => (isSticky ? 'none' : 'flex')};
                }

                & ${StyledTileInfo} {
                    display: ${({ isSticky }) => (isSticky ? 'none' : 'flex')};
                }
            }
        }

        & ${StyledDaysContainer} {
            & > .MuiBox-root > .MuiBox-root:first-child {
                position: ${({ isSticky }) => (isSticky ? 'sticky' : 'relative')};
                top: ${({ isSticky }) => (isSticky ? `${HEADER_HEIGHT_STICKY}px` : 'static')};
                z-index: 2;
                margin-inline: ${({ isSticky }) => (isSticky ? '-2px' : '0')};
            }
        }
    }
`;
