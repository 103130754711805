import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { captureException } from '@sentry/minimal';
import { H2Headline as UIH2Headline } from 'components/ui/Headline';
import fontFns from 'fontFns';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { i18n } from 'translation';
import { rejectProposal } from '../api';
import useKey from '../hooks/useKey';
import { Column, Copy, Row, Spacer } from '../ui';
import TextButton from './ui/Button/TextButton';

const Modal = styled(Column)({
    width: '400px',
    padding: '24px',
});

const H2Headline = styled(UIH2Headline, {
    shouldForwardProp: prop => prop !== 'warning',
})<{ warning?: boolean }>(({ warning }) => ({
    color: warning ? getColor(EColors.warningText) : getColor(EColors.accentedHeadline),
    textTransform: 'none',
    ...fontFns.heading,
}));

const EndRow = styled(Row)({
    justifyContent: 'flex-end',
});

const PreCopy = styled(Copy)({
    whiteSpace: 'pre-line',
});

interface ProposalRejectModalProps {
    eventId: number;
    proposalId?: number;
    open: boolean;
    closeModal: () => void;
    warning?: boolean;
}

const ProposalRejectModal = ({ eventId, proposalId, open, closeModal, warning }: ProposalRejectModalProps) => {
    useKey('Escape', closeModal);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();

    function alreadyOnInquiriesRoute() {
        return location.pathname === `/event/${eventId}/venue/inquiries`;
    }

    const handleReject = async () => {
        if (!proposalId) return;
        try {
            const { success } = await rejectProposal(proposalId);

            if (success && alreadyOnInquiriesRoute()) {
                closeModal();
                window.location.reload();
            } else {
                navigate(`/event/${eventId}/venue/inquiries`);
            }
        } catch (error) {
            captureException(error);
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        }
    };

    return (
        <Dialog open={open} onClose={closeModal}>
            <Modal>
                <H2Headline warning={warning}>{i18n.modal.areYouSure}</H2Headline>
                <Spacer />
                <PreCopy>{i18n.venue.proposal.rejectConfirmDescription}</PreCopy>
                <Spacer />
                <EndRow itemSpacing="smallish">
                    <TextButton secondary onClick={closeModal}>
                        {i18n.button.cancel}
                    </TextButton>
                    <TextButton warning={warning} onClick={handleReject}>
                        {i18n.button.confirm}
                    </TextButton>
                </EndRow>
            </Modal>
        </Dialog>
    );
};

export default ProposalRejectModal;
