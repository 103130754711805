import styled from 'styled-components';
import { Column } from 'ui';

const DashboardCard = styled(Column)<{
    scrollable?: boolean;
    small?: boolean;
    frameless?: boolean;
    noBgColor?: boolean;
}>`
    position: relative;
    height: 100%;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 16px;
    padding-top: 0;

    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.dashboardCard)};

    ${({ scrollable }) => scrollable && 'overflow: auto;'}

    ${({ frameless, theme: { getColor, EColors } }) =>
        frameless
            ? `
        height: auto;
        
        padding: 0;

        background-color: ${getColor(EColors.pureWhite)};
    `
            : ''}

    ${({ noBgColor }) => noBgColor && 'background-color: transparent;'}
    border: 1px solid ${({ theme }) => theme.getColor(theme.EColors.lightGrey)};
`;

export default DashboardCard;
