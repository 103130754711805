import { Location } from 'react-router-dom';

// TODO: this function has some brittle assumptions. rethink.
// this component is used for dashboard and subroutes for events, and we want to display additional
// information if on subroutes. we assume here that if the last piece of the current url is a number
// (the id of the event) then we are on a subroute, i.e. https://beta.bizly.com/event/11122/communication
const subroute = (location: Location) => {
    const lastPiece = location.pathname.split('/')[3] || '';
    if (isNaN(Number(lastPiece))) {
        return lastPiece.replace('-', ' ');
    }
};

export const getLast = (location: Location) =>
    subroute(location) ? location.pathname.substr(0, location.pathname.lastIndexOf('/')) : '/';
