import { Box, Typography } from '@mui/material';
import RadioGroup from 'components/Ui-V2/InputFields/RadioGroup';
import { EColors, getColor } from 'theme';

type LinearScaleProps = {
    questionField: Bizly.SurveyQuestion;
    error: string | undefined;
    onChange: (values: number | string) => void;
    onBlur?: () => void;
    value: number | string;
    disabled?: boolean | undefined;
    required?: boolean;
    ref?: React.LegacyRef<HTMLInputElement> | undefined;
};

function LinearScale({ questionField, ...props }: LinearScaleProps) {
    const options = (questionField.options || []) as Bizly.LinearQuestionOptions;
    const rangeArray = Array.from({ length: options.highValue - options.lowValue + 1 }, (_, i) => options.lowValue + i);

    return (
        <>
            <Box display="flex" gap={0.5}>
                <Typography fontWeight={500}>{questionField.prompt}</Typography>

                <Typography color={getColor(EColors.pureBlack, 0.5)} fontWeight={500}>
                    {questionField.responseRequired ? '(required)' : ''}
                </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
                <Typography fontWeight={500}>{options.lowLabel}</Typography>
                <Typography fontWeight={500}>{options.highLabel}</Typography>
            </Box>
            <RadioGroup
                {...props}
                options={rangeArray.map(val => ({ label: val, value: val }))}
                required={questionField.responseRequired}
                row
            />
        </>
    );
}

export default LinearScale;
