import { Slide } from '@mui/material';
import { useEffect, useState } from 'react';

const ANIMATION_DELAY = 50;
const ANIMATION_DURATION = 200;

type AnimatedVenueTileProps = {
    index: number;
    children: React.ReactNode;
    delay?: number;
    duration?: number;
};

export const AnimatedVenueTile = ({
    index,
    children,
    delay = ANIMATION_DELAY,
    duration = ANIMATION_DURATION,
}: AnimatedVenueTileProps) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(true);
        }, index * delay);
        return () => clearTimeout(timer);
    }, [index, delay]);

    return (
        <Slide direction="up" in={show} timeout={duration}>
            <div>{children}</div>
        </Slide>
    );
};
