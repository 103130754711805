import { TProposal } from 'components/ProposalForm/types';
import { useWithdrawProposal } from 'hooks/queries/BizlyOS/useInquiryActions';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { InitiateProposalWithdraw } from './IntitiateProposalWithdraw';
import { ProposalSent } from './ProposalSent';
import { ProposalWithdrawn } from './ProposalWithdrawn';
import { WithdrawProposal } from './WithdrawProposal';

const ProposalStatus = {
    SUBMITTED: 'submitted',
    WITHDRAW: 'withdraw',
    CANCELLED: 'cancelled',
    WITHDRAWING: 'withdrawing',
} as const;

export const ProposalManager = ({ proposal }: { proposal: TProposal }) => {
    const [status, setStatus] = useState(proposal?.status?.name);

    const { venueId = '', proposalId = '' } = useParams<{ venueId?: string; proposalId?: string }>();

    const withdrawProposal = useWithdrawProposal(venueId, proposalId);

    const handleWithdrawalSubmission = async (withdrawalData: {
        cancellationReason: string;
        cancellationNotes: string;
    }) => {
        try {
            await withdrawProposal.mutate(withdrawalData);
            setStatus(ProposalStatus.WITHDRAW);
        } catch (error) {
            console.error('Failed to withdraw proposal:', error);
        }
    };

    const startWithdrawProcess = () => {
        setStatus(ProposalStatus.WITHDRAWING);
    };

    const cancelWithdraw = () => {
        setStatus(ProposalStatus.SUBMITTED);
    };

    const confirmWithdrawIntent = () => {
        setStatus(ProposalStatus.WITHDRAW);
    };

    const RenderContent = () => {
        switch (status) {
            case ProposalStatus.WITHDRAWING:
                return <InitiateProposalWithdraw confirmWithdrawIntent={confirmWithdrawIntent} />;

            case ProposalStatus.WITHDRAW:
                return <WithdrawProposal onCancel={cancelWithdraw} onSubmit={handleWithdrawalSubmission} />;

            case ProposalStatus.CANCELLED:
                return <ProposalWithdrawn />;

            case ProposalStatus.SUBMITTED:
            default:
                return (
                    <ProposalSent
                        venueId={venueId}
                        proposalId={proposalId}
                        startWithdrawProcess={startWithdrawProcess}
                    />
                );
        }
    };

    return <RenderContent />;
};
