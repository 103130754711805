import { zodResolver } from '@hookform/resolvers/zod';
import InfoIcon from '@mui/icons-material/Info';
import {
    Box,
    Button,
    DialogContent,
    FormControl,
    Grid,
    Link,
    MenuItem,
    Popover,
    Select,
    TextField,
} from '@mui/material';
import { RichTextDisplay } from 'components/Form/RichTextEditor';
import { H2Headline } from 'components/ui/Headline';
import fontFns from 'fontFns';
import ConciergeIcon from 'images/icons/concierge.svg?react';
import { useUser } from 'providers/user';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { i18n } from 'translation';
import { CopyFaded, InlineRow, Switch } from 'ui';
import * as z from 'zod';
import { VerbiageAlert } from '../Steps/styled';
import { AppleEventForm } from '../formSchema';

const Step2Schema = z.object({
    cventId: z.string().min(1, { message: 'Cvent ID is required' }).optional(),
    costCenter: z.string().min(1, { message: 'Cost center is required' }).optional(),
    department: z.string().min(1, { message: 'Department is required' }).optional(),
    budget: z.string().optional(),
    type: z.enum(['', 'Internal', 'External']).optional(),
    recordId: z.string().min(1, { message: 'Record ID is required' }).optional(),
    useSsp: z.boolean().optional(),
});

export type Step2FormData = z.infer<typeof Step2Schema>;

const MediumDialogContent = styled(DialogContent)`
    width: 20rem;
    padding: 2rem;
`;

const FormTextFieldLabel = styled(InlineRow)`
    font-size: 1em;
    line-height: 1.25em;
    font-weight: 500;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}
    margin-bottom: 0.5em;
`;

const FieldOptionalLabel = styled.span`
    font-size: 13px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.optionalSpecifier)};
    margin-left: 5px;
`;

const SelfServiceBox = styled(Box)`
    border: 1px solid;
    border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.inactiveAction)};
    border-radius: 0.5em;
    padding: 1.25em;
`;

export const AppleEventCreateStep2 = ({
    formData,
    onSubmit,
    handleBack,
}: {
    formData: AppleEventForm;
    onSubmit: (data: Step2FormData) => void;
    handleBack: () => void;
}) => {
    const { user } = useUser();
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<Step2FormData>({
        resolver: zodResolver(Step2Schema),
        defaultValues: { ...formData },
    });

    const handleStep2Submit: SubmitHandler<Step2FormData> = data => {
        onSubmit(data);
    };

    const getSspMessage = (sspEventsRemaining: number | undefined) => {
        if (sspEventsRemaining === undefined) {
            return '';
        }
        if (sspEventsRemaining > 1) {
            return `You have ${sspEventsRemaining} Self-Service Plus meetings left. The Bizly Concierge will help you negotiate pricing, and walk you through the contracting process. Use one for this meeting?`;
        }
        if (sspEventsRemaining === 1) {
            return 'You have 1 Self-Service Plus meeting left. The Bizly Concierge will help you negotiate pricing and the contracting process. Use it for this meeting?';
        }
        return 'No Self-Service Plus meeting left. Chat with our Support Team if you have any questions.';
    };

    return (
        <Box>
            <H2Headline>{i18n.proposalForm.proposalNotes.additionalDetails}</H2Headline>
            {user.team?.authMeetingCreateFields && user.team.authMeetingCreateRedirect && (
                <VerbiageAlert severity="info" icon={<InfoIcon fontSize="inherit" />}>
                    {i18n.homepage.createMeetingModal.teamEventProtocol(user.team.name)}{' '}
                    <Link onClick={e => setAnchorEl(e.currentTarget)} style={{ cursor: 'pointer' }}>
                        {i18n.homepage.createMeetingModal.learnMore}
                    </Link>
                    <Popover
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <MediumDialogContent>
                            <RichTextDisplay value={user.team.authMeetingCreateRedirect} />
                        </MediumDialogContent>
                    </Popover>
                </VerbiageAlert>
            )}

            <form onSubmit={handleSubmit(handleStep2Submit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="cventId"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.meetingId}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.cventId}
                                        disabled
                                    />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="recordId"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.recordId}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.recordId}
                                        disabled
                                    />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="costCenter"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.costCenter}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.costCenter}
                                        disabled
                                    />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="department"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.department}
                                    </FormTextFieldLabel>
                                    <TextField {...field} variant="outlined" fullWidth error={!!errors.department} />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="budget"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.budget}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.budget}
                                        disabled
                                    />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="type"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.meetingType}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <FormControl fullWidth error={!!errors.type}>
                                        <Select {...field} defaultValue="">
                                            <MenuItem value="">
                                                <CopyFaded>{i18n.homepage.createMeetingModal.none}</CopyFaded>
                                            </MenuItem>
                                            <MenuItem value="Internal">
                                                {i18n.meetingDashboard.headerSection.settings.internal}
                                            </MenuItem>
                                            <MenuItem value="External">
                                                {i18n.meetingDashboard.headerSection.settings.external}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </>
                            )}
                        />
                    </Grid>

                    {user.ssp?.enabled && (
                        <Grid item sm={12}>
                            <SelfServiceBox display="flex" justifyContent="space-between" gap={2.5}>
                                <Box display="flex" gap={1.25}>
                                    <Box>
                                        <ConciergeIcon />
                                    </Box>
                                    <Box>{getSspMessage(user.ssp?.sspEventsRemaining)}</Box>
                                </Box>

                                {typeof user.ssp.sspEventsRemaining === 'number' && user.ssp.sspEventsRemaining > 0 && (
                                    <Controller
                                        name="useSsp"
                                        control={control}
                                        render={({ field: { value, onChange, onBlur } }) => (
                                            <Switch
                                                checked={value}
                                                onChange={e => onChange(e.target.checked)}
                                                onBlur={onBlur}
                                            />
                                        )}
                                    />
                                )}
                            </SelfServiceBox>
                        </Grid>
                    )}
                </Grid>

                <Box display="flex" justifyContent="flex-end" mt={4} gap={2}>
                    <Button variant="text" onClick={handleBack}>
                        {i18n.button.back}
                    </Button>
                    <Button type="submit" variant="contained">
                        {i18n.button.create}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};
