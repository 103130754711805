import { zodResolver } from '@hookform/resolvers/zod';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, DialogContent, Link, Popover, TextField } from '@mui/material';
import { RichTextDisplay } from 'components/Form/RichTextEditor';
import { H2Headline } from 'components/ui/Headline';
import fontFns from 'fontFns';
import moment from 'moment';
import { useUser } from 'providers/user';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { i18n } from 'translation';
import { InlineRow } from 'ui';
import { tzMoment } from 'utils/moment';
import * as z from 'zod';
import { VerbiageAlert } from '../Steps/styled';
import { AppleEventForm, AugmentedTemplatesField, CityField, EventDatesField } from '../formSchema';

const Step1Schema = z.object({
    templateId: z.number().optional(),
    name: z.string().min(1, { message: 'Event name is required' }).max(255, { message: 'Event name is too long' }),
    location: z.string().min(1, { message: 'City location is required' }),
    eventDates: z.object({
        start: z.custom<moment.Moment>(value => moment.isMoment(value), {
            message: 'Start date must be a valid moment object',
        }),
        end: z.custom<moment.Moment>(value => moment.isMoment(value), {
            message: 'End date must be a valid moment object',
        }),
    }),
});

export type Step1FormData = z.infer<typeof Step1Schema>;

const MediumDialogContent = styled(DialogContent)`
    width: 20rem;
    padding: 2rem;
`;

const FormTextFieldLabel = styled(InlineRow)`
    font-size: 1em;
    line-height: 1.25em;
    font-weight: 500;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}
    margin-bottom: 0.5em;
`;

const FieldOptionalLabel = styled.span`
    font-size: 13px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.optionalSpecifier)};
    margin-left: 5px;
`;

export const AppleEventCreateStep1 = ({
    formData,
    handleCancel,
    handleContinue,
    options,
    playbookDescription,
    onViewTemplate,
}: {
    formData: AppleEventForm;
    handleCancel?: () => void;
    handleContinue: (data: Step1FormData) => void;
    options: Record<string, unknown>[];
    playbookDescription?: string;
    onViewTemplate: (value: number) => void;
}) => {
    const { user } = useUser();
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<Step1FormData>({
        resolver: zodResolver(Step1Schema),
        defaultValues: formData,
    });

    useEffect(() => {
        reset(formData);
    }, [formData, reset]);

    const handleStep1Submit: SubmitHandler<Step1FormData> = data => {
        handleContinue(data);
    };

    return (
        <Box>
            <H2Headline>{i18n.meetingsPage.eventDetails}</H2Headline>
            {user.team?.authMeetingCreateFields && user.team.authMeetingCreateRedirect && (
                <VerbiageAlert severity="info" icon={<InfoIcon fontSize="inherit" />}>
                    {i18n.homepage.createMeetingModal.teamEventProtocol(user.team.name)}{' '}
                    <Link onClick={e => setAnchorEl(e.currentTarget)} style={{ cursor: 'pointer' }}>
                        {i18n.homepage.createMeetingModal.learnMore}
                    </Link>
                    <Popover
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <MediumDialogContent>
                            <RichTextDisplay value={user.team.authMeetingCreateRedirect} />
                        </MediumDialogContent>
                    </Popover>
                </VerbiageAlert>
            )}

            <form onSubmit={handleSubmit(handleStep1Submit)}>
                <Box mb={2}>
                    <Controller
                        name="templateId"
                        control={control}
                        render={({ field }) => (
                            <>
                                <FormTextFieldLabel>{i18n.homepage.createMeetingModal.playbook}</FormTextFieldLabel>
                                <AugmentedTemplatesField
                                    field="templateField"
                                    value={field.value ?? 0}
                                    onChange={({ value }) => field.onChange(value)}
                                    options={options}
                                    playbookDescription={playbookDescription}
                                    onViewTemplate={onViewTemplate}
                                />
                            </>
                        )}
                    />
                </Box>

                <Box mb={2}>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <>
                                <FormTextFieldLabel>
                                    {i18n.homepage.createMeetingModal.eventName}
                                    <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                </FormTextFieldLabel>
                                <TextField {...field} variant="outlined" fullWidth error={!!errors.name} />
                            </>
                        )}
                    />
                </Box>

                <Box mb={2}>
                    <Controller
                        name="location"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <FormTextFieldLabel>
                                    {i18n.homepage.createMeetingModal.location}
                                    <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                </FormTextFieldLabel>
                                <CityField
                                    field="location"
                                    onChange={({ value: cityValue }) => {
                                        onChange(cityValue.location);
                                    }}
                                    defaultValue={{ location: value || '', googlePlaceId: '' }}
                                    error={null}
                                />
                            </>
                        )}
                    />
                </Box>

                <Box mb={2}>
                    <Controller
                        name="eventDates"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <FormTextFieldLabel>
                                    {i18n.communication.eventDates}
                                    <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                </FormTextFieldLabel>
                                <EventDatesField
                                    value={value}
                                    onChange={val => {
                                        onChange({
                                            ...val,
                                            start: tzMoment(val.start),
                                            end: tzMoment(val.end),
                                        });
                                    }}
                                />
                            </>
                        )}
                    />
                </Box>

                <Box display="flex" justifyContent="flex-end" mt={4} gap={2}>
                    <Button variant="text" type="button" onClick={handleCancel}>
                        {i18n.button.cancel}
                    </Button>
                    <Button type="submit" variant="contained">
                        {i18n.button.continue}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};
