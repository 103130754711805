import omitBy from 'lodash/omitBy';
import { i18n } from 'translation';
import { tzMoment } from 'utils/moment';
import { COUNTRY_TO_CURRENCY } from '../CurrencyField';
import { TGRBooking, TGRBookingProposal, TProposal, TProposalForm } from '../types';
import { isEmptyString, isEmptyStringOrNumber } from '../utils';

type TFees = Pick<TProposal, 'resortFee' | 'occupancyTax'> & {
    currencyCode?: Exclude<TProposal['currency'], null | undefined>['code'];
};

type TCommissionFees = {
    commissionRate?: number | null;
};

export type TGRFormBooking = TGRBooking & TCommissionFees & TFees;

export type TGuestRoomsFormValue = {
    guestRooms: TGRFormBooking[];
    commissionable: TProposal['commissionable'];
    bizlyCollectsCommissions: TProposal['bizlyCollectsCommissions'];
};

const proposedEqualRequestedGR = (grBookings: TGRBooking[]) =>
    grBookings.map(booking => ({
        ...booking,
        proposedRoomRate: booking.proposedRoomRate || 0,
    }));

export const setFeeTaxOnAllGR = (
    grBookings: (TGRBooking & Partial<TFees & TCommissionFees>)[],
    { resortFee, occupancyTax, currencyCode, commissionRate }: Partial<TFees & TCommissionFees>
) => {
    return grBookings.map(booking => ({
        ...booking,
        resortFee: resortFee !== undefined ? resortFee : booking?.resortFee || null,
        occupancyTax: occupancyTax !== undefined ? occupancyTax : booking?.occupancyTax || null,
        currencyCode: currencyCode !== undefined ? currencyCode : booking?.currencyCode || undefined,
        commissionRate: commissionRate !== undefined ? commissionRate : booking?.commissionRate,
    }));
};

export const proposalFormToFormData = ({ guestRooms = [], proposal = {}, venue = {} }: Partial<TProposalForm>) => {
    const {
        commissionable = true,
        defaultNoCommission,
        resortFee = null,
        occupancyTax = null,
        currency,
        guestRoomCommissionRate,
        bizlyCollectsCommissions,
    } = proposal;
    return {
        guestRooms: setFeeTaxOnAllGR(proposedEqualRequestedGR(guestRooms), {
            resortFee,
            occupancyTax,
            currencyCode: currency?.code ?? (venue.country?.code && COUNTRY_TO_CURRENCY[venue.country.code]) ?? 'USD',
            commissionRate: guestRoomCommissionRate,
        }),
        commissionable: commissionable === null ? (defaultNoCommission ? false : true) : commissionable,
        bizlyCollectsCommissions: Boolean(bizlyCollectsCommissions),
    };
};

export const formDataToProposalForm = ({ guestRooms = [], commissionable = null }: TGuestRoomsFormValue) => {
    const { resortFee, occupancyTax, currencyCode, commissionRate } = guestRooms[0] || {};

    const filterOutNoAnswers = (booking: (typeof guestRooms)[number]) => booking.proposedRoomCount !== null;
    const filteredBookings = guestRooms.filter(filterOutNoAnswers);

    const formattedBookings = filteredBookings.map(({ resortFee, occupancyTax, ...booking }) => ({
        ...(omitBy<TGRBookingProposal>(booking, (value, key) => key.includes('request')) as TGRBookingProposal),
        ...(booking.proposedRoomCount === 0
            ? {
                  proposedRoomRate: 0,
                  proposedRoomName: null,
                  image: null,
              }
            : {}),
        ...(!commissionable
            ? {
                  commissionRate: 0,
              }
            : {}),
    }));

    return {
        guestRooms: formattedBookings,
        proposal: {
            resortFee,
            occupancyTax,
            currencyCode,
            commissionable,
            guestRoomCommissionRate: !commissionable ? 0 : commissionRate,
        },
    };
};

export const getErrorMessage = ({ guestRooms, commissionable }: TGuestRoomsFormValue, isContinue?: boolean) => {
    const missingInfoError = ({ dayIdx, headingText }: { dayIdx: number; headingText?: string }) => (
        <span>
            {headingText || i18n.proposalForm.guestRoom.error.fillAll}
            <br />
            {i18n.common.dayIndex(dayIdx + 1)}
            {` - ${tzMoment(guestRooms[dayIdx].date).format('dddd, ll')}`}
        </span>
    );

    const isMissingInfo = ({
        proposedRoomCount,
        proposedRoomRate,
        proposedRoomName,
        resortFee,
        occupancyTax,
        commissionRate,
    }: TGRFormBooking) =>
        proposedRoomCount &&
        (isEmptyStringOrNumber(proposedRoomRate) ||
            isEmptyString(proposedRoomName || '') ||
            isEmptyStringOrNumber(resortFee) ||
            isEmptyStringOrNumber(occupancyTax) ||
            (commissionable && (!commissionRate || Number(commissionRate) === 0)));

    const missingInfoIdx = guestRooms.findIndex(isMissingInfo);

    if (missingInfoIdx >= 0) {
        return missingInfoError({ dayIdx: missingInfoIdx });
    }

    if (isContinue) {
        const hasNoAnswersIdx = guestRooms.findIndex(booking => booking.proposedRoomCount === null);

        if (hasNoAnswersIdx >= 0) {
            return missingInfoError({
                dayIdx: hasNoAnswersIdx,
                headingText: i18n.proposalForm.guestRoom.error.answerRequest,
            });
        }

        const anyBookings = guestRooms.some(booking => booking.proposedRoomCount);

        if (anyBookings && typeof commissionable !== 'boolean') {
            return i18n.proposalForm.guestRoom.error.notifyCommissionable;
        }
    }
};
