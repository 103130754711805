export function getPassthroughProps<T extends any>({
    field,
    value,
    onChange,
}: {
    field: string;
    value: T;
    onChange: (update: { field: string; value: T }) => void;
}) {
    return {
        value: { [field]: value },
        onChange: ({ value: { [field]: fieldVal } }: { value: { [field: string]: T } }) => {
            onChange({ field, value: fieldVal });
        },
    };
}

export function getIdentifier(fieldName: string, suffix = '') {
    // Handle nested fields (e.g., "user.name" -> "user-name")
    const normalizedName = fieldName?.toString().replace(/\./g, '-');
    return `${normalizedName}${suffix}`;
}
