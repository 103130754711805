import { createEvent, findPlaces } from 'api';
import { SpinnerOverlay } from 'components/Spinner';
import { useSnackbar } from 'notistack';
import { parse } from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { tzMoment } from 'utils/moment';
import { AppleEventForm, toBizlyEvent } from '../formSchema';
import { AppleEventCreateStep1, Step1FormData } from './AppleEventCreateStep1';
import { AppleEventCreateStep2, Step2FormData } from './AppleEventCreateStep2';
import { getMomentParam, getStringParam } from './queryParams';

export const populateLocationData = async (
    location: string,
    setFormData: React.Dispatch<React.SetStateAction<AppleEventForm>>,
    enqueueSnackbar: ReturnType<typeof useSnackbar>['enqueueSnackbar'],
    setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
) => {
    try {
        const gmapsResponse = await findPlaces(location);

        if (gmapsResponse.status === 'OK' && gmapsResponse.predictions.length > 0) {
            setFormData(prev => ({
                ...prev,
                location: gmapsResponse.predictions[0].description,
                city: {
                    location: gmapsResponse.predictions[0].description,
                    googlePlaceId: gmapsResponse.predictions[0].placeId,
                },
                googlePlaceId: gmapsResponse.predictions[0].placeId,
            }));
        }
    } catch (e) {
        enqueueSnackbar(String(e), { variant: 'error' });
    } finally {
        setSubmitting(false);
    }
};

const handleQueryParamLocation = async (
    queryParamLocation: string,
    isMounted: boolean,
    setFormData: React.Dispatch<React.SetStateAction<AppleEventForm>>
) => {
    const gmapsResponse = await findPlaces(queryParamLocation);

    if (isMounted && gmapsResponse.status === 'OK') {
        setFormData(prev => ({
            ...prev,
            location: gmapsResponse.predictions[0].description,
            city: {
                location: gmapsResponse.predictions[0].description,
                googlePlaceId: gmapsResponse.predictions[0].placeId,
            },
            googlePlaceId: gmapsResponse.predictions[0].placeId,
        }));
    }
};

export const AppleEventCreateModal = ({
    formData,
    setFormData,
    onCreateEvent,
    handleClose,
    options,
    playbookDescription,
    onViewTemplate,
}: {
    formData: AppleEventForm;
    setFormData: React.Dispatch<React.SetStateAction<AppleEventForm>>;
    onCreateEvent?: (eventId: number) => Promise<void>;
    handleClose: () => void;
    options: Record<string, unknown>[];
    playbookDescription?: string;
    onViewTemplate: (value: number) => void;
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { search } = useLocation();

    const queryParams = useMemo(() => parse(search, { parseBooleans: true }), [search]);

    const isCreateEvent = queryParams.create === true;

    const [submitting, setSubmitting] = useState(false);
    const [step, setStep] = useState(1);

    useEffect(() => {
        if (isCreateEvent) {
            setFormData(prev => ({
                ...prev,
                eventName: getStringParam(queryParams.event_name) ?? prev.eventName,
                budget: getStringParam(queryParams.budget) ?? prev.budget,
                internalRef: getStringParam(queryParams.internal_ref) ?? prev.internalRef,
                costCenter: getStringParam(queryParams.cost_center) ?? prev.costCenter,
                recordId: getStringParam(queryParams.record_id) ?? prev.recordId,
                location: getStringParam(queryParams.location) ?? prev.location,
                eventDates: {
                    start: getMomentParam(queryParams.start) ?? prev.eventDates?.start ?? tzMoment(),
                    end: getMomentParam(queryParams.end) ?? prev.eventDates?.end ?? tzMoment(),
                },
            }));
        } else {
            // Ensure eventDates is always defined
            setFormData(prev => ({
                ...prev,
                eventDates: prev.eventDates || {
                    start: tzMoment(),
                    end: tzMoment().add(1, 'day'),
                },
            }));
        }
    }, [isCreateEvent, queryParams]);

    useEffect(() => {
        let isMounted = true;
        const queryParamLocation = getStringParam(queryParams.location)?.trim() ?? '';

        if (isCreateEvent && queryParamLocation !== '') {
            handleQueryParamLocation(queryParamLocation, isMounted, setFormData);
        }

        return () => {
            isMounted = false;
        };
    }, [queryParams.location, isCreateEvent, setFormData]);

    const onSubmit = async (data: Step2FormData) => {
        setSubmitting(true);

        try {
            const { event } = await createEvent(toBizlyEvent({ ...formData, ...data }));
            if (onCreateEvent) {
                await onCreateEvent(event.id);
            }
            navigate(`/event/${event.id}/venue`);
            handleClose();
        } catch (e) {
            enqueueSnackbar(String(e), { variant: 'error' });
            setSubmitting(false);
        }
    };

    const handleContinue = async (data: Step1FormData) => {
        setFormData(prev => ({ ...prev, ...data }));

        if (data.location) {
            setSubmitting(true);
            await populateLocationData(data.location, setFormData, enqueueSnackbar, setSubmitting);
        }

        setStep(2);
    };

    const handleBack = () => setStep(1);

    const renderStepContent = (currentStep: number) => {
        switch (currentStep) {
            case 1:
                return (
                    <AppleEventCreateStep1
                        formData={formData}
                        handleCancel={handleClose}
                        handleContinue={handleContinue}
                        options={options}
                        playbookDescription={playbookDescription}
                        onViewTemplate={onViewTemplate}
                    />
                );
            case 2:
                return <AppleEventCreateStep2 formData={formData} onSubmit={onSubmit} handleBack={handleBack} />;
            default:
                return null;
        }
    };

    return (
        <>
            {renderStepContent(step)}
            {submitting && <SpinnerOverlay />}
        </>
    );
};
