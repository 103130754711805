import { Navigate, Route, Routes } from 'react-router-dom';
import Contracting from './Contracting';
import InquiryOverview from './InquiryOverview';
import { InquiryProposal } from './InquiryProposal';
import { CONTRACTING_PATH, INQUIRY_OVERVIEW_PATH, INQUIRY_PROPOSAL_PATH, PAYMENT_PATH } from './InquirySubNavigation';
import Payment from './Payment';

function InquiryRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to={INQUIRY_OVERVIEW_PATH} />} />
            <Route path={INQUIRY_OVERVIEW_PATH} element={<InquiryOverview />} />
            <Route path={INQUIRY_PROPOSAL_PATH} element={<InquiryProposal />} />
            <Route path={CONTRACTING_PATH} element={<Contracting />} />
            <Route path={PAYMENT_PATH} element={<Payment />} />
        </Routes>
    );
}

export default InquiryRoutes;
