import colorFns from 'colorFns';
import fontFns from 'fontFns';
import styled from 'styled-components';
import { Button, Column, Copy } from 'ui';
import { HEADER_HEIGHT } from 'utils/header';
import { contentWidth } from '../../../shared';

export const Subheader = styled(Copy)`
    line-height: 1.5em;
    font-size: 1.125rem;
`;

export const Centered = styled(Column)`
    width: ${contentWidth};
    margin: ${HEADER_HEIGHT}px auto;
    padding: 2.25rem 0;
`;

export const FormLabel = styled(Copy)`
    color: ${colorFns.formLabel};
    ${fontFns.formLabel}
`;

export const CopyDatesButton = styled(Button)`
    padding-left: 0.5rem;
    padding-right: 0.5rem;
`;

export const InviteeRoomBlock = styled(Column)`
    background-color: ${colorFns.containerBackground};
    border: 1px dashed ${colorFns.preferredRank40};
    border-radius: 5px;
    padding: 1rem;

    input,
    .MuiSelect-root,
    .MuiOutlinedInput-root,
    .Richtext-container {
        background-color: ${colorFns.placeholderBackground} !important;
        border: 1px solid ${colorFns.fieldBorder} !important;
    }
`;
