import { Stack, styled, Typography } from '@mui/material';
import { Button } from 'components/Ui-V2/Button/Button';
import DraggableList from './DraggableList';

const Header = styled(Stack)(({ theme: { getColor, EColors } }) => ({
    borderBottom: '0.5px solid',
    borderColor: getColor(EColors.greyBorder),
    padding: '10px 20px',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

const TasksList = () => {
    return (
        <Stack>
            <Header direction="row">
                <Typography>Tasks</Typography>
                <Button variant="text">+ Add Task</Button>
            </Header>
            <DraggableList />
        </Stack>
    );
};

export default TasksList;
