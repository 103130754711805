import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import React, { PropsWithChildren } from 'react';
import { PostHogFeatureFlags } from 'shared';
import { EColors, getColor } from 'theme';
import { Copy } from 'ui';
import { HEADER_HEIGHT } from 'utils/header';

const FOOTER_HEIGHT = 84;

const Container = styled(Box)({
    width: '38rem',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const DrawerHeader = styled('header')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1.25rem',
    padding: '1.25rem',
    boxSizing: 'border-box',
    height: HEADER_HEIGHT,
    background: getColor(EColors.pureWhite),
    borderBottom: `1px solid ${getColor(EColors.grey)}`,
    boxShadow: `0px 0px 1px ${getColor(EColors.grey)}`,
});

const Title = styled(Copy)({
    fontSize: '1.125rem',
    lineHeight: '1.3em',
    fontWeight: 600,
    flex: 1,
});

const DrawerBody = styled(Box)({
    padding: '1.25rem',
    boxSizing: 'border-box',
    flex: 1,
});

const DrawerFooter = styled(Box)({
    height: FOOTER_HEIGHT,
    padding: '1.25rem',
    boxSizing: 'border-box',
    background: getColor(EColors.pureWhite),
    boxShadow: `0 1px 5px ${getColor(EColors.grey)}`,
});

const CloseButton = styled(IconButton)({
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '100px',
    backgroundColor: getColor(EColors.bizlyInsightsBlue, 0.1),
    '&:hover': {
        backgroundColor: getColor(EColors.bizlyInsightsBlue, 0.2),
    },
    '& svg': {
        color: getColor(EColors.bizlyInsightsBlue),
        fontSize: '1.25rem',
    },
});

type Props = PropsWithChildren<{
    drawerOpen: boolean;
    onClose?: () => void;
    title?: string;
    subtitle?: string;
    footer?: React.ReactNode;
}>;

const RightDrawer = ({ drawerOpen, onClose, title, children, footer }: Props) => {
    const newVenueTileLayout = useFeatureFlagEnabled(PostHogFeatureFlags.toggleNewVenueTileLayout);

    const showFooterDrawer = footer && !newVenueTileLayout ? true : false;

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    return (
        <Drawer anchor="right" open={drawerOpen} onClose={handleClose}>
            <Container>
                <DrawerHeader>
                    <Title>{title}</Title>
                    <CloseButton onClick={handleClose}>
                        <CloseIcon />
                    </CloseButton>
                </DrawerHeader>
                <DrawerBody>{children}</DrawerBody>
                {showFooterDrawer && <DrawerFooter>{footer}</DrawerFooter>}
            </Container>
        </Drawer>
    );
};

export default RightDrawer;
