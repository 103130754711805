import { Box } from '@mui/material';
import { useGetProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import EventSpacesAccordion from '../InquiryProposal/InquiryProposalBody/EventSpaces/EventSpacesAccordion';
import { EventSpacesCount } from '../InquiryProposal/InquiryProposalBody/EventSpaces/EventSpacesCount';

export function EventSpaces() {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: proposalInquiry, isLoading } = useGetProposalInquiry(venueId, proposalId);

    const eventSpaces = proposalInquiry?.eventSpaces || [];
    const options = proposalInquiry?.options;

    const [accordionExpands, setAccordionExpands] = useState<number[]>([]);

    const onAccordionExpandChange = (panel: number) => (_: React.SyntheticEvent, newExpanded: boolean) => {
        setAccordionExpands(prev => {
            if (newExpanded) {
                return [...prev, panel];
            }
            return prev.filter(index => index !== panel);
        });
    };

    if (isLoading || !eventSpaces) return null;

    return (
        <Box display="flex" flexDirection="column" gap={2.5}>
            <EventSpacesCount count={eventSpaces?.length || 0} />

            {eventSpaces.map((eventSpace, index) => {
                return (
                    <EventSpacesAccordion
                        key={eventSpace.inquirySpaceId}
                        index={index}
                        eventSpace={eventSpace}
                        expanded={accordionExpands.includes(index)}
                        onExpandChange={onAccordionExpandChange}
                        options={options}
                        requestedDetailsOnly
                    />
                );
            })}
        </Box>
    );
}
