import { Box, Typography } from '@mui/material';
import DatePicker from 'components/Ui-V2/InputFields/DatePicker';
import ImageInput from 'components/Ui-V2/InputFields/ImageInput';
import GooglePlacesAutocomplete from 'components/Ui-V2/InputFields/PlaceInput';
import RichTextEditorField from 'components/Ui-V2/InputFields/RichTextEditorField';
import TextField from 'components/Ui-V2/InputFields/TextField';
import TimePicker from 'components/Ui-V2/InputFields/TimePicker';
import { WEBREGISTRATION_PREVIEW_IDS } from 'constants/event';
import PreviewImage from 'images/web-preview.jpeg';
import { EColors, getColor } from 'theme';
import { i18n } from 'translation';
import { TabPlanelProps } from './WebRegistrationContent';

function ContentForm({ parcel, isDisabled, errors, handleChange, onSave, scrollToElement }: TabPlanelProps) {
    let locationValue = '';

    if (parcel.locationName) {
        locationValue = parcel.locationName;
        if (parcel.locationAddress) {
            locationValue += `, ${parcel.locationAddress}`;
        }
    }

    return (
        <Box display="flex" flexDirection="column" gap={2.5} marginTop={2.5}>
            <ImageInput
                label="Banner"
                onChange={url => {
                    scrollToElement(WEBREGISTRATION_PREVIEW_IDS.banner);
                    onSave('customImage', {
                        customImage: { url },
                        customImageUrl: url,
                    });
                }}
                disabled={isDisabled}
                placeholder="Select the image"
                value={parcel.customImageUrl}
                error={errors.customImage}
                defaultValue={PreviewImage}
                ref={null}
            />
            <ImageInput
                label="Logo"
                onChange={url => {
                    scrollToElement(WEBREGISTRATION_PREVIEW_IDS.logo);
                    onSave('customLogo', {
                        customLogo: { url, title: '' },
                        customLogoUrl: url,
                    });
                }}
                disabled={isDisabled}
                placeholder="Select the image"
                value={parcel.customLogoUrl}
                error={errors.customLogo}
                ref={null}
            />
            <TextField
                name="subject"
                label="Event Title"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    scrollToElement(WEBREGISTRATION_PREVIEW_IDS.eventTitle);
                    handleChange(e.target.value, 'subject');
                }}
                disabled={isDisabled}
                placeholder={i18n.communication.eventSubjectPlaceHolder}
                value={parcel.subject || ''}
                onBlur={() => onSave('subject')}
                error={errors.subject}
                required
                ref={null}
            />
            <Box display="flex" gap={1.25}>
                <Box flex={1} display="flex" flexDirection="column" gap={1}>
                    <DatePicker
                        label="Event Start"
                        required
                        value={parcel.startDate}
                        error={errors.startDate}
                        onAccept={(startDate: string) => onSave('startDate', { startDate })}
                        disabled={isDisabled}
                        disablePast
                        timeZone={parcel.timeZone}
                        showDefaultError
                    />
                    <TimePicker
                        value={parcel.startTime}
                        error={errors.startTime}
                        onAccept={startTime => onSave('startTime', { startTime })}
                        disabled={isDisabled}
                        timeZone={parcel.timeZone}
                    />
                </Box>
                <Box flex={1} display="flex" flexDirection="column" gap={1}>
                    <DatePicker
                        label="Event End"
                        required
                        error={errors.endDate}
                        value={parcel.endDate}
                        onAccept={(endDate: string) => onSave('endDate', { endDate })}
                        disabled={isDisabled}
                        shouldDisableDate={date => {
                            return date.isBefore(parcel.startDate);
                        }}
                        timeZone={parcel.timeZone}
                        showDefaultError
                    />
                    <TimePicker
                        value={parcel.endTime}
                        error={errors.endTime}
                        onAccept={endTime => onSave('endTime', { endTime })}
                        disabled={isDisabled}
                        timeZone={parcel.timeZone}
                    />
                </Box>
            </Box>

            <Box>
                <Typography
                    fontWeight={500}
                    marginBottom={1}
                    sx={{ '& span': { color: getColor(EColors.pureBlack, 0.5) } }}
                >
                    Event Location <span>(required)</span>
                </Typography>

                <GooglePlacesAutocomplete
                    locationValue={locationValue}
                    placeId={parcel.locationGooglePlaceId}
                    onChange={props => {
                        scrollToElement(WEBREGISTRATION_PREVIEW_IDS.eventLocation);
                        onSave('locationName', {
                            locationName: props.mainText,
                            locationAddress: props.secondaryText,
                            locationGooglePlaceId: props.placeId,
                        });
                    }}
                    clearable
                    required
                    placeholder="Select your event location"
                    error={errors.locationName}
                    disabled={isDisabled}
                />
            </Box>

            <RichTextEditorField
                name="content"
                label="Event Overview"
                value={parcel.content ? parcel.content : ''}
                onChange={text => {
                    scrollToElement(WEBREGISTRATION_PREVIEW_IDS.eventOverview);
                    handleChange(text || '', 'content');
                }}
                onBlur={() => onSave('content')}
                error={errors.content}
                disabled={isDisabled}
                required
            />
        </Box>
    );
}

export default ContentForm;
