export const EVENT_NAV_WIDTH = 200;

export const OVERVIEW_PATH = '';
export const VENUE_PATH = 'venue';
export const PROPOSALS_PATH = 'venue/inquiries';
export const CONTRACTING_PATH = 'venue/contracting';
export const GUEST_LIST_PATH = 'guest-list';
export const INQUIRY_BUILDER_PATH = 'venue/inquiries/:inquiryId';
export const COMMUNICATION_PATH = 'communication';
export const SPEND_TRACKER_PATH = 'spend-tracker';

export type NavProps = {
    eventId: number;
    children?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
};
