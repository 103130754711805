import {
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    closestCenter,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Stack, styled } from '@mui/material';
import React, { useState } from 'react';
import Task from './Task';

interface Task {
    id: string;
    text: string;
}

const initialTasks: Task[] = [
    { id: '1', text: 'Submit Inquiry' },
    { id: '2', text: 'Review Proposals' },
    { id: '3', text: 'Invite Guests' },
    { id: '4', text: 'Create Web Registration' },
    { id: '5', text: 'Email Hotel Confirmations to Guests' },
];

const List = styled(Stack)({
    padding: 20,
    gap: '20px',
});

const DraggableList: React.FC = () => {
    const [tasks, setTasks] = useState<Task[]>(initialTasks);

    // Sensors for drag gestures
    const sensors = useSensors(
        useSensor(PointerSensor), // Mouse / touch input
        useSensor(KeyboardSensor) // Keyboard accessibility
    );

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (!over || active.id === over.id) return;

        const oldIndex = tasks.findIndex(task => task.id === active.id);
        const newIndex = tasks.findIndex(task => task.id === over.id);
        setTasks(arrayMove(tasks, oldIndex, newIndex));
    };

    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={tasks} strategy={verticalListSortingStrategy}>
                <List>
                    {tasks.map(task => (
                        <SortableItem key={task.id} task={task} />
                    ))}
                </List>
            </SortableContext>
        </DndContext>
    );
};

// SortableItem Component to Handle Drag
const SortableItem: React.FC<{ task: Task }> = ({ task }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: task.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes}>
            <Task text={task.text} dragHandleProps={listeners} />
        </div>
    );
};

export default DraggableList;
