import { Divider } from '@material-ui/core';
import Form from 'components/Form';
import { getPassthroughProps } from 'components/Form/utils';
import Button from 'components/ui/Button';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Copy, Row, SPACER_SPACES } from 'ui';
import CurrencyField from '../CurrencyField';
import { GUEST_ROOMS_REQUEST_WIDTH } from '../EventSpacesForm/utils';

const requestColumnWidth = GUEST_ROOMS_REQUEST_WIDTH + SPACER_SPACES['large'];

const proposedRoomCountForm = {
    fields: {
        proposedRoomCount: {
            type: 'counter',
            perRow: 'auto',
            options: {
                min: 0,
            },
        },
    },
    schema: [
        {
            key: 'proposedRoomCount',
            fields: [
                'proposedRoomCount',
                {
                    type: 'display',
                    options: { label: i18n.proposalForm.guestRoom.roomsAvailable },
                },
            ],
            spacing: false,
        },
    ],
};

const FormField = styled(Row)`
    margin: 8px 0 4px;
    height: 40px;
    align-items: center;
`;

const ProposedRoomCountFormField = ({
    field,
    value = null,
    onChange,
    disabled,
    requestedGuests,
}: {
    field: string;
    value?: number | null;
    onChange: (update: { field: string; value: number }) => void;
    disabled?: boolean;
    requestedGuests: number;
}) =>
    value === null ? (
        <FormField itemSpacing="smallish">
            <Button onClick={() => onChange({ field, value: requestedGuests })} disabled={disabled}>
                {i18n.proposalForm.guestRoom.addRooms}
            </Button>
            <Button onClick={() => onChange({ field, value: 0 })} secondary disabled={disabled}>
                {i18n.proposalForm.guestRoom.noRooms}
            </Button>
        </FormField>
    ) : (
        <Form
            {...proposedRoomCountForm}
            {...getPassthroughProps({ field: 'proposedRoomCount', value, onChange })}
            disabled={disabled}
        />
    );

const dayFields = ({
    requestedGuests,
    currencyOptions,
    currencyCode,
}: {
    requestedGuests: number;
    currencyOptions: { id: string }[];
    currencyCode?: string | null;
}) => ({
    requestedGuests: {
        type: 'display',
        prompt: i18n.proposalForm.guestRoom.guestNumber,
        fixedWidth: requestColumnWidth,
        options: {
            formatter: i18n.common.guestNumberFormatter,
        },
    },
    requestNotes: {
        type: 'display',
        fixedWidth: requestColumnWidth,
    },
    proposedRoomCount: {
        type: ProposedRoomCountFormField,
        prompt: ' ',
        options: {
            requestedGuests,
        },
    },
    proposedImageUrl: {
        type: 'image_uploader',
        prompt: i18n.proposalForm.guestRoom.roomImage,
        optional: true,
        fixedWidth: requestColumnWidth,
    },
    proposedRoomName: {
        type: 'text',
        prompt: i18n.proposalForm.guestRoom.roomType,
    },
    proposedRoomRate: {
        type: 'text',
        prompt: i18n.proposalForm.guestRoom.roomRate,
        options: {
            numberType: 'currency',
            currency: currencyCode,
        },
    },
    currencyCode: {
        type: CurrencyField,
        prompt: ' ',
        perRow: 'auto',
        options: { options: currencyOptions },
    },
    divider: {
        type: Divider,
        prompt: ' ',
        optional: true,
    },
    commissionRate: {
        type: 'text',
        prompt: i18n.proposalForm.guestRoom.commissionRate,
        options: {
            placeholder: i18n.proposalForm.guestRoom.suggestedCommissionRate,
            numberType: 'percentage',
            inputProps: {
                max: 100,
            },
        },
    },
    commissionRateNote: {
        type: () => (
            <Copy small $color={'darkerGrey'}>
                {i18n.proposalForm.guestRoom.commissionRateNote}
            </Copy>
        ),
    },
    resortFee: {
        type: 'text',
        prompt: i18n.proposalForm.guestRoom.resortFee,
        options: {
            numberType: 'currency',
            currency: currencyCode,
        },
    },
    occupancyTax: {
        type: 'text',
        prompt: i18n.proposalForm.guestRoom.occupancyTax,
        options: {
            numberType: 'percentage',
            inputProps: {
                max: 100,
            },
        },
    },
});

const roomRateSchema = {
    type: 'nested',
    schema: [
        {
            key: 'rateAndCurrency',
            fields: ['proposedRoomRate', 'currencyCode'],
            spacing: false,
            itemSpacing: 'xsmall',
        },
    ],
};
const resortFeeSchema = {
    type: 'nested',
    schema: [
        {
            key: 'feeAndCurrency',
            fields: ['resortFee', 'currencyCode'],
            spacing: false,
            itemSpacing: 'small',
        },
    ],
};
const commissionRateSchema = (bizlyCollectsCommissions: boolean) => ({
    type: 'nested',
    schema: [
        {
            key: 'commissionRate',
            fields: ['commissionRate'],
            spacing: false,
        },
        ...(bizlyCollectsCommissions
            ? [
                  {
                      key: 'commissionRateNote',
                      fields: ['commissionRateNote'],
                  },
              ]
            : []),
    ],
});

const schemaRoomDetails = ({
    commissionable,
    bizlyCollectsCommissions,
}: {
    commissionable: boolean;
    bizlyCollectsCommissions: boolean;
}) => ({
    type: 'nested',
    schema: [
        { key: 'nameAndRate', fields: ['proposedRoomName', roomRateSchema], spacing: 'default' },
        { key: 'divider', fields: ['divider'], spacing: 'default' },
        { key: 'resortFee', fields: [resortFeeSchema], spacing: 'default' },
        {
            key: 'tax',
            fields: ['occupancyTax', ...(commissionable ? [commissionRateSchema(bizlyCollectsCommissions)] : [])],
            spacing: 'default',
        },
    ],
});

const daySchemaDetails = ({
    commissionable,
    bizlyCollectsCommissions,
}: {
    commissionable: boolean;
    bizlyCollectsCommissions: boolean;
}) => [
    {
        key: 'details',
        fields: ['proposedImageUrl', schemaRoomDetails({ commissionable, bizlyCollectsCommissions })],
        spacing: false,
        itemSpacing: 'large',
    },
];

export const dayForm = {
    fields: dayFields,
    schemaRooms: [
        {
            key: 'guests',
            fields: ['requestedGuests', 'proposedRoomCount'],
            spacing: false,
            itemSpacing: 'large',
        },
        { key: 'notes', fields: ['requestNotes'], spacing: false, itemSpacing: 'large' },
    ],
    schemaDetails: daySchemaDetails,
};

export const commissionableForm = {
    fields: {
        commissionable: {
            type: 'checkbox',
            perRow: 4,
            options: {
                asRadio: true,
                useBooleanAsValue: true,
            },
        },
    },
    schema: [{ key: 'commissionable', fields: ['commissionable'], spacing: false }],
};
