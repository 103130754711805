import * as Sentry from '@sentry/react';
import { getVenueInsights } from 'api/venues';
import { VENUE_INSIGHTS_QUERY_KEY } from 'constants/queryKeys';
import { useQuery, UseQueryOptions } from 'react-query';
import { StreamResponse, VenueData } from 'types/stream';

export const useVenueInsightsQuery = (
    venue: VenueData,
    section: string,
    options?: UseQueryOptions<StreamResponse, Error>
) => {
    return useQuery<StreamResponse, Error>(
        [VENUE_INSIGHTS_QUERY_KEY, section, venue.name],
        () => getVenueInsights(venue, section),
        {
            onError: (error: Error) => {
                console.error('Error fetching venue insights:', error);
                Sentry.captureException(error, {
                    tags: {
                        component: 'useVenueInsightsQuery',
                        venueName: venue.name,
                        section,
                    },
                });
            },
            enabled: !!venue.name,
            refetchOnWindowFocus: false,
            ...options,
        }
    );
};
