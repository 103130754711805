import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, styled, Typography } from '@mui/material';
import DatePicker from 'components/Ui-V2/InputFields/DatePicker';
import RadioGroup from 'components/Ui-V2/InputFields/RadioGroup';
import TextArea from 'components/Ui-V2/InputFields/TextArea';
import { Moment } from 'moment';
import { Controller, FieldError, type Control } from 'react-hook-form';
import { EColors, getColor } from 'theme';

export const BorderedContainer = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    height: '100%',
    border: `1px solid ${getColor(EColors.inactiveAction)}`,
    borderRadius: shape.borderRadius,
    boxSizing: 'border-box',
    padding: spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    transition: 'height 1s ease',
}));

export const InforContainer = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    borderRadius: shape.borderRadius,
    boxSizing: 'border-box',
    padding: spacing(1.25),
    display: 'flex',
    gap: spacing(1.25),
    backgroundColor: getColor(EColors.primaryAction, 0.1),
}));

export const BorderedBottom = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    borderBottom: `1px solid ${getColor(EColors.inactiveAction)}`,
    paddingBottom: spacing(2.5),
}));

function RoomBlocksQuestion({
    control,
    dueDate,
    disabled,
    timeZone,
    checkInDate,
}: {
    dueDate: Moment;
    control: Control;
    disabled: boolean;
    timeZone?: string;
    checkInDate?: string;
}) {
    return (
        <>
            <Typography fontWeight={600}>Accommodation</Typography>
            {dueDate && (
                <InforContainer>
                    <InfoOutlinedIcon color="primary" fontSize="small" />
                    <Typography lineHeight={1.3}>
                        Make sure to submit your accommodation needs before{' '}
                        {dueDate.format('hh:mm A z on MMM DD, YYYY ')}.
                    </Typography>
                </InforContainer>
            )}

            <Controller
                name="checkInDate"
                control={control}
                render={({ field, fieldState }) => {
                    return (
                        <DatePicker
                            {...field}
                            label="Check-in"
                            required
                            error={fieldState.error as FieldError}
                            value={field.value}
                            iconColor={getColor(EColors.pureBlack)}
                            disabled={disabled}
                            timeZone={timeZone}
                            disablePast
                        />
                    );
                }}
            />

            <BorderedBottom>
                <Controller
                    name="checkOutDate"
                    control={control}
                    render={({ field, fieldState }) => {
                        return (
                            <DatePicker
                                {...field}
                                label="Check-out"
                                required
                                error={fieldState.error as FieldError}
                                value={field.value}
                                iconColor={getColor(EColors.pureBlack)}
                                disabled={disabled}
                                timeZone={timeZone}
                                shouldDisableDate={date => {
                                    if (checkInDate) {
                                        return date.isBefore(checkInDate);
                                    }
                                    return false;
                                }}
                                showDefaultError
                            />
                        );
                    }}
                />
            </BorderedBottom>
            <BorderedBottom>
                <Controller
                    name="preferredRoomType"
                    control={control}
                    render={({ field, fieldState }) => {
                        return (
                            <RadioGroup
                                {...field}
                                label="Requested Room Type"
                                columns={2}
                                error={fieldState.error?.message}
                                options={[
                                    { label: 'Single', value: 'single' },
                                    { label: 'Double', value: 'double' },
                                    { label: 'Suite', value: 'suite' },
                                    { label: 'Accessible', value: 'accessible' },
                                ]}
                                disabled={disabled}
                            />
                        );
                    }}
                />
            </BorderedBottom>
            <BorderedBottom>
                <Controller
                    name="rsvpComments"
                    control={control}
                    render={({ field, fieldState }) => {
                        return (
                            <TextArea
                                {...field}
                                label="Additional Notes"
                                onChange={field.onChange}
                                placeholder="Notes"
                                value={field.value}
                                error={fieldState.error as FieldError}
                                minRows={3}
                                disabled={disabled}
                            />
                        );
                    }}
                />
            </BorderedBottom>
        </>
    );
}

export default RoomBlocksQuestion;
