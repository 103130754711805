import { Box, Link, Stack, styled, Typography } from '@mui/material';
import { Spinner } from 'components/Spinner';
import UploadBox from 'components/Ui-V2/UploadBox/UploadBox';
import { GET_EVENT_RESOURCES } from 'constants/queryKeys';
import { useDeleteEventResourceMutation, useUploadEventResourcesMutation } from 'hooks/mutations/useEventsMutation';
import { useGetEventsResourcesQuery } from 'hooks/queries/useEventsQuery';
import { useQueryClient } from 'react-query';
import { i18n } from 'translation';

const Label = styled(Typography)`
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.black)};
    margin-bottom: 0.5rem;
`;

const ResourcesContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

type EventResourcesListProps = {
    eventId: number;
    readonly?: boolean;
};

const EventResourcesList = (props: EventResourcesListProps) => {
    const { eventId, readonly } = props;
    const queryClient = useQueryClient();
    const { data: resources, isLoading } = useGetEventsResourcesQuery(eventId);
    const { mutate: uploadEventResources } = useUploadEventResourcesMutation(eventId);
    const { mutate: deleteEventResource } = useDeleteEventResourceMutation(eventId);

    const noResources = !resources || resources.resources.length === 0;

    const eventUploadEndpoint = `/events/${eventId}/resources`;

    const files = resources?.resources.map(resource => new File([resource.url], resource.title));
    const resourcesLinks = resources?.resources.map(resource => ({
        id: resource.id,
        title: resource.title,
        url: resource.url,
        eventId: eventId,
    }));

    const isUploading = !readonly && files;

    const handleAddResources = (files: File[]) => {
        files.forEach(file => {
            const document: Bizly.EventResource = {
                id: 0,
                title: file.name,
                description: undefined,
                url: URL.createObjectURL(file),
                cloudinaryId: '',
            };
            uploadEventResources(document, {
                onSuccess: () => {
                    queryClient.invalidateQueries([GET_EVENT_RESOURCES, eventId]);
                },
            });
        });
    };

    if (isLoading) return <Spinner />;

    return (
        <Stack>
            <Label component="h3">{i18n.playbookWizard.collaboration.documents}</Label>
            {isUploading && (
                <UploadBox
                    files={files}
                    target={eventUploadEndpoint}
                    onUpload={handleAddResources}
                    isResourceFile={true}
                    link={resourcesLinks}
                    deleteResource={deleteEventResource}
                />
            )}

            {readonly && noResources && (
                <Typography>{i18n.playbookWizard.collaboration.noDocumentsUploaded}</Typography>
            )}
            <ResourcesContainer>
                {readonly &&
                    resources?.resources.map(resource => (
                        <Link
                            data-resource-id={resource.id}
                            key={resource.id}
                            target="_blank"
                            href={resource.url}
                            download={resource.title}
                        >
                            {resource.title}
                        </Link>
                    ))}
            </ResourcesContainer>
        </Stack>
    );
};

export default EventResourcesList;
