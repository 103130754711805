import { Spinner } from 'components/Spinner';
import MirageLoader from 'components/Ui-V2/Loaders/MirageLoader';
import EditParcel from 'pages/EditParcel/EditParcel';
import { useEvent } from 'providers/event';
import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { selectMeeting, useMeetings } from 'stores/meetings';
import Communication from './Communication';

export default function CommunicationRoute() {
    const { event, updateAttendeesCount } = useEvent();
    const meeting = useMeetings(selectMeeting(event.id));
    const [isParcelCreating, setIsParcelCreating] = useState(false);

    if (!meeting) {
        return <Spinner />;
    }

    const { startsAt, endsAt, timeZone } = meeting;

    const editParcelContext = {
        ...event,
        meeting: { startsAt, endsAt, timeZone },
    };

    return (
        <>
            {isParcelCreating && <MirageLoader size="5rem" isfullScreen />}
            <Routes>
                <Route path="" element={<Navigate to={'invite'} />} />
                <Route
                    path="edit/:type/:parcelId"
                    element={<EditParcel context={editParcelContext} onParcelSend={updateAttendeesCount} />}
                />
                <Route path=":type" element={<Communication setIsParcelCreating={val => setIsParcelCreating(val)} />} />
            </Routes>
        </>
    );
}
