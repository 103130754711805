import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button } from '../Button/Button';

const StyledButtonBox = styled(Box)({
    height: '21px',
    marginLeft: 'auto',
    transform: 'translateY(-25%)',
});

interface AddAnotherButtonProps {
    onClick: () => void;
}

export const AddAnotherButton = ({ onClick }: AddAnotherButtonProps) => {
    return (
        <StyledButtonBox>
            <Button variant="text" startIcon={<PersonAddAltOutlinedIcon />} onClick={onClick}>
                Add Another
            </Button>
        </StyledButtonBox>
    );
};
