import colorFns from 'colorFns';
import ProgressTracker from 'components/ProposalForm/ProgressTracker';
import { StickyHeader } from 'components/StickyHeader';
import Button from 'components/ui/Button';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, InlineRow, Row } from 'ui';
import { HEADER_HEIGHT } from 'utils/header';
import {
    ESteps,
    ValidationError,
    createPlaybookActions,
    inquiryFormActions,
    maxStep,
    selCurStep,
    selNextStep,
    stepList,
    stepToTitle,
    useCreatePlaybook,
} from '../store';

const StickyOffset = styled.div`
    height: ${HEADER_HEIGHT}px;
`;

const HeaderCol = styled(Column)`
    background: ${colorFns.pureWhite};
    width: 100%;
`;

export default function Header() {
    const { stepIdx, id, inquirySaved } = useCreatePlaybook();
    const nextStep = useCreatePlaybook(selNextStep);
    const curStep = useCreatePlaybook(selCurStep);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const goToPlaybook = (id: number | string) => navigate(`/playbooks/${id}`);

    const create = async () => {
        try {
            await createPlaybookActions.update(curStep);
            if (id) goToPlaybook(id);
        } catch (e) {
            if (!(e instanceof ValidationError)) enqueueSnackbar(i18n.error.default, { variant: 'error' });
        }
    };

    const save = useCallback(
        async (nextStep?: ESteps) => {
            try {
                if (curStep === ESteps.inquiry) {
                    if (!inquirySaved) {
                        await inquiryFormActions.saveInquiry(nextStep);
                    }
                }

                await createPlaybookActions.update(nextStep);
                if (!nextStep) {
                    enqueueSnackbar(i18n.playbookWizard.header.saved, { variant: 'success' });
                }
            } catch (e) {
                if (!(e instanceof ValidationError)) enqueueSnackbar(i18n.error.default, { variant: 'error' });
            }
        },
        [curStep, inquirySaved, enqueueSnackbar]
    );

    return (
        <HeaderCol alignItems="center">
            <StickyHeader>
                <Row justifyContent="flex-end">
                    <InlineRow itemSpacing="smallish">
                        {stepIdx > 0 && (
                            <Button width="80px" secondary onClick={createPlaybookActions.prevStep}>
                                {i18n.button.previous}
                            </Button>
                        )}
                        {stepIdx < maxStep && (
                            <Button width="80px" secondary onClick={() => save()}>
                                {i18n.button.save}
                            </Button>
                        )}
                        {stepIdx < maxStep && (
                            <Button width="140px" onClick={() => save(nextStep)}>
                                {i18n.button.next}
                            </Button>
                        )}
                        {stepIdx === maxStep && (
                            <Button width="140px" onClick={create}>
                                {i18n.playbookWizard.header.updatePlaybook}
                            </Button>
                        )}
                    </InlineRow>
                </Row>
            </StickyHeader>
            <StickyOffset />
            <ProgressTracker
                sections={stepList.map(key => ({ key, label: stepToTitle[key] }))}
                currentSection={stepIdx}
                horizontal
                inverted
                large
                onClick={save}
            />
        </HeaderCol>
    );
}
