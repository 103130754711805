import RejectionReasonForm from 'components/ProposalForm/RejectionReasonForm';
import { ReactNode } from 'react';
import { i18n } from 'translation';
import { enableTurnDown } from 'utils';
import { tzMoment, userTimeZone } from 'utils/moment';
import { TProposalForm } from './types';

type TProposalFormStatus =
    | 'Inquiry Invalid'
    | 'Inquiry Submitted'
    | 'Inquiry Cancelled'
    | 'Inquiry Rejected Reversible'
    | 'Inquiry Rejected Terminary'
    | 'Proposal Submitted'
    | 'Proposal Accepted'
    | 'Proposal Rejected'
    | 'Proposal Cancelled Reversible'
    | 'Proposal Cancelled Terminary';

export const submittedProposal = (proposal: TProposalForm['proposal'] = {}) => proposal.status?.name !== 'draft';

export const getProposalFormStatus = ({
    proposal = {},
    inquiry = {},
}: Partial<TProposalForm>): TProposalFormStatus | undefined => {
    const { status: proposalStatus } = proposal;
    const { status: inquiryStatus, isValid } = inquiry;

    if (!proposalStatus || !inquiryStatus) {
        return undefined;
    }

    if (submittedProposal(proposal)) {
        switch (proposalStatus.name) {
            case 'submitted':
                return 'Proposal Submitted';
            case 'accepted':
                return 'Proposal Accepted';
            case 'rejected':
                return 'Proposal Rejected';
            case 'cancelled':
                return isValid ? 'Proposal Cancelled Reversible' : 'Proposal Cancelled Terminary';
            default:
                return undefined;
        }
    } else {
        switch (inquiryStatus) {
            case 'submitted':
                return isValid ? 'Inquiry Submitted' : 'Inquiry Invalid';
            case 'rejected':
                return isValid ? 'Inquiry Rejected Reversible' : 'Inquiry Rejected Terminary';
            case 'cancelled':
                return 'Inquiry Cancelled';
            default:
                return isValid ? 'Inquiry Submitted' : 'Inquiry Cancelled';
        }
    }
};

export type TAction = 'accept' | 'reject' | 'accept-extension' | 'reject-extension';

const rejectedInquiryHeading = (action?: TAction) => () =>
    action === 'reject' ? i18n.proposalForm.status.inquiryAlreadyRejected : i18n.proposalForm.status.inquiryRejected;

const cancelledProposalHeading = (action?: TAction) => () =>
    action === 'reject'
        ? i18n.proposalForm.status.proposalAlreadyCancelled
        : i18n.proposalForm.status.proposalCancelled;

export type TStatusAttributes = {
    skipToStatus: boolean;

    noProposal: boolean;
    readonly: boolean;

    canView: boolean;
    canCreate: boolean;
    canSubmit: boolean;
    isResubmit: boolean;
    canTurnDown: boolean;
    canWithdraw: boolean;

    heading: (proposalForm: Partial<TProposalForm>) => string;
    copy: (proposalForm: Partial<TProposalForm>) => ReactNode;
    links: (proposalForm: Partial<TProposalForm>) => { link: string; label: string }[];
};

export const getStatusAttributes = (
    status?: TProposalFormStatus,
    action?: TAction,
    userChangedStatus?: boolean
): TStatusAttributes => {
    const defaults = {
        skipToStatus: true,

        noProposal: false,
        readonly: false,

        canView: true,
        canCreate: false,
        canSubmit: false,
        isResubmit: false,
        canTurnDown: false,
        canWithdraw: false,

        heading: () => '',
        copy: () => '',
        links: () => [],
    };

    switch (status) {
        case 'Inquiry Submitted':
            return {
                ...defaults,
                skipToStatus: false,
                canSubmit: true,
                canTurnDown: true,
            };

        case 'Inquiry Invalid':
            return {
                ...defaults,
                noProposal: true,
                canView: false,

                heading: () => i18n.proposalForm.status.inquiryInvalid,
            };

        case 'Inquiry Rejected Reversible':
            return {
                ...defaults,
                skipToStatus: action === 'reject',
                noProposal: false,
                canCreate: true,

                heading: rejectedInquiryHeading(action),
                ...(enableTurnDown
                    ? {
                          copy: ({ inquiry }: Partial<TProposalForm>) => (
                              <RejectionReasonForm
                                  value={{
                                      reason: inquiry?.rejectionReason || '',
                                      note: inquiry?.rejectionNotes || '',
                                  }}
                                  readonly
                              />
                          ),
                      }
                    : {}),
            };

        case 'Inquiry Rejected Terminary':
            return {
                ...defaults,
                noProposal: true,
                canView: false,

                heading: rejectedInquiryHeading(action),
                ...(enableTurnDown
                    ? {
                          copy: ({ inquiry }: Partial<TProposalForm>) => (
                              <RejectionReasonForm
                                  value={{
                                      reason: inquiry?.rejectionReason || '',
                                      note: inquiry?.rejectionNotes || '',
                                  }}
                                  readonly
                              />
                          ),
                      }
                    : {}),
            };
        case 'Inquiry Cancelled':
            return {
                ...defaults,
                noProposal: true,
                canView: false,

                heading: () => i18n.proposalForm.status.inquiryCancelled,
            };
        case 'Proposal Submitted':
            return {
                ...defaults,
                canSubmit: true, // no need to worry about isValid because accepting a proposal rejects all others
                isResubmit: true,
                canWithdraw: true,

                heading: () =>
                    userChangedStatus
                        ? i18n.proposalForm.status.proposalSubmittedWithUserChange
                        : i18n.proposalForm.status.proposalSubmitted,

                copy: ({ client = {}, proposal = {}, venue = {} }: Partial<TProposalForm>) => [
                    i18n.proposalForm.status.deadlineCopy(
                        [client.firstName, client.lastName].join(' '),
                        tzMoment(proposal.expiryDate + ' 23:59:59', venue.timeZone)
                            .tz(venue.timeZone ?? userTimeZone)
                            .format('ll LTS zz')
                    ),
                ],
            };
        case 'Proposal Accepted':
            return {
                ...defaults,
                readonly: true,

                heading: ({ client = {} }: Partial<TProposalForm>) =>
                    i18n.proposalForm.status.proposalAccepted([client.firstName, client.lastName].join(' ')),
                copy: ({ booking = {} }: Partial<TProposalForm>) =>
                    booking?.contractUrl ? [''] : [i18n.proposalForm.status.proposalAcceptedCopy],
                links: ({ booking = {} }: Partial<TProposalForm>) => {
                    const urls: { link: string; label: string }[] = [];
                    booking?.contractUrl &&
                        urls.push({
                            link: booking.contractUrl,
                            label: booking?.contract2Url
                                ? i18n.proposalForm.viewContract1
                                : i18n.proposalForm.viewContract,
                        });
                    booking?.contract2Url &&
                        urls.push({ link: booking.contract2Url, label: i18n.proposalForm.viewContract2 });
                    return urls;
                },
            };
        case 'Proposal Rejected':
            return {
                ...defaults,
                readonly: true,

                heading: () => i18n.proposalForm.status.proposalRejected,
            };
        case 'Proposal Cancelled Terminary':
            return {
                ...defaults,
                readonly: true,

                heading: cancelledProposalHeading(action),
            };
        case 'Proposal Cancelled Reversible':
            return {
                ...defaults,
                canSubmit: true,

                heading: cancelledProposalHeading(action),
            };
        default:
            return defaults;
    }
};
