import * as Sentry from '@sentry/react';
import { FallbackProps } from 'react-error-boundary';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column } from 'ui';

const FullScreenCentered = styled(Column)`
    height: 100vh;
    align-items: center;
    justify-content: center;
    padding: 2em;
`;

const MainTitle = styled.h1`
    font-size: 72px;
    line-height: 0;
    text-transform: capitalize;
`;

const SectionHeader = styled.h2`
    font-size: 44px;
`;

export const GenericError = ({ message }: { message: string }) => (
    <FullScreenCentered fillWidth>
        <MainTitle>{i18n.error.somethingWentWrong}</MainTitle>
        <SectionHeader>{message}</SectionHeader>
    </FullScreenCentered>
);

const ErrorFallback = ({ error }: Readonly<FallbackProps>) => {
    Sentry.captureException(error);

    return <GenericError message={error.message} />;
};

export default ErrorFallback;
