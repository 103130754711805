import { Box, styled } from '@mui/material';
import { Switch } from 'components/BizlyOS/InputFields/SwitchField';
import { Body1 } from 'components/BizlyOS/Typography/Typography';
import { Controller } from 'react-hook-form';
import { BorderedContainer, Description, EventSpaceFormProps, Title } from './utils';

const AvList = styled(Box)({
    '& > :last-child': {
        borderBottom: 'none',
        paddingBottom: 0,
    },
});

export const OptionContainer = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1.25),
    padding: spacing(2.5, 0),
    borderBottom: `0.5px solid ${getColor(EColors.bizlyOSBorder)}`,
}));

const generateProposedAvIds = (proposedAvIds: number[], checkedValue: boolean, avId: number) => {
    let newProposedAvIds = proposedAvIds;
    if (checkedValue) {
        newProposedAvIds = [...newProposedAvIds, avId];
    } else {
        newProposedAvIds = newProposedAvIds.filter(av => av !== avId);
    }

    return newProposedAvIds;
};

export const AudioAndVisual = ({ control, eventSpace, proposalInquiry }: EventSpaceFormProps) => {
    if ((eventSpace?.requestedAvIds?.length || 0) === 0) return null;

    return (
        <BorderedContainer>
            <Title>Audio & Visual</Title>
            <Description sx={{ mb: 0 }}>
                The client is requesting the A/V below. Select what you can accommodate.
            </Description>
            <Controller
                name="proposedAvIds"
                control={control}
                render={({ field }) => {
                    return (
                        <AvList>
                            {eventSpace?.requestedAvIds?.map(avId => {
                                const avOption = proposalInquiry?.options?.avOptions?.find(av => av.id === avId);
                                if (!avOption) return null;
                                return (
                                    <OptionContainer key={avId}>
                                        <img src={avOption?.iconUrl} width="20px" />
                                        <Body1 flex={1}>{avOption?.name}</Body1>
                                        <Switch
                                            checked={field.value.includes(avId)}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                const newProposedAvIds = generateProposedAvIds(
                                                    field.value,
                                                    event.target.checked,
                                                    avId
                                                );
                                                field.onChange(newProposedAvIds);
                                            }}
                                        />
                                    </OptionContainer>
                                );
                            })}
                        </AvList>
                    );
                }}
            />
        </BorderedContainer>
    );
};
