import { useEffect, useState } from 'react';

const useTimer = (limit: number) => {
    const [seconds, setSeconds] = useState(0);
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
        let interval = null;

        if (isRunning && seconds < limit) {
            interval = setInterval(() => {
                setSeconds(prev => prev + 1);
            }, 1000);
        } else if (seconds >= limit) {
            setIsRunning(false);
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isRunning, seconds, limit]);

    const start = () => setIsRunning(true);
    const pause = () => setIsRunning(false);
    const reset = () => {
        setIsRunning(false);
        setSeconds(0);
    };

    return {
        seconds,
        isRunning,
        start,
        pause,
        reset,
        isCompleted: seconds >= limit,
    };
};

export default useTimer;
