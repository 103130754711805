import { deleteEventResource, uploadEventResources } from 'api/events';
import { UseMutationOptions, useMutation } from 'react-query';

export const useUploadEventResourcesMutation = (
    eventId: number,
    options?: UseMutationOptions<unknown, unknown, Bizly.EventResource>
) => {
    return useMutation((file: Bizly.EventResource) => uploadEventResources(eventId, file), options);
};

export const useDeleteEventResourceMutation = (
    eventId: number,
    options?: UseMutationOptions<{ success: boolean }, unknown, number>
) => {
    return useMutation((resourceId: number) => deleteEventResource(eventId, resourceId), options);
};
