import { InfoOutlined } from '@mui/icons-material';
import { Box, Tooltip, Typography, styled } from '@mui/material';
import { EColors, getColor } from 'theme';
import { formatCurrency, getBudget } from '../util';

const BudgetContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
`;

const BudgetRow = styled(Box)`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const BudgetText = styled(Typography)`
    font-size: 0.813rem;
    color: ${getColor(EColors.pureBlack)};
`;

const InfoIcon = styled(InfoOutlined)`
    font-size: small;
    color: ${getColor(EColors.grey2)};
`;

type BudgetDisplayProps = {
    proposal: Bizly.VenueProposal;
    user: Bizly.User;
    event: Bizly.Event;
    currencyCode?: string;
    budgetBufferPercentage?: number;
};

export const BudgetDisplay = ({
    proposal,
    user,
    event,
    currencyCode = 'USD',
    budgetBufferPercentage = 1.1,
}: BudgetDisplayProps) => {
    const baseBudget = getBudget(proposal, user, event, false, budgetBufferPercentage);
    const maxBudget = getBudget(proposal, user, event, true, budgetBufferPercentage);

    const formattedBaseBudget = formatCurrency(baseBudget, currencyCode);
    const formattedMaxBudget = formatCurrency(maxBudget, currencyCode);

    return (
        <BudgetContainer>
            <BudgetRow>
                <BudgetText>Budget: {formattedBaseBudget}</BudgetText>
                <Tooltip title="Your base budget constraint">
                    <InfoIcon />
                </Tooltip>
            </BudgetRow>
            <BudgetRow>
                <BudgetText>Maximum Allowed: {formattedMaxBudget}</BudgetText>
                <Tooltip title="Maximum amount allowed with buffer">
                    <InfoIcon />
                </Tooltip>
            </BudgetRow>
        </BudgetContainer>
    );
};
