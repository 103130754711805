import { darken, lighten } from '@material-ui/core';
import camelize from 'camelize';
import uniqWith from 'lodash/uniqWith';
import { defaultTeamSettings } from 'pages/TeamSettings/TeamSettings';
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PostHogFeatureFlags } from 'shared';
import { nudgesActions } from 'stores/nudges';
import { themeActions } from 'stores/theme';
import { EColors, ETeams } from 'theme';
import { isColorBright } from 'utils/colors';
import { getLoggedInUser, updateUser as updateUserAPI } from '../api';
import { getCookie } from '../utils/tokens';

// const CORE_SUB_DOMAIN = import.meta.env.VITE_APP_CORE_SUB_DOMAIN; TODO: Enable this once we swap Stencil's sub-domain to app.bizly.com

const TEAM_ADMIN = 'team-admin';

const initialState: Bizly.User = {
    firstName: '',
    lastName: '',
    phone: '',
    role: '',
    email: '',
    imageUrl: '',
    loaded: false,
    token: '',
    title: '',
    intercomIdentityVerificationHash: '',
    isVenueUser: false,
};

const UNUSED_DUMMY_ID = -1;

const UserContext = React.createContext<Bizly.UserContext | undefined>(undefined);

type TUserProvider = {
    children?: React.ReactNode;
};

const isUser = (user: unknown): user is Bizly.User => {
    return !!(user as Bizly.User).team;
};

const black = '#000000',
    white = '#ffffff';

const overridingColors: EColors[] = [
    'brand',
    'sidenavForeground',
    'subNavBackground',
    'teamPicker',
    'teamPickerText',
    'homeScreenBanner',
    'bannerLinkText',
];

export const UserProvider = ({ children }: TUserProvider) => {
    const posthog = usePostHog();
    const navigate = useNavigate();
    const location = useLocation();
    const [userState, setUserState] = React.useState<Bizly.User>(initialState);
    const workspacesEnabled = useFeatureFlagEnabled(PostHogFeatureFlags.workspacesEnabled);

    const overrideColors = React.useCallback((color: string) => {
        if (color.toLocaleLowerCase() !== defaultTeamSettings.color?.toLowerCase()) {
            themeActions.setColorOverrides({
                brand: color,
                sidenavForeground: isColorBright(color) ? black : white,
                subNavBackground: isColorBright(color) ? darken(color, 0.2) : lighten(color, 0.2),
                teamPicker: isColorBright(color) ? black : white,
                teamPickerText: isColorBright(color) ? white : black,
                homeScreenBanner: color,
                bannerLinkText: isColorBright(color) ? black : white,
            });
        } else {
            themeActions.unsetColorOverrides(overridingColors);
        }
    }, []);

    const setUserTheme = React.useCallback(
        (user: Partial<Bizly.User>) => {
            if (!user?.id) return;

            const theme = user?.team?.theme?.toLowerCase() as ETeams;
            themeActions.setTheme(theme || ETeams.bizly);
            if (theme === ETeams.apple) {
                themeActions.unsetColorOverrides(overridingColors);
            } else {
                if (user?.team?.features.enableBranding && user?.team?.color) {
                    overrideColors(user.team.color);
                }
            }
        },
        [overrideColors]
    );

    const refreshUser = React.useCallback(async () => {
        let loggedInUser;
        const token = getCookie();
        if (!token)
            return {
                email: null,
                loaded: true,
                token: null,
            }; // getLoggedInUser will unmount the entire app if we don't exit early in this case

        try {
            loggedInUser = await getLoggedInUser();

            /*
            temp disable redirect to core

            if (token && CORE_SUB_DOMAIN && !loggedInUser?.team?.migratedAt) {
                window.location.replace(`https://${CORE_SUB_DOMAIN}.bizly.com`);
            }
            */
        } catch (e) {
            return {
                email: null,
                loaded: true,
                token: null,
            };
        }

        let userNeedsToFillProfile = !loggedInUser.firstName || !loggedInUser.lastName;

        if (!loggedInUser?.isSsoUser) {
            userNeedsToFillProfile = userNeedsToFillProfile || !loggedInUser.role || !loggedInUser.phone;
        }

        if (userNeedsToFillProfile && location.pathname !== '/profile' && !location.pathname.startsWith('/os/venues')) {
            // Allow venue routes to bypass
            navigate('/profile', {
                state: {
                    redirectTo: location.pathname,
                },
            });
        }

        return {
            ...loggedInUser,
            loaded: true,
            token,
        };
    }, [navigate, location]);

    const fetchMe = React.useCallback(() => {
        refreshUser().then(refreshedUser => {
            setUserState(refreshedUser);
            setUserTheme(refreshedUser);

            if (isUser(refreshedUser)) {
                nudgesActions.mergeNudges({
                    showTeamSettings: !!refreshedUser.team?.name,
                });
            }
        });
    }, [refreshUser, setUserState, setUserTheme]);

    React.useEffect(() => {
        if (userState.connectors) {
            nudgesActions.loadIntegrationsPrompts(
                userState.shownIntegrationsModal || 0,
                (userState.connectors ?? []).length > 0
            );
        }
    }, [userState.connectors, userState.shownIntegrationsModal]);

    const userLoaded = React.useRef(false);
    React.useEffect(() => {
        if (!userLoaded.current) {
            fetchMe();
            userLoaded.current = true;
        }
    }, [fetchMe]);

    function clearUser() {
        setUserState({ ...initialState, loaded: true });
        themeActions.setTheme(ETeams.bizly);
    }

    async function updateUser(user: Partial<Bizly.User>) {
        const newUser = await updateUserAPI(user);
        setUserState(currentUser => ({ ...currentUser, ...newUser }));
        setUserTheme(user);
    }

    function setTeam(team: Bizly.Team) {
        const theme = (team.theme || ETeams.bizly) as ETeams;
        setUserState(state => ({ ...state, team }));
        themeActions.setTheme(theme);
        if (team.theme === ETeams.apple) {
            themeActions.unsetColorOverrides(overridingColors);
        } else {
            if (team?.features.enableBranding && team?.color) {
                overrideColors(team.color);
            }
        }
    }

    const toggles = React.useMemo(() => {
        const { team, featureFlags: gate = {} } = userState;
        return {
            gate,
            compliance: {
                ...(team?.compliance || {}),
            },
            workspacesEnabled: !!workspacesEnabled,
        };
    }, [userState, workspacesEnabled]);

    const isInDemoMode = React.useMemo(() => {
        const { team } = userState;
        return team?.demoMode || false;
    }, [userState]);

    const isTeamAdmin = userState?.team?.role === TEAM_ADMIN;

    const userStateCamelized = React.useMemo(
        () => ({
            ...userState,
            ...(userState.team
                ? {
                    team: {
                        ...userState.team,
                        ...(userState.team?.authMeetingCreateFields
                            ? { authMeetingCreateFields: userState.team?.authMeetingCreateFields.map(camelize) }
                            : {}),
                        ...(userState.team?.uniqueMeetingCreateFields
                            ? { uniqueMeetingCreateFields: userState.team?.uniqueMeetingCreateFields.map(camelize) }
                            : {}),
                        features: {
                            ...userState.team.features,
                            ...(userState.team.features.hiddenInternalFields
                                ? { hiddenInternalFields: userState.team.features.hiddenInternalFields.map(camelize) }
                                : {}),
                            ...(userState.team.features.requiredInternalFields
                                ? {
                                    requiredInternalFields:
                                        userState.team.features.requiredInternalFields.map(camelize),
                                }
                                : {}),
                        },
                    },
                }
                : {}),
        }),
        [userState]
    );

    return (
        <UserContext.Provider
            value={{
                user: userStateCamelized,
                toggles,
                isTeamAdmin,
                isInDemoMode,
                clearUser,
                refreshUser,
                updateUser,
                setUserState: user => {
                    setUserState(user);
                    setUserTheme(user);

                    posthog.identify(user.id?.toString(), {
                        email: user.email,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        teamId: user.team?.id,
                        teamName: user.team?.name,
                        isTeamAdmin: user.team?.role === TEAM_ADMIN,
                        isBizlyEmployee: user.email?.endsWith('@bizly.com'),
                        role: user.role,
                        teamTier: user.team?.tier,
                    });
                },
                setTeam,
                fetchMe,
                // TODO: refactor the entire user state
                // this is necessary to properly handle async updates to the user state
                addConnector: (connector: BizlyAPI.Connector) => {
                    setUserState(curUser => ({
                        ...curUser,
                        ...(curUser.loaded && curUser.token
                            ? {
                                connectors: uniqWith(
                                    [...(curUser.connectors ?? []), { id: UNUSED_DUMMY_ID, type: connector }],
                                    (a, b) => a.type === b.type
                                ),
                            }
                            : {}),
                    }));
                    nudgesActions.updateIntegrationsPrompts('showVMIntegrations', false);
                    nudgesActions.updateIntegrationsPrompts('showPublishIntegrations', false);
                },
                delConnector: (connector: BizlyAPI.Connector) => {
                    setUserState(curUser => ({
                        ...curUser,
                        ...(curUser.loaded && curUser.token
                            ? {
                                connectors: curUser.connectors?.filter(
                                    curConnector => curConnector.type !== connector
                                ),
                            }
                            : {}),
                    }));
                },
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export function useUser() {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
}

export function withUserContext<T>(Component: React.ComponentType<T>) {
    return (props: T) => (
        <UserContext.Consumer>{context => <Component userContext={context} {...props} />}</UserContext.Consumer>
    );
}