import { TitleTab, TitleTabs } from 'components/BizlyOS/Tabs';
import TitledContainer from 'components/BizlyOS/TitledContainer';
import React, { useState } from 'react';
import { ProposalSummary } from './ProposalSummary';
// import { ProposalMessages } from './ProposalMessages/ProposalMessages';
// import { ProposalShared } from './ProposalShared/ProposalShared';

const TabOptions = [
    { label: 'Summary', Element: ProposalSummary },
    // NOTE: hidden in v1
    // { label: 'Messages', Element: ProposalMessages },
    // { label: 'Shared', Element: ProposalShared },
];

export function ProposalAction() {
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (_: React.SyntheticEvent, newIndex: number) => {
        setTabIndex(newIndex);
    };

    return (
        <TitledContainer
            borderWidth="small"
            fullBodyHeight
            bodyPadding="0px"
            leftElement={
                <TitleTabs value={tabIndex} onChange={handleTabChange}>
                    {TabOptions.map((tab, index) => (
                        <TitleTab key={index} label={tab.label} value={index} />
                    ))}
                </TitleTabs>
            }
        >
            {React.createElement(TabOptions[tabIndex].Element)}
        </TitledContainer>
    );
}
