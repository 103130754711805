import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { EColors, getColor } from 'theme';

interface LogOutModalProps {
    isLogoutModalOpen: boolean;
    setIsLogoutModalOpen: (isLogoutModalOpen: boolean) => void;
    handleLogoutConfirm: () => void;
}

export const LogOutModal = ({ isLogoutModalOpen, setIsLogoutModalOpen, handleLogoutConfirm }: LogOutModalProps) => {
    return (
        <Dialog
            open={isLogoutModalOpen}
            onClose={() => setIsLogoutModalOpen(false)}
            aria-labelledby="logout-dialog-title"
            sx={{ padding: '1.25rem' }}
        >
            <DialogTitle id="logout-dialog-title" sx={{ color: getColor(EColors.warningText), fontWeight: 600 }}>
                Leaving?
            </DialogTitle>
            <DialogContent sx={{ width: '460px' }}>
                <DialogContentText sx={{ color: getColor(EColors.pureBlack) }}>
                    Please confirm you want to log out
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ paddingBottom: '1.25rem', paddingRight: '1.25rem' }}>
                <Button variant="outline" size="small" onClick={() => setIsLogoutModalOpen(false)}>
                    Cancel
                </Button>
                <Button color="error" onClick={handleLogoutConfirm}>
                    Logout
                </Button>
            </DialogActions>
        </Dialog>
    );
};
