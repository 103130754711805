import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import { Box, styled, SvgIcon, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';
import { type Editor } from '@tiptap/react';
import React, { useEffect } from 'react';
import { EColors, getColor } from 'theme';

export const Container = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${getColor(EColors.bizlyOSBorder)}`,
    boxSizing: 'border-box',
    paddingBottom: spacing(1.25),
    marginBottom: spacing(1.25),
    gap: spacing(1),
}));

export const Divider = styled(Box)(({ theme: { getColor, EColors } }) => ({
    height: '20px',
    width: '1px',
    backgroundColor: getColor(EColors.bizlyOSBorder),
}));

const AiContainer = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    gap: spacing(0.5),
    alignItems: 'center',
    backgroundColor: getColor(EColors.inactiveAction, 0.5),
    borderRadius: '4px',
    padding: '4px 8px',
    cursor: 'not-allowed',
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme: { spacing, shape } }) => ({
    gap: spacing(0.6),
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        height: '20px',
        width: '20px',
        border: 0,
        borderRadius: shape.borderRadius,
        [`&.${toggleButtonGroupClasses.disabled}`]: {
            border: 0,
        },
    },
}));

const StyledSvgIcon = styled(SvgIcon)({
    fontSize: 16,
    '&.MuiSvgIcon-root': { fontSize: '16px' },
});

type ToolbarProps = {
    editor: Editor | null;
    isAiWrite?: boolean;
};

function Toolbar({ editor, isAiWrite = false }: ToolbarProps) {
    const [formats, setFormats] = React.useState<string[]>([]);

    const handleFormat = (_: React.MouseEvent<HTMLElement>, newFormats: string[]) => {
        setFormats(newFormats);
    };

    // Update the formats state on selection changes
    useEffect(() => {
        if (!editor) return;

        const handleUpdate = () => {
            const newFormats: string[] = [];
            if (editor.isActive('bold')) {
                newFormats.push('bold');
            }
            if (editor.isActive('italic')) {
                newFormats.push('italic');
            }
            if (editor.isActive('underline')) {
                newFormats.push('underline');
            }
            setFormats(newFormats);
        };

        editor.on('update', handleUpdate);

        return () => {
            editor.off('update', handleUpdate);
        };
    }, [editor]);

    if (!editor) {
        return null;
    }

    return (
        <Container>
            <StyledToggleButtonGroup size="small" value={formats} onChange={handleFormat} aria-label="text formatting">
                <ToggleButton value="bold" onClick={() => editor.chain().focus().toggleBold().run()}>
                    <StyledSvgIcon component={FormatBoldIcon} />
                </ToggleButton>
                <ToggleButton value="italic" onClick={() => editor.chain().focus().toggleItalic().run()}>
                    <StyledSvgIcon component={FormatItalicIcon} />
                </ToggleButton>
                <ToggleButton value="underline" onClick={() => editor.chain().focus().toggleUnderline().run()}>
                    <StyledSvgIcon component={FormatUnderlinedIcon} />
                </ToggleButton>
            </StyledToggleButtonGroup>
            {isAiWrite && (
                <>
                    <Divider />

                    <AiContainer>
                        <StyledSvgIcon component={AutoAwesomeIcon} color="disabled" />
                        <Typography variant="body2" fontWeight="500" color={getColor(EColors.pureBlack, 0.5)}>
                            Write with AI
                        </Typography>
                    </AiContainer>
                </>
            )}
        </Container>
    );
}

export default Toolbar;
