import { DialogContent } from '@mui/material';
import Box from '@mui/material/Box';
import { createEvent, findPlaces, generateEventMetaData } from 'api';
import { DATE_TIME_FORMAT } from 'components/Planner/utils';
import { Button, LoadingButton } from 'components/Ui-V2/Button/Button';
import { LinearProgressWithLabel } from 'components/ui/LinearProgress';
import AIGenerateSvg from 'images/icons/ai-generate.svg?react';
import { useSnackbar } from 'notistack';
import { useUser } from 'providers/user';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { i18n } from 'translation';
import { tzMoment } from 'utils/moment';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';
import Step6 from './Steps/Step6';
import Step7 from './Steps/Step7';
import { EventCreateForm, EventCreateFormErrors } from './formSchema';
import { StyledDialog } from './styled';

type EventCreateModalProps = {
    onClose: () => void;
    onEventCreated?: (event: Bizly.Event) => Promise<void>;
};

export default function EventCreateModal(props: EventCreateModalProps) {
    const { onClose, onEventCreated } = props;
    const { user } = useUser();

    const [submitting, setSubmitting] = React.useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [formValues, setFormValues] = useState<EventCreateForm>({});
    const [isStepValid, setIsStepValid] = useState(false);
    const [errors, setErrors] = useState<EventCreateFormErrors>({});

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const onFormValueChange = (key: keyof EventCreateForm) => (value: unknown) => {
        setFormValues(prev => ({
            ...prev,
            [key]: value,
        }));
        // reset error state
        setErrors(prev => ({
            ...prev,
            [key]: false,
        }));
    };

    // Populate form fields with AI generated metadata
    const onStep1Submit = useCallback(async () => {
        if (formValues.eventDescription) {
            setSubmitting(true);
            try {
                const result = await generateEventMetaData(formValues.eventDescription);

                let locationValue: EventCreateForm['location'] = null;
                if (result.eventMetadata?.location) {
                    const gmapsResponse = await findPlaces(result.eventMetadata.location);
                    if (gmapsResponse.status === 'OK' && gmapsResponse.predictions.length > 0) {
                        locationValue = {
                            location: gmapsResponse.predictions[0].description,
                            googlePlaceId: gmapsResponse.predictions[0].placeId,
                        };
                    }
                }

                const startDate = result.eventMetadata?.startsAt ? tzMoment(result.eventMetadata.startsAt) : tzMoment();

                if (result.success) {
                    setFormValues(prev => ({
                        ...prev,
                        guests: result.eventMetadata?.attendeeCount ?? 0,
                        payment: {
                            cost: result.eventMetadata?.budget ?? 0,
                            mode: 'total',
                        },
                        location: locationValue,
                        eventDate: {
                            start: startDate,
                            end: result.eventMetadata?.endsAt ? tzMoment(result.eventMetadata.endsAt) : startDate,
                        },
                        requirements: result.eventMetadata?.requirements,
                    }));
                }
            } catch (e) {
                enqueueSnackbar(String(e), { variant: 'error' });
            } finally {
                setSubmitting(false);
            }
        }
    }, [formValues.eventDescription, enqueueSnackbar]);

    const getStepErrors = useCallback(
        (step: number): EventCreateFormErrors => {
            const stepErrors = {} as EventCreateFormErrors;

            switch (step) {
                case 1:
                    if (!formValues.eventName) {
                        stepErrors.eventName = true;
                    }
                    break;
                case 7:
                    if (user.team?.features.requiredInternalFields.includes('costCenter') && !formValues.costCenter) {
                        stepErrors.costCenter = true;
                    }
                    if (user.team?.features.requiredInternalFields.includes('cventId') && !formValues.cventId) {
                        stepErrors.cventId = true;
                    }
                    if (user.team?.features.requiredInternalFields.includes('department') && !formValues.department) {
                        stepErrors.department = true;
                    }
                    if (user.team?.features.requiredInternalFields.includes('type') && !formValues.meetingType) {
                        stepErrors.meetingType = true;
                    }
                    break;
            }
            return stepErrors;
        },
        [formValues, user.team?.features.requiredInternalFields]
    );

    useEffect(() => {
        let isValid = true;
        switch (currentStep) {
            case 1:
                if (!formValues.eventName) {
                    isValid = false;
                }
                break;
            case 2:
                if (!formValues.location) {
                    isValid = false;
                }
                break;
            case 3:
                if (!formValues.requirements || formValues.requirements.length === 0) {
                    isValid = false;
                }
                break;
            case 4:
                if (!formValues.eventDate?.start || !formValues.eventDate?.end) {
                    isValid = false;
                } else {
                    if (
                        formValues.eventDate.start.isBefore(tzMoment()) ||
                        formValues.eventDate.start.isAfter(formValues.eventDate.end)
                    ) {
                        isValid = false;
                    }
                }
                break;
            case 5:
                if (!formValues.guests) {
                    isValid = false;
                }
                break;
            case 6:
                if (!formValues.payment?.cost || formValues.payment.cost <= 0) {
                    isValid = false;
                }
                break;
            case 7: {
                const stepErrors = getStepErrors(7);
                isValid = Object.keys(stepErrors).length === 0;
                break;
            }
        }

        setIsStepValid(isValid);
    }, [currentStep, formValues, getStepErrors]);

    const formSteps = [
        {
            component: <Step1 key="step1" onChange={onFormValueChange} values={formValues} errors={errors} />,
            required: true,
            onContinue: onStep1Submit,
        },
        {
            component: (
                <Step2 key="step2" onChange={onFormValueChange('location')} value={formValues.location ?? null} />
            ),
            required: true,
        },
        {
            component: (
                <Step3 key="step3" onChange={onFormValueChange('requirements')} value={formValues.requirements ?? []} />
            ),
        },
        {
            component: <Step4 key="step4" onChange={onFormValueChange('eventDate')} value={formValues.eventDate} />,
        },
        {
            component: <Step5 key="step5" onChange={onFormValueChange('guests')} value={formValues.guests ?? 0} />,
        },
        {
            component: <Step6 key="step6" onChange={onFormValueChange('payment')} value={formValues.payment} />,
        },
        {
            component: <Step7 key="step7" onChange={onFormValueChange} values={formValues} errors={errors} />,
            required: true,
        },
    ];

    const onBack = () => {
        if (currentStep <= 1) {
            onClose();
        }
        setCurrentStep(step => step - 1);
    };

    const onContinue = async () => {
        if (currentStep >= formSteps.length) {
            return;
        }
        await formSteps[currentStep - 1].onContinue?.();
        setCurrentStep(step => step + 1);
    };

    const onSubmit = async () => {
        let formErrors = {} as EventCreateFormErrors;
        let errorStep = -1;
        for (let i = formSteps.length; i > 0; i--) {
            const stepErrors = getStepErrors(i);
            const hasError = Object.keys(stepErrors).length > 0;
            if (hasError) {
                errorStep = i;
            }
            formErrors = {
                ...formErrors,
                ...stepErrors,
            };
        }
        setErrors(formErrors);

        if (errorStep >= 0) {
            // send user to the step that has errors
            setCurrentStep(errorStep);
            return;
        }

        setSubmitting(true);
        const eventBody: Partial<Bizly.Event> = {
            name: formValues.eventName,
            description: formValues.eventDescription,
            requirements: formValues.requirements,
            googlePlaceId: formValues.location?.googlePlaceId,
            cventId: formValues.cventId,
            estAttendance: formValues.guests,
            costCenter: formValues.costCenter,
            department: formValues.department,
            type: formValues.meetingType || i18n.common.emptyValue,
            startsAt: formValues.eventDate?.start?.format(DATE_TIME_FORMAT),
            endsAt: formValues.eventDate?.end?.format(DATE_TIME_FORMAT),
        };
        if (formValues.payment?.mode === 'total') {
            eventBody.budget = formValues.payment.cost;
        } else if (formValues.payment?.mode === 'perPerson') {
            eventBody.budgetPerPerson = formValues.payment.cost;
        }
        try {
            const { event } = await createEvent(eventBody);
            await onEventCreated?.(event);
            navigate(`/event/${event.id}/venue`);
            onClose();
        } catch (e) {
            // leaving this direct error for Apple, even though it breaks i18n
            enqueueSnackbar(String(e), { variant: 'error' });
            setSubmitting(false);
        }
    };

    return (
        <StyledDialog open onBackdropClick={submitting ? undefined : onClose}>
            <DialogContent>
                <Box>{formSteps[currentStep - 1].component}</Box>
                <Box>
                    <LinearProgressWithLabel totalSteps={formSteps.length} value={currentStep} />
                    <Box display="flex" justifyContent="space-between" sx={{ mt: 3 }}>
                        <Button variant="text" onClick={onBack} colorKey="black" data-cy="button-cancel">
                            {currentStep <= 1 ? i18n.button.cancel : i18n.button.back}
                        </Button>
                        <Box display="flex" gap={1}>
                            {!formSteps[currentStep - 1].required && (
                                <Button variant="text" onClick={onContinue} colorKey="black">
                                    {i18n.button.skip}
                                </Button>
                            )}
                            {currentStep === formSteps.length || (currentStep === 1 && formValues.eventDescription) ? (
                                <LoadingButton
                                    variant="contained"
                                    onClick={currentStep === formSteps.length ? onSubmit : onContinue}
                                    disabled={!isStepValid}
                                    loading={submitting}
                                    loadingPosition="center"
                                    startIcon={currentStep === formSteps.length ? <AIGenerateSvg /> : null}
                                >
                                    <span>{currentStep === 1 ? i18n.button.continue : i18n.button.createEvent}</span>
                                </LoadingButton>
                            ) : (
                                <Button variant="contained" onClick={onContinue} disabled={!isStepValid}>
                                    {i18n.button.continue}
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </StyledDialog>
    );
}
