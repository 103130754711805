import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import TextField from 'components/Ui-V2/InputFields/TextField';
import { QuestionField } from '../../utills';

const StyledDatePicker = styled(DatePicker)(({ theme: { getColor, EColors } }) => ({
    boxSizing: 'border-box',
    width: '270px',
    height: '50px',
    borderRadius: '4px',
    '& .MuiInputBase-root > input': {
        fontSize: '0.875rem',
    },
    '& .MuiInputBase-root > .MuiOutlinedInput-notchedOutline': {
        borderRadius: '4px',
        borderColor: getColor(EColors.inactiveAction),
    },
    '& .MuiInputBase-root .MuiIconButton-root': {
        color: getColor(EColors.pureBlack),
    },
    '& .MuiInputBase-root .MuiIconButton-root > .MuiSvgIcon-root': {
        fontSize: '1.25rem',
    },
}));

type QuestionTextProps = {
    onQuestionTextChange: (text: string, index: number) => void;
    questionField: QuestionField;
    index: number;
};

function QuestionDate({ index, questionField, onQuestionTextChange }: QuestionTextProps) {
    return (
        <>
            <TextField
                name=""
                onChange={e => onQuestionTextChange(e.target.value, index)}
                value={questionField.value}
                error={undefined}
                placeholder="Question"
            />

            <StyledDatePicker
                disabled
                slots={{
                    openPickerIcon: CalendarMonthOutlinedIcon,
                }}
            />
        </>
    );
}

export default QuestionDate;
