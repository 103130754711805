import TextArea from 'components/Ui-V2/InputFields/TextArea';
import TextField from 'components/Ui-V2/InputFields/TextField';
import { QuestionField } from '../../utills';

type QuestionTextProps = {
    onQuestionTextChange: (text: string, index: number) => void;
    questionField: QuestionField;
    index: number;
};

function QuestionText({ index, questionField, onQuestionTextChange }: QuestionTextProps) {
    return (
        <>
            <TextField
                name=""
                onChange={e => onQuestionTextChange(e.target.value, index)}
                value={questionField.value}
                error={undefined}
                placeholder="Question"
            />

            <TextArea
                disabled
                name=""
                value=""
                placeholder="Answer"
                onChange={() => {}}
                error={undefined}
                minRows={2}
                maxRows={2}
            />
        </>
    );
}

export default QuestionText;
