import Box from '@mui/material/Box';
import debounce from 'lodash/debounce';
import keyBy from 'lodash/keyBy';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { styled } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import EmptyVisual from 'components/Ui-V2/EmptyVisual';
import { REJECTED_STATUSES } from 'components/VenueCard';
import VenueMap from 'components/VenueMap';
import { NoSearchGrid } from 'components/VenueSearch/VenueSearchInfiniteGrid';
import { useVenuesQuery } from 'hooks/queries/useVenuesQuery';
import EmptyPageImage from 'images/empty-pages-assets/empty_venues.png';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { PostHogFeatureFlags } from 'shared';
import { hasAcceptedProposal, useCurrentInquiry } from 'stores/current-inquiry';
import { i18n } from 'translation';
import { PaginatedVenuesGrid } from './PaginatedVenuesGrid';
import { sortAndStringify } from './SearchUtils';
import VenueListingSkeleton from './VenueListingSkeleton';
import { TFilterValue } from './VenueSearchFilters';

const StackContainer = styled(Stack, {
    shouldForwardProp: prop => prop !== 'extraWidth' && prop !== 'noResults',
})<{ extraWidth?: number; noResults?: boolean }>`
    position: relative;
    width: ${props => (props.extraWidth || props.extraWidth === 0 ? `calc(100% + ${props.extraWidth}px)` : '100%')};
    height: 100%;

    flex-direction: column;
    @media (min-width: 600px) {
        flex-direction: row;
        gap: 30px;
    }

    margin-top: 4px;
    display: ${props => (props.noResults ? 'block' : 'flex')};
`;

const FittedVenueMap = styled(VenueMap, {
    shouldForwardProp: prop => prop !== 'isWideMap',
})<{ isWideMap?: boolean }>`
    & {
        display: ${props => (props.isWideMap ? 'block' : 'flex')};
        flex: ${props => (props.isWideMap ? '1' : '0 0 calc(100% - 20px)')};
        width: ${props => (props.isWideMap ? '100%' : 'initial')};
        max-width: ${props => (props.isWideMap ? 'none' : '503px')};
        min-width: ${props => (props.isWideMap ? 'initial' : '503px')};
        max-height: 100vh;
        position: sticky;
        top: 0;

        @media (max-width: 960px) {
            display: none;
        }
    }
`;

type TPaginatedVenues = {
    event?: Bizly.Event;
    viewVenueListing: (venueId: number) => void;
    searchFilters: TFilterValue;
    latLng?: Bizly.Location;
    showMap: boolean;
    hideActions: boolean;
    selectedVenues?: { [venueId: number]: Bizly.Venue };
    onSelect?: (venue: Bizly.Venue) => void;
    onDeselect?: (venue: Bizly.Venue) => void;
};

const PaginatedVenues = (props: TPaginatedVenues) => {
    const newVenueTileLayout = useFeatureFlagEnabled(PostHogFeatureFlags.toggleNewVenueTileLayout);
    const [highlightedId, setHighlightedId] = useState<number>();
    const [pinHighlightedId, setPinHighlightedId] = useState<number>();
    const stackRef = useRef<HTMLDivElement>(null);
    const [extraWidth, setExtraWidth] = useState<number>();

    const { event, viewVenueListing, showMap, searchFilters, latLng, ...restProps } = props;

    const { venues: inquiryVenues } = useCurrentInquiry();
    const hasAnAcceptedProposal = hasAcceptedProposal(inquiryVenues ?? []);

    const [page, setPage] = useState(1);
    const [animationKey, setAnimationKey] = useState(0);
    const { data: pageData, totalPages } = useVenuesQuery({
        filters: searchFilters,
        eventId: event?.id,
        latLng,
        page,
    });

    const prevQueryKey = useRef<string>('');
    useEffect(() => {
        const queryKey = sortAndStringify({ eventId: event?.id, ...searchFilters, ...latLng });
        if (queryKey !== prevQueryKey.current) {
            prevQueryKey.current = queryKey;
            setPage(1);
        }
    }, [searchFilters, latLng, event]);

    const venuesById = useMemo(() => (inquiryVenues ? keyBy(inquiryVenues, venue => venue.id) : {}), [inquiryVenues]);

    const resultsWithStatus = useMemo(() => {
        if (!pageData?.venues) return [];

        return pageData.venues.map(venue => {
            const inquiryVenue = venuesById[venue.id];
            if (inquiryVenue?.status && REJECTED_STATUSES.has(inquiryVenue.status)) {
                return { ...venue, status: inquiryVenue.status };
            }
            return venue;
        });
    }, [pageData, venuesById]);

    const handlePageChange = useCallback((_: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        setAnimationKey(prev => prev + 1);
        window.scrollTo({
            top: 0,
            behavior: 'auto',
        });
    }, []);

    const onPinClick = useCallback(
        (venueId: number) => {
            setPinHighlightedId(venueId);
        },
        [setPinHighlightedId]
    );

    function renderSkeleton() {
        return newVenueTileLayout ? <VenueListingSkeleton numberOfItems={10} /> : <NoSearchGrid />;
    }

    const noResults = resultsWithStatus.length === 0;

    const lastWidthRef = useRef<number>(0);

    const calculateWidth = useCallback(() => {
        const shouldCalculateWidth = stackRef.current && showMap && newVenueTileLayout && pageData?.venues;
        if (!shouldCalculateWidth) {
            return;
        }

        const stackRect = stackRef.current.getBoundingClientRect();
        const viewportWidth = document.documentElement.clientWidth;
        const extraWidthNeeded = viewportWidth - stackRect.right;

        // Only update if the width actually changed
        const widthChangeThreshold = 1;
        const widthDifference = Math.abs(extraWidthNeeded - lastWidthRef.current);

        if (widthDifference > widthChangeThreshold) {
            lastWidthRef.current = extraWidthNeeded;
            setExtraWidth(Math.max(0, extraWidthNeeded));
        }
    }, [showMap, newVenueTileLayout, pageData?.venues]);

    useLayoutEffect(() => {
        calculateWidth();
    }, [calculateWidth]);

    useLayoutEffect(() => {
        const handleResize = debounce(calculateWidth, 100);
        window.addEventListener('resize', handleResize);

        return () => {
            handleResize.cancel();
            window.removeEventListener('resize', handleResize);
        };
    }, [calculateWidth]);

    if (!pageData?.venues) {
        return renderSkeleton();
    }

    return (
        <StackContainer extraWidth={extraWidth} ref={stackRef} noResults={noResults}>
            <Box sx={{ mt: '26px', width: newVenueTileLayout ? 'auto' : 'initial' }}>
                {!noResults && latLng ? (
                    <>
                        <PaginatedVenuesGrid
                            key={animationKey}
                            data={resultsWithStatus}
                            onVenueHover={id => setHighlightedId(id || undefined)}
                            pinHighlightedVenue={pinHighlightedId}
                            onVisit={viewVenueListing}
                            hasAcceptedProposal={hasAnAcceptedProposal}
                            {...restProps}
                        />
                        {totalPages > 0 && (
                            <Box sx={{ pt: 5, pb: 2 }} justifyContent="center" display="flex">
                                <Pagination count={totalPages} page={page} onChange={handlePageChange} />
                            </Box>
                        )}
                    </>
                ) : (
                    <EmptyVisual
                        image={<img src={EmptyPageImage} alt="No Venues Found" />}
                        title="No Results Found"
                        description={i18n.venues.noVenuesFound}
                    />
                )}
            </Box>

            {!noResults && showMap && (
                <FittedVenueMap
                    center={latLng}
                    venues={resultsWithStatus}
                    highlightedVenueId={highlightedId}
                    pinHighlightedVenueId={pinHighlightedId}
                    onPinClick={onPinClick}
                    showLegend
                    isWideMap={newVenueTileLayout}
                />
            )}
        </StackContainer>
    );
};

export default PaginatedVenues;
