import CancelIcon from '@mui/icons-material/Cancel';
import { Box } from '@mui/material';
import RichTextEditorField from 'components/BizlyOS/InputFields/RichTextEditorField';
import SelectField from 'components/BizlyOS/InputFields/SelectField';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { useGetProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useParams } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { tzMoment } from 'utils/moment';

export const ProposalWithdrawn = () => {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: proposalInquiry } = useGetProposalInquiry(venueId, proposalId);

    return (
        <Box display="flex" flexDirection="column" gap={2.5}>
            <Box display="flex" flexDirection="row" gap={1.25}>
                <CancelIcon sx={{ color: getColor(EColors.warningText) }} />
                <Body1 fontWeight={600} sx={{ color: getColor(EColors.warningText) }}>
                    Proposal Withdrawn
                </Body1>
            </Box>

            <Body2>
                {`${proposalInquiry?.client?.firstName} ${proposalInquiry?.client?.lastName} withdrew the proposal on 
                ${tzMoment(proposalInquiry?.proposal?.cancelledAt).format('MMM D, YYYY')}.`}
            </Body2>

            <SelectField
                label="Reason"
                placeholder="Select a reason"
                options={[
                    {
                        value: proposalInquiry?.proposal?.cancellationReason ?? '',
                        label: proposalInquiry?.proposal?.cancellationReason ?? '',
                    },
                ]}
                value={proposalInquiry?.proposal?.cancellationReason ?? ''}
                disabled
            />

            <RichTextEditorField
                value={proposalInquiry?.proposal?.cancellationNotes ?? ''}
                placeholder="Write..."
                label="Message to client"
                disabled
            />
        </Box>
    );
};
