import { Box, styled, Typography } from '@mui/material';
import DatePicker from 'components/Ui-V2/InputFields/DatePicker';
import RadioGroup from 'components/Ui-V2/InputFields/RadioGroup';
import { OnOffoSwitch } from 'components/Ui-V2/InputFields/Switch';
import TextArea from 'components/Ui-V2/InputFields/TextArea';
import TextField from 'components/Ui-V2/InputFields/TextField';
import TimePicker from 'components/Ui-V2/InputFields/TimePicker';
import { TabPlanelProps } from 'components/WebRegistration/WebRegistrationContent';
import { useUser } from 'providers/user';
import { EColors, getColor } from 'theme';

export const BorderedContainer = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    height: '100%',
    border: `1px solid ${getColor(EColors.inactiveAction)}`,
    borderRadius: shape.borderRadius,
    boxSizing: 'border-box',
    padding: spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    transition: 'height 1s ease',
}));
export const BorderedBottom = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    borderBottom: `1px solid ${getColor(EColors.inactiveAction)}`,
    paddingBottom: spacing(2.5),
}));

function RoomBlocksQuestion({ parcel, isDisabled, errors, handleChange, onSave }: TabPlanelProps) {
    const { user } = useUser();

    return (
        <BorderedContainer>
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={0.5}>
                    <Typography fontWeight={600}>Room Block</Typography>
                    <OnOffoSwitch
                        checked={Boolean(parcel.roomBlockEnabled)}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleChange(event.target.checked, 'roomBlockEnabled');
                            onSave('roomBlockEnabled', { roomBlockEnabled: event.target.checked });
                        }}
                        disabled={isDisabled}
                    />
                </Box>
                <Typography>
                    Determine if your guests need accommodation and the number of guest rooms needed for your event.
                </Typography>
            </Box>
            {Boolean(parcel.roomBlockEnabled) && (
                <>
                    <BorderedBottom>
                        <DatePicker
                            required
                            label="Invitees should submit room block details by"
                            error={errors.rsvpDueDate}
                            value={parcel.rsvpDueDate || ''}
                            iconColor={getColor(EColors.pureBlack)}
                            onAccept={(date: string) => onSave('rsvpDueDate', { rsvpDueDate: date })}
                            disabled={isDisabled}
                            disablePast
                            showDefaultError
                        />
                        <TimePicker
                            error={errors.rsvpDueTime}
                            value={parcel.rsvpDueTime || ''}
                            iconColor={getColor(EColors.pureBlack)}
                            onAccept={(time: string) => {
                                onSave('rsvpDueTime', { rsvpDueTime: time });
                            }}
                            disabled={isDisabled}
                            timeZone={parcel.timeZone}
                        />
                    </BorderedBottom>
                    <DatePicker
                        label="Check-in"
                        required
                        error={undefined}
                        value={''}
                        onChange={dateText => handleChange(dateText, 'endDate')}
                        iconColor={getColor(EColors.pureBlack)}
                        disabled
                    />
                    <BorderedBottom>
                        <DatePicker
                            label="Check-out"
                            required
                            error={undefined}
                            value={''}
                            onChange={dateText => handleChange(dateText, 'endDate')}
                            iconColor={getColor(EColors.pureBlack)}
                            disabled
                        />
                    </BorderedBottom>
                    <BorderedBottom>
                        <RadioGroup
                            error={undefined}
                            label="Requested Room Type"
                            options={[
                                { label: 'Single', value: 1 },
                                { label: 'Double', value: 2 },
                                { label: 'Suite', value: 3 },
                                { label: 'Accessible ', value: 4 },
                            ]}
                            onChange={() => {}}
                            disabled
                            columns={2}
                        />
                    </BorderedBottom>
                    <BorderedBottom>
                        <TextArea
                            name=""
                            value={''}
                            onChange={() => {}}
                            error={undefined}
                            label="Additional Notes"
                            placeholder="Example: Make the invite exciting and semi-casual. "
                            disabled
                            minRows={3}
                        />
                    </BorderedBottom>
                    <BorderedBottom>
                        <TextField
                            name="postCutoffContact"
                            value={user.email}
                            error={undefined}
                            label="Post-cut-off Contact"
                            placeholder="Email"
                            disabled
                        />
                    </BorderedBottom>
                </>
            )}
        </BorderedContainer>
    );
}

export default RoomBlocksQuestion;
