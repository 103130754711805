import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Box, Checkbox, FormControlLabel, FormGroup, Input, InputProps, styled, Typography } from '@mui/material';
import React from 'react';
import { EColors, getColor } from 'theme';

const Grid = styled(Box, {
    shouldForwardProp: prop => prop !== 'columns',
})(({ theme: { spacing, breakpoints }, columns }) => ({
    width: '100%',
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gridAutoRows: 'auto',
    gap: spacing(1.25),
    ...(columns > 2
        ? {
              [breakpoints.down('sm')]: {
                  gridTemplateColumns: `repeat(${columns === 1 ? columns : '2'}, 1fr)`,
              },
          }
        : {}),
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme: { getColor, EColors, spacing } }) => ({
    padding: 0,
    marginLeft: 0,
    '& .MuiCheckbox-root': {
        padding: 0,
        marginRight: spacing(1),
    },
    '& .MuiSvgIcon-root': {
        fontSize: spacing(2.25),
    },
    '& > .MuiFormControlLabel-label': {
        fontSize: '1rem',
        color: getColor(EColors.pureBlack),
    },
}));

const StyledInputField = styled(Input)<InputProps & { error: boolean }>(({ theme: { getColor, EColors }, error }) => ({
    flex: 1,
    boxSizing: 'border-box',
    width: '100%',
    height: '29px',
    minHeight: '29px',
    fontSize: '1rem',
    border: '1px solid',
    borderColor: error ? getColor(EColors.red) : getColor(EColors.inactiveAction),
    borderRadius: '4px',
    padding: '14.5px 10px',
    color: getColor(EColors.pureBlack),
    '& .MuiInput-input': {
        padding: 0,
    },
    '& .MuiInput-input:focus': {
        outline: 'none',
        WebkitBoxShadow: 'none',
        boxShadow: 'none',
    },
    '& .MuiInput-input:focus::placeholder': {
        color: getColor(EColors.pureBlack),
        opacity: 0.5,
    },
    '& .MuiInput-input::placeholder': {
        color: error ? getColor(EColors.red) : getColor(EColors.pureBlack),
    },
}));

type CheckboxGroupProps = {
    options: { value: number | string; label: string }[];
    label?: string;
    columns?: number;
    placeholder?: string;
    error: string | undefined;
    onChange: (values: (number | string)[]) => void;
    onBlur?: () => void;
    values?: (number | string)[];
    disabled?: boolean | undefined;
    required?: boolean;
    other?: boolean;
};

const CheckboxGroup = React.forwardRef<HTMLDivElement, CheckboxGroupProps>(
    (
        { label, error = '', values = [], required = false, options, disabled, columns = 2, other = false, onChange },
        ref
    ) => {
        const onChangeCheckbox = (value: number | string, isSelected: boolean) => {
            let newValues = values;
            if (isSelected) {
                newValues = values.filter(v => v !== value);
            } else {
                newValues = [...values, value];
            }
            onChange(newValues);
        };
        return (
            <Box>
                {label && (
                    <Box display="flex" gap={0.5} marginBottom={1}>
                        <Typography>{label}</Typography>

                        <Typography color={getColor(EColors.pureBlack, 0.5)} fontWeight={500}>
                            {required ? '(required)' : ''}
                        </Typography>
                    </Box>
                )}
                <FormGroup ref={ref}>
                    <Grid columns={columns}>
                        {options.map(option => {
                            const isSelected = values?.includes(option.value);
                            return (
                                <StyledFormControlLabel
                                    key={option.value}
                                    disabled={disabled}
                                    checked={isSelected}
                                    label={option.label}
                                    onChange={() => onChangeCheckbox(option.value, isSelected)}
                                    control={
                                        <Checkbox
                                            checkedIcon={
                                                <CheckedIcon
                                                    sx={{
                                                        color: disabled
                                                            ? getColor(EColors.pureBlack, 0.3)
                                                            : getColor(EColors.primaryAction),
                                                    }}
                                                />
                                            }
                                            name={option.label}
                                        />
                                    }
                                />
                            );
                        })}

                        {other && (
                            <Box display="flex" alignItems="center">
                                <CheckBoxOutlineBlankIcon
                                    sx={{ fontSize: '18px', color: getColor(EColors.pureBlack, 0.25), marginRight: 1 }}
                                />
                                <StyledInputField placeholder="Other" disabled={disabled} disableUnderline />
                            </Box>
                        )}
                    </Grid>
                </FormGroup>
                {!!error && (
                    <Typography variant="body2" color={getColor(EColors.red)} fontWeight={500}>
                        {error}
                    </Typography>
                )}
            </Box>
        );
    }
);

export default CheckboxGroup;
