import { Box, Stack, Typography } from '@mui/material';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal';
import DeleteModalForm from 'components/EventDashboard/DeleteModalForm';
import EventDetails from 'components/EventOverview/EventDetails';
import TasksList from 'components/EventOverview/TasksList';
import { SpinnerOverlay } from 'components/Spinner';
import { Tab, TabContainer } from 'components/Ui-V2/TabGroup/TabGroup';
import useShowModal from 'hooks/useShowModal';
import { useSnackbar } from 'notistack';
import { useAppConfigContext } from 'providers/app-config';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { isUpdating, meetingsActions, selectMeeting, useMeetings } from 'stores/meetings';
import styled from 'styled-components';
import { EColors } from 'theme';
import { i18n } from 'translation';
import { Button, Row, Column as UIColumn } from 'ui';
import { getHeaderHeight } from 'utils/header';
import { TEventDashboard } from './utils';

const OverviewContent = styled(UIColumn)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    border-radius: 8px;
    flex-basis: 60%;
    flex-grow: 1;
`;

const Dashboard = styled(Stack)<{ headerHeight: number; pagePadding: number }>`
    width: 100%;
    background-color: ${({ theme }) => theme.getColor(EColors.drWhite)};
    padding-inline: ${({ pagePadding }) => pagePadding}px;
    box-sizing: border-box;
    min-height: ${({ headerHeight }) => `calc(100vh - ${headerHeight}px)`};
`;

const DashboardTopActions = styled(Box)`
    padding-block: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledCancelButton = styled(Button)<{ disabled?: boolean }>`
    &.MuiButton-root {
        padding-inline: 1.88em;
        height: 36.5px;
        min-width: 5.875rem;
        background-color: transparent;
        color: ${({ disabled, theme }) =>
            disabled ? theme.getColor(EColors.lightGrey) : theme.getColor(EColors.cancelledOrRejectedStatus)};
    }
`;

const TabHeader = styled(Box)`
    border-bottom: 0.5px solid ${({ theme }) => theme.getColor(EColors.greyBorder)};
`;

const TabContent = styled(Box)`
    padding: 20px;
`;

const TopHeading = styled(Typography)`
    font-size: 1.125rem;
    font-weight: 600;
`;

const Tasks = styled(Box)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    border-radius: 8px;
    flex: 0 0 40%;
`;

export default function EventOverview({ pagePadding = 0 }: TEventDashboard) {
    const { user, toggles } = useUser();
    const { event, refreshEvent, reloadProvider } = useEvent();
    const { transparentHeader } = useAppConfigContext();
    const meetingUpdating = useMeetings(isUpdating(event.id));
    const meeting = useMeetings(selectMeeting(event.id));

    const [tab, setTab] = useState('eventDetails');
    const [cancellationNotes, setCancellationNotes] = useState('');

    const { enqueueSnackbar } = useSnackbar();
    const { modalShown, showModal, hideModal } = useShowModal();

    const headerHeight = getHeaderHeight(transparentHeader);

    if (toggles.gate.createMeetingsFlow) {
        return <Navigate to={`/events/${event.id}`} replace />;
    }

    const onDefaultCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
        showModal();
        e.stopPropagation();
    };

    const cancelMeeting = async () => {
        if (!cancellationNotes) {
            return enqueueSnackbar(i18n.meetingDashboard.cancelError, { variant: 'error' });
        }
        await meetingsActions.cancel(event.id, cancellationNotes);
        await refreshEvent();
        reloadProvider();
        hideModal();
    };

    const tabs = [
        { label: i18n.meetingsPage.eventDetails, value: 'eventDetails' },
        { label: i18n.playbookWizard.collaboration.collaborators, value: 'collaborators' },
        { label: i18n.meetingsPage.files, value: 'files' },
        { label: i18n.meetingsPage.internalNotes, value: 'internalNotes' },
    ];

    const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
    };

    return (
        <>
            {!meeting ? (
                <SpinnerOverlay />
            ) : (
                <Dashboard headerHeight={headerHeight} pagePadding={pagePadding}>
                    <DashboardTopActions>
                        <TopHeading>{i18n.meetingsPage.eventOverview}</TopHeading>
                        {!event.cancelledAt && (
                            <StyledCancelButton variant="text" onClick={onDefaultCancel}>
                                {i18n.button.cancelEvent}
                            </StyledCancelButton>
                        )}
                    </DashboardTopActions>
                    <Row itemSpacing="default">
                        <OverviewContent>
                            <TabHeader>
                                <TabContainer value={tab} onChange={handleTabChange}>
                                    {tabs.map(({ value, label }) => (
                                        <Tab key={value} label={label} value={value} />
                                    ))}
                                </TabContainer>
                            </TabHeader>
                            <TabContent>{tab === 'eventDetails' && <EventDetails />}</TabContent>
                        </OverviewContent>

                        <Tasks>
                            <TasksList />
                        </Tasks>
                    </Row>

                    <DeleteConfirmationModal
                        isActive={modalShown && !event.cancelledAt}
                        wide
                        onDismiss={hideModal}
                        onProceed={cancelMeeting}
                        ctaLabel={i18n.button.proceed}
                        prompt={
                            <DeleteModalForm
                                cancellationNotes={cancellationNotes}
                                setCancellationNotes={setCancellationNotes}
                                userCancellationMessage={user.team?.cancellationInformationMessage}
                                userTeamName={user.team?.name}
                            />
                        }
                        loading={meetingUpdating}
                    />
                </Dashboard>
            )}
        </>
    );
}
