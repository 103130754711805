import { deleteCall, get, post, put } from 'api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { z } from 'zod';

export const StaffMemberSchema = z.object({
    id: z.number().or(z.string()),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string().email(),
    phone: z.string().nullable(),
    imageUrl: z.string().url().nullable(),
    title: z.string().nullable(),
    department: z.string().nullable(),
    contactType: z.string().nullable(),
    role: z.string(),
    roleId: z.number(),
    admin: z.boolean(),
    createdAt: z.string().datetime(),
    lastActiveAt: z.string().datetime().nullable(),
    notifications: z.object({
        inquiries: z.boolean(),
    }),
});

const StaffMemberApiResponseSchema = z.object({
    success: z.boolean(),
    staffMembers: z.array(StaffMemberSchema),
});

const StaffMemberInviteSchema = z.object({
    first_name: z.string(),
    last_name: z.string(),
    email: z.string().email(),
    phone: z.string(),
    image_url: z.string().url().optional(),
    role_id: z.number(),
    title: z.string(),
    department: z.string(),
    contact_type: z.string(),
    notifications: z.object({
        inquiries: z.boolean(),
    }),
});

const StaffMemberInviteResponseSchema = z.object({
    success: z.boolean(),
    staffMember: StaffMemberSchema,
});

const UpdateStaffMemberSchema = z.object({
    first_name: z.string(),
    last_name: z.string(),
    email: z.string().email(),
    phone: z.string(),
    role_id: z.number(),
    title: z.string(),
    department: z.string(),
    contact_type: z.string(),
    notifications: z.object({
        inquiries: z.boolean(),
    }),
});

const UpdateStaffMemberResponseSchema = z.object({
    success: z.boolean(),
    staffMember: z.object({
        id: z.number(),
        firstName: z.string(),
        lastName: z.string(),
        email: z.string().email(),
        phone: z.string().nullable(),
        title: z.string().nullable(),
        role: z.string(),
        admin: z.boolean(),
        createdAt: z.string().datetime(),
        lastActiveAt: z.string().datetime().nullable(),
    }),
});

export const StaffRoleSchema = z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string(),
    description: z.string(),
    level: z.number(),
});

const StaffRolesResponseSchema = z.object({
    roles: z.array(StaffRoleSchema),
    success: z.boolean(),
});

const DeleteStaffMemberResponseSchema = z.object({
    success: z.boolean(),
});

export type StaffMemberResponse = z.infer<typeof StaffMemberApiResponseSchema>;
export type StaffMemberType = z.infer<typeof StaffMemberSchema>;
type StaffMemberInvite = z.infer<typeof StaffMemberInviteSchema>;
type StaffMemberInviteResponse = z.infer<typeof StaffMemberInviteResponseSchema>;
type UpdateStaffMemberRequest = z.infer<typeof UpdateStaffMemberSchema>;
type UpdateStaffMemberResponse = z.infer<typeof UpdateStaffMemberResponseSchema>;
type StaffRolesResponse = z.infer<typeof StaffRolesResponseSchema>;
type DeleteStaffMemberResponse = z.infer<typeof DeleteStaffMemberResponseSchema>;

export const STAFF_QUERIES = {
    STAFF: 'staff',
    STAFF_ROLES: 'staffRoles',
} as const;

const getStaffMembers = async (venueId: string): Promise<StaffMemberResponse> => {
    try {
        const response = await get(`venues/${venueId}/staff`);
        return StaffMemberApiResponseSchema.parse(response);
    } catch (error) {
        console.error('Error fetching staff members:', error);
        throw error;
    }
};

const inviteStaffMember = async (
    venueId: string,
    staffMember: StaffMemberInvite
): Promise<StaffMemberInviteResponse> => {
    try {
        const response = await post(`venues/${venueId}/staff`, staffMember);
        return StaffMemberInviteResponseSchema.parse(response);
    } catch (error) {
        console.error('Error inviting staff member:', error);
        throw error;
    }
};

const updateStaffMember = async (
    venueId: string,
    staffUserId: string,
    staffMember: UpdateStaffMemberRequest
): Promise<UpdateStaffMemberResponse> => {
    try {
        const response = await put(`venues/${venueId}/staff/${staffUserId}`, staffMember);
        return UpdateStaffMemberResponseSchema.parse(response);
    } catch (error) {
        console.error('Error updating staff member:', error);
        throw error;
    }
};

const getStaffRoles = async (venueId: string): Promise<StaffRolesResponse> => {
    try {
        const response = await get(`venues/${venueId}/staff/roles`);
        return StaffRolesResponseSchema.parse(response);
    } catch (error) {
        console.error('Error fetching staff roles:', error);
        throw error;
    }
};

const deleteStaffMember = async (venueId: string, staffUserId: string): Promise<DeleteStaffMemberResponse> => {
    try {
        const response = await deleteCall(`venues/${venueId}/staff/${staffUserId}`);
        return DeleteStaffMemberResponseSchema.parse(response);
    } catch (error) {
        console.error('Error deleting staff member:', error);
        throw error;
    }
};

export const useStaffQuery = (venueId: string) => {
    const result = useQuery<StaffMemberResponse, Error>([STAFF_QUERIES.STAFF, venueId], () => getStaffMembers(venueId));
    return { ...result, staffMembers: result.data?.staffMembers };
};

export const useInviteStaffMemberMutation = (venueId: string) => {
    const queryClient = useQueryClient();
    return useMutation<StaffMemberInviteResponse, Error, StaffMemberInvite>(
        staffMember => inviteStaffMember(venueId, staffMember),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([STAFF_QUERIES.STAFF, venueId]);
            },
        }
    );
};

export const useUpdateStaffMemberMutation = (venueId: string, staffUserId: string) => {
    const queryClient = useQueryClient();
    return useMutation<UpdateStaffMemberResponse, Error, UpdateStaffMemberRequest>(
        staffMember => updateStaffMember(venueId, staffUserId, staffMember),
        {
            onSuccess: response => {
                if (response.success) {
                    queryClient.invalidateQueries([STAFF_QUERIES.STAFF, venueId]);
                }
            },
        }
    );
};

export const useStaffRoles = (venueId: string) => {
    const result = useQuery<StaffRolesResponse, Error>([STAFF_QUERIES.STAFF_ROLES, venueId], () =>
        getStaffRoles(venueId)
    );
    return { ...result, roles: result.data?.roles };
};

export const useDeleteStaffMemberMutation = (venueId: string) => {
    const queryClient = useQueryClient();
    return useMutation<DeleteStaffMemberResponse, Error, string>(
        staffUserId => deleteStaffMember(venueId, staffUserId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([STAFF_QUERIES.STAFF, venueId]);
            },
        }
    );
};
