import { captureException } from '@sentry/minimal';
import { useSnackbar } from 'notistack';
import { useEvent } from 'providers/event';
import { useNavigate } from 'react-router-dom';
import { currentInquiryActions } from 'stores/current-inquiry';
import { i18n } from 'translation';
import { userFormattedTimestamp } from 'utils/moment';
import { acceptProposal, requestExtendProposal } from '../api';
import useKey from '../hooks/useKey';
import ConfirmationModal from './ConfirmationModal';

const ProposalAcceptModal = ({
    eventId,
    proposal,
    open,
    closeModal,
    setVenueBooked,
    onExtensionRequest,
}: {
    eventId: number;
    proposal: Bizly.VenueProposal;
    open: boolean;
    closeModal: () => void;
    setVenueBooked: (booked: boolean) => void;
    onExtensionRequest?: () => void;
}) => {
    useKey('Escape', closeModal);
    const { enqueueSnackbar } = useSnackbar();
    const { event } = useEvent();

    const navigate = useNavigate();
    const expired = proposal && new Date() > new Date(proposal.onHoldUntil);

    const confirm = async () => {
        if (proposal) {
            try {
                await acceptProposal(proposal.id);
                await currentInquiryActions.load(event.id, true);
                setVenueBooked(true);
                navigate(`/event/${eventId}/venue/contracting`, { replace: true });
                closeModal();
            } catch (error) {
                captureException(error);
                enqueueSnackbar(i18n.error.default, { variant: 'error' });
            }
        }
    };
    const extend = async () => {
        if (proposal) {
            try {
                await requestExtendProposal(proposal.id);
                if (onExtensionRequest) onExtensionRequest();
                navigate(`/event/${eventId}/venue/proposal/${proposal.id}`);
                closeModal();
                enqueueSnackbar(i18n.venue.proposal.requestSent, { variant: 'success' });
            } catch (error) {
                captureException(error);
                enqueueSnackbar(i18n.error.default, { variant: 'error' });
            }
        }
    };

    const modalContent = () => {
        const extReqTimestamp = userFormattedTimestamp(proposal.extensionRequestedAt, true);
        if (expired && proposal?.extensionRejected) {
            const extRejectTimestamp = userFormattedTimestamp(proposal.extensionRejectedAt, true);
            return {
                headline: i18n.venue.proposal.extensionRejected,
                prompt: (
                    <>
                        {i18n.venue.proposal.proposalHasExpired}
                        <br />
                        <br />
                        {/* TODO: better international timestamp format */}
                        {i18n.venue.proposal.extensionRejectedAt(
                            proposal.venue.name,
                            extRejectTimestamp.formattedTime,
                            extRejectTimestamp.preposition
                        )}
                        <br />
                        <br />
                        {i18n.venue.proposal.contactVenueToUpdateExpiry}
                    </>
                ),
                warning: true,
            };
        }

        if (expired && proposal?.extensionRequestedAt && !proposal?.extensionRejected) {
            return {
                headline: i18n.venue.proposal.extensionRequested,
                prompt: (
                    <>
                        {/* TODO: better international timestamp format */}
                        {i18n.venue.proposal.extensionReqSentAt(
                            extReqTimestamp.formattedTime,
                            extReqTimestamp.preposition
                        )}
                        <br />
                        <br />
                        {i18n.venue.proposal.resendRequestInquiry}
                    </>
                ),
                ctaLabel: i18n.venue.proposal.resendRequest,
                onProceed: extend,
            };
        }

        if (expired) {
            return {
                headline: i18n.venue.proposal.proposalExpired,
                prompt: (
                    <>
                        {i18n.venue.proposal.cannotAcceptAfterExpiry}
                        <br />
                        <br />
                        {i18n.venue.proposal.requestExtensionInquiry}
                    </>
                ),
                ctaLabel: i18n.venue.inquiry.requestExtension,
                onProceed: extend,
            };
        }

        return {
            headline: i18n.modal.areYouSure,
            prompt: i18n.venue.proposal.acceptConfirmDescription,
            ctaLabel: i18n.button.confirm,
            onProceed: confirm,
        };
    };

    return <ConfirmationModal isActive={open} onDismiss={closeModal} {...modalContent()} />;
};

export default ProposalAcceptModal;
