import { BLANK_TEMPLATE_ID } from 'api/eventTemplates';
import mapValues from 'lodash/mapValues';
import { useUser } from 'providers/user';
import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';
import { i18n } from 'translation';
import { tzMoment } from 'utils/moment';
import { takeFirstIfArray } from '../../util';

const URLFieldNames: Partial<{ [fieldName in Bizly.EventCreateCheckFields]: string }> = {
    name: 'event_name',
    start: 'start',
    end: 'end',
    location: 'location',
    googlePlaceId: 'googlePlaceId',
    recordId: 'recordId',
    costCenter: 'cost_center',
    cventId: 'internal_ref',
    type: 'event_type',
    templateId: 'template_id',
    budget: 'budget',
};

export const useCreateEventModalUrlValues = () => {
    const locations = useLocation();
    const { user } = useUser();

    const parsedEvent = takeFirstIfArray(parse(locations.search));

    const {
        event_name,
        start,
        end,
        location,
        googlePlaceId,
        recordId,
        budget,
        cost_center,
        internal_ref,
        event_type,
        auth,
    } = mapValues(parsedEvent, value => (value === null ? undefined : value));

    let formValues: Partial<{ [fieldName: string]: string | number }> = {
        name: event_name,
        start: tzMoment(start).format('MM/DD/YYYY hh:mm a'),
        end: tzMoment(end).format('MM/DD/YYYY hh:mm a'),
        location,
        googlePlaceId,
        recordId,
        budget,
        costCenter: cost_center,
        cventId: internal_ref,
        type: event_type,
        templateId: BLANK_TEMPLATE_ID,
    };

    const errors: string[] = [];

    const checkFields = user.team?.authMeetingCreateFields;

    if (checkFields?.length) {
        const urlCheckVals = auth && JSON.parse(atob(auth));
        if (typeof urlCheckVals !== 'object' || urlCheckVals === null) {
            errors.push(i18n.homepage.createMeetingModal.requiredFieldsError);
        } else {
            let missingFieldsCount = 0;
            checkFields.forEach(checkFieldName => {
                const URLFieldName = URLFieldNames[checkFieldName];
                if (!URLFieldName) return;
                if (
                    !urlCheckVals[URLFieldName] ||
                    decodeURI(urlCheckVals[URLFieldName]) !== decodeURI(String(formValues[checkFieldName]))
                ) {
                    missingFieldsCount++;
                }
            });

            if (missingFieldsCount > 0) {
                errors.push(
                    `${missingFieldsCount} of the required fields ${
                        missingFieldsCount > 1 ? 'were' : 'was'
                    } not provided.`
                );
            }

            if (checkFields.includes('email') && urlCheckVals['email'] !== user.email) {
                errors.push(i18n.homepage.createMeetingModal.wrongUserError);
            }
        }
    }

    if (errors.length > 0) formValues = {};

    const { create } = parse(locations.search, {
        parseBooleans: true,
    });
    const showModalOnPageLoad = create === true;

    return showModalOnPageLoad ? { ...formValues, errors } : undefined;
};

export const useBudgetRequired = () => {
    const { user } = useUser();

    const checkFields = user.team?.authMeetingCreateFields;

    return checkFields?.includes('budget');
};

export const getNextMonthAndYear = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const isDecember = currentMonth === 11;
    const nextMonthName = new Date(currentYear, currentMonth + 1, 1).toLocaleString('default', { month: 'long' });
    const year = (isDecember ? currentYear + 1 : currentYear).toString();

    return { nextMonthName, year };
};
