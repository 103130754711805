import { TProposalForm } from 'components/ProposalForm/types';
import { deleteCall, get, post, put } from '.';

export type VenuePutType = {
    slug?: string;
    name?: string;
    headline?: string;
    website?: string;
    phone?: string;
    summary?: string;
    googlePlaceId?: string;
    chainId?: number;
    travelstarRating?: number;
    maxCapacity?: number;
    room_count?: number;
    highlights?: { category_id: number; content: string | null; name?: string }[];
    faq?: Bizly.VenueFaq[];
};

export type GuestRoomResponse = { guestroomCategory: Bizly.GuestroomCategory; success: boolean };

export const getAllProposals = () => get(`proposals/`, { showErrorCode: true });

type VenueSearchParams = {
    page?: number;
    perPage?: number;
    q?: string;
    // Additional parameters that might be needed for venue search
    types?: string[];
    brands?: number[];
    chainIds?: number[];
    statusIds?: number[];
    [key: string]: number | string | string[] | number[] | undefined;
};

export const bizlyOSVenueSearch = (
    params: VenueSearchParams = {}
): Promise<{ venues: Bizly.Venue[]; meta: Bizly.SearchMeta }> =>
    post(`venues/search`, {
        page: params.page || 1,
        perPage: 10,
        q: params.q || '',
        ...params,
    }).then(res => ({ venues: res.venues, meta: res.searchMeta }));

export const getProposalForVenue = (venueId: number | string, proposalId: number | string) =>
    get(`venues/${venueId}/proposals/${proposalId}`, { showErrorCode: true }) as Promise<TProposalForm>;

export const getVenueMeta = (): Promise<{
    venueBrands: Bizly.VenueBrandsMeta[];
    venueTypes: Bizly.VenueType[];
    success: boolean;
}> => get('venues/meta');

export const getVenueById = (venueId: string): Promise<{ venue: Bizly.Venue; success: boolean }> => {
    return get(`venues/${venueId}`);
};

export const getVenueImagesBatch = (
    venueId: string | number
): Promise<{ images: Bizly.VenueImage[]; success: boolean }> => {
    return post(`venues/${venueId}/images/batch`);
};

export const updateVenue = (
    venueId: string | number,
    data: VenuePutType
): Promise<{ venue: Bizly.Venue; success: boolean }> => {
    return put(`venues/${venueId}`, data);
};

export const getGuestRoomCategories = (
    venueId: string
): Promise<{ guestroomCategories: Bizly.GuestroomCategory[]; success: boolean }> => {
    return get(`venues/${venueId}/guestroom-categories`);
};

export const getGuestRoomCategoryType = (): Promise<{
    guestroomTypes: Bizly.GuestroomCategoryType[];
    success: boolean;
}> => {
    return get(`venues/guestroom-types`);
};

export const addGuestRoomCategory = (venueId: string, data: Bizly.GuestroomCategory): Promise<GuestRoomResponse> => {
    return post(`venues/${venueId}/guestroom-categories`, data);
};

export const updateGuestRoomCategory = (
    venueId: string,
    categoryId: string,
    data: Bizly.GuestroomCategory
): Promise<GuestRoomResponse> => {
    return put(`venues/${venueId}/guestroom-categories/${categoryId}`, data);
};

export const removeGuestRoomCategory = (venueId: string, ids: number[]): Promise<BizlyAPI.DeleteResponse> => {
    return deleteCall(`venues/${venueId}/guestroom-categories`, { ids });
};
export const postVenueImagesBatch = (
    venueId: string | number,
    images: Partial<Bizly.VenueImage>[]
): Promise<{ images: Bizly.VenueImage[]; success: boolean }> => {
    return post(`venues/${venueId}/images/batch`, { images });
};

export const getEventSpaceType = (): Promise<{
    guestroomTypes: Bizly.OsEventSpace[];
    success: boolean;
}> => {
    return get('venues/space-types');
};

export const getVenueTags = (): Promise<{
    tags: Bizly.VenueTags[];
    success: boolean;
}> => {
    return get('venues/tags');
};

export const getEventSpace = (venueId: string): Promise<{ spaces: Bizly.OsEventSpace[]; success: boolean }> => {
    return get(`venues/${venueId}/spaces`);
};

export const createEventSpace = (
    venueId: string,
    data: Bizly.OsEventSpaceCreate
): Promise<{ space: Bizly.OsEventSpace; success: boolean }> => {
    return post(`venues/${venueId}/spaces`, data);
};

export const updateEventSpace = (
    venueId: string,
    spacesId: number,
    data: Bizly.OsEventSpaceCreate
): Promise<{ space: Bizly.OsEventSpace; success: boolean }> => {
    return put(`venues/${venueId}/spaces/${spacesId}`, data);
};

export const removeEventSpaces = (venueId: string, id: number): Promise<BizlyAPI.DeleteResponse> => {
    return deleteCall(`venues/${venueId}/spaces/${id}`);
};
