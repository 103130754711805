import { REJECTED_OR_ADDED, REJECTED_STATUSES } from 'components/VenueCard';

export const MAX_INQUIRY_VENUE_SLOTS = 4;

export const getFilterTypeIds = (filterTypes: BizlyAPI.Venue.Types[] | undefined, playbookTypes: Bizly.VenueType[]) => {
    if (!filterTypes || filterTypes.length === 0) {
        return [];
    }
    const ids = filterTypes.map(filterType => {
        const playbookVenueType = playbookTypes.find(type => type.name.toLowerCase() === filterType.toLowerCase());
        if (playbookVenueType) {
            return playbookVenueType.id;
        }
        return -1;
    });
    return ids;
};

export const getBrandOptions = (
    filterTypes: BizlyAPI.Venue.Types[] | undefined,
    playbookTypes: Bizly.VenueType[],
    brands: Bizly.VenueBrand[]
) => {
    const filterTypeIds = getFilterTypeIds(filterTypes, playbookTypes);
    const brandOptions = brands.map(brand => ({
        id: brand.id,
        name: brand.name,
        disabled: filterTypeIds.length > 0 && filterTypeIds.indexOf(brand.venueTypeId) < 0,
    }));

    // move disabled brand options to the end of the list
    const activeBrands = brandOptions.filter(brand => !brand.disabled);
    const disabledBrands = brandOptions.filter(brand => brand.disabled);
    return [...activeBrands, ...disabledBrands];
};

export const hasContract = (venue?: Bizly.Venue) => {
    if (venue) {
        if (venue.status === 'Contract Pending' || venue.status === 'Booked' || venue.status === 'Booking Cancelled') {
            return true;
        }
    }
    return false;
};

export const getActiveVenues = (venues: Bizly.Venue[]) => venues.filter(venue => !REJECTED_OR_ADDED.has(venue.status));

export const getActiveVenuesCount = (venues: Bizly.Venue[]) => getActiveVenues(venues).length;

export const getSelectedVenueInqs = (venues: Bizly.Venue[]) =>
    venues.filter(venue => !REJECTED_STATUSES.has(venue.status));

export const isUrlFromSubNav = (pathname: string): boolean => {
    return /\/event\/\d+\/?(venue\/?)?/.test(pathname);
};

export const isVenueBookable = (venue?: Bizly.Venue) => {
    if (!venue) return false;
    return !(venue.externalUrl || venue.type?.id === 10); // type.id 10 is corporate office
};
