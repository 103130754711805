import { LanguageSelector } from 'components/LanguageSelector';
import { useUser } from 'providers/user';
import { parse } from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { bannerActions } from 'stores/home/bannerStore';
import styled from 'styled-components';
import { setCookie } from 'utils/tokens';
import { SignInPage } from './SignInPage';
import { TAuthForm } from './types';

const TopRight = styled.div`
    position: absolute;
    top: 36px;
    right: 36px;
`;

type AuthenticationProp = {
    page: 'sign-in';
};
export const ExternalRedirect = ({ to }: { to: string }) => {
    useEffect(() => {
        window.location.href = to;
    }, [to]);

    return null;
};

export const Authentication = ({ page }: AuthenticationProp) => {
    const [authFormValue, setAuthFormValue] = useState<TAuthForm>({});

    const { setUserState } = useUser();
    const navigate = useNavigate();
    const location = useLocation();

    const routeState = location.state || {};

    const { redirect } = parse(window.location.search);
    const parsedRedirect = typeof redirect === 'string' ? redirect : '/';

    const onFormChange = ({ value }: { value: TAuthForm }) =>
        setAuthFormValue(prevAuthForm => ({
            ...prevAuthForm,
            ...value,
        }));

    const finalizeLogin = async (user: BizlyAPI.User) => {
        await setCookie(user.authToken);
        await setUserState({ ...user, loaded: true, token: user.authToken });
        bannerActions.setBannerVisibility(true);

        // Determine redirection based on platform_role
        let targetPath = parsedRedirect;
        if (user.platformRole === 'venue') {
            targetPath = '/os';
        } else if (user.platformRole === 'planner' && targetPath.startsWith('/os')) {
            targetPath = '/'; // Prevent planner from accessing OS
        }

        navigate(targetPath, { replace: true });
    };

    return (
        <>
            {import.meta.env.VITE_APP_LOCALIZATION === 'true' && (
                <TopRight>
                    <LanguageSelector />
                </TopRight>
            )}
            {page === 'sign-in' ? (
                <SignInPage
                    authFormValue={authFormValue}
                    onChange={onFormChange}
                    onGoogleSignInSuccess={finalizeLogin}
                    initialLoginStatus={routeState.initialLoginStatus}
                />
            ) : (
                <ExternalRedirect to="https://www.bizly.com/pricing/" />
            )}
        </>
    );
};
