import HelpIcon from '@mui/icons-material/Help';
import { Box, styled } from '@mui/material';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { TProposal } from 'components/ProposalForm/types';
import { useGetProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useParams } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { InquiryManager } from './InquiryActions/InquiryManager';
import { ProposalManager } from './ProposalActions/ProposalManager';

const Container = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    border: `.0625rem solid ${getColor(EColors.bizlyOSBorder)}`,
    borderRadius: shape.borderRadius,
    boxSizing: 'border-box',
    padding: spacing(2.5),
}));

const ProposalStatus = {
    Accepted: 'accepted',
    Submitted: 'submitted',
    Cancelled: 'cancelled',
} as const;

const InquiryStatus = {
    Pending: 'pending',
    Accepted: 'accepted',
    Rejected: 'rejected',
} as const;

const getProposalStatus = (inquiryStatus?: string, proposalStatus?: string) => {
    const isAcceptedWithSubmission =
        inquiryStatus === InquiryStatus.Accepted && proposalStatus === ProposalStatus.Submitted;

    const isWithdrawn = proposalStatus === ProposalStatus.Cancelled;

    return isAcceptedWithSubmission || isWithdrawn;
};

export const InquiryAction = () => {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: proposalInquiry } = useGetProposalInquiry(venueId, proposalId);

    const { inquiry = {}, proposal = {} } = proposalInquiry || {};

    const shouldShowProposalManager = getProposalStatus(inquiry.status, proposal?.status?.name);

    return (
        <Box display="flex" flexDirection="column" gap={2.5}>
            <Container>
                {shouldShowProposalManager ? (
                    <ProposalManager proposal={proposalInquiry?.proposal as TProposal} />
                ) : (
                    <InquiryManager />
                )}
            </Container>

            <Container>
                <Box display="flex" flexDirection="row" gap={1.25}>
                    <HelpIcon sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                    <Box>
                        <Body1 fontWeight={600}>Got Questions?</Body1>
                        <Body2>
                            Email us at{' '}
                            <a href="mailto:concierge@bizly.com" style={{ color: getColor(EColors.bizlyOSPrimary) }}>
                                concierge@bizly.com
                            </a>
                            . We're here to help!
                        </Body2>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};
