import { Box, Tab, TabProps, Tabs, TabsProps, Typography, styled } from '@mui/material';
import CursorInWindowIcon from 'images/icons/cursor-in-window.svg?react';
import ReservationIcon from 'images/icons/reservation.svg?react';
import React, { useState } from 'react';

import { WebRegistrationPreviewIdsType } from 'constants/event';
import { EditParcelState, ParcelFormState, TEventParcelContext } from '../../pages/EditParcel/EditParcel';
import ContentForm from './ContentForm';
import RsvpForm from './RsvpForm';
import { ErrorType, HandleSaveType } from './WebRegistrationModal';

const TabOptions = [
    { label: 'Content', icon: <CursorInWindowIcon />, Element: ContentForm },
    { label: 'RSVP Form', icon: <ReservationIcon />, Element: RsvpForm },
    //TODO: Add StyleForm once API is ready
    // { label: 'Style', icon: <ColorLensOutlinedIcon color="primary" />, Element: StyleForm },
];

const StyledTab = styled(Tab)<TabProps>(({ theme: { getColor, EColors } }) => ({
    '&.MuiTab-root': {
        minWidth: 'unset',
        textTransform: 'unset',
        height: '40px',
        minHeight: '40px',
        padding: '0px',
        marginRight: '40px',
        color: getColor(EColors.pureBlack),
        fontSize: '1rem',
    },
}));

const StyledTabs = styled(Tabs)<TabsProps>(({ theme: { getColor, EColors } }) => ({
    backgroundColor: getColor(EColors.pureWhite),
    minHeight: '40px',
    '&.MuiTabs-root': {
        minHeight: '40px',
        height: '40px',
    },
    '& .MuiTabs-indicator': {
        height: '3px',
    },

    '& .MuiTabs-flexContainer': {
        height: '40px',
    },
}));

export type TabPlanelProps = {
    isDisabled: boolean;
    parcel: ParcelFormState;
    errors: ErrorType;
    handleChange: <K extends keyof ParcelFormState>(value: ParcelFormState[K], field: K) => void;
    onSave: HandleSaveType;
    scrollToElement: (type: WebRegistrationPreviewIdsType) => void;
};

type WebRegistrationContentProps = {
    context: TEventParcelContext;
    state: EditParcelState;
    isPublished: boolean;
    errors: ErrorType;
    handleChange: <K extends keyof ParcelFormState>(value: ParcelFormState[K], field: K) => void;
    onSave: HandleSaveType;
    scrollToElement: (type: WebRegistrationPreviewIdsType) => void;
};

const WebRegistrationContent = ({
    context,
    state,
    isPublished,
    errors,
    handleChange,
    onSave,
    scrollToElement,
}: WebRegistrationContentProps) => {
    const { parcel } = state;

    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (_: React.SyntheticEvent, newIndex: number) => {
        setTabIndex(newIndex);
    };

    const isDisabled = !context.editable || isPublished;

    return (
        <Box overflow="auto" borderRight="0.5px solid #DBDBDB" padding={2.5}>
            <Typography lineHeight="100%" fontWeight={600} marginBottom={1.25}>
                Content
            </Typography>
            <StyledTabs onChange={handleTabChange} value={tabIndex}>
                {TabOptions.map((tab, index) => (
                    <StyledTab key={index} label={tab.label} value={index} icon={tab.icon} iconPosition="start" />
                ))}
            </StyledTabs>
            {React.createElement(TabOptions[tabIndex].Element, {
                parcel,
                isDisabled,
                errors,
                handleChange,
                onSave,
                scrollToElement,
            })}
        </Box>
    );
};

export default WebRegistrationContent;
