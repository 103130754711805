import { zodResolver } from '@hookform/resolvers/zod';
import { Box, styled } from '@mui/material';
import InputField from 'components/BizlyOS/InputFields/InputField';
import PhoneField from 'components/BizlyOS/InputFields/PhoneField';
import SelectField from 'components/BizlyOS/InputFields/SelectField';
import ClaimStepContainer from 'components/BizlyOS/ListingsClaim/ClaimStepContainer';
import StepItemsContainer from 'components/BizlyOS/ListingsClaim/StepItemsContainer';
import { Body2 } from 'components/BizlyOS/Typography/Typography';
import { map } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { DEPARTMENT_OPTIONS } from 'shared';
import * as z from 'zod';
import { ListingsCreateStepProps } from './ListingsCreate';

const FORMATTED_DEPARTMENT_OPTIONS = DEPARTMENT_OPTIONS.map(option => ({
    label: option.name,
    value: option.id,
}));

const GridContainer = styled(Box)(({ theme: { spacing } }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: spacing(2.5),
}));

const Info = styled(Body2)(({ theme: { getColor, EColors, spacing } }) => ({
    fontWeight: 500,
    marginTop: spacing(2.5),
    '& a': { color: getColor(EColors.bizlyOSPrimary), textDecoration: 'underline' },
}));

const phoneRegex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;

const AccountInfoSchema = z.object({
    firstName: z.string().min(1, 'First Name is required'),
    lastName: z.string().min(1, 'Last Name is required'),
    email: z.string().min(1, 'Email is required').email('Invalid Email'),
    phone: z
        .string()
        .min(8, 'Valid phone number is required')
        .regex(phoneRegex, { message: 'Invalid phone number format' }),
    jobTitle: z.string().min(1, 'Job Title is required'),
    department: z.string().min(1, 'Department is required'),
});

type AccountInfoFormData = z.infer<typeof AccountInfoSchema>;

export default function ContactDetails({ onNextStepClicked, listingsCreateData, ...props }: ListingsCreateStepProps) {
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        control,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
    } = useForm<AccountInfoFormData>({
        resolver: zodResolver(AccountInfoSchema),
        defaultValues: {
            firstName: listingsCreateData.firstName || '',
            lastName: listingsCreateData.lastName || '',
            email: listingsCreateData.email || '',
            phone: listingsCreateData.phone || '',
            jobTitle: listingsCreateData.jobTitle || '',
            department: listingsCreateData.department || '',
        },
        mode: 'onBlur',
    });

    const onSubmit = (data: AccountInfoFormData) => {
        map(data, (value, key) => {
            searchParams.set(key, value);
        });
        setSearchParams(searchParams);
        onNextStepClicked(data);
    };

    return (
        <ClaimStepContainer
            {...props}
            onSave={handleSubmit(onSubmit)}
            disabled={!isValid || isSubmitting}
            contentCentered
        >
            <StepItemsContainer
                title="Let’s get to know you"
                description="We use this information to confirm your identity and for our team to contact you once your venue is approved."
            >
                <GridContainer>
                    <Controller
                        name="firstName"
                        control={control}
                        render={({ field }) => {
                            return <InputField {...field} error={errors.firstName} label="First Name" required />;
                        }}
                    />
                    <Controller
                        name="lastName"
                        control={control}
                        render={({ field }) => {
                            return <InputField {...field} error={errors.lastName} label="Last Name" required />;
                        }}
                    />
                    <Controller
                        name="email"
                        control={control}
                        render={({ field }) => {
                            return <InputField {...field} error={errors.email} label="Business Email" required />;
                        }}
                    />
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => {
                            return <PhoneField {...field} error={errors.phone} label="Phone Number" required />;
                        }}
                    />
                    <Controller
                        name="jobTitle"
                        control={control}
                        render={({ field }) => {
                            return <InputField {...field} error={errors.jobTitle} label="Job Title" required />;
                        }}
                    />
                    <Controller
                        name="department"
                        control={control}
                        render={({ field }) => {
                            return (
                                <SelectField
                                    {...field}
                                    value={field.value || ''}
                                    error={errors.department}
                                    label="Department"
                                    options={FORMATTED_DEPARTMENT_OPTIONS}
                                    required
                                />
                            );
                        }}
                    />
                </GridContainer>
                <Info>
                    By continuing, you agree to our{' '}
                    <a target="_blank" href="https://www.bizly.com/terms-of-use">
                        Terms of Service
                    </a>{' '}
                    and{' '}
                    <a target="_blank" href="https://www.bizly.com/privacy-policy">
                        Privacy Policy
                    </a>
                    .
                </Info>
            </StepItemsContainer>
        </ClaimStepContainer>
    );
}
