import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, styled } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { DATE_FORMAT, DEFAULT_ROOM_TYPE, TypeOfRoomTypes } from 'components/BizlyOS/Proposals/utils';
import { Body1 } from 'components/BizlyOS/Typography/Typography';
import { TGRBooking } from 'components/ProposalForm/types';
import { EColors, getColor } from 'theme';
import { tzMoment } from 'utils/moment';
import { RoomContent } from './RoomContent';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)(
    ({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&::before': {
            display: 'none',
        },
        borderRadius: theme.shape.borderRadius,
    })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: getColor(EColors.pureBlack), fontSize: 22 }} />}
        {...props}
    />
))(({ theme }) => ({
    height: '64px',
    padding: theme.spacing(2.5),
    '& .MuiAccordionSummary-content': {
        margin: 0,
    },
    '& .MuiAccordionSummary-content > p': {
        fontSize: '1rem',
        fontWeight: '600',
        lineHeight: '24px',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2.5),
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
}));

type RoomBlockAccordionProps = {
    roomBlock: TGRBooking;
    index: number;
    isFilled: boolean;
    expanded: boolean;
    currencySymbol: string;
    onExpandChange: (panel: number) => (_: React.SyntheticEvent, newExpanded: boolean) => void;
    onRoomTypeSelect: (indexOfRoomBolck: number, roomType: TypeOfRoomTypes) => void;
};

export function RoomBlockAccordion({
    roomBlock,
    index,
    isFilled,
    expanded,
    currencySymbol,
    onExpandChange,
    onRoomTypeSelect,
}: RoomBlockAccordionProps) {
    const roomBlocksDividedByType = [
        {
            id: roomBlock.date,
            type: DEFAULT_ROOM_TYPE,
            roomCount: roomBlock.proposedRoomCount || roomBlock.requestedGuests || 0,
            dailyRate: roomBlock.proposedRoomRate || 0,
            onAction: () => onRoomTypeSelect(index, DEFAULT_ROOM_TYPE),
            currencySymbol,
        },
        // TODO: Add 'Double' | 'Suite' data here once API is ready
    ];
    return (
        <Accordion expanded={expanded} onChange={onExpandChange(index)}>
            <AccordionSummary>
                <Box display="flex" alignItems="center" gap={1.25}>
                    <CheckCircleIcon
                        fontSize="medium"
                        color="disabled"
                        sx={{
                            color: isFilled ? getColor(EColors.bizlyOSPrimary) : getColor(EColors.bizlyOSBorder),
                        }}
                    />
                    <Body1 fontWeight={500}>{tzMoment(roomBlock.date).format(DATE_FORMAT)}</Body1>
                    <CircleIcon sx={{ fontSize: 6 }} />
                    <Body1>{`${roomBlock.requestedGuests} Guest Rooms`}</Body1>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <RoomContent roomBlocks={roomBlocksDividedByType} />
            </AccordionDetails>
        </Accordion>
    );
}
