import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { sendVerificationCode } from 'api';
import { INQUIRY_OVERVIEW_PATH } from 'components/BizlyOS/Proposals/InquirySubNavigation';
import {
    BizlyOSSideNav,
    HEADER_HEIGHT,
    SIDE_NAV_COLLAPSED_WIDTH,
    SIDE_NAV_WIDTH,
} from 'components/BizlyOS/SideNav/BizlyOSSideNav';
import CollapseIcon from 'images/bizlyOS/header/collapse-icon.svg?react';
import ExpandIcon from 'images/bizlyOS/header/expand-icon.svg?react';
import BizlyLogo from 'images/logos/bizly.svg?react';
import { Authentication } from 'pages/Authentication/Authentication';
import { LOGIN_STATUS } from 'pages/Authentication/SignInPage';
import { Analytics } from 'pages/BizlyOS/Analytics/Analytics';
import { Calendar } from 'pages/BizlyOS/Calendar/Calendar';
import Listing from 'pages/BizlyOS/Listings/Listing';
import { Listings } from 'pages/BizlyOS/Listings/Listings';
import ListingsClaim from 'pages/BizlyOS/ListingsClaim/ListingsClaim';
import ListingsCreate from 'pages/BizlyOS/ListingsCreate/ListingsCreate';
import { Messages } from 'pages/BizlyOS/Messages/Messages';
import { ProposalInquiries } from 'pages/BizlyOS/Proposals/ProposalInquries';
import { ProposalInqury } from 'pages/BizlyOS/Proposals/ProposalInqury';
import { Proposals } from 'pages/BizlyOS/Proposals/Proposals';
import { Settings } from 'pages/BizlyOS/Settings/Settings';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { OSLayoutProvider, useOSLayout } from 'providers/bizly-os';
import { BizlyOSUserProvider } from 'providers/bizly-os-user';
import { useUser } from 'providers/user';
import { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { BizlyOsPostHogFeatureFlags } from 'shared';

interface BizlyOSHeaderProps {
    toggleSideNav: () => void;
    isCollapsed: boolean;
}

const StyledHeader = styled('header')(({ theme: { getColor, EColors } }) => ({
    width: '100%',
    backgroundColor: getColor(EColors.pureBlack),
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    padding: '0.625rem 1.25rem',
    height: HEADER_HEIGHT,
    boxSizing: 'border-box',
}));

const BizlyOSHeader = ({ toggleSideNav, isCollapsed }: BizlyOSHeaderProps) => (
    <StyledHeader>
        <IconButton onClick={toggleSideNav} sx={{ marginRight: '1rem' }}>
            {isCollapsed ? <ExpandIcon /> : <CollapseIcon />}
        </IconButton>
        <BizlyLogo />
    </StyledHeader>
);

const MainContent = styled(Box)({
    flexGrow: 1,
    overflow: 'auto',
    overflowX: 'hidden',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    marginLeft: `${SIDE_NAV_COLLAPSED_WIDTH}px`,
    transition: 'margin-left 0.3s ease',
});

export const ProposalRedirect = () => {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const venueId = params.get('venueId');
    const { proposalId } = useParams<{ proposalId: string }>();

    if (venueId && proposalId) {
        return <Navigate to={`/os/venues/${venueId}/proposals/${proposalId}`} replace />;
    }

    return <Navigate to="/os/inquiries" replace />;
};

export const BizlyOSLayout = () => {
    const { navCollapsed, setNavCollapsed } = useOSLayout();
    const newProposalFlowFlag = useFeatureFlagEnabled(BizlyOsPostHogFeatureFlags.toggleNewProposalFlow);

    const toggleSideNav = () => {
        setNavCollapsed(!navCollapsed);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
            <BizlyOSHeader toggleSideNav={toggleSideNav} isCollapsed={navCollapsed} />

            <Box sx={{ display: 'flex', flexGrow: 1, pt: `${HEADER_HEIGHT}px` }}>
                <BizlyOSSideNav isCollapsed={navCollapsed} />

                <MainContent
                    sx={{
                        marginLeft: navCollapsed ? `${SIDE_NAV_COLLAPSED_WIDTH}px` : `${SIDE_NAV_WIDTH}px`,
                    }}
                >
                    <Routes>
                        {/* NOTE: Home is hidden in v1 */}
                        {/* <Route path="/" element={<Home />} /> */}
                        <Route path="/" element={<Navigate to="inquiries" />} />
                        <Route path="inquiries" element={<ProposalInquiries />} />

                        <Route
                            path="venues/:venueId/proposals/:proposalId/*"
                            element={newProposalFlowFlag ? <ProposalInqury /> : <Proposals />}
                        />

                        {/* TODO: Bookings and Listings pages */}
                        {/*<Route path="bookings" element={<ProposalInquiries />} />*/}
                        <Route path="listings" element={<Listings />} />
                        <Route path="listings/:listingId/*" element={<Listing />} />

                        <Route path="messages" element={<Messages />} />
                        <Route path="calendar" element={<Calendar />} />
                        <Route path="analytics" element={<Analytics />} />
                        <Route path="settings" element={<Settings />} />
                    </Routes>
                </MainContent>
            </Box>
        </Box>
    );
};

const ProtectedRoute = () => {
    const authToken = localStorage.getItem('email_verification');
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useUser();
    const currentLocation = location.pathname.includes(INQUIRY_OVERVIEW_PATH);

    useEffect(() => {
        const handleVerification = async () => {
            // Guard clause for early return
            if (authToken || !currentLocation || !user?.email) return;

            try {
                const { success } = await sendVerificationCode({ email: user.email });
                const state = {
                    from: location,
                    initialLoginStatus: LOGIN_STATUS.VERIFICATION_CODE_SENT,
                };

                navigate('/os/sign-in', {
                    replace: true,
                    state: success ? state : undefined,
                });
            } catch (error) {
                console.error('Failed to send verification code:', error);
                navigate('/os/sign-in', { replace: true });
            }
        };

        handleVerification();
    }, [authToken, currentLocation, location, navigate, user?.email]);

    if (!authToken && currentLocation) return null;

    return (
        <OSLayoutProvider>
            <BizlyOSUserProvider>
                <Outlet />
            </BizlyOSUserProvider>
        </OSLayoutProvider>
    );
};

export const BizlyOS = () => {
    return (
        <Routes>
            <Route path="/sign-in/*" element={<Authentication page="sign-in" />} />
            <Route element={<ProtectedRoute />}>
                <Route path="listings-claim" element={<ListingsClaim />} />
                <Route path="listings-create" element={<ListingsCreate />} />
                <Route path="/*" element={<BizlyOSLayout />} />
            </Route>
        </Routes>
    );
};
