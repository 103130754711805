import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import { playbooksActions } from 'stores/playbooks';
import styled from 'styled-components';
import { EColors } from 'theme';
import { i18n } from 'translation';
import { Copy, ExternalLink, Spacer } from 'ui';
import pluckFileName from 'utils/pluckFileName';

const DropzoneContainer = styled.div`
    border: 2px dashed ${({ theme }) => theme.getColor(EColors.grey)};
    background-color: ${({ theme }) => theme.getColor(EColors.lighterGrey, 0.4)};
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s;

    &:hover {
        border-color: ${({ theme }) => theme.getColor(EColors.darkerGrey)};
    }
`;

const UploadIcon = styled(UploadFileOutlinedIcon)`
    color: ${({ theme }) => theme.getColor(EColors.primaryAction)};
`;

const PlaceholderText = styled.p`
    font-size: 0.938rem;
    color: ${({ theme }) => theme.getColor(EColors.pureBlack)};
`;

const FileList = styled.ul`
    list-style: none;
    padding: 0;
    margin-top: 16px;
`;

const FileItem = styled.li`
    display: flex;
    justify-content: space-between;
    margin: 8px 0;
`;

const LinkItem = styled.div`
    padding: 0.5em;
    border-radius: 4px;
    width: 100%;
`;

const NewItems = styled.div`
    padding: 0.75em;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.getColor(EColors.lightGrey, 0.3)};
    width: 100%;
    display: flex;
`;

const FullWidthLabel = styled.span`
    width: 100%;
`;

const H4Headline = styled.h4`
    font-weight: 500;
    margin: 0;
`;

const NoticeLabel = styled.span`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
    font-size: 0.75rem;
    margin-top: 5px;

    &.label {
        margin-top: 5px;
    }
`;

const SmallCopy = styled(Copy)`
    font-size: 0.813rem;
`;

type UploadBoxProps = {
    files: File[];
    target: string;
    onUpload: (files: File[], target: string) => void;
    link?: Array<string | { id: number; title: string; url: string; playbookId?: string | number }>;
    isResourceFile: boolean;
    deleteResource?: (resourceId: number) => void;
};

const UploadBox = ({ files, target, onUpload, link, isResourceFile, deleteResource }: UploadBoxProps) => {
    const { enqueueSnackbar } = useSnackbar();

    const onDrop = (acceptedFiles: File[]) => {
        const MAX_FILES = 2;
        const currentFileCount = files && acceptedFiles ? files.length + acceptedFiles.length : 0;

        if (currentFileCount > MAX_FILES) {
            enqueueSnackbar(i18n.error.maxFilesExceeded, {
                variant: 'error',
            });
            return;
        }

        const filesToUpload = isResourceFile ? [...acceptedFiles] : [...files, ...acceptedFiles];
        onUpload(filesToUpload, target);
    };

    const handleRemoveFile = (file: File) => {
        const updatedFiles = files.filter(f => f !== file);
        onUpload(updatedFiles, target);
    };

    const handleRemoveResource = (playbookId: string | number, resourceId: number) => {
        if (!playbookId) return;
        playbooksActions.removePlaybookResource(playbookId, resourceId);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true });

    const renderFileItem = (
        fileLinkOrObject: string | { id: number; title: string; url: string; playbookId?: string | number },
        isResourceFile: boolean
    ) => {
        if (!isResourceFile && typeof fileLinkOrObject === 'string') {
            return (
                <FileItem key={fileLinkOrObject}>
                    <LinkItem>
                        <ExternalLink href={fileLinkOrObject} openInNewTab>
                            {pluckFileName(fileLinkOrObject)}
                        </ExternalLink>
                    </LinkItem>
                </FileItem>
            );
        }

        if (isResourceFile && typeof fileLinkOrObject === 'object') {
            const { id, url, title, playbookId } = fileLinkOrObject;

            const handleDelete = playbookId
                ? () => handleRemoveResource(playbookId, id)
                : deleteResource
                  ? () => deleteResource(id)
                  : undefined;

            return (
                <FileItem key={id}>
                    <NewItems>
                        <LinkItem>
                            <ExternalLink href={url} openInNewTab>
                                {title}
                            </ExternalLink>
                        </LinkItem>
                        <IconButton
                            onClick={handleDelete}
                            aria-label="delete"
                            color="error"
                            sx={{ fontSize: '0.75rem', padding: 0 }}
                        >
                            <DeleteOutlinedIcon />
                        </IconButton>
                    </NewItems>
                </FileItem>
            );
        }

        return null;
    };

    return (
        <>
            <DropzoneContainer {...getRootProps()}>
                <input {...getInputProps()} />
                <PlaceholderText>
                    <UploadIcon />
                    <Spacer />
                    {i18n.venue.proposal.dropHere}
                    <SmallCopy>{i18n.venue.proposal.maxFileUploadSize}</SmallCopy>
                </PlaceholderText>
            </DropzoneContainer>
            <NoticeLabel className="label">
                {isResourceFile ? i18n.venue.proposal.uploadResource : i18n.venue.proposal.uploadNotice}
            </NoticeLabel>

            {files && files.length > 0 && !isResourceFile && (
                <FileList>
                    <H4Headline>{i18n.venue.proposal.newlyUploadedFiles}</H4Headline>
                    {files.map(file => (
                        <FileItem key={file.name}>
                            <NewItems>
                                <FullWidthLabel>{file.name}</FullWidthLabel>
                                <IconButton
                                    onClick={() => handleRemoveFile(file)}
                                    aria-label="delete"
                                    color="error"
                                    sx={{ fontSize: '0.75rem', padding: 0 }}
                                >
                                    <DeleteOutlinedIcon />
                                </IconButton>
                            </NewItems>
                        </FileItem>
                    ))}
                </FileList>
            )}

            {link && link.length > 0 && (
                <FileList>
                    {!isResourceFile && <H4Headline>{i18n.venue.proposal.previouslyUploadedFiles}</H4Headline>}
                    {link.map(file => renderFileItem(file, isResourceFile))}
                </FileList>
            )}
        </>
    );
};

export default UploadBox;
