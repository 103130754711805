import { Box, styled } from '@mui/material';
import BulletList from '@tiptap/extension-bullet-list';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useEffect } from 'react';
import Toolbar from './Toolbar';

const StyledEditorContent = styled(EditorContent)(({ theme: { getColor, EColors } }) => ({
    '.tiptap p.is-editor-empty:first-child::before': {
        content: 'attr(data-placeholder)',
        float: 'left',
        color: getColor(EColors.darkGrey),
        pointerEvents: 'none',
        height: 0,
        fontSize: '14px',
    },
}));

export const Container = styled(Box, {
    shouldForwardProp: prop => prop !== 'disabled', // Prevent passing 'disabled' to DOM
})<{ disabled: boolean }>(({ theme: { getColor, EColors, shape, spacing }, disabled }) => ({
    height: 'auto',
    border: `1px solid ${getColor(EColors.bizlyOSBorder)}`,
    borderRadius: shape.borderRadius,
    boxSizing: 'border-box',
    padding: spacing(1.25),
    backgroundColor: disabled ? getColor(EColors.drWhite) : getColor(EColors.pureWhite),
    '&:focus-within': {
        borderColor: disabled ? getColor(EColors.bizlyOSBorder) : getColor(EColors.bizlyOSPrimary),
    },
}));

type TiptapProps = {
    description: string;
    onChange: (_: string) => void;
    placeholder?: string;
    disabled?: boolean;
};

export default function Tiptap({
    description,
    onChange,
    placeholder = 'Enter your description...',
    disabled = false,
}: TiptapProps) {
    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            BulletList,
            Placeholder.configure({
                placeholder: placeholder,
            }),
        ],
        content: description,
        editable: !disabled,
        editorProps: {
            attributes: {
                class: 'tiptap-input',
            },
        },
        onUpdate({ editor }) {
            if (!disabled) {
                onChange(editor.getHTML());
            }
        },
    });

    useEffect(() => {
        if (editor) {
            editor.setEditable(!disabled);
        }
    }, [editor, disabled]);

    useEffect(() => {
        if (editor && description === '') {
            editor.commands.clearContent();
        }
    }, [description, editor]);

    return (
        <Container disabled={disabled}>
            {!disabled && <Toolbar editor={editor} />}
            <StyledEditorContent editor={editor} />
        </Container>
    );
}
