import { InfoOutlined, KeyboardArrowDown } from '@mui/icons-material';
import {
    Alert,
    Box,
    Divider,
    Grid,
    Menu,
    MenuItem,
    Button as MuiButton,
    Stack,
    Typography,
    alpha,
} from '@mui/material';
import colorFns from 'colorFns';
import fontFns from 'fontFns';
import he from 'he';
import isEmpty from 'lodash/isEmpty';
import { useUser } from 'providers/user';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCurrentInquiry } from 'stores/current-inquiry';
import styled from 'styled-components';
import { i18n } from 'translation';
import { HEADER_HEIGHT } from 'utils/header';
import { tzMoment, userTimeZone } from 'utils/moment';
import { hasContract } from 'utils/venue';
import { getVenueProposal } from '../api';
import { EventHeaderIconsFill } from '../components/EventHeader';
import { MessageCardFlyout } from '../components/MessageCard';
import DocumentIcon from '../images/icons/document.svg?react';
import { EventContext } from '../providers/event';
import { Column, ExternalLink, SmallerCopy, SpacedRow, Spacer } from '../ui';
import { calculateIsAboveBudgetWithBuffer, formatCurrency } from '../util';
import { BudgetDisplay } from './BudgetDisplay';
import BudgetLabelTotal, { AlertBox } from './BudgetLabelTotal';
import { eventSpacesSubTotal, getTotalDisplay } from './CompareModal/compareUtils';
import ProposalAcceptModal from './ProposalAcceptModal';
import ProposalRejectModal from './ProposalRejectModal';
import { SpinnerOverlay } from './Spinner';
import ThrustCarbonScore from './ThrustCarbonScore';
import { Button } from './Ui-V2/Button/Button';
import { ProposalActionButton } from './VenueInquiry/ProposalActionButton';
import { ProposalExtensionButton } from './VenueInquiry/ProposalExtensionButton';
import { useProposalRequest } from './VenueInquiry/useProposalRequest';
import DayDetail from './VenueProposal/DayDetail';
import VenueProposalAccepted from './VenueProposalAccepted';
import DashboardVenueTile from './VenueTile/DashboardVenueTile';
import { LargeHeadline, PageHeadline } from './ui/Headline';
import { TAB_HEIGHT } from './ui/ScrollTabs';

const BUDGET_BUFFER_PERCENTAGE = 1.1;

const SubSectionHeader = styled.h6<{ alert?: boolean; pending?: boolean }>`
    font-weight: normal;
    font-size: 1em;
    line-height: 1.28;
    color: ${props => (props.alert ? colorFns.warningText : props.pending ? colorFns.darkGrey : colorFns.grey2)};
    margin: 0;
`;

const VenueLabel = styled('div')<{ small?: boolean; href?: string }>`
    font-size: ${props => (props.small ? '15px' : '18px')};
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}

    display: flex;
    align-items: center;
`;

const ButtonsContainer = styled(SpacedRow)`
    width: auto;

    & > * {
        margin-left: 20px;
    }
`;

const SummaryContainer = styled(Box)`
    scroll-margin-top: ${HEADER_HEIGHT + TAB_HEIGHT}px;
`;

const SummaryBlock = styled(Box)`
    flex: 0.4;
`;

const ProposalSummary = styled(Box)`
    border-bottom: 2px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lighterGrey)};
`;

const VenueImageBlock = styled(Box)`
    & > div:first-child {
        padding: 0;
    }
`;

const PageHeader = styled(Box)`
    display: flex;
    justify-content: space-between;
`;

const SummaryItemRow = styled(SpacedRow)`
    display: flex;
    align-items: center;
    font-size: 1em;
    font-weight: 400;
    padding: 1em 0;
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lighterGrey)};

    > .label {
        flex: 0.45;
        font-weight: 500;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};

        &.alignTop {
            align-self: flex-start;
        }
    }

    > .value {
        flex: 0.55;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
    }

    &:last-child {
        border-bottom: none;
    }
`;

const Totals = styled(Box)`
    border-radius: 4px;
    display: flex;
    gap: 0.5rem;
`;

const TotalBox = styled(Box)<{ total?: boolean; isAboveBudget?: boolean }>`
    flex: 1;
    padding: 2em;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
    justify-content: center;
    text-align: center;
    background: ${colorFns.softAccentedBackground};

    ${({ theme: { getColor, EColors }, total }) =>
        total &&
        `
        background-color: rgba(111, 207, 151, 0.17);
            color: ${getColor(EColors.totalPrice)};
        `}

    ${({ isAboveBudget }) =>
        isAboveBudget &&
        `
            color: #ba4a41;
            background-color: ${alpha('#ba4a41', 0.17)} ;
        `}
 
    .name {
        font-size: 1em;
        flex: 2;
    }

    .value {
        font-size: 1.5em;
        flex: 1;
        margin-bottom: 1rem;
    }
`;

const BudgetInfo = styled(Box)`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    border-radius: 4px;
    padding: 1rem;
`;

const Line = styled('div')`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    height: 2px;
    width: 100%;
`;

const DetailedBreakdownContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    border-radius: 4px;
    padding: 16px;
`;

const BudgetLabel = styled('label')<{ aboveBudget?: boolean }>`
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.1px;
    color: ${({ aboveBudget, theme: { getColor, EColors } }) => (aboveBudget ? '#ba4a41' : getColor(EColors.green))};
    ${fontFns.formLabel}
`;

const VenueAddress = styled(Typography)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
    margin-top: 5px;
`;

const ActionsMenu = styled(Menu)`
    & .MuiList-root {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    & .MuiButtonBase-root {
        width: 100%;
        text-align: left;
    }

    & .MuiButtonBase-root button {
        padding: 6px 16px;
        background-color: transparent;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.black)};
        font-weight: 400;
        border: none;
        width: 100%;
        text-align: left;
        display: inline-block;

        &:disabled {
            color: ${({ theme: { getColor, EColors } }) => getColor(EColors.grey2, 0.45)};
        }
    }
`;

const ActionButton = styled(Button)`
    &.MuiButton-root {
        padding: 6px 16px;
        border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
        border-radius: 4px;
    }
`;

const ProposalDetailsBox = styled(Box)`
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    border-radius: 4px;
    padding: 16px;
`;

const OnrivaBudgetTotalContainer = styled(Box)`
    & {
        max-width: 500px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;
    }
    & .MuiStack-root {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
    }
`;

const BudgetTotalContainer = styled(Box)``;

const TotalBoxLabel = styled(Typography)`
    font-weight: 500;
    font-size: 0.875rem;
`;

const TotalBoxValue = styled(Typography)`
    font-weight: 500;
    font-size: 1rem;
`;

const grSubtotal = (guestroom: Bizly.VenueDayGR) => (guestroom?.quantity ? guestroom.rate * guestroom.quantity : null);

const VenueProposalV2 = ({
    proposalId,
    viewVenueListing,
    venueBooked,
    setVenueBooked,
    withMessagesOpen = false,
}: {
    proposalId?: string;
    viewVenueListing: (venueId: number) => void;
    venueBooked?: boolean;
    setVenueBooked?: (booked: boolean) => void;
    withMessagesOpen?: boolean;
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { venues } = useCurrentInquiry();
    const { user } = useUser();

    const { event, setCurrency } = useContext(EventContext);

    const [venueProposal, setVenueProposal] = useState<Bizly.VenueProposal>();
    const [contractLoading, setContractLoading] = useState<boolean>(false);

    const [acceptModalOpen, setAcceptModalOpen] = useState<boolean>(false);
    const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);

    const [venueMessagesAnchor, setVenueMessagesAnchor] = useState<HTMLDivElement | null>(null);
    const messageBubbleIconRef = useRef<HTMLDivElement | null>(null);

    const { showModal, modalShown, hasRequested, ProposalRequestModal } = useProposalRequest(venueProposal);

    const summaryRef = useRef<HTMLDivElement>(null);

    const toggleMessagesFlyout = () => {
        const currentPath = location.pathname.split('/');
        if (currentPath[currentPath.length - 1] === 'messages') {
            currentPath.pop();
        } else {
            currentPath.push('messages');
        }
        navigate(currentPath.join('/'), {
            replace: true,
        });
        setVenueMessagesAnchor(prevAnchor => (prevAnchor ? null : messageBubbleIconRef.current));
    };
    const showMessagesFlyout = () => {
        if (!withMessagesOpen) {
            navigate(location.pathname + '/messages', { replace: true });
        }
        setVenueMessagesAnchor(messageBubbleIconRef.current);
    };

    useEffect(() => {
        if (withMessagesOpen && messageBubbleIconRef.current) {
            setVenueMessagesAnchor(messageBubbleIconRef.current);
        }
    }, [messageBubbleIconRef, withMessagesOpen]);

    const isMounted = useRef<boolean>(false);
    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    const loadedProposalId = useRef<string | undefined>();

    useEffect(() => {
        if (proposalId && (proposalId !== loadedProposalId.current || !venueProposal)) {
            loadedProposalId.current = proposalId;
            getVenueProposal(proposalId).then(res => {
                if (isMounted.current) {
                    if (res.proposal?.accepted) {
                        setContractLoading(true);
                    }
                    setVenueProposal(res.proposal);
                }
            });
        }
    }, [proposalId, venueBooked, venueProposal]);

    useEffect(() => {
        if (venues && venues.length > 0 && venueProposal) {
            const inquiredVenue = venues.find(venue => venue.proposalId === venueProposal.id);
            if (hasContract(inquiredVenue)) {
                if (!location.pathname.includes('/contracting')) {
                    // check if it is a messages url
                    let messageUrl = '';
                    const currentPath = location.pathname.split('/');
                    if (currentPath[currentPath.length - 1] === 'messages') {
                        messageUrl = '/messages';
                    }

                    navigate(`/event/${event.id}/venue/contracting${messageUrl}`, { replace: true });
                }
            }
        }
    }, [venueProposal, venues, event, navigate, location]);

    const handleExtensionRequest = () => {
        if (proposalId) {
            getVenueProposal(proposalId).then(res => setVenueProposal(res.proposal));
        }
    };

    const onHoldUntilDate = venueProposal?.onHoldUntil
        ? tzMoment(venueProposal?.onHoldUntil.split(' ')[0] + ' 23:59:59', venueProposal?.venue.timeZone)
              .tz(venueProposal?.venue.timeZone ?? userTimeZone)
              .format('L LTS zz')
        : '';

    const expired = venueProposal && new Date() > new Date(venueProposal.onHoldUntil);
    const extensionRequestDate = tzMoment(venueProposal?.extensionRequestedAt).format('L');
    const extensionRejectDate = tzMoment(venueProposal?.extensionRejectedAt).format('L');
    const hasBeenExtended = tzMoment(venueProposal?.onHoldUntil).isAfter(venueProposal?.extensionRequestedAt);

    const currencyCode = venueProposal?.currency?.code;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleActionMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleActionMenuClose = () => {
        setAnchorEl(null);
    };

    const isAboveBudget = (proposal: Bizly.VenueProposal): boolean => {
        const useDdr = !!proposal.useDdr;
        const grSubtotalVal = proposal.byDay.reduce((sum, day) => sum + (grSubtotal(day.guestRooms[0]) ?? 0), 0);
        const esSubtotalVal = proposal.byDay.reduce(
            (sum, day) => sum + (eventSpacesSubTotal(day.eventSpaces, useDdr) ?? 0),
            0
        );
        const totalProposal = grSubtotalVal + esSubtotalVal;
        const totalConvertedProposal = totalProposal * Number(proposal.forexRate);

        const { isAboveBudget } = calculateIsAboveBudgetWithBuffer(
            proposal,
            user,
            event,
            BUDGET_BUFFER_PERCENTAGE,
            totalConvertedProposal
        );

        return isAboveBudget;
    };

    const isOnriva = !!user.team?.sourceSite;
    const venueAddress = venueProposal?.venue.address;
    const {
        totalProposal: onrivaTotalProposal,
        subtotal,
        platformFee,
    } = venueProposal
        ? getTotalDisplay(venueProposal, isOnriva)
        : { totalProposal: '-', subtotal: '-', platformFee: '-' };

    const renderTotals = (proposal: Bizly.VenueProposal) => {
        const useDdr = !!proposal.useDdr;

        const hasGR = proposal.byDay.some(day => day.guestRooms[0]?.quantity);
        const hasES = proposal.byDay.some(day => day.eventSpaces.length > 0);

        const grSubtotalVal = proposal.byDay.reduce((sum, day) => sum + (grSubtotal(day.guestRooms[0]) ?? 0), 0);
        const esSubtotalVal = proposal.byDay.reduce(
            (sum, day) => sum + (eventSpacesSubTotal(day.eventSpaces, useDdr) ?? 0),
            0
        );
        const totalProposal = grSubtotalVal + esSubtotalVal;

        const totalDisplay = formatCurrency(totalProposal, currencyCode);
        const totalConvertedProposal =
            proposal.forexRate > 0 ? totalProposal * Number(proposal.forexRate) : totalProposal;

        const { isAboveBudget, hasBudget } = calculateIsAboveBudgetWithBuffer(
            proposal,
            user,
            event,
            BUDGET_BUFFER_PERCENTAGE,
            totalConvertedProposal
        );

        const isAboveMaximumBudgetDisplay = isAboveBudget
            ? i18n.venue.inquiry.aboveMaximumBudget
            : i18n.venue.inquiry.withinMaximumBudget;

        const showGuestRoomsBox = hasGR && grSubtotalVal > 0;
        const showEventSpacesBox = hasES && esSubtotalVal > 0;

        return (
            <Column>
                <Totals justifyContent="space-between">
                    {showEventSpacesBox && (
                        <TotalBox>
                            <TotalBoxLabel>{i18n.venue.inquiry.spaces}</TotalBoxLabel>
                            <Spacer small />
                            <div className="value">
                                <TotalBoxValue>
                                    {hasES ? formatCurrency(esSubtotalVal, currencyCode) : '-'}
                                </TotalBoxValue>
                            </div>
                        </TotalBox>
                    )}
                    {showGuestRoomsBox && (
                        <TotalBox>
                            <div className="name">
                                <TotalBoxLabel>{i18n.proposalForm.sections.rooms}</TotalBoxLabel>
                            </div>
                            <Spacer small />
                            <div className="value">
                                <TotalBoxValue>
                                    {hasGR ? formatCurrency(grSubtotalVal, currencyCode) : '-'}
                                </TotalBoxValue>
                            </div>
                        </TotalBox>
                    )}
                    {isOnriva && (
                        <TotalBox justifyContent="center">
                            <div className="name">
                                <TotalBoxLabel>{i18n.venue.proposal.platformFee}</TotalBoxLabel>
                            </div>
                            <Spacer small />
                            <div className="value">
                                <TotalBoxValue>{platformFee}</TotalBoxValue>
                            </div>
                        </TotalBox>
                    )}
                    <TotalBox
                        isAboveBudget={isAboveBudget}
                        total={hasBudget ? true : undefined}
                        justifyContent="center"
                    >
                        <div className="name">
                            <TotalBoxLabel>{i18n.venue.total}</TotalBoxLabel>
                        </div>
                        <Spacer small />
                        <div className="value">
                            <TotalBoxValue>{isOnriva ? onrivaTotalProposal : totalDisplay}</TotalBoxValue>
                        </div>
                        {hasBudget && (
                            <BudgetLabel aboveBudget={isAboveBudget}>{isAboveMaximumBudgetDisplay}</BudgetLabel>
                        )}
                    </TotalBox>
                </Totals>
                {hasBudget && (
                    <BudgetDisplay
                        proposal={proposal}
                        user={user}
                        event={event}
                        currencyCode={currencyCode}
                        budgetBufferPercentage={BUDGET_BUFFER_PERCENTAGE}
                    />
                )}
                <Spacer small />
                <SmallerCopy>{i18n.venue.inquiry.amountDescription}</SmallerCopy>
                <Spacer small />
            </Column>
        );
    };

    const budgetTotal = (venueProposal: Bizly.VenueProposal) => {
        return (
            <>
                {isOnriva && (
                    <>
                        <OnrivaBudgetTotalContainer>
                            <Stack direction="row" gap={15}>
                                <Typography fontWeight={500} fontSize={14}>
                                    Subtotal (Excl. {i18n.venue.proposal.platformFee})
                                </Typography>
                                <Typography fontWeight={600} fontSize={14}>
                                    {subtotal}
                                </Typography>
                            </Stack>
                            <Stack direction="row" gap={15}>
                                <Typography fontWeight={500} fontSize={14}>
                                    {i18n.venue.proposal.platformFee}
                                </Typography>
                                <Typography fontWeight={600} fontSize={14}>
                                    {platformFee}
                                </Typography>
                            </Stack>
                            <Stack direction="row" gap={15}>
                                <Typography fontWeight={500} fontSize={14}>
                                    Proposal Total ({currencyCode})
                                </Typography>
                                <Typography fontWeight={600} fontSize={14}>
                                    {onrivaTotalProposal}
                                </Typography>
                            </Stack>
                        </OnrivaBudgetTotalContainer>
                        <AlertBox>
                            <Alert variant="filled" severity="info" icon={<InfoOutlined color="info" />}>
                                Proposal total does not calculate taxes and fees which the venue will include in their
                                final invoice.
                            </Alert>
                        </AlertBox>
                    </>
                )}
                {!isOnriva && (
                    <BudgetTotalContainer>
                        <BudgetLabelTotal
                            alertVariant="filled"
                            title={`Proposal Total (${currencyCode})`}
                            totalProposal={onrivaTotalProposal}
                            isAboveBudget={isAboveBudget(venueProposal)}
                        />
                    </BudgetTotalContainer>
                )}
            </>
        );
    };

    const summaryBlock = (
        <SummaryBlock>
            <Column>
                {venueProposal && venueProposal.onHoldUntil && (
                    <ProposalSummary>
                        <Column>
                            <LargeHeadline thin>{i18n.venue.inquiry.viewProposalDetails}</LargeHeadline>
                        </Column>
                        <Divider />
                        <Spacer smallish />
                        <Box>{venueProposal && renderTotals(venueProposal)}</Box>

                        {venueProposal?.extensionRejectedAt ? (
                            <Column>
                                <SubSectionHeader alert>{i18n.venue.proposal.extensionRejectedOn}:</SubSectionHeader>
                                <SubSectionHeader alert>{extensionRejectDate}</SubSectionHeader>
                            </Column>
                        ) : (
                            venueProposal?.extensionRequestedAt &&
                            !hasBeenExtended && (
                                <Column>
                                    <SubSectionHeader pending>
                                        {i18n.venue.proposal.extensionRequestSentOn}:
                                    </SubSectionHeader>
                                    <SubSectionHeader pending>{extensionRequestDate}</SubSectionHeader>
                                </Column>
                            )
                        )}
                    </ProposalSummary>
                )}
                <SummaryItemRow>
                    <div className="label">
                        <Typography fontWeight={500} textTransform="capitalize">
                            {i18n.venue.proposal.spaceHeldUntil}
                        </Typography>
                    </div>
                    <div className="value">{onHoldUntilDate}</div>
                </SummaryItemRow>
                <SummaryItemRow>
                    <div className="label">{i18n.venue.carbonMeasurement}</div>
                    <div className="value">
                        {venueProposal?.emissionReport?.tons ? (
                            <ThrustCarbonScore
                                score={venueProposal?.emissionReport?.tons}
                                equivalents={venueProposal?.emissionReport?.equivalents}
                                noDecor
                            />
                        ) : (
                            i18n.venue.proposal.noInfo
                        )}
                    </div>
                </SummaryItemRow>
                <SummaryItemRow>
                    <div className="label alignTop">{i18n.proposalForm.modal.notes}</div>
                    <div className="value">
                        {venueProposal && !isEmpty(venueProposal.notes)
                            ? he.decode(venueProposal.notes.replace(/(<([^>]+)>)/gi, ''))
                            : i18n.venue.proposal.noInfo}
                    </div>
                </SummaryItemRow>
                <SummaryItemRow>
                    <div className="label">{i18n.common.attachments}</div>
                    <div className="value">
                        {venueProposal && !isEmpty(venueProposal.attachments)
                            ? venueProposal.attachments.map(attachment => (
                                  <VenueLabel key={attachment.name} small>
                                      <DocumentIcon />
                                      <ExternalLink href={attachment.url} openInNewTab>
                                          {attachment.name}
                                      </ExternalLink>
                                  </VenueLabel>
                              ))
                            : i18n.venue.proposal.noInfo}
                    </div>
                </SummaryItemRow>
                {venueProposal && !isEmpty(venueProposal.datesFlexibleNotes) && (
                    <SummaryItemRow>
                        <div className="label">{i18n.venue.proposal.flexibleDates}</div>
                        <div className="value">
                            {he.decode(venueProposal.datesFlexibleNotes.replace(/(<([^>]+)>)/gi, ''))}
                        </div>
                    </SummaryItemRow>
                )}
            </Column>
            <Spacer />
        </SummaryBlock>
    );

    const venueImage = (
        <VenueImageBlock>
            {venueProposal && venueProposal.venue && (
                <>
                    <DashboardVenueTile
                        venue={venueProposal.venue}
                        format="proposal"
                        fixedRatio="calc(3 / 4 * 100%)"
                        width="100%"
                        onClick={viewVenueListing}
                        pillType="preferenceCategory"
                    />

                    <Box>
                        <VenueAddress>{venueAddress}</VenueAddress>
                    </Box>
                </>
            )}
        </VenueImageBlock>
    );

    if (!venueProposal) {
        return <SpinnerOverlay />;
    }

    const shouldShowProposalModals = setVenueBooked && !venueProposal.accepted && !venueProposal.rejected;

    return (
        <>
            {proposalId && event.editable && (
                <EventHeaderIconsFill>
                    {venueMessagesAnchor && (
                        <MessageCardFlyout
                            anchor={venueMessagesAnchor}
                            onClose={toggleMessagesFlyout}
                            proposalId={proposalId}
                        />
                    )}
                </EventHeaderIconsFill>
            )}
            {venueProposal?.accepted && (
                <VenueProposalAccepted
                    proposalId={proposalId}
                    onSendMessage={showMessagesFlyout}
                    summaryRef={summaryRef}
                    onLoading={setContractLoading}
                    messageBubbleIconRef={messageBubbleIconRef}
                />
            )}
            {!contractLoading && (
                <Box>
                    {!venueProposal.accepted && (
                        <PageHeader>
                            <PageHeadline large>{i18n.venue.proposal.proposal}</PageHeadline>
                            <ButtonsContainer>
                                {event.editable && (
                                    <div ref={messageBubbleIconRef}>
                                        <Button variant="outlined" onClick={showMessagesFlyout}>
                                            {i18n.venue.messageVenue}
                                        </Button>
                                    </div>
                                )}
                                <>
                                    <Box>
                                        <ActionButton
                                            variant="outlined"
                                            onClick={handleActionMenuClick}
                                            endIcon={<KeyboardArrowDown />}
                                            color="success"
                                        >
                                            Actions
                                        </ActionButton>
                                        <ActionsMenu anchorEl={anchorEl} open={open} onClose={handleActionMenuClose}>
                                            <ProposalActionButton
                                                proposal={venueProposal}
                                                expired={expired}
                                                onAccept={() => setAcceptModalOpen(true)}
                                                eventBudget={event?.budget}
                                                showModal={showModal}
                                                hasRequested={hasRequested}
                                                buttonBareStyle
                                            />
                                            <MenuItem>
                                                <MuiButton
                                                    disabled={!shouldShowProposalModals}
                                                    onClick={() => setRejectModalOpen(true)}
                                                >
                                                    {i18n.button.reject}
                                                </MuiButton>
                                            </MenuItem>

                                            <MenuItem>
                                                <ProposalExtensionButton
                                                    expired={expired}
                                                    proposal={venueProposal}
                                                    onAccept={() => setAcceptModalOpen(true)}
                                                />
                                            </MenuItem>
                                        </ActionsMenu>
                                    </Box>
                                </>
                            </ButtonsContainer>
                        </PageHeader>
                    )}
                    <SummaryContainer ref={summaryRef}>
                        {venueProposal.accepted && <Spacer largest />}
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={4}>
                                {venueImage}
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <ProposalDetailsBox>{summaryBlock}</ProposalDetailsBox>
                            </Grid>
                        </Grid>
                    </SummaryContainer>
                    <Spacer />
                    <Spacer largest />

                    <DetailedBreakdownContainer>
                        <Typography fontWeight={500} fontSize={20} variant="h4">
                            {i18n.venue.proposal.detailedBreakdown}
                        </Typography>
                        <Spacer small />
                        <Line />
                        <Spacer />
                        {venueProposal.byDay.map((day, i) => (
                            <Fragment key={i}>
                                <DayDetail dayIndex={i + 1} details={day} venueProposal={venueProposal} />
                                <Spacer small />
                            </Fragment>
                        ))}

                        <BudgetInfo>{budgetTotal(venueProposal)}</BudgetInfo>
                    </DetailedBreakdownContainer>

                    <Spacer largest />
                </Box>
            )}
            {shouldShowProposalModals && (
                <>
                    <ProposalAcceptModal
                        open={acceptModalOpen}
                        closeModal={() => setAcceptModalOpen(false)}
                        proposal={venueProposal}
                        eventId={event.id}
                        setVenueBooked={booked => {
                            acceptModalOpen && setCurrency(venueProposal?.currency);
                            setVenueBooked(booked);
                        }}
                        onExtensionRequest={handleExtensionRequest}
                    />
                    <ProposalRejectModal
                        open={rejectModalOpen}
                        closeModal={() => setRejectModalOpen(false)}
                        proposalId={venueProposal.id}
                        eventId={event.id}
                    />
                </>
            )}
            {modalShown && ProposalRequestModal}
        </>
    );
};

export default VenueProposalV2;
