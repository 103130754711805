import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined';
import { Box, BoxProps, Button, ButtonProps, Typography, styled } from '@mui/material';
import { APIProvider, AdvancedMarker, Map, Pin, useMap } from '@vis.gl/react-google-maps';
import { getPlace } from 'api';
import { WEBREGISTRATION_PREVIEW_IDS } from 'constants/event';
import useTimer from 'hooks/useTimer';
import BizlyLogo from 'images/logos/bizly-light.svg';
import PreviewImage from 'images/web-preview.jpeg';
import React, { RefObject, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { EColors, getColor } from 'theme';
import { TiptapContentRender } from './Ui-V2/InputFields/TipTap';
import MirageLoader from './Ui-V2/Loaders/MirageLoader';

const MAP_KEY = import.meta.env.VITE_APP_GMAPS_KEY;
const LOADING_INTERVEL_IN_SECOND = 2;

const PlaceMap = ({ position }: { position: { lat: number; lng: number } | undefined }) => {
    const map = useMap('venues-map');

    useEffect(() => {
        if (!position || !map) return;
        map.panTo(position);
    }, [map, position]);

    const mapInitialized = useRef(false);
    useEffect(() => {
        if (map && !mapInitialized.current) {
            map.setOptions({
                disableDefaultUI: true,
            });

            google.maps.event.addListener(map, 'click', (event: google.maps.MapMouseEvent) => {
                event.stop();
            });

            mapInitialized.current = true;
        }
    }, [map]);

    return (
        <Box width="100%" height="100%">
            <Map id="venues-map" mapId="venues-map" defaultZoom={14} defaultCenter={position}>
                <AdvancedMarker position={position} clickable>
                    <Pin />
                </AdvancedMarker>
            </Map>
        </Box>
    );
};

const CountMeInButton = styled((props: ButtonProps) => (
    <Button variant="text" startIcon={<ThumbUpOffAltOutlinedIcon />} {...props} />
))({
    '&.MuiButton-root.MuiButton-contained': {
        borderRadius: '50px',
        backgroundColor: getColor(EColors.pureBlack),
    },

    '&.MuiButton-root.MuiButton-contained:hover': {
        backgroundColor: getColor(EColors.pureBlack, 0.8),
    },
});

const Section = styled(Box)<BoxProps>(({ theme: { getColor, EColors, spacing } }) => ({
    boxSizing: 'border-box',
    width: '100%',
    backgroundColor: getColor(EColors.pureWhite),
    borderRadius: spacing(1),
    padding: spacing(2.5),
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: spacing(2.5),
}));

const LoadingSection = styled(Box)<BoxProps>(({ theme: { getColor, EColors, spacing } }) => ({
    boxSizing: 'border-box',
    width: '100%',
    height: '400px',
    backgroundColor: getColor(EColors.pureWhite),
    borderRadius: spacing(1),
    padding: spacing(2.5),
}));

const IconContainer = styled(Box)<BoxProps>(({ theme: { getColor, EColors, spacing } }) => ({
    width: '40px',
    height: '40px',
    backgroundColor: getColor(EColors.primaryAction, 0.1),
    borderRadius: spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const Center = ({ children }: React.PropsWithChildren) => (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2.5}>
        <Box display="flex" flexDirection="column" gap={2.5} width="100%" maxWidth={600}>
            {children}
        </Box>
    </Box>
);

type EventRegistrationWebPageBodyType = {
    preview?: boolean;
    previewRef: RefObject<HTMLElement> | null;
    coverImageUrl: string;
    logoUrl: string;
    eventTitle: string;
    dateRange: string;
    startTime: string;
    endTime: string;
    timeZone: string;
    locationName: string;
    locationAddress: string;
    eventOverview: string;
    googleLocationId: string;
    isSubmitted?: boolean;
    onClickCountMeIn: () => void;
};

function EventRegistrationWebPageBody({
    isSubmitted = false,
    preview = false,
    previewRef = null,
    coverImageUrl,
    logoUrl,
    eventTitle,
    dateRange,
    startTime,
    endTime,
    timeZone,
    locationName,
    eventOverview,
    googleLocationId,
    onClickCountMeIn,
}: EventRegistrationWebPageBodyType) {
    const { data: googleMapData } = useQuery({
        queryKey: ['google_location', googleLocationId],
        queryFn: async () => getPlace(googleLocationId),
        select: data => data?.result,
    });

    const locationPosition = googleMapData?.geometry?.location as Bizly.Location;
    const formattedAddress = (googleMapData?.formattedAddress as string) || '';

    const itemList = [
        <>
            {coverImageUrl ? (
                <img
                    src={coverImageUrl}
                    height={300}
                    style={{ borderRadius: '8px', width: '100%', aspectRatio: '3 / 2', objectFit: 'cover' }}
                    id={WEBREGISTRATION_PREVIEW_IDS.banner}
                />
            ) : (
                <img
                    src={PreviewImage}
                    style={{ borderRadius: '8px', width: '100%', aspectRatio: '3 / 2', objectFit: 'cover' }}
                    id={WEBREGISTRATION_PREVIEW_IDS.banner}
                />
            )}
        </>,
        <Section>
            {logoUrl && (
                <img src={logoUrl} height={30} style={{ objectFit: 'contain' }} id={WEBREGISTRATION_PREVIEW_IDS.logo} />
            )}
            <Typography
                fontWeight={600}
                color={getColor(EColors.pureBlack, 0.75)}
                lineHeight="100%"
                textTransform="uppercase"
            >
                {isSubmitted ? 'Thank you for registering!' : 'JOIN US FOR'}
            </Typography>
            <Typography variant="h6" fontWeight={600} lineHeight="100%" id={WEBREGISTRATION_PREVIEW_IDS.eventTitle}>
                {eventTitle}
            </Typography>

            {dateRange && (
                <Box display="flex" gap={1.25} id={WEBREGISTRATION_PREVIEW_IDS.dates}>
                    <IconContainer>
                        <CalendarMonthOutlinedIcon fontSize="small" color="primary" />
                    </IconContainer>
                    <Box flex={1}>
                        <Typography fontWeight={500} letterSpacing="1px">
                            {dateRange}
                        </Typography>
                        <Typography variant="body2" lineHeight="100%">
                            {`${startTime} - ${endTime} ${timeZone}`}
                        </Typography>
                    </Box>
                </Box>
            )}

            {locationName && (
                <Box display="flex" gap={1.25} id={WEBREGISTRATION_PREVIEW_IDS.eventLocation}>
                    <IconContainer>
                        <LocationOnOutlinedIcon fontSize="small" color="primary" />
                    </IconContainer>
                    <Box flex={1}>
                        <Typography fontWeight={500} letterSpacing="1px">
                            {locationName}
                        </Typography>
                        <Typography variant="body2" lineHeight="100%">
                            {formattedAddress || '-'}
                        </Typography>
                    </Box>
                </Box>
            )}

            {isSubmitted ? (
                <CountMeInButton variant="contained" onClick={onClickCountMeIn}>
                    Registration Details
                </CountMeInButton>
            ) : (
                <>
                    <Typography fontWeight={600}>Please RSVP:</Typography>
                    <CountMeInButton
                        variant="contained"
                        onClick={onClickCountMeIn}
                        id={WEBREGISTRATION_PREVIEW_IDS.rsvp}
                    >
                        Count me in!
                    </CountMeInButton>
                </>
            )}
        </Section>,
        <Section id={WEBREGISTRATION_PREVIEW_IDS.eventOverview}>
            <Typography
                fontWeight={600}
                width="100%"
                borderBottom={'1px solid ' + getColor(EColors.inactiveAction)}
                paddingBottom={1.25}
            >
                Event Overview
            </Typography>
            <TiptapContentRender dangerouslySetInnerHTML={{ __html: eventOverview || '' }} textAlign="justify" />
        </Section>,
        <Section id={WEBREGISTRATION_PREVIEW_IDS.eventLocationMap}>
            <Typography
                fontWeight={600}
                width="100%"
                borderBottom={'1px solid ' + getColor(EColors.inactiveAction)}
                paddingBottom={1.25}
            >
                Venue
            </Typography>

            {locationPosition ? (
                <>
                    <Box>
                        <Typography fontWeight={600}>{locationName}</Typography>
                        <Typography variant="body2">{formattedAddress}</Typography>
                    </Box>
                    <Box boxSizing="border-box" width="100%" height="260px">
                        <APIProvider apiKey={MAP_KEY}>
                            <PlaceMap position={locationPosition} />
                        </APIProvider>
                    </Box>
                </>
            ) : (
                <Typography>Please select the event location</Typography>
            )}
        </Section>,
    ];

    const { seconds, isRunning: isLoading, start } = useTimer(itemList.length * LOADING_INTERVEL_IN_SECOND);

    useEffect(() => {
        if (preview) {
            start();
        }
    }, [preview]);

    return (
        <Box bgcolor={getColor(EColors.pureBlack)} overflow="auto" p={2.5} ref={previewRef}>
            {preview && (
                <Typography lineHeight="100%" fontWeight={600} marginBottom={1.25} color={getColor(EColors.pureWhite)}>
                    Preview
                </Typography>
            )}

            <Center>
                {preview ? (
                    <>
                        {itemList.map((item, index) => {
                            if ((index + 1) * LOADING_INTERVEL_IN_SECOND > seconds) return null;
                            return item;
                        })}
                        {isLoading && (
                            <LoadingSection>
                                <MirageLoader size="5rem" text="Generating" />
                            </LoadingSection>
                        )}
                    </>
                ) : (
                    itemList
                )}

                <Box display="flex" justifyContent="center" alignItems="center" gap={0.5}>
                    <Typography lineHeight={1} fontSize={12} color={getColor(EColors.pureWhite)}>
                        Powered by
                    </Typography>
                    <a style={{ height: '16px' }} target="_blank" href="https://www.bizly.com">
                        <img src={BizlyLogo} width={35.65} />
                    </a>
                </Box>
            </Center>
        </Box>
    );
}

export default EventRegistrationWebPageBody;
