import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import UploadIcon from '@mui/icons-material/UploadFile';
import { CircularProgress, styled } from '@mui/material';
import { EColors, getColor } from 'theme';
import { Body2, Caption } from '../../Typography/Typography';

const UploadFileIcon = styled(UploadIcon)(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.bizlyOSPrimary),
}));

const Description = styled(Body2)(({ theme: { getColor, EColors } }) => ({
    '& > span': {
        color: getColor(EColors.bizlyOSPrimary),
        fontWeight: '500',
        cursor: 'pointer',
    },
}));

type UploadContent = {
    isDragActive: boolean;
    isUploading?: boolean;
    onUploadClick?: () => void;
};

export function UploadContent({ isDragActive, isUploading, onUploadClick }: UploadContent) {
    if (isDragActive) {
        return (
            <>
                <CloudUploadOutlinedIcon fontSize="large" sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                <Body2>Drop the files here</Body2>
            </>
        );
    }

    if (isUploading) {
        return <CircularProgress size={24} sx={{ color: getColor(EColors.bizlyOSPrimary) }} />;
    }

    return (
        <>
            <UploadFileIcon />
            <Description>
                <span onClick={onUploadClick}>Click to upload</span> or drag and drop
            </Description>
            <Caption size="large">3MB max file size</Caption>
        </>
    );
}
