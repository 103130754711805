import CancelIcon from '@mui/icons-material/Cancel';
import { Box } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { EColors, getColor } from 'theme';

export const InitiateProposalWithdraw = ({ confirmWithdrawIntent }: { confirmWithdrawIntent: () => void }) => {
    return (
        <>
            <Box display="flex" flexDirection="row" gap={1.25} marginBottom={1.25}>
                <CancelIcon sx={{ color: getColor(EColors.warningText) }} />
                <Body1 fontWeight={600} sx={{ color: getColor(EColors.warningText) }}>
                    Withdraw Proposal?
                </Body1>
            </Box>

            <Body2 marginBottom={2.5}>
                We recommend messaging the client first if there are any concerns. Once withdrawn, your proposal cannot
                be reinstated and the client will be informed.{' '}
            </Body2>
            <Button fullWidth size="small" sx={{ marginBottom: '10px', height: '40px' }} onClick={() => {}}>
                Message Client
            </Button>
            <Button fullWidth variant="outline" color="error" sx={{ height: '40px' }} onClick={confirmWithdrawIntent}>
                Withdraw Proposal
            </Button>
        </>
    );
};
