import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Box, styled } from '@mui/material';
import { StatusChip } from 'components/BizlyOS/StatusChip/StatusChip';
import { TProposalForm } from 'components/ProposalForm/types';
import { useNavigate } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { formatDateRange } from 'utils/date_util';
import { Body2 } from '../../Typography/Typography';
//NOTE: hidden in v1
// import { useState } from 'react';
// import { InviteStaffDrawer } from 'components/BizlyOS/InviteStaffDrawer';
// import { AssignInquirySideDrawer } from './AssignInquirySideDrawer/AssignInquirySideDrawer';

const primaryColor = getColor(EColors.bizlyOSPrimary);
const INQUIRIES_PATH = '/os/inquiries';

const ItemWrapper1 = styled(Box)(({ theme }) => ({
    marginRight: theme.spacing(2.5),
    alignSelf: 'center',
}));

const ItemWrapper2 = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(0.5),
    padding: '5px 10px',
    borderLeft: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

const BackIcon = styled(KeyboardBackspaceIcon)(({ theme: { getColor, EColors } }) => ({
    fontSize: '1rem',
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    borderRadius: '50%',
    padding: '2px',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    '&:hover': {
        backgroundColor: getColor(EColors.bizlyOSPrimary, 0.2),
    },
}));

export const ProposalInquiryHeader = ({ proposalInquiryForm }: { proposalInquiryForm: TProposalForm }) => {
    const navigate = useNavigate();

    //NOTE: hidden in v1
    // const [isAssignDrawerOpen, setIsAssignDrawerOpen] = useState<boolean>(false);
    // const [inviteColleagueOpen, setInviteColleagueOpen] = useState(false);

    const { eventSpaces, proposal, event } = proposalInquiryForm;

    const calcGuestsCount = () =>
        eventSpaces?.reduce((accumulator, currentValue) => {
            return accumulator + (currentValue.requestedGuests || 0);
        }, 0) || 0;

    //NOTE: hidden in v1
    // const onInviteColleagueClicked = () => {
    //     setIsAssignDrawerOpen(false);
    //     setInviteColleagueOpen(true);
    // };
    // const onSetInviteColleagueOpen = (value: boolean) => {
    //     if (!value) {
    //         setIsAssignDrawerOpen(true);
    //     }
    //     setInviteColleagueOpen(value);
    // };

    const friendlyStatusName = proposal?.status?.friendlyName;

    return (
        <>
            <Box display="flex" alignItems="center">
                <ItemWrapper1>
                    <BackIcon onClick={() => navigate(INQUIRIES_PATH)} />
                </ItemWrapper1>
                <ItemWrapper1>
                    <Body2 fontWeight={500}>Inquiry {proposal?.id}</Body2>
                </ItemWrapper1>
                <ItemWrapper1>
                    <StatusChip label={friendlyStatusName} status={friendlyStatusName} bold />
                </ItemWrapper1>
                <ItemWrapper2>
                    <CalendarTodayOutlinedIcon fontSize="small" sx={{ color: primaryColor }} />
                    <Body2 fontWeight={500}>Date:</Body2>
                    <Body2 fontWeight={500}>{formatDateRange(event?.startsAt || '', event?.endsAt || '')}</Body2>
                </ItemWrapper2>
                <ItemWrapper2>
                    <PeopleAltOutlinedIcon fontSize="small" sx={{ color: primaryColor }} />
                    <Body2 fontWeight={500}>Guests:</Body2>
                    <Body2>{calcGuestsCount()}</Body2>
                </ItemWrapper2>
                {/* NOTE: hidden in v1
                <ItemWrapper2 style={{ cursor: 'pointer' }} onClick={() => setIsAssignDrawerOpen(true)}>
                    <PersonAddAltOutlinedIcon fontSize="small" sx={{ color: primaryColor }} />
                    <Body2 fontWeight={500} color={EColors.bizlyOSPrimary}>
                        Assign
                    </Body2>
                </ItemWrapper2> */}
            </Box>
            {/* NOTE: hidden in v1
            <AssignInquirySideDrawer
                drawerOpen={isAssignDrawerOpen}
                onClose={() => setIsAssignDrawerOpen(false)}
                onInviteColleagueClicked={onInviteColleagueClicked}
            />
            <InviteStaffDrawer
                drawerOpen={inviteColleagueOpen}
                setDrawerOpen={onSetInviteColleagueOpen}
                addMultiple
                goBackEnabled
            /> */}
        </>
    );
};
