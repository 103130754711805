import { zodResolver } from '@hookform/resolvers/zod';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import RichTextEditorField from 'components/BizlyOS/InputFields/RichTextEditorField';
import SelectField from 'components/BizlyOS/InputFields/SelectField';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { useInquiryRejectionReasons } from 'hooks/queries/BizlyOS/useInquiryActions';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { EColors, getColor } from 'theme';
import { z } from 'zod';

const declineSchema = z.object({
    cancellationReason: z.string().min(1, { message: 'Please select a reason' }),
    cancellationNotes: z.string().min(1, { message: 'Please provide a message to the client' }),
});

type DeclineFormData = z.infer<typeof declineSchema>;

interface DeclineInquiryFormProps {
    onSubmit: (data: DeclineFormData) => void;
    onCancel: () => void;
    isLoading?: boolean;
    defaultValues?: Partial<DeclineFormData>;
}

export const WithdrawProposal = ({ onSubmit, onCancel, defaultValues = {} }: DeclineInquiryFormProps) => {
    const { data: rejectionReasonsData, isLoading: isReasonsLoading } = useInquiryRejectionReasons();

    const DECLINE_REASONS = useMemo(
        () =>
            rejectionReasonsData?.inquiryRejectionReasons?.map(({ reason, id }) => ({
                value: reason,
                label: reason,
                id,
            })) || [],
        [rejectionReasonsData]
    );

    const { control, handleSubmit, watch } = useForm<DeclineFormData>({
        resolver: zodResolver(declineSchema),
        mode: 'onSubmit',
        defaultValues: {
            cancellationReason: '',
            cancellationNotes: '',
            ...defaultValues,
        },
    });

    const formValues = watch();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" flexDirection="column" gap={1.25}>
                <Box display="flex" alignItems="center" gap={1.25}>
                    <CancelIcon sx={{ color: getColor(EColors.warningText) }} />
                    <Body1 fontWeight={600} sx={{ color: getColor(EColors.warningText) }}>
                        Withdraw Proposal?
                    </Body1>
                </Box>

                <Body2>
                    We recommend messaging the client first if there are any concerns. Once withdrawn, your proposal
                    cannot be reinstated and the client will be informed.
                </Body2>

                <Controller
                    name="cancellationReason"
                    control={control}
                    render={({ field }) => (
                        <SelectField
                            label="Reason"
                            placeholder="Select a reason"
                            options={DECLINE_REASONS}
                            {...field}
                            required
                            disabled={isReasonsLoading}
                        />
                    )}
                />

                <Controller
                    name="cancellationNotes"
                    control={control}
                    render={({ field }) => (
                        <RichTextEditorField {...field} placeholder="Write..." label="Message to client" required />
                    )}
                />

                <Box display="flex" flexDirection="column" gap={2}>
                    <Button
                        fullWidth
                        sx={{ height: 40 }}
                        variant="primary"
                        color="error"
                        type="submit"
                        disabled={!formValues.cancellationReason || !formValues.cancellationNotes}
                    >
                        Withdraw Bid
                    </Button>
                    <Button fullWidth variant="outline" sx={{ height: 40 }} onClick={onCancel}>
                        Go Back
                    </Button>
                </Box>
            </Box>
        </form>
    );
};
