import { Box, styled } from '@mui/material';
import RadioGroup from 'components/BizlyOS/InputFields/RadioGroup';
import { Switch } from 'components/BizlyOS/InputFields/SwitchField';
import { Body1 } from 'components/BizlyOS/Typography/Typography';
import { TFBOption, TFBRequest } from 'components/ProposalForm/EventSpacesForm/utils';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { BorderedContainer, Description, EventSpaceFormProps, Title } from './utils';

const RECEPTION_DINING_STYLE_ID = 3;

const List = styled(Box)({
    '& > :last-child': {
        borderBottom: 'none',
        paddingBottom: 0,
    },
});

const OptionContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
    padding: theme.spacing(2.5, 0),
    borderBottom: `0.5px solid ${theme.getColor(theme.EColors.bizlyOSBorder)}`,
}));

type FBOptionItemProps = {
    fbOption: TFBOption;
    requestedFb: TFBRequest;
    proposedFb: TFBRequest[];
    onChange: (newProposedFb: TFBRequest[]) => void;
    diningStyleOptions: Array<{ label: string; value: number }>;
    error?: string;
};

const FBOptionItem = ({
    fbOption,
    requestedFb,
    proposedFb,
    onChange,
    diningStyleOptions,
    error,
}: FBOptionItemProps) => {
    const currentProposed = proposedFb.find(pFb => pFb.fbOptionId === fbOption.id);

    const handleSwitchChange = (checked: boolean) => {
        const newProposedFb = checked
            ? [...proposedFb, { fbOptionId: fbOption.id, diningStyleId: requestedFb.diningStyleId }]
            : proposedFb.filter(fb => fb.fbOptionId !== fbOption.id);

        onChange(newProposedFb);
    };

    const handleDiningStyleChange = (value: number | string | boolean) => {
        if (typeof value !== 'number') return;
        const newProposedFb = proposedFb.map(fb =>
            fb.fbOptionId === fbOption.id ? { ...fb, diningStyleId: value } : fb
        );
        onChange(newProposedFb);
    };

    return (
        <OptionContainer key={fbOption.id}>
            <Box display="flex" alignItems="center" gap={1.25}>
                <img src={fbOption.iconUrl} alt={fbOption.name} width="20px" />
                <Body1 flex={1}>{fbOption.name}</Body1>
                <Switch
                    checked={!!currentProposed}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSwitchChange(e.target.checked)}
                />
            </Box>
            {fbOption.hasDiningStyle && currentProposed && (
                <RadioGroup
                    value={currentProposed.diningStyleId ?? 0}
                    onChange={handleDiningStyleChange}
                    columns={2}
                    error={error}
                    options={diningStyleOptions}
                />
            )}
        </OptionContainer>
    );
};

export const FoodAndBeverage = ({ control, eventSpace, proposalInquiry, errors }: EventSpaceFormProps) => {
    const diningStyleOptions = useMemo(
        () =>
            proposalInquiry.options.diningStyles
                .filter(diningStyle => diningStyle.id !== RECEPTION_DINING_STYLE_ID)
                .map(diningStyle => ({
                    label: diningStyle.name,
                    value: diningStyle.id,
                })),
        [proposalInquiry.options.diningStyles]
    );

    if (!eventSpace?.requestedFb?.length) return null;

    return (
        <BorderedContainer>
            <Title>Food & Beverage</Title>
            <Description sx={{ mb: 0 }}>
                The client is requesting the F&B below. Select what you can accommodate.
            </Description>

            <Controller
                name="proposedFb"
                control={control}
                render={({ field }) => (
                    <List>
                        {eventSpace?.requestedFb?.map(requestedFb => {
                            const fbOption = proposalInquiry.options.fbOptions.find(
                                fb => fb.id === requestedFb.fbOptionId
                            );

                            return fbOption ? (
                                <FBOptionItem
                                    key={fbOption.id}
                                    fbOption={fbOption}
                                    requestedFb={requestedFb}
                                    proposedFb={field.value}
                                    onChange={field.onChange}
                                    diningStyleOptions={diningStyleOptions}
                                    error={errors.proposedFb?.message}
                                />
                            ) : null;
                        })}
                    </List>
                )}
            />
        </BorderedContainer>
    );
};
