import { StaffMemberType, StaffRoleSchema } from 'hooks/queries/BizlyOS/useStaffQuery';
import { BIZLYOS_CONTACT_TYPES, DEPARTMENT_OPTIONS } from 'shared';
import { z } from 'zod';
export const CREATE_ERROR_MESSAGE = 'Something went wrong.  Please try again';
export const PERMISSION_NOT_SELECTED_VALUE = -1;
export const EMPTY_STATE = '';

export const STAFF_DEFAULT_VALUE = {
    firstName: '',
    lastName: '',
    email: '',
    permission: PERMISSION_NOT_SELECTED_VALUE,
    contactType: '',
    department: '',
    receiveEmails: true,
};

export const STAFF_FORM_DEFAULT_VALUES = {
    forms: [STAFF_DEFAULT_VALUE],
};

export const StaffSchema = z.object({
    firstName: z.string().min(1, 'First name is required'),
    lastName: z.string().min(1, 'Last name is required'),
    email: z.string().email('Invalid email address'),
    permission: z.number().min(1, 'Permission is required'),
    contactType: z.string().min(1, 'Contact type is required'),
    department: z.string().min(1, 'Department is required'),
    receiveEmails: z.boolean(),
});

export const StaffFormSchema = z.object({
    forms: z.array(StaffSchema),
});

export type StaffForm = z.infer<typeof StaffSchema>;

export type StaffFormsData = z.infer<typeof StaffFormSchema>;

export type StaffRole = z.infer<typeof StaffRoleSchema>;

export function getFilledStaffObject(staffMember: StaffMemberType, prmissionRoles: StaffRole[]) {
    const role = prmissionRoles.find(role => role.name === staffMember?.role);
    const contactType = BIZLYOS_CONTACT_TYPES.find(type => type.id === staffMember?.contactType);
    const department = DEPARTMENT_OPTIONS.find(department => department.name === staffMember?.department);

    return {
        firstName: staffMember.firstName,
        lastName: staffMember.lastName,
        email: staffMember.email,
        permission: role?.id || PERMISSION_NOT_SELECTED_VALUE,
        contactType: contactType?.id || '',
        department: department?.id || '',
        receiveEmails: staffMember.notifications.inquiries,
    };
}

export const formatStaffMember = (staffMember: StaffForm) => ({
    first_name: staffMember.firstName,
    last_name: staffMember.lastName,
    email: staffMember.email,
    phone: EMPTY_STATE,
    role_id: staffMember.permission,
    title: EMPTY_STATE,
    department: staffMember.department,
    contact_type: staffMember.contactType,
    notifications: {
        inquiries: staffMember.receiveEmails,
    },
});
