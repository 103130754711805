import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { H2Headline } from 'components/Ui-V2/Headline/Headline';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { EColors, getColor } from 'theme';
import { HEADER_HEIGHT } from 'utils/header';

const Container = styled(Box)`
    width: 38rem;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const DrawerHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25rem;
    box-sizing: border-box;
    height: ${HEADER_HEIGHT}px;
    transition: 0.3s all ease-in-out;

    ${({ theme: { getColor, EColors } }) => `
        background: ${getColor(EColors.pureWhite)};
        border-bottom: 1px solid ${getColor(EColors.grey)};
    `}
`;

const DrawerBody = styled(Box)`
    padding: 1.25rem;
    box-sizing: border-box;
    flex: 1;
    overflow-y: auto;
`;

const DrawerFooter = styled(Box)`
    padding: 1.25rem;
    box-sizing: border-box;

    ${({ theme: { getColor, EColors } }) => `
        background: ${getColor(EColors.pureWhite)};
        box-shadow: 0 1px 5px ${getColor(EColors.grey)};
    `}
`;

type SideDrawerProps = PropsWithChildren<{
    drawerOpen: boolean;
    title?: string;
    footer?: React.ReactNode;
    invisibleBackdrop?: boolean;
    onClose?: () => void;
}>;

export const SideDrawer = ({
    drawerOpen,
    title,
    children,
    footer,
    invisibleBackdrop = false,
    onClose,
}: SideDrawerProps) => {
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    return (
        <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={handleClose}
            slotProps={{ backdrop: { invisible: invisibleBackdrop } }}
        >
            <Container>
                <DrawerHeader>
                    <H2Headline>{title}</H2Headline>
                    <IconButton onClick={() => handleClose()}>
                        <CloseIcon sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                    </IconButton>
                </DrawerHeader>
                <DrawerBody>{children}</DrawerBody>
                {footer && <DrawerFooter>{footer}</DrawerFooter>}
            </Container>
        </Drawer>
    );
};
