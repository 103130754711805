import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Box, Menu, MenuItem, MenuProps, Skeleton, styled } from '@mui/material';
import { Button } from 'components/Ui-V2/Button/Button';
import { WEBREGISTRATION_PREVIEW_IDS, WebRegistrationPreviewIdsType } from 'constants/event';
import {
    useCreateParcelsQuestionsMutation,
    useGetParcelsQuestionsQuery,
    useRemoveParcelsQuestionsMutation,
    useUpdateParcelsQuestionsMutation,
} from 'hooks/queries/useEventsQuery';
import { compact } from 'lodash';
import React, { useEffect, useState } from 'react';
import { QUESTION_TYPES, QuestionField, QuestionTypes, getNewUnsavedId, isIdUnsaved } from '../../utills';
import QuestionsList from './QuestionsList';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme: { getColor, EColors, spacing } }) => {
    return {
        '& .MuiPaper-root': {
            borderRadius: 4,
            marginTop: spacing(1),
            minWidth: 180,
            color: getColor(EColors.pureBlack, 0.8),
            boxShadow: `${getColor(EColors.pureWhite)} 0px 0px 0px 0px, ${getColor(EColors.pureBlack, 0.05)} 0px 0px 0px 1px, ${getColor(EColors.pureBlack, 0.1)} 0px 10px 15px -3px, ${getColor(EColors.pureBlack, 0.05)} 0px 4px 6px -2px`,
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: getColor(EColors.inactiveAction),
                    marginRight: spacing(1.5),
                },
                '&:active': {
                    backgroundColor: getColor(EColors.inactiveAction),
                },
            },
        },
    };
});

type AddQuestionsProps = {
    title: string;
    parcelId: number;
    disabled?: boolean;
    scrollToElement: (type: WebRegistrationPreviewIdsType) => void;
};

function AddQuestions({ title, parcelId, disabled = false, scrollToElement }: AddQuestionsProps) {
    const { data: questions, isLoading } = useGetParcelsQuestionsQuery(parcelId);
    const updateParcelsQuestionsMutation = useUpdateParcelsQuestionsMutation(parcelId, onQuestionSaveFinish);
    const createParcelsQuestionsMutation = useCreateParcelsQuestionsMutation(parcelId, onQuestionSaveFinish);
    const removeParcelsQuestionsMutation = useRemoveParcelsQuestionsMutation(parcelId);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [questionFields, setQuestionFields] = useState<QuestionField[]>([]);
    //questionSaveId is there to track currenly edited question
    const [questionSaveId, setQuestionSaveId] = useState<number>();
    const [questionEditId, setQuestionEditId] = useState<number>();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onQuestionAdd = (type: Bizly.QuestionType) => {
        scrollToElement(WEBREGISTRATION_PREVIEW_IDS.rsvp);
        setAnchorEl(null);
        setQuestionFields(currentQuestions => [
            ...currentQuestions,
            {
                id: getNewUnsavedId(),
                type,
                required: false,
                value: '',
                linearScaleValues: { lowValue: 1, highValue: 10, lowLabel: '', highLabel: '' },
                options: ['', ''],
            },
        ]);
    };

    function onQuestionSaveFinish() {
        setQuestionSaveId(undefined);
        setQuestionEditId(undefined);
    }

    const onQuestionSave = (questionField: QuestionField) => {
        scrollToElement(WEBREGISTRATION_PREVIEW_IDS.rsvp);
        setQuestionSaveId(questionField.id);
        const { id, linearScaleValues, ...question } = questionField;
        const formattedQuestion: Bizly.ParcelRsvpQuestion = { ...question };
        if (questionField.type === QUESTION_TYPES.linearScale) {
            formattedQuestion.options = linearScaleValues;
        }
        const isNew = isIdUnsaved(questionField.id);
        if (isNew) {
            createParcelsQuestionsMutation.mutate(formattedQuestion);
        } else {
            formattedQuestion.id = id;
            updateParcelsQuestionsMutation.mutate(formattedQuestion);
        }
    };

    const onQuestionRemove = (index: number) => {
        const questionField: QuestionField = questionFields[index];
        const isNew = isIdUnsaved(questionField.id);

        if (!isNew) {
            removeParcelsQuestionsMutation.mutate(questionField.id);
        }

        const newQuestions = [...questionFields];
        newQuestions.splice(index, 1);
        setQuestionFields(newQuestions);
    };

    useEffect(() => {
        if (questions) {
            const formattedQuestions: QuestionField[] = compact(
                questions.map((question: Bizly.ParcelRsvpQuestion) => {
                    // filter out dietary restrictions
                    if (question.type === QUESTION_TYPES.dietaryRestrictions) return null;

                    const _question = {
                        id: question.id,
                        type: question.type,
                        required: question.required,
                        value: question.value,
                        linearScaleValues: { lowValue: 1, highValue: 10, lowLabel: '', highLabel: '' },
                        options: question.options,
                    };

                    if (typeof question.options === 'object' && question.options !== null) {
                        _question.linearScaleValues = question.options as Bizly.LinearScaleValuesType;
                    }

                    return _question;
                })
            );
            setQuestionFields(formattedQuestions);
        }
    }, [questions]);

    if (isLoading) {
        return <Skeleton variant="rounded" width="100%" height={126} />;
    }

    return (
        <Box>
            <QuestionsList
                questionFields={questionFields}
                questionSaveId={questionSaveId}
                questionEditId={questionEditId}
                onQuestionSave={onQuestionSave}
                onQuestionRemove={onQuestionRemove}
                setQuestionFields={setQuestionFields}
                setQuestionEditId={setQuestionEditId}
            />
            <Button
                variant="text"
                onClick={handleClick}
                startIcon={<AddCircleOutlineOutlinedIcon />}
                disabled={disabled}
                sx={{ fontSize: '1rem' }}
            >
                {title}
            </Button>
            <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                {QuestionTypes.map(questionType => (
                    <MenuItem key={questionType.type} onClick={() => onQuestionAdd(questionType.type)} disableRipple>
                        {questionType.name}
                    </MenuItem>
                ))}
            </StyledMenu>
        </Box>
    );
}

export default AddQuestions;
