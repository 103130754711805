import { isEmptyString } from 'components/ProposalForm/utils';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import { getSurveyQuestionError, getSurveyQuestionErrorMessage } from 'pages/Survey/helper';
import { i18n } from 'translation';
import { questionDefinitionIds } from '../../api';

const isNullOrEmptyString = (strOrNull: string | null) => strOrNull === null || isEmptyString(strOrNull);

export const getAttendeeBaseError = (attendee: Bizly.EventAttendee) => {
    const { firstName, lastName, phone, activeUser } = attendee;

    // If the user is not logged in, we only need to validate the first and last name
    if (!activeUser) {
        if (isNullOrEmptyString(firstName)) return 'First name is required.';
        if (isNullOrEmptyString(lastName)) return 'Last name is required.';

        return false;
    }

    if (isNullOrEmptyString(firstName)) return 'First name is required.';
    if (isNullOrEmptyString(lastName)) return 'Last name is required.';
    if (isNullOrEmptyString(phone)) return 'Phone number is required.';

    return false;
};

export const getAttendeeDefaultsError = (
    attendee: Bizly.EventAttendee & {
        attendanceType?: string;
    },
    formSettings: Bizly.FormSetting
) => {
    const enabledDefaultsById = keyBy(formSettings.defaultFieldsEnabled, 'id');
    const { attendanceType: attendanceTypeId } = questionDefinitionIds;

    const { attendanceType } = attendee;

    if (attendanceType && enabledDefaultsById[attendanceTypeId] && isNullOrEmptyString(attendanceType))
        return 'Attendance type is required.';

    return false;
};

export const getAttendeeCustomQuestionsErrorMessage = (
    attendee: Bizly.EventAttendee & {
        customQuestionResponses?: Array<Bizly.SurveyQuestion>;
    }
) => {
    return getSurveyQuestionErrorMessage(
        attendee.customQuestions,
        isEmpty(attendee.customQuestionResponses) ? attendee.customQuestions : attendee.customQuestionResponses
    );
};

export const getAttendeeCustomQuestionsError = (
    attendee: Bizly.EventAttendee & {
        customQuestionResponses?: Array<Bizly.SurveyQuestion>;
    }
) => {
    return getSurveyQuestionError(
        attendee.customQuestions,
        isEmpty(attendee.customQuestionResponses) ? attendee.customQuestions : attendee.customQuestionResponses
    );
};

export const getAttendeeRoomBlockErrorMessge = (attendee: Bizly.EventAttendee) => {
    if (attendee.checkInDate && attendee.checkOutDate) {
        if (!attendee.preferredRoomType) {
            return i18n.communication.validationError.preferredRoomType;
        }
    }
};
