import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { getEventPlannerData } from 'api';
import { Button } from 'components/Ui-V2/Button/Button';
import { Spacer } from 'components/Ui-V2/Spacer/Spacer';
import FourBedsSvg from 'images/icons/four-beds.svg?react';
import HiveSvg from 'images/icons/hive.svg?react';
import OrgChartSvg from 'images/icons/org-chart.svg?react';
import RedCarpetSvg from 'images/icons/red-carpet.svg?react';
import { useEvent } from 'providers/event';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { BizlyMuiTheme } from 'types/cssprop';
import { AlignedRow, Copy } from 'ui';
import { tzMoment } from 'utils/moment';

const DetailsContainer = styled(Stack)`
    padding: 20px;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.greyBorder)};
    border-radius: 4px;
`;

const ItemLabel = styled(Copy)`
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.5)};
    display: inline-flex;
    gap: 3px;
`;

const ItemContent = styled(AlignedRow)`
    font-size: 1rem;
    font-weight: 400;
    border: none;
    padding: 0;
    border-radius: 5px;
    box-sizing: border-box;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};
`;

const Title = styled(Copy)`
    font-size: 1rem;
    font-weight: 600;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};
`;

const Section = styled(Box)<{ first?: boolean }>`
    ${({ theme: { getColor, EColors }, first }) =>
        !first &&
        `
            border-top: 1px solid ${getColor(EColors.greyBorder)};
        `}
`;

const SectionTitle = styled(Title)`
    font-weight: 500;
`;

const Description = styled.div`
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};
`;

type TDetailItem = {
    label: string;
    value: ReactNode;
    isRequired?: boolean;
    muiIcon?: typeof SvgIcon;
    icon?: ReactNode;
};

export const DetailItem = ({ label, value, isRequired, icon, muiIcon: MuiIcon }: TDetailItem) => {
    const { getColor, EColors } = useTheme<BizlyMuiTheme>();
    return (
        <Stack direction="row" gap="10px">
            {icon && icon}
            {MuiIcon && <MuiIcon sx={{ color: getColor(EColors.primaryAction), width: 20, height: 20 }} />}
            <Stack gap="10px">
                <ItemLabel>
                    {label}
                    {isRequired && (
                        <Typography fontSize="14px" component="span" color="grey">
                            ({i18n.common.required})
                        </Typography>
                    )}
                </ItemLabel>
                <ItemContent>{value}</ItemContent>
            </Stack>
        </Stack>
    );
};

const EMPTY_VALUE = 'N/A';

export default function EventDetails() {
    const { event } = useEvent();

    const [planner, setPlanner] = useState<BizlyAPI.Planner>({});

    const timeFormat = 'MMM DD, YYYY - h:mm A';
    const startsAt = tzMoment(event.startsAt).format(timeFormat);
    const endsAt = tzMoment(event.endsAt).format(timeFormat);
    let budget = event.budget;
    if (!budget) {
        if (event.budgetPerPerson && event.estAttendance) {
            budget = Number(event.budgetPerPerson) * event.estAttendance;
        }
    }

    const loadPlanner = useCallback(async () => {
        const { planner = {} } = await getEventPlannerData(event.id);
        setPlanner(planner);
    }, [event]);

    useEffect(() => {
        loadPlanner();
    }, [loadPlanner]);

    const guestroomsCount = useMemo(
        () => planner.accommodations?.reduce((acc, curr) => acc + (curr.count || 0), 0) ?? 0,
        [planner]
    );
    const spaceCount = useMemo(() => planner.eventSpaces?.filter(space => space.date).length ?? 0, [planner]);

    return (
        <DetailsContainer gap={2.5}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Title>{event.name}</Title>
                <Button onClick={() => {}} variant="text">
                    {i18n.button.edit}
                </Button>
            </Stack>

            <Section first>
                <Grid container spacing={2.5}>
                    <Grid item xs={12}>
                        <DetailItem
                            label={i18n.homepage.createMeetingModal.location}
                            value={event.location ?? EMPTY_VALUE}
                            muiIcon={LocationOnOutlinedIcon}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DetailItem
                            muiIcon={CalendarTodayOutlinedIcon}
                            label={i18n.meetingsPage.eventStart}
                            value={startsAt}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DetailItem
                            muiIcon={CalendarTodayOutlinedIcon}
                            label={i18n.meetingsPage.eventEnd}
                            value={endsAt}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DetailItem
                            muiIcon={PeopleOutlinedIcon}
                            label={i18n.meetingsPage.attendeeCount}
                            value={event.estAttendance ?? 0}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DetailItem
                            label={i18n.meetingsPage.totalBudget}
                            value={Number(budget).toLocaleString('en-US')}
                            muiIcon={AttachMoneyIcon}
                        />
                    </Grid>
                </Grid>
            </Section>

            <Section>
                <Grid container spacing={2.5}>
                    <Grid item xs={12}>
                        <Spacer smallish />
                        <SectionTitle>{i18n.meetingsPage.spaceRequirements}</SectionTitle>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DetailItem
                            label={i18n.meetingsPage.noOfGuestrooms}
                            value={guestroomsCount}
                            icon={<FourBedsSvg />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DetailItem label={i18n.meetingsPage.noOfSpaces} value={spaceCount} icon={<RedCarpetSvg />} />
                    </Grid>
                </Grid>
            </Section>

            <Section>
                <Spacer smallish />
                <Stack gap={2.5}>
                    <SectionTitle>{i18n.meetingsPage.eventDescription}</SectionTitle>
                    <Description dangerouslySetInnerHTML={{ __html: event.description || EMPTY_VALUE }} />
                </Stack>
            </Section>

            <Section>
                <Grid container spacing={2.5}>
                    <Grid item xs={12}>
                        <Spacer smallish />
                        <SectionTitle>{i18n.meetingsPage.admin}</SectionTitle>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DetailItem
                            label={i18n.meetingsPage.meetingType}
                            value={event.type ?? EMPTY_VALUE}
                            icon={<HiveSvg />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DetailItem
                            label={i18n.meetingsPage.department}
                            value={event.department ?? EMPTY_VALUE}
                            icon={<OrgChartSvg />}
                        />
                    </Grid>
                </Grid>
            </Section>
        </DetailsContainer>
    );
}
