import { Box, styled, Typography } from '@mui/material';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers-pro';
import moment, { Moment } from 'moment';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { EColors, getColor } from 'theme';
import { tzMoment } from 'utils/moment';

const StyledTimePicker = styled(MuiTimePicker, {
    shouldForwardProp: prop => prop !== 'iconColor' && prop !== 'showEmptyError' && prop !== 'error',
})(({ iconColor, disabled, theme: { getColor, EColors }, showEmptyError, error }) => ({
    boxSizing: 'border-box',
    width: '100%',
    height: '50px',
    borderRadius: '4px',
    '& .MuiInputBase-root > input': {
        fontSize: '1rem',
        padding: '13.5px',
    },
    '& .MuiInputBase-root > .MuiOutlinedInput-notchedOutline': {
        borderRadius: '4px',
        ...(showEmptyError || error ? { borderColor: getColor(EColors.red) } : {}), // Disabling the default error indicator
    },
    '& .MuiInputBase-root .MuiIconButton-root': {
        color: disabled ? getColor(EColors.inactiveAction) : iconColor,
    },
    '& .MuiInputBase-root .MuiIconButton-root > .MuiSvgIcon-root': {
        fontSize: '1.25rem',
    },
}));

type ImageInputProps = {
    label?: string;
    timeZone?: string;
    error: FieldError | undefined;
    showEmptyError?: boolean;
    onChange?: (time: string) => void;
    value: string | undefined;
    disabled?: boolean | undefined;
    required?: boolean;
    iconColor?: string;
    onAccept?: (time: string) => void;
};

const dateToday = moment().format('YYYY-MM-DD');

const TimePicker = React.forwardRef<HTMLDivElement, ImageInputProps>(
    (
        {
            label,
            error,
            showEmptyError = false,
            required = false,
            value: time,
            disabled,
            iconColor = getColor(EColors.primaryAction),
            timeZone = 'UTC',
            onChange,
            onAccept,
        },
        ref
    ) => {
        return (
            <Box>
                {label && (
                    <Box display="flex" gap={0.5} marginBottom={1}>
                        <Typography fontWeight={500}>{label}</Typography>

                        <Typography color={getColor(EColors.pureBlack, 0.5)} fontWeight={500}>
                            {required ? '(required)' : ''}
                        </Typography>
                    </Box>
                )}
                <StyledTimePicker
                    iconColor={iconColor}
                    disabled={disabled}
                    value={time ? tzMoment(`${dateToday}T${time}`, timeZone) : null}
                    onChange={(value: Moment) => {
                        onChange?.(value.format('HH:mm:ss'));
                    }}
                    onAccept={(value: Moment) => {
                        onAccept?.(value.format('HH:mm:ss'));
                    }}
                    ref={ref}
                    error={!!error}
                    showEmptyError={showEmptyError && !time}
                />

                {!!error && (
                    <Typography variant="body2" color={getColor(EColors.red)} fontWeight={500}>
                        {error.message}
                    </Typography>
                )}
            </Box>
        );
    }
);

export default TimePicker;
