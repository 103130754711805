import { styled, Switch } from '@mui/material';

export const OnOffoSwitch = styled(Switch)(({ theme: { EColors, getColor } }) => ({
    width: 66,
    height: 32,
    padding: 2,
    '& .MuiSwitch-switchBase': {
        transform: 'translateX(1px) translateY(-2px)',
        color: getColor(EColors.inactiveAction),
        '&.Mui-checked': {
            transform: 'translateX(28px) translateY(-2px)',
            color: getColor(EColors.primaryAction),
            '& + .MuiSwitch-track': {
                backgroundColor: getColor(EColors.pureWhite),
                opacity: 1,
                border: '1px solid ' + getColor(EColors.primaryAction),
            },
        },
    },
    '& .MuiSwitch-thumb': {
        width: 20,
        height: 20,
        boxShadow: 'none',
    },
    '& .MuiSwitch-track': {
        borderRadius: 34 / 2,
        backgroundColor: getColor(EColors.pureWhite),
        opacity: 1,
        border: '1px solid ' + getColor(EColors.inactiveAction),
        '&::before': {
            content: '"On"',
            color: getColor(EColors.pureBlack),
            fontSize: '13px',
            position: 'absolute',
            left: 12,
            top: 9,
        },
        '&::after': {
            content: '"Off"',
            color: getColor(EColors.pureBlack),
            fontSize: '13px',
            position: 'absolute',
            top: 10,
            left: 37,
        },
    },
}));

export const YesNoSwitch = styled(Switch)(({ theme: { EColors, getColor } }) => ({
    width: 66,
    height: 32,
    padding: 2,
    '& .MuiSwitch-switchBase': {
        transform: 'translateX(1px) translateY(-2px)',
        color: getColor(EColors.inactiveAction),
        '&.Mui-checked': {
            transform: 'translateX(28px) translateY(-2px)',
            color: getColor(EColors.primaryAction),
            '& + .MuiSwitch-track': {
                backgroundColor: getColor(EColors.pureWhite),
                opacity: 1,
                border: '1px solid ' + getColor(EColors.primaryAction),
            },
        },
    },
    '& .MuiSwitch-thumb': {
        width: 20,
        height: 20,
        boxShadow: 'none',
    },
    '& .MuiSwitch-track': {
        borderRadius: 34 / 2,
        backgroundColor: getColor(EColors.pureWhite),
        opacity: 1,
        border: '1px solid ' + getColor(EColors.inactiveAction),
        '&::before': {
            content: '"Yes"',
            color: getColor(EColors.pureBlack),
            fontSize: '12px',
            letterSpacing: '-0.2px',
            position: 'absolute',
            left: 10.4,
            top: 10,
        },
        '&::after': {
            content: '"No"',
            color: getColor(EColors.pureBlack),
            fontSize: '12px',
            position: 'absolute',
            top: 10,
            left: 38,
        },
    },
}));
