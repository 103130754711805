import { numericSchema } from 'components/BizlyOS/Proposals/utils';
import { TCurrency, TGRBooking, TProposal } from 'components/ProposalForm/types';
import { z } from 'zod';

const ImageSchema = z.object({
    id: z.string(),
    srcUrl: z.string().url({ message: 'Must be a valid URL' }),
    name: z.string(),
});

export const RoomBlockSchema = z.object({
    proposedRoomRate: numericSchema({ min: 1, minMessage: 'Value must be greater than zero' }),
    currencyCode: z.string().min(1, { message: 'Currency code is required' }),
    proposedRoomCount: numericSchema({ min: 1, minMessage: 'Value must be greater than zero' }),
    images: z.array(ImageSchema).min(1, { message: 'At least one image is required' }),
    copyToNextDay: z.boolean().optional(),
    copyToAllDays: z.boolean().optional(),
});

export type RoomBlockFormData = z.infer<typeof RoomBlockSchema>;

type GenarateUpdatedRoomBlockProps = {
    data: RoomBlockFormData;
    index: number;
    guestRooms: TGRBooking[];
    proposal:
        | (Partial<TProposal> & {
              currencyCode?: string;
              currencyId?: number;
          })
        | undefined;
    currencies?: TCurrency[];
};

export const genarateUpdatedRoomBlockData = ({
    data,
    index,
    guestRooms,
    proposal,
    currencies = [],
}: GenarateUpdatedRoomBlockProps) => {
    const newGuestRooms = [];

    // Update current room
    newGuestRooms.push({
        ...guestRooms[index],
        proposedRoomCount: data.proposedRoomCount,
        proposedRoomRate: data.proposedRoomRate,
        proposedImageUrl: data.images[0].srcUrl,
    });

    // Apply changes to other rooms if needed
    if (data.copyToAllDays) {
        // Copy to all other days
        guestRooms.forEach((room, i) => {
            if (i === index) return; // Skip current room
            newGuestRooms.push({
                ...room,
                proposedRoomCount: room.requestedGuests || 0,
                proposedRoomRate: data.proposedRoomRate,
                proposedImageUrl: data.images[0].srcUrl,
            });
        });
    } else if (data.copyToNextDay && index + 1 < guestRooms.length) {
        // Copy only to next day
        newGuestRooms.push({
            ...guestRooms[index + 1],
            proposedRoomCount: guestRooms[index + 1].requestedGuests || 0,
            proposedRoomRate: data.proposedRoomRate,
            proposedImageUrl: data.images[0].srcUrl,
        });
    }

    // Find currency and update proposal
    const currency = currencies.find(c => c.code === data.currencyCode);
    const newProposal = {
        ...proposal,
        currencyCode: data.currencyCode,
        currencyId: currency?.id as number,
    };

    return {
        newGuestRooms,
        newProposal,
    };
};
