import { Box, Input, InputProps, MenuItem, Select, styled } from '@mui/material';
import TextField from 'components/Ui-V2/InputFields/TextField';
import { QuestionField } from '../../utills';

const NUMBERS_ARRAY = Array.from({ length: 10 }, (_, i) => i + 1);

const StyledInputField = styled(Input)<InputProps & { error: boolean }>(({ theme: { getColor, EColors }, error }) => ({
    flex: 1,
    boxSizing: 'border-box',
    width: '100%',
    fontSize: '1rem',
    border: '1px solid',
    borderColor: error ? getColor(EColors.red) : getColor(EColors.inactiveAction),
    borderRadius: '4px',
    padding: '9px',
    color: getColor(EColors.pureBlack),
    '& .MuiInput-input': {
        padding: 0,
    },
    '& .MuiInput-input:focus': {
        outline: 'none',
        WebkitBoxShadow: 'none',
        boxShadow: 'none',
    },
    '& .MuiInput-input:focus::placeholder': {
        color: getColor(EColors.pureBlack),
        opacity: 0.5,
    },
    '& .MuiInput-input::placeholder': {
        color: error ? getColor(EColors.red) : getColor(EColors.pureBlack),
    },
}));

const StyledSelect = styled(Select)(({ theme: { getColor, EColors, shape } }) => ({
    height: '40px',
    width: '70px',
    fontSize: '0.875rem',
    '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid ' + getColor(EColors.inactiveAction),
        borderRadius: shape.borderRadius,
    },
}));

type QuestionTextProps = {
    onQuestionTextChange: (text: string, index: number) => void;
    questionField: QuestionField;
    index: number;
    onQuestionValueChange: <K extends keyof Bizly.LinearScaleValuesType>(
        field: K,
        value: Bizly.LinearScaleValuesType[K],
        index: number
    ) => void;
};

function QuestionLinearScale({ index, questionField, onQuestionTextChange, onQuestionValueChange }: QuestionTextProps) {
    return (
        <>
            <TextField
                name=""
                onChange={e => onQuestionTextChange(e.target.value, index)}
                value={questionField.value}
                error={undefined}
                placeholder="Question"
            />

            <Box display="flex" alignItems="center" gap={1.25}>
                <StyledSelect
                    label=""
                    value={questionField.linearScaleValues?.lowValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onQuestionValueChange('lowValue', Number(e.target.value), index)
                    }
                >
                    {NUMBERS_ARRAY.map(number => (
                        <MenuItem key={number} value={number}>
                            {number}
                        </MenuItem>
                    ))}
                </StyledSelect>

                <StyledInputField
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onQuestionValueChange('lowLabel', e.target.value, index);
                    }}
                    value={questionField.linearScaleValues?.lowLabel || ''}
                    error={false}
                    placeholder="Option 1"
                    disableUnderline
                />
            </Box>

            <Box display="flex" alignItems="center" gap={1.25}>
                <StyledSelect
                    label=""
                    value={questionField.linearScaleValues?.highValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onQuestionValueChange('highValue', Number(e.target.value), index)
                    }
                >
                    {NUMBERS_ARRAY.map(number => (
                        <MenuItem key={number} value={number}>
                            {number}
                        </MenuItem>
                    ))}
                </StyledSelect>

                <StyledInputField
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onQuestionValueChange('highLabel', e.target.value, index);
                    }}
                    value={questionField.linearScaleValues?.highLabel || ''}
                    error={false}
                    placeholder="Option 1"
                    disableUnderline
                />
            </Box>
        </>
    );
}

export default QuestionLinearScale;
