import { Box } from '@mui/material';
import { getPlace } from 'api';
import GooglePlacesAutocomplete from 'components/BizlyOS/InputFields/PlaceInput';
import ClaimStepContainer from 'components/BizlyOS/ListingsClaim/ClaimStepContainer';
import { Body1, Heading2 } from 'components/BizlyOS/Typography/Typography';
import MirageLoader from 'components/Ui-V2/Loaders/MirageLoader';
import SearchSvg from 'images/bizlyOS/coming-soon/calendar.svg';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { ClaimStepProps } from './ListingsClaim';

export default function SearchVenue(props: ClaimStepProps) {
    const navigate = useNavigate();
    const [venue, setVenue] = useState<string>(props.listingsClaimData.venue?.name || '');
    const [placeId, setPlaceId] = useState<string>(props.listingsClaimData.venue?.placeId || '');

    const locationPlaseId = props.listingsClaimData.venueLocation?.placeId || '';

    const { data, isLoading } = useQuery({
        queryKey: ['google_location', locationPlaseId],
        queryFn: async () => getPlace(locationPlaseId),
        select: data => data?.result?.geometry?.location as Bizly.Location,
    });

    const onChange = (venue: string, placeId: string | undefined) => {
        setVenue(venue);
        setPlaceId(placeId || '');
    };

    const onSuggestionClicked = (textInput: string) => {
        navigate(
            `/os/listings-create?location_place_id=${locationPlaseId}&venue_name=${textInput}&location_lat=${data?.lat}&location_lng=${data?.lng}`
        );
    };

    const onSave = () => {
        props.onNextStepClicked({ venue: { name: venue, placeId } });
    };

    return (
        <ClaimStepContainer {...props} onSave={onSave} disabled={!venue}>
            <Box width="600px">
                <img src={SearchSvg} width={100} height={100} alt="Search Image" />
                <Heading2 marginBottom={1.25}>Let’s find your listing</Heading2>
                <Body1 size="large" marginBottom={2.5}>
                    Search for your venue by name in our database. If you can’t find it, simply apply to list on Bizly!
                </Body1>
                {isLoading ? (
                    <Box height="56px">
                        <MirageLoader color={getColor(EColors.bizlyOSPrimary)} />
                    </Box>
                ) : (
                    <GooglePlacesAutocomplete
                        locationValue={venue}
                        placeId={placeId}
                        onChange={onChange}
                        locationBias={data}
                        inputAsSuggestion
                        suggestionText="Apply to list a venue with this name"
                        onSuggestionClicked={onSuggestionClicked}
                        noOptionIcon
                    />
                )}
            </Box>
        </ClaimStepContainer>
    );
}
