import { InfoOutlined } from '@mui/icons-material';
import { Box, Typography, styled } from '@mui/material';
import { SpinnerOverlay as UISpinnerOverlay } from 'components/Spinner';
import { QueryParamEnum } from 'constants/queryParams';
import { useMaxInquiryVenueSlots } from 'hooks/useMaxInquiryVenueSlots';
import { useEvent } from 'providers/event';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { hasAcceptedProposal, useCurrentInquiry } from 'stores/current-inquiry';
import { padArray } from '../util';
import { Button } from './Ui-V2/Button/Button';
import { Spacer } from './Ui-V2/Spacer/Spacer';
import { REJECTED_STATUSES } from './VenueCard';
import VenueTileV3 from './VenueTile/VenueTileV3';

const SpinnerOverlay = styled(UISpinnerOverlay)`
    z-index: 1300;
`;

const EmptyVenuesMessageContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.625rem;
    padding: 1.25rem;
    border-radius: 4px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.bizlyInsightsBlue, 0.1)};
`;

const InfoIconWrapper = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
`;

const StyledInfoIcon = styled(InfoOutlined)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.bizlyInsightsBlue)};
    font-size: 1.25rem;
`;

const MessageContent = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1;
`;

const MessageTitle = styled(Typography)`
    font-size: 1rem;
    line-height: 1.5em;
    font-weight: 600;
`;

const MessageText = styled(Typography)`
    font-size: 0.875rem;
    line-height: 1.5em;
    font-weight: 400;
`;

const VenueList = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const InquiryCartRightDrawerV2 = ({
    onClose,
    onRemoveVenue,
}: {
    onClose: () => void;
    onRemoveVenue?: (venue: Bizly.Venue) => void;
}) => {
    const { loading, inquiry, venues } = useCurrentInquiry();
    const location = useLocation();
    const navigate = useNavigate();
    const { event } = useEvent();
    const [removingVenueId, setRemovingVenueId] = useState<number | null>(null);
    const maxVenueSlots = useMaxInquiryVenueSlots();

    const validVenues = useMemo(() => (venues || [])?.filter(venue => !REJECTED_STATUSES.has(venue.status)), [venues]);
    const paddedVenues = useMemo(() => padArray(validVenues, maxVenueSlots), [validVenues, maxVenueSlots]);
    const noVenuesAdded = useMemo(() => validVenues.length === 0, [validVenues]);
    const hasOpenSlots = useMemo(() => validVenues.length < maxVenueSlots, [validVenues, maxVenueSlots]);
    const isBooked = hasAcceptedProposal(validVenues);

    const viewVenueListing = (venueId: number) => {
        onClose();
        const params = new URLSearchParams(location.search);
        params.set(QueryParamEnum.VENUE_ID, venueId.toString());
        navigate({
            pathname: location.pathname,
            search: params.toString(),
        });
    };

    const handleRemoveVenue = async (venue: Bizly.Venue) => {
        if (!onRemoveVenue) return;
        setRemovingVenueId(venue.id);
        try {
            await onRemoveVenue(venue);
        } finally {
            setRemovingVenueId(null);
        }
    };

    if (noVenuesAdded) {
        return (
            <EmptyVenuesMessageContainer>
                <InfoIconWrapper>
                    <StyledInfoIcon />
                </InfoIconWrapper>
                <MessageContent>
                    <MessageTitle>No Venues Selected</MessageTitle>
                    <MessageText>
                        Selected venues will appear here. You can shortlist up to {maxVenueSlots} venues.
                    </MessageText>
                </MessageContent>
            </EmptyVenuesMessageContainer>
        );
    }

    return (
        <Box>
            {loading && <SpinnerOverlay />}
            <VenueList>
                {paddedVenues.map(
                    venue =>
                        venue && (
                            <Box key={venue.id} position="relative">
                                {removingVenueId === venue.id && <SpinnerOverlay />}
                                <VenueTileV3
                                    inDrawer
                                    selected={true}
                                    venue={{ ...venue.venue, status: venue.status }}
                                    onClick={viewVenueListing}
                                    onClose={
                                        !inquiry?.submittedAt && event.editable
                                            ? () => handleRemoveVenue(venue)
                                            : undefined
                                    }
                                />
                            </Box>
                        )
                )}
            </VenueList>

            {hasOpenSlots && !isBooked && (
                <>
                    <Spacer small />
                    <Button onClick={onClose} fullWidth variant="outlined" size="large">
                        <Typography>Add more venues</Typography>
                    </Button>
                </>
            )}
        </Box>
    );
};

export default InquiryCartRightDrawerV2;
