import Grid, { GridDirection } from '@mui/material/Grid';
import VenueTileV3 from 'components/VenueTile/VenueTileV3';
import { useMaxInquiryVenueSlots } from 'hooks/useMaxInquiryVenueSlots';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useCallback, useMemo } from 'react';
import { PostHogFeatureFlags } from 'shared';
import { AnimatedVenueTile } from '../VenueTile/AnimatedVenueTile';
import VenueTile, { VenueTileProps } from '../VenueTile/VenueTileV2';

type LayoutConfig = {
    gridDirection: GridDirection;
    gridItemSize: number | undefined;
    tileWidth: string;
    VenueTileVersion: React.ComponentType<VenueTileProps>;
};

type TPaginatedVenuesGrid = {
    data: Bizly.Venue[];
    selectedVenues?: { [venueId: number]: Bizly.Venue };
    onSelect?: (venue: Bizly.Venue) => void;
    onDeselect?: (venue: Bizly.Venue) => void;
    onVenueHover?: (venueId: number | null) => void;
    onVisit?: (venueId: number) => void;
    pinHighlightedVenue?: number;
    hideActions: boolean;
    hasAcceptedProposal?: boolean;
};

export const PaginatedVenuesGrid = ({
    data,
    selectedVenues,
    onSelect,
    onDeselect,
    onVenueHover,
    onVisit,
    pinHighlightedVenue,
    hideActions,
    hasAcceptedProposal,
}: TPaginatedVenuesGrid) => {
    const mouseLeave = useCallback(() => onVenueHover && onVenueHover(null), [onVenueHover]);
    const _selectedVenues = useMemo(
        () => (hideActions || !selectedVenues ? [] : selectedVenues),
        [hideActions, selectedVenues]
    );

    const MAX_INQUIRY_VENUE_COUNT = useMaxInquiryVenueSlots();
    const hasReachedMaxInquiryCount = selectedVenues && Object.keys(selectedVenues).length >= MAX_INQUIRY_VENUE_COUNT;
    const disableSelection = hasAcceptedProposal || hasReachedMaxInquiryCount;
    const disabledToolTipMessage = hasAcceptedProposal
        ? 'Proposal already accepted'
        : hasReachedMaxInquiryCount
          ? `${MAX_INQUIRY_VENUE_COUNT} venues already selected`
          : '';

    const newVenueTileLayout = useFeatureFlagEnabled(PostHogFeatureFlags.toggleNewVenueTileLayout);

    // Consolidate all layout-dependent properties in one place
    const layoutConfig: LayoutConfig = useMemo(
        () => ({
            gridDirection: newVenueTileLayout ? 'column' : 'row',
            gridItemSize: newVenueTileLayout ? 12 : undefined,
            tileWidth: newVenueTileLayout ? '37.5rem' : '16.25em',
            VenueTileVersion: newVenueTileLayout ? VenueTileV3 : VenueTile,
        }),
        [newVenueTileLayout]
    );

    return (
        <Grid container spacing={2.5} direction={layoutConfig.gridDirection}>
            {data.map((v, index) => (
                <Grid xs={layoutConfig.gridItemSize} item key={v.id}>
                    <AnimatedVenueTile index={index}>
                        <layoutConfig.VenueTileVersion
                            venue={v}
                            width={layoutConfig.tileWidth}
                            padding="0.625em"
                            onMouseLeave={mouseLeave}
                            onMouseOver={onVenueHover}
                            highlighted={v.id === pinHighlightedVenue}
                            onClick={onVisit}
                            hideActions={hideActions}
                            disableRejected
                            selectionDisabled={disableSelection}
                            onSelect={onSelect}
                            onDeselect={onDeselect}
                            selected={!!_selectedVenues[v.id]}
                            disabledToolTipMessage={disabledToolTipMessage || ''}
                        />
                    </AnimatedVenueTile>
                </Grid>
            ))}
        </Grid>
    );
};
