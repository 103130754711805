import { Box, Dialog, DialogContent, DialogProps, Slide, styled, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Button, LoadingButton } from 'components/Ui-V2/Button/Button';
import React, { JSXElementConstructor, useState } from 'react';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<unknown, JSXElementConstructor<unknown>>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialog = styled((props: DialogProps) => (
    <Dialog TransitionComponent={Transition} transitionDuration={300} {...props} />
))(({ theme }) => ({ '& .MuiPaper-root': { borderRadius: theme.spacing(1.5) } }));

const StyledDialogContent = styled(DialogContent)(({ theme: { spacing } }) => ({
    boxSizing: 'border-box',
    padding: spacing(5),
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
}));

type UnpublishedConfirmationModalType = {
    open: boolean;
    onClose: () => void;
    onDone: () => void;
    onUnpublish: () => void;
};

function UnpublishedConfirmationModal({ open, onClose, onDone, onUnpublish }: UnpublishedConfirmationModalType) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUnpublishDone, setIsUnpublishDone] = useState<boolean>(false);

    const _onUnpublish = async () => {
        setIsLoading(true);
        await onUnpublish();
        setIsLoading(false);
        setIsUnpublishDone(true);
    };

    const _onClose = () => {
        onClose();
        if (!isUnpublishDone) return;
        onDone();
    };

    return (
        <StyledDialog open={open} onClose={_onClose}>
            <StyledDialogContent>
                {!isUnpublishDone ? (
                    <>
                        <Box display="flex" flexDirection="column" gap={1}>
                            <Typography fontSize="20px" lineHeight="150%" fontWeight={600}>
                                Delete Page?
                            </Typography>
                            <Typography variant="body1">
                                Are you sure you want to delete this registration page? Your network won't be able to
                                register for your event if you proceed.
                            </Typography>
                        </Box>
                        <Box display="flex" flexDirection="column" gap={1.25}>
                            <LoadingButton
                                loading={isLoading}
                                fullWidth
                                onClick={_onUnpublish}
                                variant="contained"
                                color="error"
                                sx={{ height: 40 }}
                            >
                                Delete
                            </LoadingButton>
                            <Button fullWidth onClick={_onClose} variant="outlined" sx={{ height: 40 }}>
                                Close
                            </Button>
                        </Box>
                    </>
                ) : (
                    <>
                        <Typography variant="h6" fontWeight={600}>
                            Registration Page Deleted
                        </Typography>
                        <Typography variant="body1">Your registration page is now deleted.</Typography>
                        <Button fullWidth onClick={_onClose} variant="outlined" sx={{ height: 40 }}>
                            Close
                        </Button>
                    </>
                )}
            </StyledDialogContent>
        </StyledDialog>
    );
}

export default UnpublishedConfirmationModal;
