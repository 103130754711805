import { styled, TabProps, TabsProps } from '@mui/material';
import { SecondaryTab, SecondaryTabs } from 'components/BizlyOS/Tabs';
import TitledContainer from 'components/BizlyOS/TitledContainer';
import { useProposalInquiryStore } from 'providers/proposal-inquiry';
import React, { useState } from 'react';
import { EventSpaces } from './EventSpaces';
import ImportantDetails from './ImportantDetails';
import { Overview } from './Overview';
import { RoomBlock } from './RoomBlock';

const OVERVIEW_ID = 'overview';
const ROOMBLOCK_ID = 'room-block';
const EVENT_SPACE_ID = 'event-space';
const IMPORTANT_DETALS_ID = 'important-detals';

const TabOptions = [
    { id: OVERVIEW_ID, label: 'Overview', Element: Overview },
    { id: ROOMBLOCK_ID, label: 'Room Block', Element: RoomBlock },
    { id: EVENT_SPACE_ID, label: 'Meeting / Event Spaces', Element: EventSpaces },
    { id: IMPORTANT_DETALS_ID, label: 'Important Details', Element: ImportantDetails },
];

const StyledTabs = styled(SecondaryTabs)<TabsProps>(({ theme: { getColor, EColors } }) => ({
    backgroundColor: getColor(EColors.drWhite),
    padding: 0,
    '&.MuiTabs-root': {
        minHeight: 'unset',
        borderBottom: 'none',
    },
}));

const StyledTab = styled(SecondaryTab)<TabProps>({
    '&.MuiTab-root': {
        minHeight: '38px',
        padding: '0px 0px',
    },
});

export default function InquiryOverviewBody() {
    const { eventSpaces, guestRooms, proposal } = useProposalInquiryStore(state => state.proposalInquiryForm);
    const [tabIndex, setTabIndex] = useState(0);

    const visibleTabIds = [
        OVERVIEW_ID,
        ...(eventSpaces?.length ? [EVENT_SPACE_ID] : []),
        ...(guestRooms?.length ? [ROOMBLOCK_ID] : []),
        ...(proposal?.msaDocumentUrl ? [IMPORTANT_DETALS_ID] : []),
    ];

    const handleTabChange = (_: React.SyntheticEvent, newIndex: number) => {
        setTabIndex(newIndex);
    };

    return (
        <TitledContainer
            borderWidth="small"
            leftElement={
                <StyledTabs value={tabIndex} onChange={handleTabChange}>
                    {TabOptions.map(
                        (tab, index) =>
                            visibleTabIds.includes(tab.id) && <StyledTab key={index} label={tab.label} value={index} />
                    )}
                </StyledTabs>
            }
        >
            {React.createElement(TabOptions[tabIndex].Element)}
        </TitledContainer>
    );
}
