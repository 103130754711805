import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, IconButton, styled } from '@mui/material';
import { uploadFile } from 'cloudinary';
import { Body2 } from 'components/BizlyOS/Typography/Typography';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { UploadContent } from './UploadContent';

const EmptyUploadContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    boxSizing: 'border-box',
    minHeight: '112px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing(0.5),
    backgroundColor: getColor(EColors.drWhite),
    border: '1px dashed ' + getColor(EColors.bizlyOSBorder),
    borderRadius: shape.borderRadius,
    padding: spacing(2.5),
}));

const FileContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1.25),
    backgroundColor: getColor(EColors.pureWhite),
    border: '1px solid ' + getColor(EColors.bizlyOSBorder),
    borderRadius: shape.borderRadius,
    padding: spacing(0, 1.25),
}));

const FileName = styled(Body2)(({ theme: { getColor, EColors } }) => ({
    flex: 1,
    '& > a': { color: getColor(EColors.bizlyOSPrimary) },
}));

const AttachIcon = styled(AttachFileIcon)(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.bizlyOSPrimary),
}));

const DeleteFileIcon = styled(DeleteOutlineIcon)(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.bizlyOSPrimary),
}));

type UploadedFile = {
    id: string;
    url: string;
    name: string;
    size: number;
};

type FileInputFieldProps = {
    label: string;
    error: boolean;
    maxFileLimit?: number;
    required?: boolean;
    listingId?: string;
    disabled?: boolean;
    values: UploadedFile[];
    onChange: (files: UploadedFile[]) => void;
};

export function FileInputField({
    maxFileLimit = 6,
    disabled = false,
    values: files,
    onChange: setFiles,
}: FileInputFieldProps) {
    const [isUploading, setIsUploading] = useState(false);

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            if (disabled || isUploading) return;

            setIsUploading(true);

            const availableSlots = maxFileLimit - files.length;

            if (availableSlots <= 0) {
                setIsUploading(false);
                return;
            }

            const filesToUpload = acceptedFiles.slice(0, availableSlots);

            // Process files in parallel but preserve order
            Promise.all(
                filesToUpload.map(async file => {
                    const uploadResult = await uploadFile(file);
                    return uploadResult
                        ? {
                              id: crypto.randomUUID() as string,
                              url: uploadResult.url as string,
                              name: file.name,
                              size: file.size,
                          }
                        : null;
                })
            )
                .then(results => {
                    const newImages = results.filter(Boolean) as UploadedFile[];
                    setFiles([...files, ...newImages]);
                })
                .finally(() => setIsUploading(false));
        },
        [isUploading, disabled, maxFileLimit, files, setFiles]
    );

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        noClick: true,
        maxFiles: maxFileLimit,
        maxSize: 1024 * 1024 * 3, // 3mb
        disabled: disabled || isUploading || files.length >= maxFileLimit,
    });

    const onDelete = (id: string) => {
        const newFiles = files.filter(file => file.id !== id);
        setFiles(newFiles);
    };

    return (
        <Box display="flex" flexDirection="column" gap={2.5}>
            <EmptyUploadContainer {...getRootProps()}>
                <input {...getInputProps()} />
                <UploadContent
                    isDragActive={isDragActive}
                    isUploading={isUploading}
                    onUploadClick={isUploading ? undefined : open}
                />
            </EmptyUploadContainer>
            {files.map(file => (
                <FileContainer key={file.id}>
                    <AttachIcon />

                    <FileName noWrap>
                        <a href={file.url} download>
                            {file.name}
                        </a>
                    </FileName>
                    <IconButton onClick={() => onDelete(file.id)}>
                        <DeleteFileIcon />
                    </IconButton>
                </FileContainer>
            ))}
        </Box>
    );
}
