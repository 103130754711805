import { Box, Skeleton, Stack, styled } from '@mui/material';

const MapSkeleton = styled(Box)`
    @media (max-width: 960px) {
        display: none;
    }
`;

type VenueListingSkeletonProps = {
    numberOfItems?: number;
};

const VenueListingSkeleton = ({ numberOfItems = 3 }: VenueListingSkeletonProps) => {
    return (
        <Stack direction={{ xs: 'column', sm: 'row', md: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} mt={4}>
            {/* Venue listings section */}
            <Box sx={{ width: '100%', maxWidth: '600px' }}>
                {/* Venue card skeleton */}
                {[...Array(numberOfItems)].map((_, index) => (
                    <Box key={index} sx={{ mb: 2, display: 'flex', gap: 2 }}>
                        {/* Image skeleton */}
                        <Skeleton variant="rectangular" width={120} height={120} sx={{ borderRadius: 1 }} />
                        <Box sx={{ flex: 1 }}>
                            {/* Title */}
                            <Skeleton variant="text" width="40%" height={32} />
                            {/* Address */}
                            <Skeleton variant="text" width="60%" />
                            {/* Rating and details */}
                            <Skeleton variant="text" width="30%" />
                        </Box>
                    </Box>
                ))}

                {/* Pagination skeleton */}
                <Box sx={{ pt: 5, pb: 2 }} display="flex" justifyContent="center">
                    <Skeleton variant="rectangular" width={300} height={36} sx={{ borderRadius: 1 }} />
                </Box>
            </Box>

            {/* Map skeleton */}
            <MapSkeleton sx={{ flex: 1 }}>
                <Skeleton variant="rectangular" width="100%" height="calc(100vh - 100px)" sx={{ borderRadius: 1 }} />
            </MapSkeleton>
        </Stack>
    );
};

export default VenueListingSkeleton;
