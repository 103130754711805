import { create } from 'zustand';

type VenueSearchStore = {
    // Store URL search params by eventId
    searchParamsByEvent: Record<number, string>;

    setSearchParams: (eventId: number, searchParams: string) => void;
    getSearchParams: (eventId: number) => string | undefined;
    clearSearchParams: (eventId: number) => void;
};

export const useVenueSearchStore = create<VenueSearchStore>((set, get) => ({
    searchParamsByEvent: {},

    setSearchParams: (eventId, searchParams) =>
        set(state => ({
            searchParamsByEvent: {
                ...state.searchParamsByEvent,
                [eventId]: searchParams,
            },
        })),

    getSearchParams: eventId => get().searchParamsByEvent[eventId],

    clearSearchParams: eventId =>
        set(state => {
            const { [eventId]: _, ...rest } = state.searchParamsByEvent;
            return { searchParamsByEvent: rest };
        }),
}));
