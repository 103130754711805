import { HotelOutlined, LocalBarOutlined, PaletteOutlined, RestaurantOutlined } from '@mui/icons-material';
import BreweryIcon from 'images/icons/brewery.svg';
import UniqueVenueIcon from 'images/icons/unique_venue.svg';
import WineryIcon from 'images/icons/winery.svg';
import { EColors, getColor } from 'theme';

export type SupportedPropertyType = 'Hotel' | 'Restaurant' | 'Museum' | 'Bar' | 'Brewery' | 'Winery' | 'Unique Venue';

export const getVenueTypeInfo = (venue: Bizly.Venue) => {
    const venueType = venue.type?.name;

    const iconSize = 16;
    const defaultColor = getColor(EColors.bizlyInsightsBlue);
    const defaultSX = { width: iconSize, height: iconSize, color: defaultColor };

    switch (venueType) {
        case 'Hotel':
            return {
                icon: <HotelOutlined sx={defaultSX} />,
                label: venue.qualityLevel ? `${venue.qualityLevel} Hotel` : 'Hotel',
                subtitle: venue.address,
                rating: venue.travelstarRating,
            };
        case 'Restaurant':
            return {
                icon: <RestaurantOutlined sx={defaultSX} />,
                label: 'Restaurant',
                subtitle: venue.address,
                rating: venue.travelstarRating,
            };
        case 'Bar':
            return {
                icon: <LocalBarOutlined sx={defaultSX} />,
                label: 'Bar',
                subtitle: venue.address,
                rating: venue.travelstarRating,
            };
        case 'Museum':
            return {
                icon: <PaletteOutlined sx={defaultSX} />,
                label: 'Museum',
                subtitle: venue.headline,
                rating: venue.travelstarRating,
            };

        case 'Brewery':
            return {
                icon: <img src={BreweryIcon} alt="Brewery" />,
                label: 'Brewery',
                rating: venue.travelstarRating,
            };
        case 'Winery':
            return {
                icon: <img src={WineryIcon} alt="Winery" />,
                label: 'Winery',
            };
        case 'Unique Venue':
            return {
                icon: <img src={UniqueVenueIcon} alt="Unique Venue" />,
                label: 'Unique Venue',
                rating: venue.travelstarRating,
            };

        default:
            return {
                icon: <img src={UniqueVenueIcon} alt="Unique Venue" />,
                label: venueType || 'Venue',
                subtitle: venue.address,
                rating: venue.travelstarRating,
            };
    }
};
