import AssessmentIcon from '@mui/icons-material/Assessment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LogoutIcon from '@mui/icons-material/Logout';
import MessageIcon from '@mui/icons-material/Message';
import SettingsIcon from '@mui/icons-material/Settings';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import VillaIcon from '@mui/icons-material/Villa';
import { ElementType } from 'react';
import { i18n } from 'translation';

// NOTE: Home is hidden in v1
// const HOME_PATH = '/os';
const OS_PATH = '/os';
const OS_INQUIRIES_PATH = `${OS_PATH}/inquiries`;
const OS_LISTINGS_PATH = `${OS_PATH}/listings`;
const OS_MESSAGES_PATH = `${OS_PATH}/messages`;
const OS_CALENDAR_PATH = `${OS_PATH}/calendar`;
const OS_ANALYTICS_PATH = `${OS_PATH}/analytics`;
const OS_SETTINGS_PATH = `${OS_PATH}/settings`;

export type RouteType = {
    matches: RegExp[];
    path: string;
    icon: ElementType;
    title: string;
    featureFlag?: string;
};

export const PATHS: ReadonlyArray<RouteType> = [
    // NOTE: Home is hidden in v1
    // {
    //     matches: [/^\/$/, /^\/os$/],
    //     path: HOME_PATH,
    //     icon: HomeIcon,
    //     title: i18n.proposals.home,
    //     featureFlag: 'toggleHomeComponent',
    // },
    {
        matches: [/^\/os\/inquiries/],
        path: OS_INQUIRIES_PATH,
        icon: TrendingUpIcon,
        title: i18n.proposals.inquiries,
    },
    {
        matches: [/^\/os\/listings/],
        path: OS_LISTINGS_PATH,
        icon: VillaIcon,
        title: i18n.proposals.listings,
    },
    {
        matches: [/^\/os\/messages/],
        path: OS_MESSAGES_PATH,
        icon: MessageIcon,
        title: i18n.proposals.messages,
    },
    {
        matches: [/^\/os\/calendar/],
        path: OS_CALENDAR_PATH,
        icon: CalendarMonthIcon,
        title: i18n.proposals.calender,
    },
    {
        matches: [/^\/os\/analytics/],
        path: OS_ANALYTICS_PATH,
        icon: AssessmentIcon,
        title: i18n.proposals.anayltics,
    },
] as const;

export const SETTINGS_PATH = {
    matches: [/^\/os\/settings/],
    path: OS_SETTINGS_PATH,
    icon: SettingsIcon,
    title: i18n.proposals.settings,
} as const;

export const LOGOUT_PATH = {
    icon: LogoutIcon,
    title: 'Logout',
} as const;
