import { Tooltip } from '@material-ui/core';
import { MenuItem } from '@mui/material';
import { calculateBudgetStatus } from 'components/CompareModal/compareUtils';
import Button from 'components/ui/Button';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import { PostHogFeatureFlags } from 'shared';
import styled from 'styled-components';
import { i18n } from 'translation';
import { isAppleUser } from '../../util';

const APPLE_EVENTS_URI = `https://meetingsandevents.apple.com/meeting-request/#/details`;

export const AcceptRejectButton = styled(Button)<{ bareStyle?: boolean }>`
    ${({ bareStyle }) =>
        bareStyle &&
        `
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
    color: inherit;
    font-size: 0.875rem;
    width: initial;
  `}
    :disabled {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.grey2, 0.45)};
    }
`;
export const ProposalActionButton = ({
    proposal,
    expired,
    onAccept,
    showModal,
    hasRequested,
    buttonBareStyle = false,
}: {
    proposal: Bizly.VenueProposal;
    expired?: boolean;
    onAccept: () => void;
    eventBudget?: string | number;
    showModal: () => void;
    hasRequested?: boolean;
    buttonBareStyle?: boolean;
}) => {
    const { user } = useUser();
    const { event } = useEvent();
    const eventBudgetApprovalFlag = useFeatureFlagEnabled(PostHogFeatureFlags.eventBudgetApproval);

    const proposalRequest = proposal?.approvalRequests[0];
    const isRejected = Boolean(proposal?.rejected);
    const isApproved = Boolean(proposalRequest?.approvedBy);
    const isApple = isAppleUser(user);

    const { hasBudget, isAboveBudget } = calculateBudgetStatus(proposal, user, event);
    const eventRecordId = event.metadata?.recordId || '';

    const budgetBasedApprovalFeatureFlag = user.featureFlags?.budgetBasedApprovals && user.team?.budgetApprover;

    const isBudgetBasedApproval = hasBudget && isAboveBudget && !isApproved;

    if (isRejected) {
        return (
            <Tooltip title={i18n.venue.inquiry.requestRejectedInfo}>
                <span>
                    <AcceptRejectButton onClick={() => {}} disabled width={150}>
                        {i18n.venue.inquiry.requestRejected}
                    </AcceptRejectButton>
                </span>
            </Tooltip>
        );
    }

    const handleRequestApproval = () => {
        if (budgetBasedApprovalFeatureFlag) {
            showModal();
        } else {
            if (isApple && eventRecordId) {
                window.location.href = `${APPLE_EVENTS_URI}/${eventRecordId}`;
            }
        }
    };

    return (
        <>
            <MenuItem>
                <AcceptRejectButton
                    onClick={() => onAccept()}
                    disabled={expired || isAboveBudget}
                    bareStyle={buttonBareStyle}
                >
                    {i18n.button.accept}
                </AcceptRejectButton>
            </MenuItem>

            {(budgetBasedApprovalFeatureFlag || eventBudgetApprovalFlag) && (
                <MenuItem>
                    <AcceptRejectButton
                        onClick={handleRequestApproval}
                        width={hasRequested ? 160 : 150}
                        disabled={!isBudgetBasedApproval || hasRequested || expired}
                        bareStyle={buttonBareStyle}
                    >
                        {hasRequested ? i18n.venue.inquiry.approvalRequested : i18n.venue.inquiry.requestApproval}
                    </AcceptRejectButton>
                </MenuItem>
            )}
        </>
    );
};
