import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';

import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { Box, InputAdornment, Popper, PopperProps, styled, SvgIcon, Typography } from '@mui/material';
import Autocomplete, {
    autocompleteClasses,
    AutocompleteRenderOptionState,
    AutocompleteValue,
} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { findCities, findPlaces } from 'api';
import LocationIcon from 'images/bizlyOS/icons/location.svg?react';
import EmptyIcon from 'images/empty-pages-assets/empty_table.svg';
import { EColors, getColor } from 'theme';
import { i18n } from 'translation';

const Input = styled(TextField)(({ theme, showEmptyError, error }) => ({
    paddingRight: '9px',
    '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: theme.spacing(0.5),
        borderColor: showEmptyError || error ? getColor(EColors.warningText) : getColor(EColors.inactiveAction),
    },
    '& > .MuiInputBase-root.MuiOutlinedInput-root': {
        paddingRight: '9px',
    },
}));

const OptionBox = styled(Box)(({ theme: { getColor, EColors }, selected, borderBottom, height = '44px' }) => ({
    height,
    margin: '5px',
    [`&.${autocompleteClasses.option}.${autocompleteClasses.focused}`]: {
        backgroundColor: getColor(EColors.primaryAction, 0.1),
    },
    [`&.${autocompleteClasses.option}`]: {
        backgroundColor: selected ? getColor(EColors.primaryAction, 0.1) : 'transparent',
        borderBottom: borderBottom ? '1px solid ' + getColor(EColors.inactiveAction) : 'none',
        borderRadius: borderBottom ? 'none' : '4px',
    },
}));

type TOption = {
    description: string;
    placeId: string;
    types?: string[];
    suggestion?: string;
    mainText?: string;
    secondaryText?: string;
};

type TPlaceInput<TInputAsSuggestion extends boolean | undefined> = {
    locationBias?: Bizly.Location;
    clearable?: boolean;
    directVenueLinking?: boolean;
    locationValue?: string;
    placeId?: string;
    disabled?: boolean;
    placeholder?: string;
    required?: boolean;
    isCities?: boolean;
    noOptionIcon?: boolean;
    suggestionText?: string;
    error?: { message: string } | undefined;
    showEmptyError?: boolean;
    onSuggestionClicked?: (input: string) => void;
    onRemove?: () => void;
    onBlur?: () => void;
} & (TInputAsSuggestion extends true
    ? {
          inputAsSuggestion: true;
          onChange: (props: TOption) => void;
      }
    : {
          inputAsSuggestion?: false | undefined;
          onChange: (props: TOption) => void;
      });

const GooglePlacesAutocomplete = (props: TPlaceInput<true> | TPlaceInput<false>) => {
    const {
        locationBias,
        clearable,
        inputAsSuggestion,
        suggestionText,
        locationValue,
        placeId,
        disabled,
        placeholder,
        required,
        isCities = false,
        noOptionIcon = false,
        error,
        onChange,
        onRemove,
        onBlur,
        onSuggestionClicked,
        showEmptyError,
    } = props;

    const [inputQuery, setInputQuery] = useState<string>(locationValue ?? '');
    const [options, setOptions] = useState<TOption[]>([]);
    const [loading, setLoading] = useState(false);
    const [noOptionsText, setNoOptionsText] = useState('');

    const inputOption = useMemo(
        () => ({
            description: inputQuery,
            placeId: placeId ?? '',
            types: undefined,
        }),
        [inputQuery, placeId]
    );

    const resetOptions = () => {
        setLoading(false);
        setOptions([]);
    };

    const handleSelection = (props: TOption) => {
        setInputQuery(props.description);
        onChange?.(props);
    };

    function handleClear() {
        resetOptions();
        if (clearable) onRemove?.();
    }

    const onInputChange = (_: React.SyntheticEvent, newInputQuery: string) => {
        setInputQuery(newInputQuery);
        if (!newInputQuery) {
            handleClear();
        }
    };

    const onInputBlur = () => {
        if ((!inputQuery && locationValue) || (locationValue && inputQuery !== locationValue)) {
            setInputQuery(locationValue);
        }
        onBlur?.();
    };

    const onSelectionChange = (e: React.SyntheticEvent, val: AutocompleteValue<TOption, false, false, true>) => {
        if (!e || !val || typeof val === 'string') {
            handleClear();
            return;
        }
        handleSelection(val);
    };

    const getPlaces = useMemo(
        () =>
            debounce(async (query: string) => {
                setNoOptionsText(i18n.venue.noLocationsForQuery(query || ''));
                if (query.length > 0) {
                    setLoading(true);
                    try {
                        const response = await findPlaces(query, locationBias?.lat, locationBias?.lng);
                        if (response.status === 'OK') {
                            const predictions: TOption[] = [];

                            response.predictions.forEach((prediction: BizlyAPI.AutocompletePrediction) => {
                                // Check if the prediction is not a city or a similar type
                                if (!prediction.types.includes('locality') && !prediction.types.includes('political')) {
                                    predictions.push({
                                        description: prediction.description,
                                        placeId: prediction.placeId,
                                        mainText: prediction.structuredFormatting.mainText,
                                        secondaryText: prediction.structuredFormatting.secondaryText,
                                    });
                                }
                                return;
                            });
                            setOptions(predictions);
                        } else {
                            resetOptions();
                        }
                    } catch {
                        setNoOptionsText(i18n.error.default);
                    } finally {
                        setLoading(false);
                    }
                } else {
                    resetOptions();
                }
            }, 300),
        [locationBias]
    );

    const getCities = useMemo(
        () =>
            debounce(async (query: string) => {
                if (query.length > 0) {
                    setLoading(true);
                    try {
                        const response = await findCities(query);
                        if (response.status === 'OK') {
                            setOptions([...response.predictions]);
                        } else {
                            resetOptions();
                        }
                    } catch {
                        setNoOptionsText(i18n.error.default);
                    } finally {
                        setLoading(false);
                    }
                } else {
                    resetOptions();
                }
            }, 300),
        []
    );

    useEffect(() => {
        if (!inputQuery) {
            setOptions([]);
            return;
        }

        // Call the debounced function whenever the input value changes
        if (isCities) {
            getCities(inputQuery);
        } else {
            getPlaces(inputQuery);
        }

        // Cleanup function to cancel any pending debounced calls on component unmount
        return () => {
            getPlaces.cancel();
            getCities.cancel();
        };
    }, [inputQuery, getPlaces, getCities, isCities]);

    const renderOption = (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: TOption,
        state: AutocompleteRenderOptionState
    ) => {
        const { 'aria-selected': _, ...optionProps } = props;
        return (
            <OptionBox
                {...optionProps}
                component="li"
                key={option.placeId}
                selected={state.selected}
                borderBottom={option.suggestion && options.length > 0}
                height={option.mainText ? '64px' : option.suggestion ? '54px' : '44px'}
            >
                {!option.suggestion ? (
                    <Box display="flex" alignItems="center" gap="10px">
                        {!noOptionIcon && (
                            <SvgIcon color="primary">
                                <LocationIcon />
                            </SvgIcon>
                        )}
                        {option.mainText ? (
                            <Box overflow="hidden">
                                <Typography
                                    fontWeight={600}
                                    paddingTop={1.25}
                                    whiteSpace="nowrap"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                >
                                    {option.mainText}
                                </Typography>
                                <Typography variant="body2" color={getColor(EColors.tagColor)} paddingBottom={1.25}>
                                    {option.secondaryText}
                                </Typography>
                            </Box>
                        ) : (
                            <>
                                <Typography variant="body2" fontWeight={500}>
                                    {option.description}
                                </Typography>
                            </>
                        )}
                    </Box>
                ) : (
                    <Box
                        display="flex"
                        alignItems="center"
                        gap="10px"
                        onClick={() => onSuggestionClicked?.(inputQuery || '')}
                    >
                        <img src={EmptyIcon} width={44} height={44} />
                        <Box flex={1}>
                            <Typography fontWeight={600}>{option.description}</Typography>
                            <Typography variant="body2" color={EColors.tagColor} paddingBottom={1.25}>
                                {option.suggestion}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </OptionBox>
        );
    };

    const AutocompletePopper = function (props: PopperProps) {
        return (
            <Popper
                {...props}
                disablePortal
                open={true}
                sx={{
                    width: '600px',
                    paddingTop: '4px',
                    '& .MuiAutocomplete-paper': {
                        padding: 0,
                        boxShadow: 'none',
                        border: '1px solid #DBDBDB',
                        borderRadius: '4px',
                    },
                    '& ul': {
                        padding: '5px 0px',
                    },
                    boxShadow: 'none',
                }}
            />
        );
    };

    const getFinalOptions = () => {
        const inputSuggestionOption = {
            description: inputQuery,
            placeId: '',
            suggestion: suggestionText || '',
        };

        let finalOptions: TOption[] = [];
        if (options.length > 0) {
            if (inputAsSuggestion && options[0].description !== inputSuggestionOption.description) {
                finalOptions = [inputSuggestionOption];
            }
            finalOptions = [...finalOptions, ...options];
        } else {
            if (inputAsSuggestion && inputQuery) {
                finalOptions = [inputSuggestionOption];
            }
        }
        return finalOptions;
    };

    return (
        <>
            <Autocomplete
                freeSolo
                disabled={disabled}
                loading={loading}
                disableClearable={!clearable}
                options={getFinalOptions()}
                getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
                noOptionsText={noOptionsText}
                value={inputOption}
                isOptionEqualToValue={(option: TOption, value: TOption) => option.description === value.description}
                filterOptions={x => x}
                onInputChange={onInputChange}
                onChange={onSelectionChange}
                onBlur={onInputBlur}
                renderOption={renderOption}
                PopperComponent={AutocompletePopper}
                renderInput={params => (
                    <Input
                        {...params}
                        placeholder={placeholder ?? `${i18n.homepage.createMeetingModal.find} (required)`}
                        variant="outlined"
                        fullWidth
                        required={required}
                        showEmptyError={showEmptyError && !locationValue}
                        error={!!error}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <RoomOutlinedIcon color="primary" />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />
            {!!error && (
                <Typography variant="body2" color={getColor(EColors.red)} fontWeight={500}>
                    {error.message}
                </Typography>
            )}
        </>
    );
};

export default GooglePlacesAutocomplete;
