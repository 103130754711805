import { Box, CircularProgress, styled } from '@mui/material';
import { useMemo } from 'react';
import { EColors, getColor } from 'theme';
import { Button } from '../../Button/Button';
import { Body2, Caption } from '../../Typography/Typography';

const StyledButton = styled(({ ...props }) => <Button variant="text" {...props} />)({
    padding: 0,
    height: 21,
});

type ImageInputFieldProps = {
    label: string;
    required: boolean;
    limit?: number;
    error: boolean;
    onChange: (images: Bizly.VenueImage[]) => void;
    value: Bizly.VenueImage[];
    listingId: string;
};

type ImageInputFieldHeaderProps = Partial<ImageInputFieldProps> & { isUploading: boolean };

export const ImageInputFieldHeader = ({
    label,
    required,
    limit,
    error,
    isUploading,
    value: images,
    onChange: setImages,
}: ImageInputFieldHeaderProps) => {
    const numberOfSelectedImages = useMemo(() => {
        if (!images) return 0;
        let count = 0;
        images.forEach(image => image.isSelected && count++);
        return count;
    }, [images]);

    const onSelectedPhotosCancel = () => {
        if (!images || !setImages) return;
        const newImages = images.map(image => ({ ...image, isSelected: false }));
        setImages(newImages);
    };

    const onSelectedPhotosDelete = () => {
        if (!images || !setImages) return;
        const newImages = images.filter(image => !image.isSelected);
        setImages(newImages);
    };

    return (
        <Box display="flex" gap={0.5} marginBottom={1} justifyContent="space-between">
            <Box display="flex" gap={0.5}>
                <Body2 fontWeight={500}>{label}</Body2>
                {required && (
                    <Body2 color={EColors.bizlyOSPrimary} fontWeight={500}>
                        *
                    </Body2>
                )}
                {limit && limit > 1 && (
                    <Body2 color={EColors.tagColor} fontWeight={500}>
                        (Up to {limit})
                    </Body2>
                )}
                {error && (
                    <Caption color={EColors.warningText} fontWeight={500} size="large">
                        Images are required
                    </Caption>
                )}
            </Box>
            {numberOfSelectedImages > 0 ? (
                <Box display="flex">
                    <StyledButton onClick={onSelectedPhotosCancel}>Cancel</StyledButton>
                    <StyledButton onClick={onSelectedPhotosDelete} color="error">
                        Delete
                    </StyledButton>
                </Box>
            ) : isUploading ? (
                <Box display="flex" gap={0.5} alignItems="center">
                    <CircularProgress size={14} sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                    <Body2 fontWeight="500" color={EColors.bizlyOSPrimary}>
                        Uploading...
                    </Body2>
                </Box>
            ) : null}
        </Box>
    );
};
