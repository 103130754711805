import { Box, styled, Typography } from '@mui/material';
import TextField from 'components/Ui-V2/InputFields/TextField';
import { Control, Controller, FieldError } from 'react-hook-form';

export const BorderedContainer = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    height: '100%',
    border: `1px solid ${getColor(EColors.inactiveAction)}`,
    borderRadius: shape.borderRadius,
    boxSizing: 'border-box',
    padding: spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    transition: 'height 1s ease',
}));
export const BorderedBottom = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    borderBottom: `1px solid ${getColor(EColors.inactiveAction)}`,
    paddingBottom: spacing(2.5),
}));

function GuestDetails({ control, disabled }: { control: Control; disabled: boolean }) {
    return (
        <>
            <Typography fontWeight={600}>Guest Details</Typography>
            <Controller
                name="firstName"
                control={control}
                render={({ field, fieldState }) => {
                    return (
                        <TextField
                            {...field}
                            label="First Name"
                            onChange={field.onChange}
                            placeholder="Your First Name"
                            value={field.value}
                            error={fieldState.error as FieldError}
                            required
                            disabled={disabled}
                        />
                    );
                }}
            />
            <Controller
                name="lastName"
                control={control}
                render={({ field, fieldState }) => {
                    return (
                        <TextField
                            {...field}
                            label="Last Name"
                            onChange={field.onChange}
                            placeholder="Your Last Name"
                            value={field.value}
                            error={fieldState.error as FieldError}
                            required
                            disabled={disabled}
                        />
                    );
                }}
            />
            <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => {
                    return (
                        <TextField
                            {...field}
                            label="Email"
                            onChange={field.onChange}
                            placeholder="Your Email"
                            value={field.value}
                            error={fieldState.error as FieldError}
                            required
                            disabled={disabled}
                        />
                    );
                }}
            />
        </>
    );
}

export default GuestDetails;
