import SearchIcon from '@mui/icons-material/Search';
import { TextField, styled } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { GridToolbarContainer, GridToolbarProps } from '@mui/x-data-grid-pro';
import { Tab, Tabs } from 'components/BizlyOS/Tabs';
import { debounce } from 'lodash';
import { KeyboardEvent, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EColors, getColor } from 'theme';

const StyledToolbarContainer = styled(GridToolbarContainer)(() => ({
    backgroundColor: getColor(EColors.drWhite),
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const StyledSearchField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        width: '18.75rem',
        height: '2.5rem',
        paddingLeft: theme.spacing(1),
    },
    '& .MuiInputBase-root > input': {
        fontSize: '0.875rem',
    },
}));

interface ListingsToolBarProps extends GridToolbarProps {
    filterValue: StatusType;
    onFilterChange: (value: StatusType) => void;
}

export const ListingsToolBar = (props: ListingsToolBarProps) => {
    const { filterValue, onFilterChange } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchText, setSearchText] = useState(searchParams.get('q') || '');

    const handleSearchSubmit = (searchText: string) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('q', searchText.trim());
        newSearchParams.set('page', '1');
        setSearchParams(newSearchParams);
    };

    const debouncedSearchSubmit = useCallback(
        debounce(value => {
            handleSearchSubmit(value);
        }, 500),
        []
    );

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearchSubmit(searchText);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
        debouncedSearchSubmit(event.target.value);
    };

    return (
        <StyledToolbarContainer>
            <Tabs
                value={filterValue}
                onChange={(_: React.SyntheticEvent, newValue: StatusType) => {
                    onFilterChange(newValue);
                }}
            >
                <Tab value="All" label="All" />
                <Tab value="Active" label="Active" />
                <Tab value="Draft" label="Draft" />
            </Tabs>
            <StyledSearchField
                placeholder="Search listings..."
                value={searchText}
                onChange={handleSearchChange}
                onKeyPress={handleKeyPress}
                size="small"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </StyledToolbarContainer>
    );
};
