import { Box } from '@mui/material';
import { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';
import { EColors } from 'theme';
import { Body2, Caption } from '../Typography/Typography';
import Tiptap from './TipTap';

type TextAreaFieldProps = {
    label?: string;
    error?: FieldError | undefined;
    onChange?: (text: string) => void;
    onBlur?: () => void;
    value: string;
    disabled?: boolean | undefined;
    required?: boolean;
    name?: string;
    placeholder?: string;
};

// Utility function to strip HTML tags
const stripHtmlTags = (html: string): string => {
    const temp = document.createElement('div');
    temp.innerHTML = html;
    return temp.textContent || temp.innerText || '';
};

const RichTextEditorField = forwardRef<HTMLDivElement, TextAreaFieldProps>(
    ({ label, placeholder, error, required = false, onChange, disabled = false, ...field }, ref) => {
        const handleChange = (value: string) => {
            if (!disabled) {
                const plainText = stripHtmlTags(value).trim();
                // Do not call onChange if the field is empty and the user is not typing to prevent triggering react-hook-form validation
                if (!plainText && !field.value) return;
                onChange?.(plainText);
            }
        };

        return (
            <Box ref={ref}>
                {label && (
                    <Box display="flex" gap={0.5} marginBottom={1}>
                        <Body2 fontWeight={500}>{label}</Body2>
                        {required && (
                            <Body2 color={EColors.bizlyOSPrimary} fontWeight={500}>
                                *
                            </Body2>
                        )}
                    </Box>
                )}

                <Tiptap
                    description={field.value || ''}
                    onChange={handleChange}
                    placeholder={placeholder}
                    disabled={disabled}
                />

                {!!error && (
                    <Caption size="large" color={EColors.red} fontWeight={500}>
                        {error.message}
                    </Caption>
                )}
            </Box>
        );
    }
);

export default RichTextEditorField;
