import { Box, styled } from '@mui/material';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers-pro';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { FieldError } from 'react-hook-form';
import { EColors, getColor } from 'theme';
import { getShortTimeZone } from 'utils/date_util';
import { tzMoment } from 'utils/moment';
import { Body1, Body2 } from '../Typography/Typography';

const StyledTimePicker = styled(MuiTimePicker, {
    shouldForwardProp: prop => prop !== 'iconColor' && prop !== 'showEmptyError' && prop !== 'error',
})(({ iconColor, disabled, theme: { getColor, EColors }, showEmptyError, error }) => ({
    boxSizing: 'border-box',
    width: '100%',
    height: '50px',
    borderRadius: '4px',
    '& .MuiInputBase-root > input': {
        fontSize: '0.875rem',
        padding: '13.5px',
    },
    '& .MuiInputBase-root > .MuiOutlinedInput-notchedOutline': {
        borderRadius: '4px',
        borderColor: getColor(EColors.bizlyOSBorder),
        ...(showEmptyError || error ? { borderColor: getColor(EColors.red) } : {}), // Disabling the default error indicator
    },
    '& .MuiInputBase-root .MuiIconButton-root': {
        color: disabled ? getColor(EColors.inactiveAction) : iconColor,
    },
    '& .MuiInputBase-root .MuiIconButton-root > .MuiSvgIcon-root': {
        fontSize: '1.25rem',
    },
}));

const TimeZoneContainer = styled(Body2)(({ theme: { getColor, EColors, spacing } }) => ({
    fontWeight: 500,
    paddingLeft: spacing(1.75),
    borderLeft: '1px solid ' + getColor(EColors.bizlyOSBorder),
}));

type TimePickerPropsBase = {
    label?: string;
    error: FieldError | undefined;
    showEmptyError?: boolean;
    value: string | undefined;
    disabled?: boolean | undefined;
    required?: boolean;
    iconColor?: string;
    onAccept?: (time: string) => void;
    onChange?: (time: string) => void;
};

type TimePickerProps =
    | (TimePickerPropsBase & {
          showTimeZone?: false | undefined;
          timeZone?: string;
      })
    | (TimePickerPropsBase & {
          showTimeZone: true;
          timeZone: string; // Required when showTimeZone is true
      });

const dateToday = moment().format('YYYY-MM-DD');

const TimePicker = React.forwardRef<HTMLInputElement, TimePickerProps>(
    (
        {
            label,
            error,
            showEmptyError = false,
            required = false,
            value: time,
            disabled,
            iconColor = getColor(EColors.bizlyOSPrimary),
            timeZone = 'UTC',
            showTimeZone = false,
            onChange,
            onAccept,
        },
        ref
    ) => {
        const [openPicker, setOpenPicker] = useState(false);

        const onClosePicker = () => setOpenPicker(false);

        const onOpenPicker = () => {
            if (disabled) return;
            setOpenPicker(true);
        };

        return (
            <Box>
                {label && (
                    <Box display="flex" gap={0.5} marginBottom={1}>
                        <Body1 fontWeight={500}>{label}</Body1>
                        {required && (
                            <Body2 color={EColors.bizlyOSPrimary} fontWeight={500}>
                                *
                            </Body2>
                        )}
                    </Box>
                )}
                <StyledTimePicker
                    ref={ref}
                    value={time ? tzMoment(`${dateToday}T${time}`, timeZone) : null}
                    iconColor={iconColor}
                    disabled={disabled}
                    error={!!error}
                    showEmptyError={showEmptyError && !time}
                    onChange={(value: Moment) => {
                        onChange?.(value.format('HH:mm:ss'));
                    }}
                    onAccept={(value: Moment) => {
                        onAccept?.(value.format('HH:mm:ss'));
                    }}
                    open={openPicker}
                    onClose={onClosePicker}
                    onOpen={onOpenPicker}
                    slotProps={{
                        textField: {
                            onClick: onOpenPicker,
                        },
                    }}
                    {...(showTimeZone
                        ? {
                              slots: {
                                  openPickerButton: () => (
                                      <TimeZoneContainer>{getShortTimeZone(dateToday, timeZone)}</TimeZoneContainer>
                                  ),
                              },
                          }
                        : {})}
                />

                {!!error && (
                    <Body2 color={EColors.red} fontWeight={500}>
                        {error.message}
                    </Body2>
                )}
            </Box>
        );
    }
);

export default TimePicker;
