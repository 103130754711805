import { Box, Switch as MuiSwitch, styled } from '@mui/material';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { EColors, getColor } from 'theme';
import { Body2, Caption } from '../Typography/Typography';

type SwitchVariant = 'yesno' | 'onoff';

const variantConfig = {
    yesno: { offLabel: 'No', offTop: 9, offLeft: 38, onLabel: 'Yes', onTop: 9, onLeft: 10.2 },
    onoff: { offLabel: 'Off', offTop: 9, offLeft: 37, onLabel: 'On', onTop: 9, onLeft: 12 },
} as const;

export const Switch = styled(MuiSwitch)(({ variant = 'yesno' }: { variant: SwitchVariant }) => {
    const { offLabel, offTop, offLeft, onLabel, onTop, onLeft } = variantConfig[variant];

    return {
        width: 66,
        height: 32,
        padding: 2,
        '& .MuiSwitch-switchBase': {
            transform: 'translateX(1px) translateY(-2px)',
            color: getColor(EColors.inactiveAction),
            '& .MuiSwitch-thumb': {
                width: 20,
                height: 20,
                boxShadow: 'none',
            },
            '&+.MuiSwitch-track': {
                borderRadius: 34 / 2,
                backgroundColor: getColor(EColors.pureWhite),
                opacity: 1,
                border: '1px solid ' + getColor(EColors.inactiveAction),
                '&::after': {
                    content: `"${offLabel}"`,
                    color: getColor(EColors.pureBlack),
                    fontSize: '13px',
                    position: 'absolute',
                    top: offTop,
                    left: offLeft,
                },
            },
            '&.Mui-checked': {
                transform: 'translateX(28px) translateY(-2px)',
                color: getColor(EColors.bizlyOSPrimary),
                '& + .MuiSwitch-track': {
                    backgroundColor: getColor(EColors.pureWhite),
                    opacity: 1,
                    border: '1px solid ' + getColor(EColors.bizlyOSPrimary),
                    '&::before': {
                        content: `"${onLabel}"`,
                        color: getColor(EColors.pureBlack),
                        fontSize: '13px',
                        position: 'absolute',
                        top: onTop,
                        left: onLeft,
                    },
                },
            },
            '&.Mui-disabled': {
                '&.Mui-checked': {
                    color: getColor(EColors.bizlyOSGray20),
                },
                '&+.MuiSwitch-track': {
                    border: '1px solid ' + getColor(EColors.bizlyOSGray20),
                    '&::before': {
                        color: getColor(EColors.bizlyOSGray20),
                    },
                    '&::after': {
                        color: getColor(EColors.bizlyOSGray20),
                    },
                },
            },
        },
    };
});

const Container = styled(Box)(({ theme: { shape, spacing }, backgroundColor }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: shape.borderRadius,
    padding: spacing(3),
    backgroundColor,
}));

type SwitchFieldProps = {
    label: string;
    value: boolean | undefined | null;
    name?: string;
    error?: FieldError | undefined;
    disabled?: boolean | undefined;
    checkedBackgroundColor?: string;
    unCheckedBackgroundColor?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

export const SwitchField = React.forwardRef<HTMLDivElement, SwitchFieldProps>(
    (
        {
            label = '',
            error,
            checkedBackgroundColor = getColor(EColors.bizlyOSPrimary, 0.2),
            unCheckedBackgroundColor = getColor(EColors.bizlyOSBorder, 0.2),
            ...field
        },
        ref
    ) => {
        return (
            <>
                <Container backgroundColor={field.value ? checkedBackgroundColor : unCheckedBackgroundColor}>
                    <Body2 fontWeight={500}>{label}</Body2>
                    <Switch {...field} ref={ref} checked={field.value} />
                </Container>
                {!!error && (
                    <Caption color={EColors.red} fontWeight={500} size="large">
                        {error.message}
                    </Caption>
                )}
            </>
        );
    }
);
