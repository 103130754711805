import { SelectChangeEvent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from 'components/BizlyOS/Button/Button';
import { Body2 } from 'components/BizlyOS/Typography/Typography';
import { TCurrency } from 'components/ProposalForm/types';
import { useEffect, useState } from 'react';
import SelectField from '../SelectField';

type CurrencyModalProps = {
    value: string;
    descpription?: string;
    currencyOptions: TCurrency[];
    open: boolean;
    handleClose: () => void;
    onCurrencyCodeChange: (value: string) => void;
};

export const CurrencyModal = ({
    open,
    value,
    currencyOptions,
    descpription,
    handleClose,
    onCurrencyCodeChange,
}: CurrencyModalProps) => {
    const [selectedCodeTemp, setSeletedTempCode] = useState<string>(value);

    useEffect(() => {
        setSeletedTempCode(value);
    }, [value]);

    const handleChange = (event: SelectChangeEvent<string | number>) => {
        setSeletedTempCode(event.target.value.toString() || '');
    };

    const onChange = () => {
        onCurrencyCodeChange(selectedCodeTemp || '');
        handleClose();
    };

    return (
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
            <DialogTitle>Change Currency</DialogTitle>
            <DialogContent sx={{ minWidth: '320px' }}>
                {descpription && <Body2 marginBottom={2.5}>{descpription}</Body2>}
                <SelectField
                    value={selectedCodeTemp}
                    options={currencyOptions.map(currencyOption => ({
                        label: `${currencyOption.code} - ${currencyOption.name}`,
                        value: currencyOption.code,
                    }))}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions sx={{ padding: 2.5, paddingTop: 0 }}>
                <Button variant="text" color="error" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="text" onClick={onChange}>
                    Change
                </Button>
            </DialogActions>
        </Dialog>
    );
};
