import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, styled } from '@mui/material';
import {
    DATE_FORMAT,
    DEFAULT_CURRENCY_SYMBOL,
    TypeOfRoomTypes,
    hasAllRoomBlockBeenFilled,
} from 'components/BizlyOS/Proposals/utils';
import { Body2 } from 'components/BizlyOS/Typography/Typography';
import { useGetProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { tzMoment } from 'utils/moment';
import { RoomBlockAccordion } from './RoomBlockAccordion';
import { RoomBlockInfo } from './RoomBlockInfo';
import { RoomBlockSideDrawer } from './RoomBlockSideDrawer';

const InfoContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1.25),
    borderRadius: shape.borderRadius,
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    gap: spacing(1.25),
}));

export function RoomBlock() {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: proposalInquiry, isLoading } = useGetProposalInquiry(venueId, proposalId);

    const proposal = proposalInquiry?.proposal;
    const guestRooms = proposalInquiry?.guestRooms || [];
    const venue = proposalInquiry?.venue || {};

    const totalRooms = guestRooms?.reduce((acc, { requestedGuests }) => acc + (requestedGuests ?? 0), 0);

    const [accordionExpands, setAccordionExpands] = useState<number[]>([0]);

    const [selectedRoomBlockIndex, setSelectedRoomBlockIndex] = useState<number>();
    const [selectedRoomType, setSelectedRoomType] = useState<TypeOfRoomTypes>();

    const [isActionDrawerOpen, setIsActionDrawerOpen] = useState<boolean>(false);

    const onAccordionExpandChange = (panel: number) => (_: React.SyntheticEvent, newExpanded: boolean) => {
        setAccordionExpands(prev => {
            if (newExpanded) {
                return [...prev, panel];
            }
            return prev.filter(index => index !== panel);
        });
    };

    const onDrawerClose = () => {
        setSelectedRoomBlockIndex(undefined);
        setIsActionDrawerOpen(false);
    };

    const onRoomTypeSelect = (indexOfRoomBolck: number, roomType: TypeOfRoomTypes) => {
        setSelectedRoomBlockIndex(indexOfRoomBolck);
        setSelectedRoomType(roomType);
        setIsActionDrawerOpen(true);
    };

    if (isLoading || !guestRooms || guestRooms?.length === 0) return null;

    const roomBlockCheckInDate = guestRooms[0].date;
    const roomBlockCheckOutDate = guestRooms[guestRooms.length - 1].date;

    const roomBlockInfo = [
        { lable: 'Check In', value: tzMoment(roomBlockCheckInDate, venue?.timeZone).format(DATE_FORMAT) },
        { lable: 'Check Out', value: tzMoment(roomBlockCheckOutDate, venue?.timeZone).format(DATE_FORMAT) },
        { lable: 'Room Nights', value: totalRooms?.toString() || '0' },
    ];

    const isAllRoomFilled = hasAllRoomBlockBeenFilled(guestRooms, proposal?.resortFee, proposal?.occupancyTax);

    return (
        <>
            <Box display="flex" flexDirection="column" gap={2.5}>
                {!isAllRoomFilled && (
                    <InfoContainer>
                        <InfoOutlinedIcon fontSize="small" sx={{ color: getColor(EColors.primaryAction) }} />
                        <Body2>Add or edit requested guest rooms.</Body2>
                    </InfoContainer>
                )}

                <RoomBlockInfo infos={roomBlockInfo} />

                {guestRooms.map((roomBlock, index) => {
                    const isFilled = roomBlock.proposedRoomCount && roomBlock.proposedRoomRate;

                    return (
                        <RoomBlockAccordion
                            index={index}
                            roomBlock={roomBlock}
                            expanded={accordionExpands.includes(index)}
                            isFilled={!!isFilled}
                            onExpandChange={onAccordionExpandChange}
                            onRoomTypeSelect={onRoomTypeSelect}
                            currencySymbol={proposal?.currency?.symbol || DEFAULT_CURRENCY_SYMBOL}
                        />
                    );
                })}
            </Box>
            <RoomBlockSideDrawer
                drawerOpen={isActionDrawerOpen}
                onClose={onDrawerClose}
                roomBlock={selectedRoomBlockIndex === undefined ? undefined : guestRooms[selectedRoomBlockIndex]}
                selectedRoomType={selectedRoomType}
                index={selectedRoomBlockIndex}
            />
        </>
    );
}
