import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    StaffMemberType,
    useInviteStaffMemberMutation,
    useStaffRoles,
    useUpdateStaffMemberMutation,
} from 'hooks/queries/BizlyOS/useStaffQuery';
import { useSnackbar } from 'notistack';
import { useBizlyOSUser } from 'providers/bizly-os-user';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { InviteSuccessComponent } from '../InviteSuccess/InviteSuccess';
import { SideDrawer } from '../SideDrawer/SideDrawer';
import { DrawerActionButtons } from './DrawerActionButtons';
import { DrawerHeader } from './DrawerHeader';
import { FormFields } from './FormFields';
import {
    CREATE_ERROR_MESSAGE,
    STAFF_DEFAULT_VALUE,
    STAFF_FORM_DEFAULT_VALUES,
    StaffFormSchema,
    StaffFormsData,
    formatStaffMember,
    getFilledStaffObject,
} from './utils';

const Container = styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
}));

type InviteStaffDrawerProps = {
    title?: string;
    drawerOpen: boolean;
    setDrawerOpen: (toggle: boolean) => void;
    selectedStaffMember?: StaffMemberType;
    addMultiple?: boolean;
    goBackEnabled?: boolean;
    isEdit?: boolean;
    isView?: boolean;
};

export const InviteStaffDrawer = ({
    title = 'Invite Colleagues',
    drawerOpen,
    isEdit = false,
    isView = false,
    addMultiple = false,
    goBackEnabled = false,
    selectedStaffMember,
    setDrawerOpen,
}: InviteStaffDrawerProps) => {
    const { enqueueSnackbar } = useSnackbar();

    const [inviteSuccess, setInviteSuccess] = useState(false);
    const { venueId } = useBizlyOSUser();

    const inviteStaffMemberMutation = useInviteStaffMemberMutation(venueId as string);
    const updateStaffMutation = useUpdateStaffMemberMutation(
        venueId as string,
        selectedStaffMember?.id.toString() || ''
    );
    const { roles } = useStaffRoles(venueId as string);

    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        trigger,
        reset,
    } = useForm<StaffFormsData>({
        resolver: zodResolver(StaffFormSchema),
        defaultValues: STAFF_FORM_DEFAULT_VALUES,
        mode: 'onTouched',
        disabled: isView,
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'forms',
    });

    useEffect(() => {
        if ((isEdit || isView) && selectedStaffMember && roles) {
            reset({
                forms: [getFilledStaffObject(selectedStaffMember, roles)],
            });
        }
    }, [isEdit, isView, selectedStaffMember, roles, reset]);

    const onCreate = async (data: StaffFormsData) => {
        const staffMembers = data.forms.map(staffMember => formatStaffMember(staffMember));

        try {
            const results = await Promise.all(
                staffMembers.map(member => inviteStaffMemberMutation.mutateAsync(member))
            );
            if (results.every(res => res.success)) {
                setInviteSuccess(true);
                reset();
            } else {
                enqueueSnackbar(CREATE_ERROR_MESSAGE, { variant: 'error' });
            }
        } catch (error) {
            if (error instanceof Error) {
                console.error(error.message);
                enqueueSnackbar(CREATE_ERROR_MESSAGE, { variant: 'error' });
            }
        }
    };

    const onEdit = async (data: StaffFormsData) => {
        const newStaffMember = data.forms[0];

        updateStaffMutation.mutate(formatStaffMember(newStaffMember), {
            onSuccess: () => {
                onDrawerClose();
                enqueueSnackbar('Successfully Updated!', { variant: 'success' });
            },
            onError: error => {
                let errorMessage = error.message;
                if (typeof error === 'string') {
                    errorMessage = error;
                }
                enqueueSnackbar(errorMessage, { variant: 'error' });
                onDrawerClose();
            },
        });
    };

    const onSubmit = async (data: StaffFormsData) => {
        if (isEdit) {
            onEdit(data);
        } else {
            onCreate(data);
        }
    };

    const handleAddAnother = async () => {
        const isValid = await trigger();
        if (isValid) {
            append({ ...STAFF_DEFAULT_VALUE });
        } else {
            console.error('Please fill out all required fields before adding another.');
        }
    };

    const onDrawerClose = () => {
        if (inviteSuccess) {
            setInviteSuccess(false);
            setDrawerOpen(false);
        } else {
            setDrawerOpen(false);
        }
        reset(STAFF_FORM_DEFAULT_VALUES);
    };

    return (
        <SideDrawer
            drawerOpen={drawerOpen}
            onClose={onDrawerClose}
            footer={
                !inviteSuccess && !isView ? (
                    <DrawerActionButtons
                        onCancel={() => setDrawerOpen(false)}
                        onSubmit={handleSubmit(onSubmit)}
                        isSubmitting={inviteStaffMemberMutation.isLoading || updateStaffMutation.isLoading}
                        isValid={isValid}
                        goBackEnabled={goBackEnabled}
                    />
                ) : null
            }
            title={title}
        >
            {inviteSuccess ? (
                <InviteSuccessComponent />
            ) : (
                <Container>
                    <DrawerHeader
                        goBackEnabled={goBackEnabled}
                        isEdit={isEdit}
                        addMultiple={addMultiple}
                        handleAddAnother={handleAddAnother}
                    />

                    {fields.map((field, index) => (
                        <FormFields
                            key={field.id}
                            index={index}
                            remove={() => remove(index)}
                            control={control}
                            errors={errors}
                            roles={roles || []}
                            disableEmail={isEdit || isView}
                            isEdit={isEdit}
                            isActiveUser={Boolean(selectedStaffMember?.lastActiveAt)}
                        />
                    ))}
                </Container>
            )}
        </SideDrawer>
    );
};
