import { Box, MenuItem, Select, SelectChangeEvent, Typography, styled } from '@mui/material';
import { Button } from 'components/Ui-V2/Button/Button';
import { YesNoSwitch } from 'components/Ui-V2/InputFields/Switch';
import React, { Dispatch, SetStateAction } from 'react';
import { QUESTION_TYPES, QuestionField, QuestionTypes, isIdUnsaved, isQuestionNotValid } from '../../utills';
import QuestionDate from './QuestionDate';
import QuestionLinearScale from './QuestionLinearScale';
import QuestionMultipleChoice from './QuestionMultipleChoice';
import QuestionText from './QuestionText';

const QuestionsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
}));

const QuestionContainer = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    border: `0.5px solid ${getColor(EColors.inactiveAction)}`,
    borderRadius: shape.borderRadius,
    boxSizing: 'border-box',
    padding: spacing(2.5),
}));

const QuestionBody = styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    paddingTop: spacing(1.25),
}));

const QuestionFooter = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    borderTop: `0.5px solid ${getColor(EColors.inactiveAction)}`,
    boxSizing: 'border-box',
    marginTop: spacing(2.5),
    paddingTop: spacing(2.5),
    display: 'flex',
    justifyContent: 'space-between',
}));

const StyledSelect = styled(Select)(({ theme: { getColor, EColors, shape } }) => ({
    height: '50px',
    width: '100%',
    fontSize: '1rem',
    '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid ' + getColor(EColors.bizlyOSBorder),
        borderRadius: shape.borderRadius,
    },
}));

type QuestionsListProps = {
    questionFields: QuestionField[];
    questionSaveId?: number;
    questionEditId?: number;
    onQuestionRemove: (index: number) => void;
    onQuestionSave: (questionField: QuestionField) => void;
    setQuestionFields: Dispatch<SetStateAction<QuestionField[]>>;
    setQuestionEditId: Dispatch<SetStateAction<number | undefined>>;
};

function QuestionsList({
    questionFields,
    setQuestionFields,
    onQuestionSave,
    onQuestionRemove,
    questionSaveId,
    questionEditId,
    setQuestionEditId,
}: QuestionsListProps) {
    const onQuestionTypeChange = (type: Bizly.QuestionType, index: number) => {
        const newQuestions = [...questionFields];
        newQuestions[index] = {
            ...questionFields[index],
            type,
        };
        setQuestionFields(newQuestions);
    };

    const onQuestionRequiredChange = (required: boolean, index: number) => {
        const newQuestions = [...questionFields];
        newQuestions[index] = {
            ...questionFields[index],
            required,
        };
        setQuestionFields(newQuestions);
    };

    const onQuestionTextChange = (text: string, index: number) => {
        const newQuestions = [...questionFields];
        newQuestions[index].value = text;
        setQuestionFields(newQuestions);
    };

    const onQuestionOptionChange = (value: string, optionIndex: number, index: number) => {
        const newQuestions = [...questionFields];
        if (newQuestions[index].options) {
            newQuestions[index].options[optionIndex] = value;
        }
        setQuestionFields(newQuestions);
    };

    const onQuestionValueChange = <K extends keyof Bizly.LinearScaleValuesType>(
        field: K,
        value: Bizly.LinearScaleValuesType[K],
        index: number
    ) => {
        const newQuestions = [...questionFields];
        if (newQuestions[index].linearScaleValues) {
            newQuestions[index].linearScaleValues[field] = value;
        }
        setQuestionFields(newQuestions);
    };

    const onQuestionOptionAdd = (index: number) => {
        const newQuestions = [...questionFields];
        newQuestions[index].options = questionFields[index].options ? [...questionFields[index].options, ''] : [''];
        setQuestionFields(newQuestions);
    };

    const getQuestionBody = (questionField: QuestionField, index: number) => {
        switch (questionField.type) {
            case QUESTION_TYPES.text:
                return (
                    <QuestionText
                        index={index}
                        questionField={questionField}
                        onQuestionTextChange={onQuestionTextChange}
                    />
                );
            case QUESTION_TYPES.checkBox:
                return (
                    <QuestionMultipleChoice
                        type="checkbox"
                        index={index}
                        questionField={questionField}
                        onQuestionTextChange={onQuestionTextChange}
                        onQuestionOptionAdd={onQuestionOptionAdd}
                        onQuestionOptionChange={onQuestionOptionChange}
                    />
                );
            case QUESTION_TYPES.radio:
                return (
                    <QuestionMultipleChoice
                        type="radio"
                        index={index}
                        questionField={questionField}
                        onQuestionTextChange={onQuestionTextChange}
                        onQuestionOptionAdd={onQuestionOptionAdd}
                        onQuestionOptionChange={onQuestionOptionChange}
                    />
                );
            case QUESTION_TYPES.linearScale:
                return (
                    <QuestionLinearScale
                        index={index}
                        questionField={questionField}
                        onQuestionTextChange={onQuestionTextChange}
                        onQuestionValueChange={onQuestionValueChange}
                    />
                );
            case QUESTION_TYPES.date:
                return (
                    <QuestionDate
                        index={index}
                        questionField={questionField}
                        onQuestionTextChange={onQuestionTextChange}
                    />
                );
            default:
                return null;
        }
    };

    const getNewQuestion = (questionField: QuestionField, index: number) => {
        return (
            <QuestionContainer key={index}>
                <StyledSelect
                    value={questionField.type}
                    renderValue={(value: string) => {
                        const option = QuestionTypes.find(o => o.type === value);
                        return (
                            <Box display="flex" alignItems="center" gap={1.25}>
                                {option?.icon}
                                {option?.name}
                            </Box>
                        );
                    }}
                    onChange={(value: SelectChangeEvent) => {
                        onQuestionTypeChange(value.target.value as Bizly.QuestionType, index);
                    }}
                >
                    {QuestionTypes.map(option => (
                        <MenuItem key={option.type} value={option.type}>
                            <Box display="flex" alignItems="center" gap={1.25}>
                                {option?.icon}
                                {option?.name}
                            </Box>
                        </MenuItem>
                    ))}
                </StyledSelect>
                <QuestionBody>{getQuestionBody(questionField, index)}</QuestionBody>

                <QuestionFooter>
                    <Box display="flex" gap={2.5} alignItems="center">
                        <Typography>Required</Typography>
                        <YesNoSwitch
                            checked={questionField.required}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                onQuestionRequiredChange(event.target.checked, index);
                            }}
                        />
                    </Box>

                    <Box display="flex" gap={0.5} alignItems="center">
                        <Button variant="text" color="error" onClick={() => onQuestionRemove(index)}>
                            Delete
                        </Button>
                        <Button
                            variant="text"
                            onClick={() => onQuestionSave(questionFields[index])}
                            disabled={isQuestionNotValid(questionField) || questionSaveId === questionField.id}
                        >
                            {questionSaveId === questionField.id ? 'Saving...' : 'Save'}
                        </Button>
                    </Box>
                </QuestionFooter>
            </QuestionContainer>
        );
    };

    const getSavedQuestion = (questionField: QuestionField, index: number) => {
        const isEdit = questionEditId === questionField.id;
        return (
            <QuestionContainer key={index}>
                {!isEdit ? (
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography fontWeight={500}>{questionField.value}</Typography>
                        <Button
                            variant="text"
                            onClick={() => setQuestionEditId(questionField.id)}
                            disabled={isQuestionNotValid(questionField) || questionSaveId === questionField.id}
                            sx={{ height: 24 }}
                        >
                            Edit
                        </Button>
                    </Box>
                ) : (
                    <>
                        <QuestionBody>{getQuestionBody(questionField, index)}</QuestionBody>

                        <QuestionFooter>
                            <Box display="flex" gap={2.5} alignItems="center">
                                <Typography>Required</Typography>
                                <YesNoSwitch
                                    checked={questionField.required}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        onQuestionRequiredChange(event.target.checked, index);
                                    }}
                                />
                            </Box>

                            <Box display="flex" gap={0.5} alignItems="center">
                                <Button variant="text" color="error" onClick={() => onQuestionRemove(index)}>
                                    Delete
                                </Button>
                                <Button
                                    variant="text"
                                    onClick={() => onQuestionSave(questionFields[index])}
                                    disabled={isQuestionNotValid(questionField) || questionSaveId === questionField.id}
                                >
                                    {questionSaveId === questionField.id ? 'Saving...' : 'Save'}
                                </Button>
                            </Box>
                        </QuestionFooter>
                    </>
                )}
            </QuestionContainer>
        );
    };

    if (questionFields.length === 0) {
        return null;
    }

    return (
        <QuestionsContainer>
            {questionFields.map((questionField: QuestionField, index: number) => {
                const isNew = isIdUnsaved(questionField.id);

                if (isNew) return getNewQuestion(questionField, index);

                return getSavedQuestion(questionField, index);
            })}
        </QuestionsContainer>
    );
}

export default QuestionsList;
