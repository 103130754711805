import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import * as XLSX from 'xlsx';

import { loadAttendees, removeAttendee, updateAttendee } from 'api';
import colorFns from 'colorFns';
import AttendeeDrawer from 'components/Attendees/AttendeeDrawer';
import { ButtonMenu } from 'components/ButtonMenu';
import { DeleteConfirmationModal } from 'components/DeleteConfirmationModal';
import RightDrawer from 'components/Drawer/RightDrawer';
import { GuestListTable } from 'components/GuestListTable';
import HowToPopper from 'components/HowToPopper/HowToPopper';
import { MaterialTable } from 'components/MaterialTable';
import { Button } from 'components/Ui-V2/Button/Button';
import { PageHeadline } from 'components/ui/Headline';
import TallyDisplay from 'components/ui/TallyDisplay';
import { dietaryMap, foodAllergyMap } from 'constants/attendeeTypes';
import { useEvent } from 'providers/event';
import { contentWidth, statusColors, statusMap } from 'shared';
import { useCurrentInquiry } from 'stores/current-inquiry';
import { i18n } from 'translation';
import { AlignedColumn, Column, Copy, Row, SpacedRow, Spacer, StatusChip } from 'ui';
import { tzMoment } from 'utils/moment';
import { downloadFromUrl } from '../../util';
import { PageDescription } from '../BuildInquiry';
import { AddAttendees } from './AddAttendees';
import { AddAttendeesImport } from './AddAttendeesImport';

const attendeeStatusMap = {
    total: 'total',
    'not sent': 'notSent',
    invited: 'invited',
    attending: 'attending',
    'not attending': 'notAttending',
    tentative: 'tentative',
};

/**
 * Instead of using styled-components, we use @mui/material/styles styled()
 * and apply baseline styles on top of the existing ui components (SpacedRow, Row, etc.).
 */
const BaselineAlignedRow = styled(SpacedRow)(() => ({
    alignItems: 'baseline',
}));

const TotalRow = styled(Row)(() => ({
    paddingRight: '1rem',
    boxSizing: 'border-box',
}));

const PrimaryLink = styled(Link)(() => ({
    color: colorFns.primaryAction,
}));

export const Attendees = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { event } = useEvent();
    const { loaded, inquiry } = useCurrentInquiry.getState();

    const inquiryId = loaded ? String(inquiry?.id || 'new') : '';

    const [attendees, setAttendees] = useState([]);
    const [roomBlocks, setRoomBlocks] = useState(null);
    const [summary, setSummary] = useState({});
    const [deleteIntent, setDeleteIntent] = useState({});
    const [editIntent, setEditIntent] = useState(null);
    const [pending, setPending] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedAttendeesIds, setSelectedAttendeesIds] = useState([]);
    const [isDeleteSelectedAttendees, setIsDeleteSelectedAttendees] = useState(false);
    const [filteredAttendeesRows, setFilteredAttendeesRows] = useState([]);

    const updateEventAttendeesCount = useCallback(attendeesSummary => {
        if (!attendeesSummary) return;

        const transformedSummary = Object.keys(attendeesSummary).reduce(
            (acc, key) => ({ ...acc, [attendeeStatusMap[key]]: attendeesSummary[key] }),
            {}
        );

        setSummary(transformedSummary);
    }, []);

    /**
     * Fetches attendees from the API for the current event.
     */
    const refreshAttendees = useCallback(async eventId => {
        setPending(true);
        try {
            const response = await loadAttendees(eventId);
            updateEventAttendeesCount(response.summary);

            const formattedRoomBlocks = (response.roomBlock || []).map(roomBlock => ({
                ...roomBlock,
                single: roomBlock.single || 0,
                double: roomBlock.double || 0,
                suite: roomBlock.suite || 0,
                handicapAccessible: roomBlock.handicapAccessible || 0,
            }));

            setAttendees(response.attendees);
            setRoomBlocks(formattedRoomBlocks);
        } catch (e) {
            console.error(e);
            enqueueSnackbar(i18n.error.fetchMeetingData(eventId), {
                variant: 'error',
            });
        } finally {
            setPending(false);
        }
    }, []);

    const loadedAttendees = useRef(false);
    useEffect(() => {
        if (event?.id && !loadedAttendees.current) {
            refreshAttendees(event.id);
            loadedAttendees.current = true;
        }
    }, [event?.id]);

    // Handlers
    const handleEditClick = id => {
        if (!attendees || attendees.length === 0) return;
        setEditIntent(attendees.find(a => a.id === id));
    };

    const handleRemoveClick = id => {
        if (!attendees || attendees.length === 0) return;
        setDeleteIntent(attendees.find(a => a.id === id));
    };

    const getCombinedDietaryFoodAllergies = attendee => {
        const dietary = Array.isArray(attendee.dietaryRestrictions)
            ? attendee.dietaryRestrictions.map(id => dietaryMap[id])
            : [];
        const allergies = Array.isArray(attendee.foodAllergies)
            ? attendee.foodAllergies.map(id => foodAllergyMap[id])
            : [];
        return [...dietary, ...allergies];
    };

    const getFilteredAttendees = (allAttendees, filteredRows) => {
        const filteredAttendeeIds = filteredRows.map(row => row.id);
        return allAttendees.filter(a => filteredAttendeeIds.includes(a.id));
    };

    /**
     * Export the attendee list to an xlsx file.
     */
    const handleExportClick = () => {
        if (!attendees) return;
        const filteredAttendees = getFilteredAttendees(attendees, filteredAttendeesRows);

        if (filteredAttendees.length === 0) {
            enqueueSnackbar(i18n.guests.error.noAttendeesFound, { variant: 'warning' });
            return;
        }

        const dataToExport = filteredAttendees.map(attendee => ({
            'First Name': attendee.firstName,
            'Last Name': attendee.lastName,
            'Email Address': attendee.email,
            'Phone Number': attendee.phone,
            ID: attendee.externalId,
            'RSVP Status': attendee.status,
            'Check-In': attendee.checkInDate ? tzMoment(attendee.checkInDate).format('MM/DD/YYYY') : '',
            'Check-Out': attendee.checkOutDate ? tzMoment(attendee.checkOutDate).format('MM/DD/YYYY') : '',
            'Preferred Room Type': attendee.preferredRoomType,
            'Dietary Restrictions': getCombinedDietaryFoodAllergies(attendee).join(', '),
        }));

        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Attendees');

        const columnWidths = [20, 20, 20, 20, 20, 20, 20, 20, 30, 40];
        worksheet['!cols'] = columnWidths.map(width => ({ wch: width }));

        XLSX.writeFile(workbook, 'guest_list.xlsx');
    };

    /**
     * Delete/Confirm/Delete multiple attendees
     */
    const handleModalDismiss = () => {
        setDeleteIntent({});
        setIsDeleteSelectedAttendees(false);
    };

    const handleSelectAttendees = selected => {
        setSelectedAttendeesIds(selected);
    };

    const handleConfirmDeleteSelectedAttendees = () => {
        setIsDeleteSelectedAttendees(true);
    };

    const handleDeleteSelectedAttendees = async () => {
        if (!attendees || attendees.length === 0) return;
        setPending(true);

        const attendeesToDelete = attendees.filter(a => selectedAttendeesIds.includes(a.id));
        const attendeesCount = attendeesToDelete.length;

        try {
            await Promise.all(attendeesToDelete.map(a => removeAttendee(event.id, a)));

            if (attendeesCount > 2) {
                enqueueSnackbar(i18n.guests.removeConfirmation(`${attendeesCount} attendees`), {
                    variant: 'info',
                });
            } else {
                const emails = attendeesToDelete.map(a => a.email).join(', ');
                enqueueSnackbar(i18n.guests.removeConfirmation(emails), {
                    variant: 'info',
                });
            }
            handleDismissEdit();
        } catch (e) {
            console.error(e);
            enqueueSnackbar(i18n.guests.error.removeAttendees(event.id), {
                variant: 'error',
            });
        } finally {
            setDeleteIntent({});
            setPending(false);
            setIsDeleteSelectedAttendees(false);
            refreshAttendees(event.id);
        }
    };

    const handleDeleteConfirm = async () => {
        setPending(true);
        try {
            await removeAttendee(event.id, deleteIntent);
            enqueueSnackbar(i18n.guests.removeConfirmation(deleteIntent.email), {
                variant: 'info',
            });
            handleDismissEdit();
        } catch (e) {
            console.error(e);
            enqueueSnackbar(i18n.guests.error.removeAttendee(deleteIntent.id, event.id), {
                variant: 'error',
            });
        } finally {
            setDeleteIntent({});
            setPending(false);
            refreshAttendees(event.id);
        }
    };

    const handleDismissEdit = () => {
        setEditIntent(null);
        setDeleteIntent({});
    };

    const handleAttendeeSave = async draft => {
        try {
            await updateAttendee(event.id, draft);
            await refreshAttendees(event.id);
            enqueueSnackbar(i18n.guests.updateConfirmation(draft.email), { variant: 'info' });
            setEditIntent(null);
        } catch (e) {
            enqueueSnackbar(i18n.guests.error.updateAttendee(draft.email), {
                variant: 'error',
            });
        }
    };

    const handleTallyClick = label => {
        if (label === 'roomBlocks') {
            setDrawerOpen(true);
        }
    };

    const exportCsv = () => {
        if (event.roomBlockExportUrl) {
            downloadFromUrl(event.roomBlockExportUrl);
        }
    };

    const isDeleteModalActive = () => {
        return isDeleteSelectedAttendees || deleteIntent.status;
    };

    const handleDeleteAttendees = () => {
        if (isDeleteSelectedAttendees) {
            handleDeleteSelectedAttendees();
        } else {
            handleDeleteConfirm();
        }
    };

    const handlePrompt = () => {
        if (isDeleteSelectedAttendees) {
            return i18n.guests.removeMultipleGuests;
        }
        return deleteIntent.status === 'attending'
            ? i18n.guests.removeAttendingGuestPrompt
            : i18n.guests.removeNotAttendingGuestPrompt;
    };

    const handleSetFilteredAttendeesRows = rows => {
        if (!isEqual(filteredAttendeesRows, rows)) {
            setFilteredAttendeesRows(rows);
        }
    };

    const updateAttendees = updatedAttendees => {
        setAttendees(updatedAttendees);
        refreshAttendees(event.id);
        updateEventAttendeesCount(updatedAttendees);
    };

    // Transform data for the table
    const attendeeRows = (attendees || []).map(a => ({
        id: a.id,
        name: a.firstName && a.lastName ? `${a.firstName} ${a.lastName}` : '',
        email: a.email,
        phone: a.phone,
        status: <StatusChip status={a.status} label={statusMap[a.status]} statusColor={statusColors[a.status]} />,
    }));

    return (
        <Routes>
            <Route
                path="add/by-email"
                element={<AddAttendees attendees={attendees} setAttendees={updateAttendees} event={event} />}
            />
            <Route path="add/by-import" element={<AddAttendeesImport setAttendees={updateAttendees} />} />
            <Route
                path="*"
                element={
                    <AlignedColumn style={{ width: '100%' }}>
                        <DeleteConfirmationModal
                            isActive={isDeleteModalActive()}
                            onDismiss={handleModalDismiss}
                            onProceed={handleDeleteAttendees}
                            prompt={handlePrompt()}
                        />
                        <BaselineAlignedRow>
                            <Column>
                                <Row alignItems="center">
                                    <PageHeadline withDescription>
                                        {i18n.meetingDashboard.guestListSection.guestList}
                                    </PageHeadline>
                                    <HowToPopper sectionId="guest-lists" />
                                </Row>
                                <div style={{ width: contentWidth }}>
                                    <PageDescription large>{i18n.guests.subHeading}</PageDescription>
                                </div>
                            </Column>
                            <Row style={{ justifyContent: 'flex-end' }}>
                                <Button onClick={handleExportClick} style={{ marginRight: '16px' }} variant="outlined">
                                    {i18n.guests.exportList}
                                </Button>
                                {event.editable && (
                                    <ButtonMenu label={i18n.guests.addPeople}>
                                        <Link to={`/event/${event.id}/guest-list/add/by-email`}>
                                            <MenuItem>{i18n.guests.addByEmail}</MenuItem>
                                        </Link>
                                        <Link to={`/event/${event.id}/guest-list/add/by-import`}>
                                            <MenuItem>{i18n.guests.importList}</MenuItem>
                                        </Link>
                                    </ButtonMenu>
                                )}
                            </Row>
                        </BaselineAlignedRow>
                        <Spacer largest />
                        <TallyDisplay
                            flex
                            tallies={[
                                { label: 'total', tally: summary?.total ?? 0 },
                                { label: 'invited', tally: summary?.invited ?? 0 },
                                { label: 'not attending', tally: summary?.notAttending ?? 0 },
                                { label: 'attending', tally: summary?.attending ?? 0 },
                                {
                                    label: 'roomBlocks',
                                    tally: roomBlocks ? roomBlocks.reduce((total, rb) => total + rb.total, 0) : 0,
                                    onClick: label => handleTallyClick(label),
                                    description: `(${i18n.guests.clickable})`,
                                },
                            ]}
                            itemSize="auto"
                        />
                        <Spacer />
                        <Spacer />
                        <GuestListTable
                            onMultiDeleteAttendeesClick={handleConfirmDeleteSelectedAttendees}
                            onEditClick={handleEditClick}
                            handleSelectAttendees={handleSelectAttendees}
                            rows={attendeeRows}
                            setFilteredAttendeesRows={handleSetFilteredAttendeesRows}
                            loading={pending}
                        />
                        <RightDrawer
                            drawerOpen={drawerOpen}
                            onClose={() => setDrawerOpen(false)}
                            title={i18n.meetingDashboard.roomBlockSection.headline}
                            footer={
                                <Row itemSpacing="smallish">
                                    <Button onClick={exportCsv}>
                                        {i18n.meetingDashboard.roomBlockSection.exportCsv}
                                    </Button>
                                </Row>
                            }
                        >
                            <MaterialTable
                                headers={[
                                    { id: 'night', label: i18n.datetime.date },
                                    {
                                        id: 'single',
                                        label: i18n.meetingDashboard.roomBlockSection.single,
                                        align: 'center',
                                    },
                                    {
                                        id: 'double',
                                        label: i18n.meetingDashboard.roomBlockSection.double,
                                        align: 'center',
                                    },
                                    {
                                        id: 'suite',
                                        label: i18n.meetingDashboard.roomBlockSection.suite,
                                        align: 'center',
                                    },
                                    {
                                        id: 'handicapAccessible',
                                        label: i18n.meetingDashboard.roomBlockSection.handicapAccessible,
                                        align: 'center',
                                    },
                                    { id: 'total', label: i18n.guests.total, align: 'right' },
                                ]}
                                rows={
                                    roomBlocks?.map(roomBlock => ({
                                        id: roomBlock.night,
                                        data: {
                                            ...roomBlock,
                                            night: tzMoment(roomBlock.night).format('ll'),
                                        },
                                    })) ?? []
                                }
                            />
                            <Spacer small />
                            <TotalRow justifyContent="flex-end">
                                <Copy>{i18n.guests.total}</Copy>
                                <Spacer col largest />
                                <Copy>{roomBlocks ? roomBlocks.reduce((total, rb) => total + rb.total, 0) : '0'}</Copy>
                            </TotalRow>
                            <Spacer large />
                            <Row>
                                <span>
                                    {i18n.meetingDashboard.roomBlockSection.importNote}
                                    <PrimaryLink to={`/event/${event.id}/venue/inquiries/${inquiryId}`}>
                                        {i18n.meetingDashboard.roomBlockSection.inquiryBuilderPage}
                                    </PrimaryLink>
                                    .
                                </span>
                            </Row>
                        </RightDrawer>
                        {editIntent && (
                            <AttendeeDrawer
                                editable={event.editable}
                                attendee={editIntent}
                                eventId={event.id}
                                onDismiss={handleDismissEdit}
                                onSave={handleAttendeeSave}
                                onRemove={handleRemoveClick}
                            />
                        )}
                    </AlignedColumn>
                }
            />
        </Routes>
    );
};
