import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useState } from 'react';
import { Image } from './Image';

export type SortableImageItemProps = {
    onItemSelect?: (id: string) => void;
    isDragable?: boolean;
} & Bizly.VenueImage;

export const SortableImageItem = (props: SortableImageItemProps) => {
    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
    const sortable = useSortable({ id: props.id });
    const { attributes, listeners, setNodeRef, transform, transition } = sortable;

    const dragStyle = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
    };

    return (
        <Image
            isMouseOver={isMouseOver}
            onMouseOver={() => setIsMouseOver(true)}
            onMouseLeave={() => setIsMouseOver(false)}
            ref={setNodeRef}
            dragStyle={dragStyle}
            {...props}
            id={`${props.id}`}
            {...attributes}
            {...listeners}
        />
    );
};
