import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import { Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import BackArrowIcon from 'images/icons/back_arrow.svg?react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useAppConfigContext } from 'providers/app-config';
import { useEvent } from 'providers/event';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Fill, Slot } from 'react-slot-fill';
import { PostHogFeatureFlags } from 'shared';
import { LoadCurrentInquiry, hasBookedVenue, useCurrentInquiry } from 'stores/current-inquiry';
import styled from 'styled-components';
import { BizlyMuiTheme } from 'types/cssprop';
import { AlignedRow } from 'ui';
import { getHeaderHeight } from 'utils/header';
import { tzMoment } from 'utils/moment';
import CollaboratorsHeaderSection from './Collaborators/CollaboratorsHeaderSection';
import { showFinalSpendReminders } from './FinalSpendTracking';
import FinalSpendBanner from './FinalSpendTracking/Banner';
import { Notifications } from './NotificationHeader/Notifications';
import Button, { ButtonProps } from './ui/Button';
import { H3Headline } from './ui/Headline';

const Header = styled.header<{ transparent?: boolean; pageOffset: number; maxSearchWidth: number }>`
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: fixed;
    top: 0;
    z-index: 1000;
    width: calc(100vw - ${({ pageOffset }) => pageOffset}px);
    box-sizing: border-box;

    transition: 0.3s all ease-in-out;

    ${({ transparent, theme: { getColor, EColors } }) =>
        transparent
            ? `
    height: ${getHeaderHeight(transparent)}px;
    background: transparent;
    border-bottom: 1px solid transparent;`
            : `
    height: ${getHeaderHeight(transparent)}px;
    background: ${getColor(EColors.pureWhite)};
    border-bottom: 1px solid ${getColor(EColors.grey)};
  `}

    & > *:first-child {
        flex: 1 0 0;
        min-width: 0px;
        padding-right: 32px;
        padding-left: 36px;
        box-sizing: border-box;
    }

    & > *:nth-child(2) {
        flex: ${({ maxSearchWidth }) => maxSearchWidth}00 0 0; /* This allows the middle to grow before everything else */
        min-width: 324px;
        max-width: ${({ maxSearchWidth }) => maxSearchWidth}px;
    }

    & > *:last-child {
        padding-left: 32px;
        padding-right: 36px;
        box-sizing: border-box;
    }
    /* If the middle exists, size the right as same as left, if not, don't grow right */
    & > *:last-child:nth-child(2) {
        flex: 0 0 auto;
        width: auto;
        min-width: unset;
        max-width: unset;
    }
    & > *:last-child:nth-child(3) {
        flex: 1 0 0;
        min-width: 0px;
    }
`;

const HeaderShadow = styled(Header)`
    position: relative;
    width: 1px;
    visibility: hidden;
`;

const ItemsRight = styled(AlignedRow)`
    justify-content: flex-end;
`;
const ItemsLeft = styled(AlignedRow)`
    justify-content: flex-start;
`;

const BackButtonWrapper = styled(Button)`
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    align-items: center;
    justify-content: center;
`;

const NewHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${getHeaderHeight()}px;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.inactiveAction)};
    padding: 10px 20px;
    box-sizing: border-box;
    gap: 10px;
`;

const Divider = styled.hr`
    margin: 0 10px;
    border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.inactiveAction)};
    border-style: solid;
    border-width: 0;
    border-right-width: thin;
    height: auto;
    align-self: stretch;
`;

const EventInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

const EventInfoText = styled(Typography)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};
    font-size: 0.875rem;
    font-weight: 400;
`;

export const BackButton = ({ onClick, inverted }: { onClick: ButtonProps['onClick']; inverted?: boolean }) => {
    return (
        <BackButtonWrapper
            aria-label="navigate-back"
            onClick={onClick}
            secondary={inverted}
            noBorder
            width={30}
            height={30}
        >
            <BackArrowIcon />
        </BackButtonWrapper>
    );
};

const EventTitleStatic = styled(H3Headline)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};
    flex: 0 0 auto;
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 500;
`;

const EventLeftContent = () => {
    const { event } = useEvent();
    const EventTitleText = event.name || 'Untitled Meeting';

    return <EventTitleStatic>{EventTitleText}</EventTitleStatic>;
};

const EventRightContent = () => {
    const { event } = useEvent();
    const currentInquiry = useCurrentInquiry();

    return (
        <>
            <LoadCurrentInquiry />
            <ItemsRight>
                <CollaboratorsHeaderSection />
            </ItemsRight>
            {showFinalSpendReminders(event) && currentInquiry?.venues && hasBookedVenue(currentInquiry?.venues) && (
                <EventHeaderBannerFill>
                    <FinalSpendBanner />
                </EventHeaderBannerFill>
            )}
        </>
    );
};

const Left = ItemsLeft;
const Right = ItemsRight;

const EventHeaderLeftSlotName = 'Event.Header.Left.Slot';
const EventHeaderLeftSlot = () => <Slot name={EventHeaderLeftSlotName} />;

export const EventHeaderLeftFill = ({ children }: { children: React.ReactNode }) => (
    <Fill name={EventHeaderLeftSlotName}>{children}</Fill>
);

const EventHeaderRightSlotName = 'Event.Header.Right.Slot';
export const EventHeaderIconsSlot = () => <Slot name={EventHeaderRightSlotName} />;

export const EventHeaderIconsFill = ({ children }: { children: React.ReactNode }) => (
    <Fill name={EventHeaderRightSlotName}>{children}</Fill>
);

const EventHeaderBannerName = 'Event.Header.Banner.Slot';
const EventHeaderBannerSlot = () => <Slot name={EventHeaderBannerName} />;

const EventHeaderBannerFill = ({ children }: { children: React.ReactNode }) => (
    <Fill name={EventHeaderBannerName}>{children}</Fill>
);

const EventInfoIcon = ({ icon: IconComponent }: { icon: typeof SvgIcon }) => {
    const { getColor, EColors } = useTheme<BizlyMuiTheme>();
    return <IconComponent sx={{ color: getColor(EColors.primaryAction), width: 20, height: 20 }} />;
};

const LegacyEventHeader = ({
    loading,
    pageOffset = 0,
    maxSearchWidth = 440,
    isCreateMeeting,
}: {
    loading: boolean;
    pageOffset?: number;
    maxSearchWidth?: number;
    isCreateMeeting?: boolean;
}) => {
    const { transparentHeader } = useAppConfigContext();

    return (
        <>
            <Header transparent={transparentHeader} pageOffset={pageOffset} maxSearchWidth={maxSearchWidth}>
                <Left>
                    <Routes>
                        {isCreateMeeting && <Route path={`venue`} element={<EventHeaderLeftSlot />} />}
                        <Route path="*" element={<EventLeftContent />} />
                    </Routes>
                </Left>

                <Right>
                    <Routes>
                        <Route index element={!loading ? <EventRightContent /> : null} />
                    </Routes>
                </Right>
            </Header>
            <HeaderShadow pageOffset={pageOffset} maxSearchWidth={maxSearchWidth} />
        </>
    );
};

const NewEventHeader = () => {
    const { event } = useEvent();

    const locationName = `${event.city?.name ?? ''}, ${event.state?.code ?? ''}`;
    const timeFormat = 'MMM DD, YYYY - h:mm A';
    const startsAt = tzMoment(event.startsAt).format(timeFormat);
    const endsAt = tzMoment(event.endsAt).format(timeFormat);
    let budget = event.budget;
    if (!budget) {
        if (event.budgetPerPerson && event.estAttendance) {
            budget = Number(event.budgetPerPerson) * event.estAttendance;
        }
    }
    if (budget) {
        if (typeof budget === 'number' || !isNaN(Number(budget))) {
            budget = Number(budget).toLocaleString('en-US');
        }
    }

    return (
        <NewHeader>
            <Stack direction="row" alignItems="center">
                <EventTitleStatic>{event.name || 'Untitled Meeting'}</EventTitleStatic>
                <Divider />
                <EventInfo>
                    <EventInfoIcon icon={LocationOnOutlinedIcon} />
                    <EventInfoText>{locationName}</EventInfoText>
                </EventInfo>
                <Divider />
                <EventInfo>
                    <EventInfoIcon icon={CalendarTodayOutlinedIcon} />
                    <EventInfoText>{startsAt}</EventInfoText>
                </EventInfo>
                <Divider />
                <EventInfo>
                    <EventInfoIcon icon={CalendarTodayOutlinedIcon} />
                    <EventInfoText>{endsAt}</EventInfoText>
                </EventInfo>
                <Divider />
                <EventInfo>
                    <EventInfoIcon icon={PeopleOutlinedIcon} />
                    <EventInfoText>{event.estAttendance ?? 0}</EventInfoText>
                </EventInfo>
                {budget && (
                    <>
                        <Divider />
                        <EventInfo>
                            <EventInfoIcon icon={AttachMoneyIcon} />
                            <EventInfoText>{budget}</EventInfoText>
                        </EventInfo>
                    </>
                )}
            </Stack>
            <Stack direction="row" alignItems="center">
                <Notifications />
            </Stack>
        </NewHeader>
    );
};

const EventHeader = (props: {
    loading: boolean;
    pageOffset?: number;
    maxSearchWidth?: number;
    isCreateMeeting?: boolean;
}) => {
    const newEventHeaderEnabled = useFeatureFlagEnabled(PostHogFeatureFlags.toggleNewEventHeader);

    return (
        <>
            {newEventHeaderEnabled ? <NewEventHeader /> : <LegacyEventHeader {...props} />}
            <EventHeaderBannerSlot />
        </>
    );
};

export default EventHeader;
