import { TProposalForm } from 'components/ProposalForm/types';
import { create } from 'zustand';

type ProposalInquiryState = {
    proposalInquiryForm: Partial<TProposalForm>;
    setProposalInquiryForm: (form: Partial<TProposalForm>) => void;
    updateProposalInquiryForm: (updates: Partial<TProposalForm>) => void;
};

export const useProposalInquiryStore = create<ProposalInquiryState>(set => ({
    proposalInquiryForm: {
        client: {},
        event: {},
        inquiry: {},
        venue: {},
        contact: {},
        proposal: {},
        booking: {},
        guestRooms: [],
        eventSpaces: [],
    },
    setProposalInquiryForm: form => set({ proposalInquiryForm: form }),
    updateProposalInquiryForm: updates =>
        set(state => ({
            proposalInquiryForm: {
                ...state.proposalInquiryForm,
                ...updates,
            },
        })),
}));
