import AddIcon from '@mui/icons-material/Add';
import { alpha, Box, Stack } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { EventCreateProtocolModal, useCreateEventModalUrlValues } from 'components/EventCreateModal';
import LegacyEventCreateModal from 'components/EventCreateModal/EventCreateModal';
import EventCreateModal from 'components/EventCreateModal/NewEventCreateModal';
import { BottomBanner } from 'components/Home/BottomBanner';
import { EventTable } from 'components/Home/EventTable';
import IntegrationsPrompt from 'components/IntegrationsPrompt';
import NotificationHeader from 'components/NotificationHeader';
import SideNav from 'components/SideNav';
import { ButtonWithIcon } from 'components/Ui-V2/Button/Button';
import { H2Headline, PageHeadline } from 'components/Ui-V2/Headline/Headline';
import { Spacer } from 'components/Ui-V2/Spacer/Spacer';
import { useCreateMeeting } from 'hooks/useCreateMeeting';
import useShowModal from 'hooks/useShowModal';
import { useUser } from 'providers/user';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadBannerOptions from 'stores/home/LoadBannerOptions';
import { nudgesActions, useNudges } from 'stores/nudges';
import { ThemeContext } from 'styled-components';
import { i18n } from 'translation';

const StyledAddIcon = muiStyled(AddIcon)`
    font-size: 1.125rem;
`;

const StyledEventButton = muiStyled(ButtonWithIcon)(({ theme: { getColor, EColors } }) => ({
    '&.MuiButton-root': {
        boxShadow: `
        0rem .25rem .5rem 0rem ${alpha(getColor(EColors.pureBlack), 0.06)},
        0rem 0rem .25rem 0rem ${alpha(getColor(EColors.pureBlack), 0.04)}
      `,
        borderColor: getColor(EColors.lightGrey),
        height: '36.5px',
    },
}));

const StyledButtonWithIcon = muiStyled(ButtonWithIcon)({
    '&.MuiButton-containedPrimary': {
        width: 'fit-content',
        height: '36.5px',
    },
});

const VISITED_KEY = 'visited';

export default function Home() {
    const { user } = useUser();
    const theme = useContext(ThemeContext);
    const navigate = useNavigate();

    const { createEvent, createModalShown, protocolModalShown, closeCreateModal, hideProtocolModal } =
        useCreateMeeting();

    const values = useCreateEventModalUrlValues();
    const { errors, ...urlCreateFormValues } = values || {};
    const { modalShown: legacyModalShown, hideModal: hideLegacyCreateModal } = useShowModal(!!values);

    const { showFirstTimeIntegrations } = useNudges();
    const skip = () => {
        nudgesActions.mergeNudges({
            showFirstTimeIntegrations: false,
        });
    };

    useEffect(() => {
        const visited = localStorage.getItem(VISITED_KEY);
        if (!visited) {
            localStorage.setItem(VISITED_KEY, 'true');
            createEvent();
        }
    }, []);

    return (
        <SideNav>
            <LoadBannerOptions />
            <NotificationHeader />

            <Stack gap={2}>
                <PageHeadline withDescription>
                    {user.firstName ? i18n.homepage.welcome(user.firstName) : i18n.homepage.welcomeToBizly}
                </PageHeadline>

                <Box display="flex" flexDirection="row">
                    <StyledButtonWithIcon leftIcon={StyledAddIcon} onClick={() => createEvent()}>
                        {i18n.homepage.eventButtons.newEvent}
                    </StyledButtonWithIcon>

                    <StyledEventButton
                        leftIcon={StyledAddIcon}
                        variant="outlined"
                        color="secondary"
                        onClick={() => navigate('/playbooks')}
                    >
                        {i18n.homepage.eventButtons.startFromTemplate}
                    </StyledEventButton>
                </Box>
            </Stack>

            <Spacer />

            <Stack flex={1}>
                <Stack direction="row" alignItems="center">
                    <H2Headline>{i18n.homepage.eventTableActions.yourEvents}</H2Headline>
                </Stack>
                <Spacer xsmall />

                <EventTable createEvent={createEvent} />
            </Stack>

            {user.team?.bulletinMessage && (
                <BottomBanner
                    message={user.team.bulletinMessage}
                    teamImage={theme?.isThemeBright ? user.team.imageUrl : user.team.imageUrlWhite}
                />
            )}

            {showFirstTimeIntegrations && (
                <IntegrationsPrompt onSkip={skip} onlySkip title={i18n.integrations.connectAnIntegration} />
            )}

            {createModalShown && <EventCreateModal onClose={closeCreateModal} />}
            {legacyModalShown && (
                <LegacyEventCreateModal
                    defaultFormValues={urlCreateFormValues}
                    onClose={hideLegacyCreateModal}
                    errors={errors}
                />
            )}
            {protocolModalShown && !legacyModalShown && (
                <EventCreateProtocolModal onBackdropClick={hideProtocolModal} />
            )}
        </SideNav>
    );
}
