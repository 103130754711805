import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, styled } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { BorderedContainer } from 'components/BizlyOS/GridContainer';
import { ImageWithSkeleton } from 'components/BizlyOS/Image/ImageWithSkeleton';
import { IMPERIAL_UNIT, METRIC_UNIT } from 'components/BizlyOS/Proposals/utils';
import { SideDrawer } from 'components/BizlyOS/SideDrawer/SideDrawer';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { Spinner } from 'components/Spinner';
import VenuePlaceholderImage from 'images/bizlyOS/empty/empty-image-placeholder.png';
import { Dispatch, SetStateAction } from 'react';

const BackIcon = styled(KeyboardBackspaceIcon)(({ theme: { getColor, EColors } }) => ({
    fontSize: '1.2rem',
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    borderRadius: '50%',
    padding: '2px',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    '&:hover': {
        backgroundColor: getColor(EColors.bizlyOSPrimary, 0.2),
    },
}));

const Text = styled(Body2)(({ theme: { getColor, EColors } }) => ({
    fontWeight: 500,
    color: getColor(EColors.pureBlack),
    '& > span': {
        fontWeight: 400,
    },
}));

const SpaceCard = styled(BorderedContainer)(({ theme: { spacing } }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    gap: spacing(2.5),
    padding: spacing(2.5),
}));

const SelectButton = styled(Button)({
    height: 30,
    '& .MuiButton-endIcon': { marginLeft: '2px' },
});

const EmptyContainer = styled(BorderedContainer)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    justifyContent: 'center',
    padding: spacing(5),
    color: getColor(EColors.pureBlack, 0.5),
}));

type AddSpaceSideDrawerProps = {
    drawerOpen: boolean;
    onClose: (select?: boolean) => void;
    venueEventSpaces?: Bizly.OsEventSpace[];
    proposedVenueSpace?: Bizly.OsEventSpace;
    setProposedVenueSpace: Dispatch<SetStateAction<Bizly.OsEventSpace | undefined>>;
    onEditVenueSpace: (id: number | undefined) => void;
    onAddVenueSpace: () => void;
};

export function SelectVenueSpaceDrawer({
    drawerOpen,
    proposedVenueSpace,
    venueEventSpaces = [],
    onClose,
    onAddVenueSpace,
    onEditVenueSpace,
    setProposedVenueSpace,
}: AddSpaceSideDrawerProps) {
    return (
        <SideDrawer drawerOpen={drawerOpen} onClose={onClose} title="Space Details" invisibleBackdrop>
            {!venueEventSpaces ? (
                <Spinner />
            ) : (
                <Box display="flex" flexDirection="column" gap={2.5}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <BackIcon onClick={onClose} />
                        <Button onClick={onAddVenueSpace}>Create New Space</Button>
                    </Box>
                    {venueEventSpaces.length > 0 ? (
                        venueEventSpaces.map(eventSpace => {
                            const imageUrl = eventSpace?.images[0]?.srcUrl || VenuePlaceholderImage;

                            const disabled = proposedVenueSpace?.id === eventSpace.id;

                            return (
                                <SpaceCard key={eventSpace.id}>
                                    <ImageWithSkeleton src={imageUrl} alt={eventSpace.name} width="130" height="100" />
                                    <Box flex={1} display="flex" flexDirection="column" gap={0.5}>
                                        <Body1 fontWeight={600} lineHeight="21px">
                                            {eventSpace.name}
                                        </Body1>
                                        <Text>
                                            Size:{' '}
                                            <span>
                                                {eventSpace.size || '-'}{' '}
                                                {eventSpace.metric ? METRIC_UNIT : IMPERIAL_UNIT}
                                            </span>
                                        </Text>
                                        <Text>
                                            Max Capacity: <span>{eventSpace.maxCapacity || '-'}</span>
                                        </Text>
                                        <Button
                                            sx={{ width: 80, p: 0 }}
                                            variant="text"
                                            onClick={() => onEditVenueSpace(eventSpace.id)}
                                        >
                                            Edit Details
                                        </Button>
                                    </Box>
                                    <SelectButton
                                        variant="outline"
                                        onClick={() => {
                                            setProposedVenueSpace(eventSpace);
                                            onClose(true);
                                        }}
                                        disabled={disabled}
                                        endIcon={disabled ? null : <ArrowForwardIcon />}
                                    >
                                        {disabled ? 'Selected' : 'Select'}
                                    </SelectButton>
                                </SpaceCard>
                            );
                        })
                    ) : (
                        <EmptyContainer>No Spaces</EmptyContainer>
                    )}
                </Box>
            )}
        </SideDrawer>
    );
}
