import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import LinearScaleOutlinedIcon from '@mui/icons-material/LinearScaleOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import SubjectIcon from '@mui/icons-material/Subject';
import { questionDefinitionIds } from 'api';
import { i18n } from 'translation';
import { z } from 'zod';

export type QuestionField = {
    id: number;
    type: Bizly.QuestionType;
    value: string;
    options?: string[];
    linearScaleValues?: Bizly.LinearScaleValuesType;
    required: boolean;
};

export const QUESTION_TYPES: { [key: string]: Bizly.QuestionType } = {
    text: 'text',
    checkBox: 'multipleChoiceMultipleAnswers',
    radio: 'multipleChoice',
    linearScale: 'linearScale',
    date: 'date',
    dietaryRestrictions: 'dietaryRestrictions',
} as const;

export const QuestionTypes: { name: string; type: Bizly.QuestionType; icon: React.ReactElement }[] = [
    {
        name: i18n.questions.textBox,
        type: QUESTION_TYPES.text,
        icon: <SubjectIcon color="primary" fontSize="small" />,
    },
    {
        name: 'Multiple Choice',
        type: QUESTION_TYPES.checkBox,
        icon: <CheckBoxOutlinedIcon color="primary" fontSize="small" />,
    },
    {
        name: 'Single Choice',
        type: QUESTION_TYPES.radio,
        icon: <RadioButtonCheckedIcon color="primary" fontSize="small" />,
    },
    {
        name: i18n.questions.linearScale,
        type: QUESTION_TYPES.linearScale,
        icon: <LinearScaleOutlinedIcon color="primary" fontSize="small" />,
    },
    {
        name: i18n.questions.dateSelector,
        type: QUESTION_TYPES.date,
        icon: <EventAvailableOutlinedIcon color="primary" fontSize="small" />,
    },
];

export const { getNewUnsavedId, isIdUnsaved } = (() => {
    let unsavedId = 0; // refer to unPOSTED questions

    return {
        getNewUnsavedId: () => {
            unsavedId--;
            return unsavedId;
        },
        isIdUnsaved: (id: number) => id < 0, // keep it negative
    };
})();

export const isQuestionNotValid = (question: QuestionField): boolean => {
    if (!question.value) return true;

    if (question.type === QUESTION_TYPES.checkBox || question.type === QUESTION_TYPES.radio) {
        return !(question.options && question.options.length >= 2 && question.options[0] && question.options[1]);
    }

    if (question.type === QUESTION_TYPES.linearScale) {
        return !(
            question.linearScaleValues &&
            question.linearScaleValues.lowLabel &&
            question.linearScaleValues.highLabel &&
            question.linearScaleValues.lowValue &&
            question.linearScaleValues.highValue
        );
    }

    return false;
};

export function getTypeById(id: number) {
    const typeKeys = Object.keys(questionDefinitionIds) as Bizly.QuestionType[];
    const type = typeKeys.find(key => questionDefinitionIds[key] === id);
    return type;
}

/**
 * This function is specifically designed to generate dynamic data for the RsvpDrawer.
 * The defaultValues are extracted based on the default field names of the RsvpDrawer.
 * @returns A Zod schema and defaultValues for use with react-hook-form's useForm.
 */
export const generateFormSchema = ({
    customQuestions = [],
    dietaryRestrictionsEnable = false,
    roomBlockEnabled = false,
}: {
    customQuestions?: Bizly.SurveyQuestion[];
    dietaryRestrictionsEnable?: boolean;
    roomBlockEnabled?: boolean;
}) => {
    const defaultValues: Record<string, unknown> = {
        firstName: '',
        lastName: '',
        email: '',
        dietaryRestrictions: [],
        rsvpComments: '',
        checkInDate: '',
        checkOutDate: '',
    };

    const schemaFields: Record<string, z.ZodType> = {
        firstName: z.string().min(1, 'First name is required'),
        lastName: z.string().min(1, 'Last name is required'),
        email: z.string().email('Invalid email address'),
    };

    if (dietaryRestrictionsEnable) {
        schemaFields.dietaryRestrictions = z.array(z.string()).min(1, 'Required');
    }

    if (roomBlockEnabled) {
        schemaFields.rsvpComments = z.string().optional();
        schemaFields.checkInDate = z.string().min(1, 'Check-in is required');
        schemaFields.checkOutDate = z.string().min(1, 'Check-out is required');
        schemaFields.preferredRoomType = z.string().optional();
    }

    customQuestions.forEach(q => {
        let fieldSchema: z.ZodType;
        const type = getTypeById(q.definition.id);

        switch (type) {
            case QUESTION_TYPES.text:
            case QUESTION_TYPES.date:
            case QUESTION_TYPES.linearScale:
            case QUESTION_TYPES.radio:
                fieldSchema = q.responseRequired ? z.string().min(1, 'This is required') : z.string().optional();
                break;
            case QUESTION_TYPES.checkBox:
                fieldSchema = q.responseRequired
                    ? z.array(z.union([z.number(), z.string()])).nonempty('At least one option is required')
                    : z.array(z.union([z.number(), z.string()])).optional();
                break;
            default:
                fieldSchema = z.string().optional();
        }

        schemaFields[q.id.toString()] = fieldSchema;
    });

    return { FormSchema: z.object(schemaFields), defaultValues };
};
