import { alpha, Divider, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import emailAnimationData from 'animations/magic-email.json';
import {
    isAuthenticationPartialSuccess,
    isSSOOnlyAccount,
    loginWithGoogleId,
    sendMagicLink,
    sendVerificationCode,
} from 'api';
import { Button } from 'components/Ui-V2/Button/Button';
import SignInImage from 'images/authentication/sign-in.jpeg';
import BizlyLogo from 'images/logos/bizly-dark.svg?react';
import jwt_decode from 'jwt-decode';
import Lottie from 'lottie-react';
import { useSnackbar } from 'notistack';
import { useAuthenticate } from 'pages/Login/util';
import { useCallback, useState } from 'react';
import { i18n } from 'translation';
import { emailIsValid } from 'utils';
import { ExternalRedirect } from './Authentication';
import { GoogleAuthentication } from './GoogleAuthentication';
import { PlannerLogin } from './LoginFlow/PlannerLogin';
import { VerificationCodeInput } from './LoginFlow/VerificationCodeInput';
import { TAuthForm, TGoogleAuth, TResponseObject } from './types';

const OrDivider = styled(Divider)(({ theme: { getColor, EColors }, theme }) => ({
    margin: theme.spacing(2, 0),

    '&::before, &::after': {
        borderColor: alpha(getColor(EColors.pureBlack), 0.1),
    },
}));

const RootContainer = styled(Box)({
    display: 'flex',
    height: '100vh',
});

const LeftSection = styled(Box)(({ theme }) => ({
    flex: 1,
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    gap: theme.spacing(4),
    padding: theme.spacing(8),
    maxWidth: '31.25rem',
}));

const CenteredContent = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
});

const RightSection = styled(Box)({
    flex: 1,
    backgroundImage: `url(${SignInImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

const Logo = styled(BizlyLogo)({
    marginBottom: '1.5rem',
});

const AnimationContainer = styled(Box)({
    width: '6.25rem',
    height: '6.25rem',
    marginBottom: '1rem',
});

export const LOGIN_STATUS = {
    DEFAULT: 'DEFAULT',
    SSO: 'SSO',
    MAGIC_LINK_SENT: 'MAGIC_LINK_SENT',
    VERIFICATION_CODE_SENT: 'VERIFICATION_CODE_SENT',
} as const;

interface SignInPageProps {
    authFormValue: TAuthForm;
    onChange: (params: { value: Partial<TAuthForm> }) => void;
    onGoogleSignInSuccess: (user: BizlyAPI.User) => void;
    initialLoginStatus?: keyof typeof LOGIN_STATUS;
}

export const SignInPage = ({
    authFormValue,
    onChange,
    onGoogleSignInSuccess,
    initialLoginStatus = LOGIN_STATUS.DEFAULT,
}: SignInPageProps) => {
    const [loginStatus, setLoginStatus] = useState<keyof typeof LOGIN_STATUS>(initialLoginStatus);
    const [isLoading, setIsLoading] = useState(false);
    const [SSOLink, setSSOLink] = useState<string | null>(null);

    const { enqueueSnackbar } = useSnackbar();
    const [authenticating] = useAuthenticate();

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ value: { email: event.target.value } });
    };

    const handleSendLink = useCallback(async () => {
        if (!authFormValue.email || !emailIsValid(authFormValue.email)) {
            return enqueueSnackbar('Please enter a valid email address.', {
                variant: 'error',
            });
        }

        setIsLoading(true);
        try {
            await sendMagicLink({ email: authFormValue.email });
            setLoginStatus(LOGIN_STATUS.MAGIC_LINK_SENT);
        } catch (e) {
            if (isSSOOnlyAccount(e)) {
                setLoginStatus(LOGIN_STATUS.SSO);
                return setSSOLink(e.raw.ssoLink || null);
            }

            if (isAuthenticationPartialSuccess(e)) {
                return <ExternalRedirect to="https://www.bizly.com/pricing/" />;
            }

            return enqueueSnackbar('An error occurred. Please try again.', {
                variant: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    }, [authFormValue.email, enqueueSnackbar]);

    const handleSendVerificationCode = useCallback(async (email: string) => {
        if (!email) return;

        setIsLoading(true);
        try {
            const { success } = await sendVerificationCode({ email });

            if (success) {
                setLoginStatus(LOGIN_STATUS.VERIFICATION_CODE_SENT);
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleGoogleSignIn = useCallback(
        async (credentialResponse: TGoogleAuth) => {
            if (!credentialResponse.credential) {
                return enqueueSnackbar('Google sign-in failed. Please try again.', {
                    variant: 'error',
                });
            }

            setIsLoading(true);
            try {
                const { credential } = credentialResponse;

                const decoded: TResponseObject = jwt_decode(credential);
                const { email } = decoded;

                if (!email) {
                    return enqueueSnackbar(i18n.error.default, {
                        variant: 'error',
                    });
                }

                const { user } = await loginWithGoogleId({
                    email,
                    googleIdToken: credential,
                });

                if (user?.authToken) {
                    onGoogleSignInSuccess(user);
                } else {
                    throw new Error('Login successful but no auth token received');
                }
            } catch (e) {
                console.error('Login error:', e);
                if (isSSOOnlyAccount(e)) {
                    setLoginStatus(LOGIN_STATUS.SSO);
                    return setSSOLink(e.raw.ssoLink || null);
                }

                if (isAuthenticationPartialSuccess(e)) {
                    return <ExternalRedirect to="https://www.bizly.com/pricing/" />;
                }

                enqueueSnackbar('An error occurred during login. Please try again.', {
                    variant: 'error',
                });
            } finally {
                setIsLoading(false);
            }
        },
        [enqueueSnackbar, onGoogleSignInSuccess]
    );

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && authFormValue.email && emailIsValid(authFormValue.email)) {
            handleSendLink();
        }
    };

    if (authenticating) {
        return (
            <RootContainer>
                <LeftSection>
                    <Typography component="h1" gutterBottom sx={{ fontWeight: 500 }}>
                        {i18n.signIn.authenticating}
                    </Typography>
                </LeftSection>
                <RightSection />
            </RootContainer>
        );
    }

    const renderContent = () => {
        switch (loginStatus) {
            case LOGIN_STATUS.SSO:
                return (
                    <>
                        <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 500 }}>
                            {i18n.signIn.ssoLoginRequired}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {i18n.signIn.ssoLoginDescription}
                        </Typography>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => SSOLink && (window.location.href = SSOLink)}
                            sx={{ mt: 2, mb: 2 }}
                        >
                            {i18n.signIn.ssoLoginProceed}
                        </Button>
                    </>
                );

            case LOGIN_STATUS.MAGIC_LINK_SENT:
                return (
                    <>
                        <AnimationContainer>
                            <Lottie animationData={emailAnimationData} loop={false} />
                        </AnimationContainer>
                        <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 500 }}>
                            {i18n.signIn.emailSent}
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            {i18n.signIn.emailSentDescription}
                        </Typography>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => setLoginStatus(LOGIN_STATUS.DEFAULT)}
                            sx={{ mt: 2 }}
                        >
                            {i18n.signIn.backToSignIn}
                        </Button>
                    </>
                );

            case LOGIN_STATUS.VERIFICATION_CODE_SENT:
                return <VerificationCodeInput handleSendVerificationCode={handleSendVerificationCode} />;

            case LOGIN_STATUS.DEFAULT:
            default:
                return (
                    <>
                        <PlannerLogin
                            email={authFormValue.email || ''}
                            isLoading={isLoading}
                            onEmailChange={handleEmailChange}
                            onKeyPress={handleKeyPress}
                            onSendLink={handleSendLink}
                        />

                        <OrDivider>Or</OrDivider>

                        <GoogleAuthentication onSuccess={handleGoogleSignIn} />

                        <Typography variant="body2" sx={{ mb: 4, mt: 2 }}>
                            By continuing, you agree to our{' '}
                            <Link
                                href="https://www.bizly.com/terms-of-use"
                                color="primary"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Terms of Service
                            </Link>
                            <span> and </span>
                            <Link
                                href="https://bizly.com/privacy-policy/"
                                color="primary"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </Link>
                            <span>.</span>
                        </Typography>
                    </>
                );
        }
    };

    return (
        <RootContainer>
            <LeftSection>
                <Box>
                    <Logo />
                </Box>
                <CenteredContent>
                    <Box>{renderContent()}</Box>
                </CenteredContent>
            </LeftSection>
            <RightSection />
        </RootContainer>
    );
};
