import * as Sentry from '@sentry/react';
import {
    createQuestion,
    deleteQuestion,
    getBookingsForEvent,
    getEvents,
    getEventsResources,
    loadEventRegistrationInfo,
    loadQuestions,
    updateQuestion,
} from 'api';
import { GET_BOOKINGS_FOR_EVENT, GET_EVENT_RESOURCES } from 'constants/queryKeys';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { GetEventsFilters } from 'stores/events/eventsStore';

export type TEventsResponse = {
    success: boolean;
    events: Bizly.Event[];
    collaborators?: Bizly.EventCollaborator[];
};

type Response = {
    success: boolean;
};

export const useEventsQuery = (query: Partial<GetEventsFilters>) => {
    const queryString = useMemo(() => {
        const queryObject = {} as Record<string, string>;
        if (query.include) {
            queryObject['include'] = query.include.join(',');
        }
        return new URLSearchParams(queryObject).toString();
    }, [query]);

    return useQuery<TEventsResponse>({
        queryKey: ['events', JSON.stringify(query)],
        queryFn: () => getEvents(queryString),
        onError: (error: unknown) => {
            console.error('Error fetching events:', error);

            Sentry.captureException(error, {
                tags: {
                    component: 'useEventsQuery',
                },
            });
        },
    });
};

export const useGetParcelsQuestionsQuery = (parcelId: number) => {
    const result = useQuery({
        queryKey: ['parcel_questions', parcelId],
        queryFn: () => loadQuestions(parcelId),
    });
    return result;
};

export const useUpdateParcelsQuestionsMutation = (parcelId: number, onFinish: () => void) => {
    const queryClient = useQueryClient();
    return useMutation<Response, Error, Bizly.ParcelRsvpQuestion>(question => updateQuestion(parcelId, question), {
        onSuccess: response => {
            if (response) {
                queryClient.invalidateQueries(['parcel_questions', parcelId]);
                onFinish();
            }
        },
        onError: (error: Error) => {
            Sentry.captureException(error, {
                tags: {
                    component: 'useUpdateParcelsQuestionsMutation',
                    parcelId: parcelId,
                },
                extra: {
                    parcelId: parcelId,
                },
            });
        },
    });
};

export const useCreateParcelsQuestionsMutation = (parcelId: number, onFinish: () => void) => {
    const queryClient = useQueryClient();
    return useMutation<Response, Error, Bizly.ParcelRsvpQuestion>(question => createQuestion(parcelId, question), {
        onSuccess: response => {
            if (response) {
                queryClient.invalidateQueries(['parcel_questions', parcelId]);
                onFinish();
            }
        },
        onError: (error: Error) => {
            Sentry.captureException(error, {
                tags: {
                    component: 'useCreateParcelsQuestionsMutation',
                    parcelId: parcelId,
                },
                extra: {
                    parcelId: parcelId,
                },
            });
        },
    });
};

export const useRemoveParcelsQuestionsMutation = (parcelId: number) => {
    const queryClient = useQueryClient();
    return useMutation<BizlyAPI.DeleteResponse, Error, number>(id => deleteQuestion(parcelId, id), {
        onSuccess: response => {
            if (response.success) {
                queryClient.invalidateQueries(['parcel_questions', parcelId]);
            }
        },
        onError: (error: Error) => {
            Sentry.captureException(error, {
                tags: {
                    component: 'useRemoveParcelsQuestionsMutation',
                    parcelId: parcelId,
                },
                extra: {
                    parcelId: parcelId,
                },
            });
        },
    });
};

export const useGetRegistrationInfoQuery = (eventId: number, slug: string | undefined, enabled = true) => {
    const result = useQuery({
        queryKey: ['registration_info', eventId, slug],
        queryFn: () => loadEventRegistrationInfo(eventId, slug),
        enabled,
    });
    return result;
};

export const useGetEventsResourcesQuery = (eventId: number) => {
    return useQuery({
        queryKey: [GET_EVENT_RESOURCES, eventId],
        queryFn: () => getEventsResources(eventId),
    });
};

export const useGetBookingsForEventQuery = (eventId: number) => {
    return useQuery({
        queryKey: [GET_BOOKINGS_FOR_EVENT, eventId],
        queryFn: () => getBookingsForEvent(eventId),
        enabled: !!eventId,
    });
};
