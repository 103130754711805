import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Checkbox, styled } from '@mui/material';
import { HTMLAttributes, forwardRef } from 'react';
import { EColors, getColor } from 'theme';
import { SortableImageItemProps } from './SortableImageItem';

const ImageContainer = styled(Box)(({ srcUrl }) => ({
    transformOrigin: '0 0',
    width: '100%',
    aspectRatio: 1,
    backgroundImage: `url("${srcUrl}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'grey',
    position: 'relative',
    cursor: 'grab',
}));

const ImageMask = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    boxSizing: 'border-box',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: getColor(EColors.pureBlack, 0.6),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: spacing(1.25),
}));

const StyledCheckbox = styled(Checkbox)({
    padding: 0,
    '& .MuiSvgIcon-root': {
        color: getColor(EColors.pureWhite),
    },
});

type ImageProps = {
    isMouseOver?: boolean;
    dragStyle?: object;
} & SortableImageItemProps &
    HTMLAttributes<HTMLDivElement>;

export const Image = forwardRef<HTMLDivElement, ImageProps>(
    ({ id, dragStyle, isMouseOver, onItemSelect, isSelected, isDragable = true, ...props }, ref) => {
        return (
            <ImageContainer ref={ref} sx={dragStyle} {...props}>
                {(isMouseOver || isSelected) && (
                    <ImageMask>
                        {isDragable ? <DragIndicatorIcon sx={{ color: getColor(EColors.pureWhite) }} /> : <Box />}
                        <StyledCheckbox checked={isSelected} color="primary" onChange={() => onItemSelect?.(id)} />
                    </ImageMask>
                )}
            </ImageContainer>
        );
    }
);
