import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { Box, styled, Typography } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers-pro';
import { Moment } from 'moment';
import { FieldError } from 'react-hook-form';
import { EColors, getColor } from 'theme';
import { tzMoment } from 'utils/moment';

const StyledDatePicker = styled(MuiDatePicker, {
    shouldForwardProp: prop => prop !== 'iconColor' && prop !== 'showDefaultError',
})(({ iconColor, disabled, showDefaultError, showEmptyError, error, theme: { getColor, EColors } }) => ({
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: '4px',
    '& .MuiInputBase-root > input': {
        fontSize: '1rem',
        padding: '13.5px',
    },
    '& .MuiInputBase-root > .MuiOutlinedInput-notchedOutline': {
        borderRadius: '4px',
        ...(error
            ? { borderColor: getColor(EColors.red) }
            : showDefaultError
              ? showEmptyError
                  ? { borderColor: getColor(EColors.red) }
                  : {}
              : { borderColor: getColor(EColors.inactiveAction) }), // Disabling the default error indicator
    },
    '& .MuiInputBase-root .MuiIconButton-root': {
        color: disabled ? getColor(EColors.inactiveAction) : iconColor,
    },
    '& .MuiInputBase-root .MuiIconButton-root > .MuiSvgIcon-root': {
        fontSize: '1.25rem',
    },
}));

type DatePickerProps = {
    label?: string;
    error: FieldError | undefined;
    showDefaultError?: boolean;
    showEmptyError?: boolean;
    value: string | undefined;
    timeZone?: string;
    disabled?: boolean | undefined;
    required?: boolean;
    iconColor?: string;
    disablePast?: boolean;
    onChange?: (dateText: string) => void;
    onAccept?: (dateText: string) => void;
    shouldDisableDate?: (date: Moment) => boolean;
};

const DatePicker = ({
    label,
    error,
    disabled = false,
    required = false,
    disablePast = false,
    value: date,
    iconColor = getColor(EColors.primaryAction),
    timeZone = 'UTC',
    showDefaultError = false,
    showEmptyError = false,
    onChange,
    onAccept,
    shouldDisableDate,
}: DatePickerProps) => {
    return (
        <Box>
            {label && (
                <Box display="flex" gap={0.5} marginBottom={1}>
                    <Typography fontWeight={500}>{label}</Typography>

                    <Typography color={getColor(EColors.pureBlack, 0.5)} fontWeight={500}>
                        {required ? '(required)' : ''}
                    </Typography>
                </Box>
            )}
            <StyledDatePicker
                iconColor={iconColor}
                value={date ? tzMoment(date, timeZone) : undefined}
                onChange={(value: Moment) => {
                    onChange?.(value.format('YYYY-MM-DD'));
                }}
                slots={{
                    openPickerIcon: CalendarMonthOutlinedIcon,
                }}
                onAccept={(value: Moment) => onAccept?.(value.format('YYYY-MM-DD'))}
                disabled={disabled}
                disablePast={disablePast}
                shouldDisableDate={shouldDisableDate}
                slotProps={{
                    popper: {
                        disablePortal: true,
                    },
                }}
                error={!!error}
                showDefaultError={showDefaultError}
                showEmptyError={showEmptyError && !date}
            />

            {!!error && (
                <Typography variant="body2" color={getColor(EColors.red)} fontWeight={500}>
                    {error.message}
                </Typography>
            )}
        </Box>
    );
};
// );

export default DatePicker;
