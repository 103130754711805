import colorFns from 'colorFns';
import { DragAndDropUpload } from 'components/DragAndDropUpload';
import {
    AutoComplete,
    Checkbox,
    CounterField,
    CsvUploadField,
    DatePickerField,
    DatePickerOutlined,
    DatePickerOutlinedField,
    DateRangePicker,
    DisplayField,
    ImageUploadField,
    LabeledTextField,
    LinearScaleField,
    Multiselect,
    NoneField,
    RadioSelect,
    SelectField,
    Slider,
    TextArea,
    UploadList,
    UploadSingle,
    fixedWidthStyles,
    perRowStyles,
} from 'components/FormFields';
import PhoneField from 'components/PhoneField';
import fontFns from 'fontFns';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import React from 'react';
import styled from 'styled-components';
import { Column, Copy, InlineRow, Row, Spacer, Switch } from 'ui';
import { ColorPicker, RichTextEditor, TimeZonePicker } from './fields';
import { getIdentifier } from './utils';

// FORM FIELD
export const FieldHeader = styled(InlineRow)`
    font-size: 1em;
    line-height: 1.25em;
    font-weight: 500;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}
    margin-bottom: 0.5em;
`;

export const FieldDescription = styled(Copy)`
    color: ${colorFns.darkGrey};
`;

const FieldOptionalLabel = styled.span`
    font-size: 13px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.optionalSpecifier)};
`;

const FormField = styled(Column)`
    ${({ fixedWidth, perRow }) => (fixedWidth !== undefined ? fixedWidthStyles(fixedWidth) : perRowStyles(perRow))}
`;

const InlineFormField = styled(InlineRow)`
    align-items: center;
    gap: 1em;
`;

const Field = ({
    field,
    prompt,
    description,
    optional,
    perRow,
    fixedWidth,
    readonly,
    disabled,
    densePadding,
    nullSwitch,
    options,
    Component,
    value,
    onChange,
    errors,
    nullSwitchCallback,
    inline,
}) => {
    const FieldWrapper = nullSwitch && inline ? InlineFormField : React.Fragment;

    return React.useMemo(
        () => (
            <FormField fixedWidth={fixedWidth} perRow={perRow} data-attribute={getIdentifier(field, '-wrapper')}>
                <FieldWrapper>
                    {prompt && (
                        <FieldHeader
                            alignItems="center"
                            itemSpacing="xsmall"
                            data-attribute={getIdentifier(field, '-header')}
                        >
                            <span data-attribute={getIdentifier(field, '-prompt')}>{prompt}</span>
                            {!optional && !readonly && (
                                <FieldOptionalLabel data-attribute={getIdentifier(field, '-required-label')}>
                                    (required)
                                </FieldOptionalLabel>
                            )}
                            {nullSwitch && (
                                <Switch
                                    data-attribute={getIdentifier(field, '-null-switch')}
                                    checked={value !== null}
                                    onChange={e => {
                                        nullSwitchCallback && nullSwitchCallback();
                                        onChange({ field, value: e.target.checked ? undefined : null });
                                    }}
                                />
                            )}
                        </FieldHeader>
                    )}
                    {description && (
                        <FieldDescription data-attribute={getIdentifier(field, '-description')}>
                            {description}
                        </FieldDescription>
                    )}
                    {(nullSwitch ? value !== null : true) && Component && (
                        <Component
                            field={field}
                            isNested
                            optional={optional}
                            readonly={readonly}
                            disabled={disabled}
                            densePadding={densePadding}
                            {...options}
                            value={value}
                            onChange={onChange}
                            errors={errors}
                            prompt={prompt}
                            data-attribute={getIdentifier(field, '-input')}
                        />
                    )}
                </FieldWrapper>
            </FormField>
        ),
        [
            FieldWrapper,
            Component,
            densePadding,
            disabled,
            errors,
            field,
            fixedWidth,
            onChange,
            optional,
            nullSwitch,
            options,
            perRow,
            prompt,
            description,
            readonly,
            value,
            nullSwitchCallback,
        ]
    );
};

const FormHeading = styled.div`
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formHeading)};
    ${fontFns.formHeading}

    min-height: 18px;
`;

const FieldsRow = styled(Row)`
    width: auto;
    align-items: stretch;

    ${({ combined }) =>
        combined
            ? `
    color: blue;

    > * {
        &:hover,
        & .Mui-focused {
            z-index: 1;
        }

        &:not(:last-child) fieldset,
        &:not(:last-child) ${DatePickerOutlined} input {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            margin-right: -0.5px;
            padding-right: 0;
        }

        &:not(:first-child) fieldset,
        &:not(:first-child) ${DatePickerOutlined} input {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            margin-left: -0.5px;
            padding-left: 0;
        }

        &:not(:last-child) .MuiInputBase-input {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }
    `
            : ''}
`;

const NestedSchema = styled(FormField)``;

const TYPE_TO_COMPONENT = {
    none: NoneField,
    display: DisplayField,
    select: SelectField,
    checkbox: Checkbox,
    multiselect: Multiselect,
    radioselect: RadioSelect,
    text: LabeledTextField,
    textarea: TextArea,
    rich_text: RichTextEditor,
    date: DatePickerField,
    timezone_select: TimeZonePicker,
    date_outlined: DatePickerOutlinedField,
    slider: Slider,
    drag_and_drop_upload: DragAndDropUpload,
    upload_list: UploadList,
    image_uploader: ImageUploadField,
    csv_uploader: CsvUploadField,
    upload_single: UploadSingle,
    counter: CounterField,
    linear_scale: LinearScaleField,
    color_picker: ColorPicker,
    phone: PhoneField,
    date_range: DateRangePicker,
    autocomplete: AutoComplete,
};

const noOptions = {};

const Form = ({
    fields,
    schema,
    value,
    onChange,
    errors = noOptions,
    isNested = false,
    readonly: formReadonly = false,
    hideReadonlyEmpty = false,
    disabled: formDisabled = false,
    densePadding: formDensePadding = false,
    className = undefined,
}) => {
    const curVal = React.useRef(value);
    const curErr = React.useRef(errors);

    React.useEffect(() => {
        curVal.current = value;
    }, [curVal, value]);

    React.useEffect(() => {
        curErr.current = errors;
    }, [curErr, errors]);

    const fieldOnChange = React.useMemo(
        () =>
            ({ field, value: fieldValue, errors: fieldErrors }) => {
                /* 
                    TODO: This is a workaround for some field onChange events sending a
                    DOM event rather than a short term value. This sort of inconsistency
                    will be fixed after migrating all input controls to newer implementations,
                    such as the case with the newer <Input /> utility component`
                */
                const parsedValue = fieldValue?.target ? fieldValue.target.value : fieldValue;

                return onChange({
                    value: deepSet(curVal.current, field, parsedValue),
                    errors: deepSet(curErr.current, field, fieldErrors),
                    field,
                });
            },
        [onChange]
    );

    const buildField = field => {
        const {
            prompt,
            description,
            type,
            optional,
            perRow,
            fixedWidth,
            readonly: fieldReadonly,
            disabled: fieldDisabled,
            options = noOptions,
            nullSwitch,
            nullSwitchCallback,
            inline,
        } = fields[field];
        const Component = typeof type === 'string' ? TYPE_TO_COMPONENT[type] : type;
        const fieldValue = value[field];
        const fieldErrors = errors[field];
        const props = {
            field,
            prompt,
            description,
            optional,
            perRow,
            fixedWidth,
            readonly: formReadonly || fieldReadonly,
            disabled: formDisabled || fieldDisabled,
            densePadding: formDensePadding || (formReadonly && !isNested),
            nullSwitch,
            nullSwitchCallback,
            inline,
            options,
            Component,
            value: fieldValue,
            onChange: fieldOnChange,
            errors: fieldErrors,
        };

        return hideReadonlyEmpty && props.readonly && isEmpty(fieldValue) ? null : <Field key={field} {...props} />;
    };

    const getSchemaFieldType = schemaField =>
        typeof schemaField === 'string' || typeof schemaField === 'number'
            ? 'field'
            : isPlainObject(schemaField)
              ? schemaField.type
              : '';

    const getKeyForNestedSchema = nestedSchema =>
        nestedSchema.schema.map(schemaRow => getKeyForRowDef(schemaRow)).join('|');

    const getKeyForRowDef = rowDef =>
        rowDef.fields
            ?.map(field => {
                switch (getSchemaFieldType(field)) {
                    case 'field':
                        return field;
                    case 'nested':
                        return getKeyForNestedSchema(field);
                    case 'none':
                    case 'display':
                        return field.key;
                    default:
                        return '';
                }
            })
            .join('|') + 'row';

    const mapSchemaField = schemaField => {
        switch (getSchemaFieldType(schemaField)) {
            case 'field':
                return buildField(schemaField);
            case 'nested':
                return (
                    <NestedSchema
                        fixedWidth={schemaField.fixedWidth}
                        perRow={schemaField.perRow}
                        key={getKeyForNestedSchema(schemaField)}
                    >
                        {schemaField.prompt && (
                            <FieldHeader itemSpacing="xsmall">
                                <span>{schemaField.prompt}</span>
                                {!schemaField.readonly && !schemaField.optional && (
                                    <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                )}
                            </FieldHeader>
                        )}
                        {schemaField.prompt && <Spacer small />}
                        {schemaField.description && <FieldDescription>{schemaField.description}</FieldDescription>}
                        {schemaField.description && <Spacer small />}
                        {buildSchema(schemaField.schema)}
                    </NestedSchema>
                );
            case 'none':
            case 'display':
                const { key, text, type, perRow, ...fieldDef } = schemaField;
                return (
                    <Field
                        key={schemaField.text || schemaField.key}
                        Component={TYPE_TO_COMPONENT[type]}
                        perRow={perRow || 'auto'}
                        {...fieldDef}
                    />
                );

            default:
                return null;
        }
    };

    const buildSchema = schema => {
        const buildRow = rowDef => {
            const {
                key = getKeyForRowDef(rowDef),
                header,
                headerSpacing = 'medium',
                fields: s_fields,
                spacing = 'larger',
                itemSpacing = 'smallish',
                maxWidth,
                combined,
            } = rowDef;

            const rendered = s_fields?.map(mapSchemaField);
            const allNull = isEmpty(rendered?.filter(renderedField => renderedField ?? undefined));

            return hideReadonlyEmpty && allNull ? null : (
                <Column style={{ maxWidth }} key={key}>
                    {header && <FormHeading>{header}</FormHeading>}
                    {header && <Spacer {...{ [headerSpacing]: true }} />}
                    <FieldsRow itemSpacing={itemSpacing} paddingSpacing combined={combined}>
                        {rendered}
                    </FieldsRow>
                    {spacing && <Spacer {...{ [spacing]: true }} />}
                </Column>
            );
        };

        const rowList = schema.map(buildRow);
        if (hideReadonlyEmpty && formReadonly) {
            const lastRowIdx = rowList.reduceRight((lastRowIdx, curRow, i) => {
                if (lastRowIdx !== undefined) return lastRowIdx;
                if (curRow) return i;
                return lastRowIdx;
            }, undefined);
            if (lastRowIdx !== undefined) {
                rowList[lastRowIdx] = buildRow({ ...schema[lastRowIdx], spacing: false });
            }
        }

        return rowList;
    };

    const FormOrDiv = isNested ? 'form' : 'div';
    const builtSchema = buildSchema(schema);
    if (hideReadonlyEmpty && isEmpty(builtSchema.filter(renderedField => renderedField ?? undefined))) return null;
    return <FormOrDiv className={className}>{builtSchema}</FormOrDiv>;
};

// Helper to copy and set a deep value given an object path like: 'social.facebook'
const deepSet = (obj, path, value) => {
    const objCopy = { ...obj };

    const pathPieces = ('' + path).split('.');
    const lastKey = pathPieces[pathPieces.length - 1];
    const beforeLast = pathPieces[pathPieces.length - 2];

    pathPieces.reduce((newObj, key) => {
        // duplicate the parent of the object/key to set
        if (key === beforeLast) {
            newObj[key] = { ...newObj[key] };
        }

        // set the value
        return key === lastKey ? (newObj[key] = value) : newObj[key];
    }, objCopy);

    return objCopy;
};

export default Form;
