import { AudioAndVisual } from './AudioAndVisual';
import { FoodAndBeverage } from './FoodAndBeverage';
import { Pricing } from './Pricing';
import { SetupRequest } from './SetupRequest';
import { EventSpaceFormProps } from './utils';

export function EventSpaceForm(props: EventSpaceFormProps) {
    return (
        <>
            <SetupRequest {...props} />
            <AudioAndVisual {...props} />
            <FoodAndBeverage {...props} />
            <Pricing {...props} />
        </>
    );
}
