import { zodResolver } from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { confirmVerificationCode, logOutUser } from 'api';
import { useUser } from 'providers/user';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { getColor } from 'theme';
import { EColors } from 'types/themenum';
import { removeCookie } from 'utils/tokens';
import * as z from 'zod';
import { LOGIN_STATUS } from '../SignInPage';

const verificationSchema = z.object({
    code: z.array(z.string().regex(/^\d$/)).length(6),
});

type VerificationFormData = z.infer<typeof verificationSchema>;

interface VerificationCodeInputProps {
    handleSendVerificationCode: (email: string) => void;
}

const StyledTextField = styled(TextField, {
    shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>(({ error }) => ({
    '& .MuiInputBase-root': {
        height: '50px',
        '&.Mui-focused.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${error ? getColor(EColors.warningText) : getColor(EColors.bizlyOSGray20)} !important`,
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '4px',
        border: error ? `1px solid ${getColor(EColors.warningText)}` : `1px solid ${getColor(EColors.bizlyOSGray20)}`,
    },
    '& input': {
        color: error ? getColor(EColors.warningText) : 'inherit',
    },
}));

const StyledResendLink = styled(Link)({
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
    },
    verticalAlign: 'baseline',
});

export const VerificationCodeInput = ({ handleSendVerificationCode }: VerificationCodeInputProps) => {
    const { user, clearUser } = useUser();
    const [error, setError] = useState<string | null>(null);
    const [failedAttempts, setFailedAttempts] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const { control, setValue, getValues } = useForm<VerificationFormData>({
        defaultValues: {
            code: ['', '', '', '', '', ''],
        },
        resolver: zodResolver(verificationSchema),
    });

    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const verificationAttempted = useRef(false);

    const handleVerifyCode = useCallback(async () => {
        try {
            if (verificationAttempted.current) return;
            verificationAttempted.current = true;

            const code = getValues('code').join('');
            if (!user?.email) {
                throw new Error('Email is required');
            }

            const { success } = await confirmVerificationCode({
                email: user.email,
                code,
            });

            if (success) {
                localStorage.setItem('email_verification', user.email);
                const fromPath = location.state?.from?.pathname || '/os';
                navigate(fromPath, { replace: true });
                setFailedAttempts(0);
            } else {
                throw new Error('Invalid verification code');
            }
        } catch (error) {
            const message = error instanceof Error ? error.message : 'Invalid verification code';
            setError(message);
            setFailedAttempts(prev => prev + 1);
        } finally {
            verificationAttempted.current = false;
        }
    }, [user?.email, navigate, getValues, location.state?.from]);

    const handleSignOut = useCallback(async () => {
        if (user?.id) {
            await logOutUser(user.id);
            removeCookie();
            clearUser();
        }
    }, [user?.id, clearUser]);

    useEffect(() => {
        if (failedAttempts === 3) {
            handleSignOut();
            const timer = setTimeout(() => {
                navigate('/sign-in', {
                    replace: true,
                    state: { initialLoginStatus: LOGIN_STATUS.DEFAULT },
                });
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [failedAttempts, handleSignOut, navigate]);

    const handleChange = (index: number, value: string) => {
        const newValue = value.replace(/\D/g, '');
        const currentCode = getValues('code');
        const newCode = [...currentCode];
        newCode[index] = newValue;

        setValue('code', newCode);

        if (newValue && index < 5) {
            inputRefs.current[index + 1]?.focus();
        } else if (newCode.every(c => c.length === 1)) {
            handleVerifyCode();
        }
        setError(null);
    };

    const handleKeyDown = (index: number, e: React.KeyboardEvent) => {
        if (e.key === 'Backspace') {
            e.preventDefault();
            const currentCode = getValues('code');
            const newCode = [...currentCode];
            newCode[index] = '';
            setValue('code', newCode);

            if (index > 0) {
                inputRefs.current[index - 1]?.focus();
            }
            setError(null);
        }
    };

    const handlePaste = (e: React.ClipboardEvent) => {
        e.preventDefault();

        const numericRegex = /^\d+$/;
        const pastedData = e.clipboardData.getData('text/plain');

        if (numericRegex.test(pastedData)) {
            const numbers = pastedData.slice(0, 6).split('');
            const newCode = [...numbers, ...Array(6 - numbers.length).fill('')];
            setValue('code', newCode);

            if (numbers.length === 6) {
                handleVerifyCode();
            }

            const nextEmptyIndex = Math.min(numbers.length, 5);
            inputRefs.current[nextEmptyIndex]?.focus();
            setError(null);
        }
    };

    return (
        <>
            <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 500 }}>
                Verification
            </Typography>
            <Typography variant="body1" paragraph>
                {`Enter the 6-digit code we just sent to `}
                <Typography component="span" sx={{ fontWeight: 'bold' }}>
                    {user?.email}
                </Typography>
                {` below.`}
            </Typography>

            <form>
                <Box display="flex" flexDirection="column" gap={2}>
                    <Box display="flex" gap={1.5}>
                        {Array.from({ length: 6 }).map((_, index) => (
                            <Controller
                                key={index}
                                name={`code.${index}`}
                                control={control}
                                render={({ field }) => (
                                    <StyledTextField
                                        {...field}
                                        value={field.value || ''}
                                        inputRef={(el: HTMLInputElement | null) => (inputRefs.current[index] = el)}
                                        variant="outlined"
                                        error={Boolean(error)}
                                        inputProps={{
                                            maxLength: 1,
                                            style: { textAlign: 'center' },
                                            inputMode: 'numeric',
                                            pattern: '[0-9]*',
                                        }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleChange(index, e.target.value)
                                        }
                                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                                            handleKeyDown(index, e)
                                        }
                                        onPaste={handlePaste}
                                    />
                                )}
                            />
                        ))}
                    </Box>
                </Box>
            </form>

            {error ? (
                <Typography variant="body1" paragraph sx={{ mt: 2, color: getColor(EColors.warningText) }}>
                    Invalid code. Try again or{' '}
                    <StyledResendLink
                        component="button"
                        variant="body1"
                        onClick={() => handleSendVerificationCode(user?.email)}
                    >
                        get a new one
                    </StyledResendLink>
                    <span>.</span>
                </Typography>
            ) : (
                <Typography variant="body1" paragraph sx={{ mt: 2 }}>
                    No code after 2 minutes?{' '}
                    <StyledResendLink
                        component="button"
                        variant="body1"
                        onClick={() => handleSendVerificationCode(user?.email)}
                    >
                        get a new one
                    </StyledResendLink>
                    <span>.</span>
                </Typography>
            )}
        </>
    );
};
