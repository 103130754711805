import { Box } from '@mui/material';
import { SpinnerOverlay } from 'components/Spinner';
import VenueProposalRenderer from 'components/VenueProposalRenderer';
import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useCurrentInquiry } from 'stores/current-inquiry';
import { hasContract } from 'utils/venue';
import { EmptyState } from './Contracting/EmptyState';

const Contracting = ({ viewVenueListing }: { viewVenueListing: (venueId: number) => void }) => {
    const { venues, loading, loaded } = useCurrentInquiry();

    const proposalId = useMemo(() => {
        if (loaded && venues.length > 0) {
            const acceptedVenue = venues.find(venue => hasContract(venue));
            return acceptedVenue?.proposalId;
        }
    }, [loaded, venues]);

    if (loading) {
        return <SpinnerOverlay />;
    }

    const renderCommonVenueProps = {
        proposalId: proposalId?.toString(),
        viewVenueListing,
    };

    return (
        <Box>
            {proposalId ? (
                <Routes>
                    <Route
                        path={'messages'}
                        element={<VenueProposalRenderer {...renderCommonVenueProps} withMessagesOpen />}
                    />
                    <Route path="*" element={<VenueProposalRenderer {...renderCommonVenueProps} />} />
                </Routes>
            ) : (
                <EmptyState />
            )}
        </Box>
    );
};

export default Contracting;
