import { Divider, Stack, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';

import Image from 'components/Ui-V2/Image/Image';
import { REJECTED_STATUSES } from 'components/VenueCard';
import RatingIcon from 'images/icons/rating.svg';
import React, { useCallback, useMemo } from 'react';
import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';
import { i18n } from 'translation';
import { isVenueBookable } from 'utils/venue';

import { useCurrentInquiry } from 'stores/current-inquiry';
import TileInfo from './TileInfo';
import { getVenueTypeInfo } from './VenueTileConfig';
import {
    ImageContainer,
    InquiryRow,
    InquiryText,
    PillContainer,
    PreferencePill,
    StyledSwitch,
    TempClosedPill,
    TileCard,
    VenueDetailsTextHiddenAction,
    VenueExpandIcon,
    VenueHeading,
    VenueHiddenActionsContainer,
    VenueInfoRow,
    VenueMetaItem,
    VenueMetaItemLabel,
    VenueRightStack,
    VenueSubtitle,
} from './VenueTilesStyles';

export type VenueTileProps = {
    className?: string;
    venue: Bizly.Venue;
    width?: string;
    minWidth?: string;
    padding?: string;
    highlighted?: boolean;
    disabledToolTipMessage?: string;
    selectionDisabled?: boolean;
    disableRejected?: boolean;
    onClick?: (venueId: number) => void;
    onMouseOver?: (venueId: number) => void;
    onMouseLeave?: () => void;
    onSelect?: (venue: Bizly.Venue) => void;
    onDeselect?: (venue: Bizly.Venue) => void;
    selected?: boolean;
    enableView?: boolean;
    hideActions?: boolean;
    inDrawer?: boolean;
    onClose?: () => void;
};

const VenueTileV3 = ({
    venue,
    highlighted,
    onMouseLeave,
    onMouseOver,
    onClick,
    onSelect,
    onDeselect,
    disableRejected,
    selectionDisabled,
    disabledToolTipMessage,
    enableView,
    width,
    padding,
    hideActions,
    selected,
    inDrawer,
    onClose,
}: VenueTileProps) => {
    const { venues: currentInquiryVenues } = useCurrentInquiry();
    const venueInquiry = currentInquiryVenues?.find(venueInquiry => venueInquiry.id === venue.id);

    const disabled = useMemo(
        () => (disableRejected && venueInquiry?.status ? REJECTED_STATUSES.has(venueInquiry.status) : false),
        [disableRejected, venueInquiry?.status]
    );

    const isBooked = useMemo(() => venueInquiry?.status === 'Booked', [venueInquiry?.status]);

    const canSelect = !disabled && !selectionDisabled && isVenueBookable(venue);
    const isSelectableAndVenueSelected = !selectionDisabled && selected;

    const showToggleActions = !hideActions && canSelect && !isBooked;

    const handleInquiryChange = useCallback(
        (e: React.SyntheticEvent) => {
            e.stopPropagation();

            if (inDrawer) {
                onClose?.();
            }

            if (isSelectableAndVenueSelected) {
                onDeselect?.(venue);
            } else {
                onSelect?.(venue);
            }
        },
        [isSelectableAndVenueSelected, onDeselect, onSelect, venue]
    );

    const handleClick = useCallback(() => {
        if ((enableView || !disabled) && onClick) {
            onClick(venue.id);
        }
    }, [enableView, onClick, disabled, venue.id]);

    const handleMouseOver = useCallback(
        () => !disabled && onMouseOver && onMouseOver(venue.id),
        [onMouseOver, venue.id, disabled]
    );

    const handleMouseLeave = useCallback(() => !disabled && onMouseLeave && onMouseLeave(), [onMouseLeave, disabled]);

    const preferenceCategory = useMemo(() => {
        const NO_RANK = 0;

        if (!venue.preferenceCategory) {
            return {
                rank: NO_RANK,
                name: i18n.venue.preferred,
            };
        } else {
            return venue.preferenceCategory;
        }
    }, [venue.preferenceCategory]);

    const renderImageWithStatus = () => {
        const imageHeight = '160px';
        const imageWidth = '160px';

        return (
            <Box position="relative">
                <PillContainer>
                    {venue.preferred && (
                        <PreferencePill rank={preferenceCategory.rank}>{preferenceCategory.name}</PreferencePill>
                    )}
                    {venue.tempClosed && <TempClosedPill>{i18n.venue.temporarilyClosed}</TempClosedPill>}
                    {inDrawer && <TileInfo venue={venue} pillType="inquiryStatus" />}
                </PillContainer>
                <Image
                    height={imageHeight}
                    width={imageWidth}
                    src={venue.imageUrl.replace('image/upload/', 'image/upload/w_300,q_auto:best/')}
                    alt={venue.name || 'Venue image'}
                />
            </Box>
        );
    };

    const venueDisplayInfo = getVenueTypeInfo(venue);

    const renderContent = () => (
        <Stack direction="row" spacing={2} sx={{ padding: inDrawer ? '10px' : '' }}>
            {/* Left side - Image */}
            <ImageContainer>{renderImageWithStatus()}</ImageContainer>

            {/* Right side - Content */}
            <VenueRightStack>
                <VenueHeading>{venue.name}</VenueHeading>
                {venueDisplayInfo.subtitle && <VenueSubtitle>{venueDisplayInfo.subtitle}</VenueSubtitle>}
                <VenueInfoRow>
                    {venueDisplayInfo.label && (
                        <VenueMetaItem>
                            {venueDisplayInfo.icon}
                            <VenueMetaItemLabel>{venueDisplayInfo.label}</VenueMetaItemLabel>
                        </VenueMetaItem>
                    )}
                    {/* TODO: We'll add this back when we gather payment requirements */}
                    {/* <VenueMetaItem>
                            <PaymentsOutlined
                                sx={{ width: 16, height: 16, color: getColor(EColors.bizlyInsightsBlue) }}
                            />
                            <VenueMetaItemLabel>-</VenueMetaItemLabel>
                        </VenueMetaItem> */}
                    {venueDisplayInfo?.rating && (
                        <VenueMetaItem>
                            <img src={RatingIcon} alt="Rating Icon" />
                            <VenueMetaItemLabel>{venueDisplayInfo.rating + '/5 Rating'}</VenueMetaItemLabel>
                        </VenueMetaItem>
                    )}
                </VenueInfoRow>
                <Divider sx={{ mt: '6px' }} />
                {showToggleActions ? (
                    <InquiryRow onClick={(e: React.MouseEvent) => e.stopPropagation()}>
                        <InquiryText>{selected ? 'Added to inquiry' : 'Add to inquiry'}</InquiryText>
                        <Tooltip title={disabledToolTipMessage}>
                            <Box>
                                <StyledSwitch
                                    checked={selected}
                                    onChange={handleInquiryChange}
                                    disabled={selectionDisabled || disabled}
                                />
                            </Box>
                        </Tooltip>
                    </InquiryRow>
                ) : (
                    <VenueHiddenActionsContainer>
                        <VenueDetailsTextHiddenAction component="span">Venue Details</VenueDetailsTextHiddenAction>
                        <VenueExpandIcon />
                    </VenueHiddenActionsContainer>
                )}
            </VenueRightStack>
        </Stack>
    );

    const CardComponent = (
        <TileCard
            onClick={handleClick}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            width={width}
            padding={padding}
            highlighted={highlighted}
        >
            {renderContent()}
        </TileCard>
    );

    return (
        <>
            {highlighted ? (
                <ScrollIntoViewIfNeeded
                    options={{
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center',
                        scrollMode: 'if-needed',
                    }}
                >
                    {CardComponent}
                </ScrollIntoViewIfNeeded>
            ) : (
                CardComponent
            )}
        </>
    );
};

export default VenueTileV3;
