import { Box } from '@mui/material';
import { ChangeEvent } from 'react';
import { FieldError } from 'react-hook-form';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { EColors } from 'theme';
import { Body2, Caption } from '../Typography/Typography';

const getCountry = () => {
    try {
        const Locale = Intl.Locale;
        return Locale ? new Locale(navigator.language).region?.toLowerCase() : undefined;
    } catch {
        return undefined;
    }
};

type PhoneFieldProps = {
    label: string;
    error: FieldError | undefined;
    onChange: (
        value: string,
        data: object | CountryData,
        event: ChangeEvent<HTMLInputElement>,
        formattedValue: string
    ) => void;
    onBlur: () => void;
    value: string | undefined;
    disabled?: boolean | undefined;
    required?: boolean;
};

const PhoneField = ({ label, error, value, required, onChange, onBlur }: PhoneFieldProps) => {
    return (
        <Box>
            <Box display="flex" gap={0.5} marginBottom={1}>
                <Body2 fontWeight={500}>{label}</Body2>
                {required && (
                    <Body2 color={EColors.bizlyOSPrimary} fontWeight={500}>
                        *
                    </Body2>
                )}
            </Box>
            <PhoneInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                country={getCountry()}
                specialLabel=""
                inputClass="phone-input"
            />
            {!!error && (
                <Caption color={EColors.red} fontWeight={500} size="large">
                    {error.message}
                </Caption>
            )}
        </Box>
    );
};

export default PhoneField;
