import { Box, styled } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { BorderedContainer } from '../GridContainer';
import { Body2 } from '../Typography/Typography';

const Container = styled(BorderedContainer)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.25),
    padding: theme.spacing(2.5),
    overflowY: 'auto',
    borderWidth: '0.5px',
}));

const StyledMenuItem = styled(Box)(({ theme: { getColor, EColors, shape }, selected }) => ({
    backgroundColor: selected ? getColor(EColors.bizlyOSPrimary, 0.2) : 'transparent',
    padding: '11.5px 8px',
    borderRadius: shape.borderRadius,
    cursor: 'pointer',
    '&:hover': !selected && {
        backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    },
}));

export const MAIN_PATH_WITH_PARAM = '/os/listings/:listingId';

export const INQUIRY_OVERVIEW_PATH = 'inquiry-overview';
export const INQUIRY_PROPOSAL_PATH = 'inquiry-proposal';
export const CONTRACTING_PATH = 'contracting';
export const PAYMENT_PATH = 'payment';

const MENU_ITEMS = [
    { title: 'Inquiry', path: INQUIRY_OVERVIEW_PATH },
    { title: 'Proposal', path: INQUIRY_PROPOSAL_PATH },
    //NOTE: hidden in v1
    // { title: 'Contracting', path: CONTRACTING_PATH },
    // { title: 'Payment', path: PAYMENT_PATH },
];

export default function InquirySubNavigation() {
    const location = useLocation();

    return (
        <Container>
            {MENU_ITEMS.map(menuItem => {
                const isActive = location.pathname.includes(menuItem.path);
                return (
                    <Link to={menuItem.path} key={menuItem.path}>
                        <StyledMenuItem selected={isActive}>
                            <Body2 fontWeight={500}>{menuItem.title}</Body2>
                        </StyledMenuItem>
                    </Link>
                );
            })}
        </Container>
    );
}
