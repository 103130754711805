import { Grid } from '@material-ui/core';
import { Check } from '@mui/icons-material';
import styled from 'styled-components';
import { EColors, getColor } from 'theme';
import { getVenueFeatures } from 'utils/getVenueFeatures';
import SpaceIcon from '../../images/icons/space.svg?react';
import { Copy } from '../../ui';

const VenueFeatureLabel = styled(Copy)`
    font-size: 0.875rem;
    line-height: 17px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
`;
const VenueFeaturesGrid = styled(Grid)`
    flex: 1;
    width: 100%;
`;

const StyledIconLabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

type VenueFeaturesV2Props = {
    venue: Bizly.Venue;
    useCheckIcon?: boolean;
    narrow?: boolean;
};

const VenueFeaturesV2 = (props: VenueFeaturesV2Props) => {
    const { venue, useCheckIcon = false } = props;

    return (
        <VenueFeaturesGrid container spacing={3}>
            {getVenueFeatures(venue).map(f => {
                const Icon = useCheckIcon ? Check : f.icon || SpaceIcon;

                return (
                    <Grid item xs={4} key={f.id}>
                        <StyledIconLabelWrapper>
                            <Icon style={{ fontSize: 20, color: getColor(EColors.primaryAction) }} />
                            <VenueFeatureLabel>{f.label}</VenueFeatureLabel>
                        </StyledIconLabelWrapper>
                    </Grid>
                );
            })}
        </VenueFeaturesGrid>
    );
};

export default VenueFeaturesV2;
