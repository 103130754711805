import { Box, styled } from '@mui/material';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { useGetProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useParams } from 'react-router-dom';
import { tzMoment } from 'utils/moment';
import { RoomBlockInfo } from '../InquiryProposal/InquiryProposalBody/RoomBlock/RoomBlockInfo';
import { DATE_FORMAT } from '../utils';

const BorderedContainer = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    padding: spacing(2.5),
    paddingTop: 0,
    borderRadius: shape.borderRadius,
    border: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

const BorderedBottomContainer = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: spacing(2.5),
    paddingTop: spacing(2.5),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

const LeftColumns = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 75px)',
    placeItems: 'center',
    gap: theme.spacing(2.5),
    maxWidth: '360px',
}));

export function RoomBlock() {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: proposalInquiryForm, isLoading } = useGetProposalInquiry(venueId, proposalId);

    const guestRooms = proposalInquiryForm?.guestRooms || [];
    const venue = proposalInquiryForm?.venue || {};

    if (isLoading || !guestRooms || guestRooms?.length === 0) return null;

    const totalRooms = guestRooms?.reduce((acc, { requestedGuests }) => acc + (requestedGuests ?? 0), 0);

    const roomBlockInfo = [
        { lable: 'Check In', value: tzMoment(guestRooms[0].date, venue?.timeZone).format(DATE_FORMAT) },
        {
            lable: 'Check Out',
            value: tzMoment(guestRooms[guestRooms.length - 1].date, venue?.timeZone).format(DATE_FORMAT),
        },
        { lable: 'Room Nights', value: totalRooms?.toString() || '0' },
    ];

    return (
        <Box display="flex" flexDirection="column" gap={2.5}>
            <RoomBlockInfo infos={roomBlockInfo} />

            <BorderedContainer>
                <BorderedBottomContainer>
                    <Body1 fontWeight={500}>Date</Body1>
                    <LeftColumns>
                        <Body1 fontWeight={500}>Single</Body1>
                        <Body1 fontWeight={500}>Double</Body1>
                        <Body1 fontWeight={500}>Suite</Body1>
                        <Body1 fontWeight={500}>Total</Body1>
                    </LeftColumns>
                </BorderedBottomContainer>
                {guestRooms?.map(room => {
                    const date = tzMoment(room.date, venue?.timeZone);
                    return (
                        <BorderedBottomContainer>
                            <Box>
                                <Body2 sx={{ opacity: 0.5 }}>{date.format('dddd')}</Body2>
                                <Body1>{date.format('MMM D, YYYY')}</Body1>
                            </Box>
                            <LeftColumns>
                                <Body1>{room.requestedGuests || 0}</Body1>
                                <Body1>{0}</Body1>
                                <Body1>{0}</Body1>
                                <Body1>{room.requestedGuests || 0}</Body1>
                            </LeftColumns>
                        </BorderedBottomContainer>
                    );
                })}
                <BorderedBottomContainer sx={{ borderBottom: 'none', paddingBottom: 0 }}>
                    <Body2 fontWeight={500}>Total Room Nights</Body2>
                    <LeftColumns>
                        <Body1 fontWeight={500}>{totalRooms}</Body1>
                        <Body1 fontWeight={500}>{0}</Body1>
                        <Body1 fontWeight={500}>{0}</Body1>
                        <Body1 fontWeight={500}>{totalRooms}</Body1>
                    </LeftColumns>
                </BorderedBottomContainer>
            </BorderedContainer>
        </Box>
    );
}
