import { Box, styled } from '@mui/material';
import { Body1 } from 'components/BizlyOS/Typography/Typography';
import { EColors } from 'theme';

const InfoContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    border: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    backgroundColor: getColor(EColors.pureBlack),
    display: 'flex',
    justifyContent: 'space-between',
}));

export function EventSpacesCount({ count }: { count: number }) {
    return (
        <InfoContainer>
            <Body1 fontWeight={500} color={EColors.pureWhite}>
                # of Requested Spaces
            </Body1>
            <Body1 fontWeight={500} color={EColors.pureWhite}>
                {count}
            </Body1>
        </InfoContainer>
    );
}
