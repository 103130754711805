import { Spinner } from 'components/Spinner';
import Communication from 'pages/Communication/Communication';
import { useState } from 'react';
import { useCreatePlaybook } from './store';

export default function CommunicationForm() {
    const { id: playbookId, parcelLoadKey } = useCreatePlaybook();
    const [isParcelCreating, setIsParcelCreating] = useState(false);

    if (!playbookId) return <Spinner />;

    if (isParcelCreating) return <Spinner />;

    return (
        <>
            <Communication
                hiddenColumns={['sendDate']}
                playbookId={playbookId}
                key={parcelLoadKey}
                setIsParcelCreating={setIsParcelCreating}
            />
        </>
    );
}
