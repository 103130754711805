import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Radio as MuiRadio, styled } from '@mui/material';
import React from 'react';

const TaskItem = styled(Box)(({ theme: { getColor, EColors } }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px',
    borderRadius: 4,
    gap: '10px',
    border: `0.5px solid ${getColor(EColors.greyBorder)}`,
    backgroundColor: getColor(EColors.pureWhite),
}));

const DragHandle = styled(Box)({
    cursor: 'grab',
});

const Radio = styled(MuiRadio)({
    padding: 0,
});

const TaskCopy = styled(Box)({
    flex: 1,
    fontSize: '0.875rem',
});

interface TaskProps {
    text: string;
    dragHandleProps?: SyntheticListenerMap;
}

const Task: React.FC<TaskProps> = ({ text, dragHandleProps }) => {
    return (
        <TaskItem>
            {/* Drag Handle */}
            <DragHandle {...dragHandleProps}>
                <DragIndicatorIcon fontSize="small" />
            </DragHandle>

            <Radio size="small" />

            {/* Task Text */}
            <TaskCopy>{text}</TaskCopy>

            {/* More Options */}
            <IconButton size="small">
                <MoreVertIcon fontSize="small" />
            </IconButton>
        </TaskItem>
    );
};

export default Task;
