import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from 'utils/header';
import { Row, SpacedRow } from '../ui';

// NOTE: z-index hack is because material ui textfields are selectable through sticky header otherwise
export const FixedRow = styled(SpacedRow)<{ pageOffset: number }>`
    position: fixed;
    top: 0;
    left: ${({ pageOffset }) => pageOffset}px;
    height: ${HEADER_HEIGHT}px;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.9)};
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    z-index: 1100;
    width: calc(100vw - ${({ pageOffset }) => pageOffset}px);
`;

export const StickyHeader = ({
    pageOffset = 0,
    children,
    className,
    style,
}: {
    pageOffset?: number;
    children: React.ReactNode;
    className?: string;
    style?: CSSProperties;
}) => (
    <FixedRow className={className} pageOffset={pageOffset}>
        <Row
            style={{
                ...style,
                margin: '0 64px', // TODO: why is this necessary?
            }}
        >
            {children}
        </Row>
    </FixedRow>
);
