import EventRegistrationWebPageBody from 'components/EventRegistrationWebPageBody';
import RsvpDrawer from 'components/Rsvp-V2/RsvpDrawer';
import { useGetRegistrationInfoQuery } from 'hooks/queries/useEventsQuery';
import { EditParcelState, TEventParcelContext } from 'pages/EditParcel/EditParcel';
import { useEvent } from 'providers/event';
import { RefObject, useState } from 'react';
import { formatDateRange, formatTimeOnly, getShortTimeZone } from 'utils/date_util';

type WebRegistrationPreviewProps = {
    context: TEventParcelContext;
    state: EditParcelState;
    previewRef: RefObject<HTMLElement>;
};

function WebRegistrationPreview({ state, previewRef }: WebRegistrationPreviewProps) {
    const { event } = useEvent();
    const { parcel } = state;
    const [openRsvpModal, setOpenRsvpModal] = useState(false);

    const slug = !Array.isArray(parcel?.traits) && parcel?.traits?.public?.registrationPage.slug;
    const { data, isLoading } = useGetRegistrationInfoQuery(event.id, slug as string, openRsvpModal);

    const handleRsvpModalOpen = () => {
        setOpenRsvpModal(true);
    };

    const handleRsvpModalClose = () => {
        setOpenRsvpModal(false);
    };

    return (
        <>
            <EventRegistrationWebPageBody
                preview
                previewRef={previewRef}
                coverImageUrl={parcel.customImage?.url || ''}
                logoUrl={parcel.customLogoUrl || ''}
                eventTitle={parcel.subject || ''}
                dateRange={formatDateRange(parcel.startDate || '', parcel.endDate || '', false)}
                startTime={parcel.startTime ? formatTimeOnly(parcel.startTime || '', event.timeZone) : ''}
                endTime={parcel.endTime ? formatTimeOnly(parcel.endTime || '', event.timeZone) : ''}
                timeZone={getShortTimeZone(parcel.startDate || '', event.timeZone || '-')}
                locationName={parcel.locationName || ''}
                locationAddress={parcel.locationAddress || '-'}
                eventOverview={parcel.content || ''}
                googleLocationId={parcel.locationGooglePlaceId || ''}
                onClickCountMeIn={handleRsvpModalOpen}
            />
            <RsvpDrawer
                preview
                open={openRsvpModal}
                isLoading={isLoading || !data}
                event={data && data.event}
                attendee={data && data.attendee}
                formSettings={data && data.formSettings}
                onClose={handleRsvpModalClose}
                eventId={event.id.toString()}
                slugId={slug as string}
            />
        </>
    );
}

export default WebRegistrationPreview;
