import Stack from '@mui/material/Stack';
import EmptyVisual from 'components/Ui-V2/EmptyVisual';
import UploadBox from 'components/Ui-V2/UploadBox/UploadBox';
import { fields, schema, schemaWithoutGuestrooms } from 'components/VenueAccepted/uploadFormSchema';
import EmptyEstimatesImage from 'images/empty-pages-assets/empty-estimates.png';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Spacer } from '../../ui';
import Form from '../Form';
import { H2Headline, H3Headline } from '../ui/Headline';

export type TSpendEstimateForm = {
    cateringCost?: string | number;
    guestroomCost?: string | number;
    guestroomNights?: string | number;
    guests?: string | number;
    roomCost?: string | number;
    total?: string | number;
    existingDocs: {
        contract1?: string;
        contract2?: string;
    };
    stagedDocs?: {
        contractUrls?: File[] | null;
    };
    currency?: string | null;
};

const Headline = styled(H2Headline)`
    margin-bottom: 32px;
`;

const UploadContainer = styled(Stack)`
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    padding: 1em;
    border-radius: 4px;
`;

export default function SpendEstimateForm({
    canUpdate,
    onFormUpdate,
    formValues,
    teamCompliance,
    hideHeader,
    readonly,
    disabled,
}: Readonly<{
    canUpdate: boolean;
    onFormUpdate: (estimatesForm: TSpendEstimateForm) => void;
    formValues: TSpendEstimateForm;
    teamCompliance: Bizly.TeamCompliance;
    readonly?: boolean;
    disabled?: boolean;
    hideHeader?: boolean;
}>) {
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const { existingDocs, currency, stagedDocs = {} } = formValues;

    const handleStagingDoc = async (files: File[], target: string) => {
        const MAX_MB = 41943040; // 40mb
        const isFileTooLarge = files.filter(file => file.size > MAX_MB);
        if (isFileTooLarge.length > 0) {
            isFileTooLarge.forEach(file => {
                enqueueSnackbar(i18n.error.tooLargeFile(file.name), {
                    variant: 'error',
                });
            });
        } else {
            onFormUpdate({
                ...formValues,
                stagedDocs: { ...stagedDocs, [target]: files },
            });

            setUploadedFiles([]);
        }
    };

    const contracts = ['contract1', 'contract2'] as const;
    const uploadedDocs = contracts
        .map(contract => existingDocs[contract as keyof typeof existingDocs])
        .filter((doc): doc is string => !isEmpty(doc));

    return (
        <>
            {!hideHeader && <Headline>{i18n.venue.proposal.uploadSignedDocs}</Headline>}
            {canUpdate ? (
                <>
                    <Form
                        fields={fields(currency ?? 'USD')}
                        schema={teamCompliance?.guestroomsDisabled ? schemaWithoutGuestrooms : schema}
                        value={{ ...formValues, currencyCode: currency ?? 'USD' }}
                        onChange={(newValue: { value: TSpendEstimateForm }) => onFormUpdate(newValue.value)}
                        isNested={false}
                        readonly={readonly}
                        densePadding={false}
                        disabled={disabled}
                    />

                    <Spacer />

                    <UploadContainer spacing={2}>
                        <H3Headline>{i18n.meetingDashboard.headerSection.spendTracking.uploadContracts}</H3Headline>
                        <UploadBox
                            onUpload={files => {
                                handleStagingDoc(files, 'contractUrls');
                                setUploadedFiles(files);
                            }}
                            files={uploadedFiles}
                            target={'contractUrls'}
                            link={uploadedDocs.length ? uploadedDocs : []}
                            isResourceFile={false}
                        />
                    </UploadContainer>
                </>
            ) : (
                <>
                    <H2Headline>{i18n.meetingDashboard.headerSection.spendTracking.estimate}s</H2Headline>
                    <Spacer />
                    <EmptyVisual
                        image={<img src={EmptyEstimatesImage} alt="Empty Estimates" />}
                        title={i18n.venue.proposal.noProposalsAcceptedYet}
                        description={i18n.venue.proposal.estimatesHelper}
                    />
                </>
            )}
        </>
    );
}
