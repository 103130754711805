import { Box, Skeleton } from '@mui/material';

const LoadingAnimation = () => (
    <Box sx={{ mt: 2 }}>
        <Skeleton
            animation="wave"
            height={24}
            sx={{
                mb: 1,
                backgroundColor: '#90caf933',
                '&::after': {
                    background: 'linear-gradient(90deg, transparent, #90caf966, transparent)',
                },
            }}
        />
    </Box>
);

export default LoadingAnimation;
