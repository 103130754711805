import { Box, styled } from '@mui/material';
import { Body1 } from 'components/BizlyOS/Typography/Typography';
import { EColors, getColor } from 'theme';
import { TextCard } from './TextCard';

const BorderedContainer = styled(Box)(({ theme: { shape, spacing } }) => ({
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    border: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    '& > :last-child': {
        borderBottom: 'none',
        paddingBottom: 0,
    },
}));

const BorderedBottomContainer = styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: spacing(2.5),
    paddingTop: spacing(2.5),
}));

const Title = styled(Body1)(({ theme: { spacing } }) => ({
    paddingBottom: spacing(2.5),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    fontWeight: 600,
}));

export function EventSpaceRequestedDetails({
    spaceName,
    time,
    headcount,
    avRequests,
    fbRequests,
    spaceConfiguration,
    requestedDate,
}: {
    spaceName: string;
    time: string;
    headcount: string;
    avRequests: string[];
    fbRequests: string[];
    spaceConfiguration: string;
    requestedDate: string;
}) {
    return (
        <BorderedContainer>
            <Title>Client Details</Title>
            <BorderedBottomContainer>
                <TextCard title="Session" description={spaceName} />
                <TextCard title="Date" description={requestedDate} />
            </BorderedBottomContainer>
            <BorderedBottomContainer>
                <TextCard title="Time" description={time} />
                <TextCard title="Headcount" description={headcount} />
            </BorderedBottomContainer>
            <BorderedBottomContainer>
                {/*TODO: change the description below with the real value once the API is ready */}
                <TextCard title="Preferred Space" description="Private Dining Room" />
                <TextCard title="Space Configuration" description={spaceConfiguration} />
            </BorderedBottomContainer>
            {avRequests.length > 0 && (
                <BorderedBottomContainer>
                    <TextCard title="Audio & Visual Requirements" descriptions={avRequests} />
                </BorderedBottomContainer>
            )}

            {fbRequests.length > 0 && (
                <BorderedBottomContainer>
                    <TextCard title="Food & Beverage Requirements" descriptions={fbRequests} />
                </BorderedBottomContainer>
            )}
        </BorderedContainer>
    );
}
