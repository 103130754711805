import { useFeatureFlagEnabled } from 'posthog-js/react';
import { PostHogFeatureFlags } from 'shared';
import { EventNavigation as NewEventNavigation } from './EventNav';
import { LegacyEventNavigation } from './LegacyEventNav';
import { NavProps } from './utils';

export const EventNavigation = (props: NavProps) => {
    const newEventNavEnabled = useFeatureFlagEnabled(PostHogFeatureFlags.toggleNewEventNav);

    return newEventNavEnabled ? <NewEventNavigation {...props} /> : <LegacyEventNavigation {...props} />;
};
