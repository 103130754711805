import { alpha, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { Link } from 'react-router-dom';
import { RouteType } from '../../Routes/routes';

const SidebarLink = styled('div', {
    shouldForwardProp: prop => prop !== 'active',
})<{ active?: boolean }>(({ theme: { getColor, EColors }, active }) => ({
    color: getColor(EColors.pureBlack),
    cursor: 'pointer',
    padding: '0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: active ? alpha(getColor(EColors.bizlyOSPrimary), 0.2) : 'transparent',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    '&:hover': {
        backgroundColor: active
            ? alpha(getColor(EColors.bizlyOSPrimary), 0.2)
            : alpha(getColor(EColors.bizlyOSPrimary), 0.1),
    },
}));

const LinkContent = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

const IconContainer = styled(Box, {
    shouldForwardProp: prop => prop !== 'isCollapsed',
})<{ isCollapsed: boolean }>(() => ({
    height: '1.25rem',
    width: '1.25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '1rem',
}));

const NavTitle = styled('span', {
    shouldForwardProp: prop => prop !== 'isCollapsed',
})<{ isCollapsed: boolean }>(({ isCollapsed }) => ({
    opacity: isCollapsed ? 0 : 1,
    transition: 'opacity 0.3s ease',
    fontSize: '.875rem',
}));

const SoonPill = styled('span')(({ theme: { getColor, EColors } }) => ({
    backgroundColor: alpha(getColor(EColors.bizlyOSPrimary), 0.2),
    color: getColor(EColors.pureBlack),
    fontSize: '0.75rem',
    padding: '0.125rem 0.5rem',
    borderRadius: '1rem',
}));

interface RouteButtonProps {
    route: RouteType;
    isActive: boolean;
    isCollapsed: boolean;
}

export const RouteButton = ({ route, isActive, isCollapsed }: RouteButtonProps) => {
    const isFeatureEnabled = useFeatureFlagEnabled(route.featureFlag || '');

    if (route.featureFlag && isFeatureEnabled === false) {
        return null;
    }

    return (
        <Link to={route.path}>
            <SidebarLink active={isActive}>
                <LinkContent>
                    <IconContainer isCollapsed={isCollapsed}>
                        <route.icon />
                    </IconContainer>
                    <NavTitle isCollapsed={isCollapsed}>{route.title}</NavTitle>
                </LinkContent>
                {(route.path === '/os/messages' || route.path === '/os/calendar' || route.path === '/os/analytics') &&
                    !isCollapsed && <SoonPill>Soon</SoonPill>}
            </SidebarLink>
        </Link>
    );
};
