import { VenueTileAdd } from 'components/VenueTile';
import DashboardVenueTile from 'components/VenueTile/DashboardVenueTile';
import { padArray } from '../../../util';
import { DEFAULT, NO_CITY, TBookedStatus } from './utils';
export const VenueTiles = ({
    venues,
    bookedState,
    viewVenueListing,
    eventId,
}: {
    venues: Bizly.Venue[];
    bookedState: TBookedStatus;
    viewVenueListing?: (venueId: number) => void;
    placeholderBackground?: Themed.Color;
    eventId: number;
}) => (
    <>
        {padArray(venues, 4)
            .slice(0, 4)
            .map((venue: Bizly.Venue | undefined, idx) =>
                venue ? (
                    <DashboardVenueTile
                        key={venue.id + idx}
                        venue={venue}
                        format="tile"
                        pillType={
                            bookedState === DEFAULT
                                ? 'preferenceCategory'
                                : bookedState !== NO_CITY
                                  ? 'inquiryStatus'
                                  : undefined
                        }
                        fixedRatio="63%"
                        onClick={viewVenueListing}
                    />
                ) : (
                    <VenueTileAdd
                        width="100%"
                        fixedRatio={'80%'}
                        noBorder
                        hideDescription
                        eventId={eventId.toString()}
                    />
                )
            )}
    </>
);
