import { styled } from '@mui/material/styles';
import React from 'react';
import { EColors } from 'theme';
import { InlineRow } from 'ui';

export const DEFAULT_PREFERENCE_COLOR = EColors.preferredVenue;

const MuiStyledPill = styled('div', {
    shouldForwardProp: prop =>
        ![
            'backgroundColor',
            'hoverColor',
            'size',
            'maxWidth',
            'noShadow',
            'centerText',
            'startAdornment',
            'endAdornment',
            'squared',
        ].includes(prop as string),
})<{
    backgroundColor?: string;
    hoverColor?: string;
    size?: 'small' | 'medium';
    maxWidth?: string;
    noShadow?: boolean;
    centerText?: boolean;
    squared?: boolean;
}>(({ theme, ...props }) => ({
    fontSize: props.size === 'small' ? 9 : 11,
    padding: props.size === 'small' ? '4px 8px' : '4px 12px',
    fontWeight: 500,
    lineHeight: props.centerText ? 1 : 1.18,
    textTransform: 'uppercase',
    maxWidth: '100%',
    color: theme.palette.text.primary,
    backgroundColor: props.backgroundColor || DEFAULT_PREFERENCE_COLOR,
    transition: 'all 0.15s ease-out',
    '&:hover': {
        backgroundColor: props.hoverColor,
    },
    borderRadius: props.squared ? 4 : 100,
    boxShadow: props.noShadow ? 'none' : theme.shadows[2],
    cursor: props.onClick ? 'pointer' : 'default',
    display: 'flex',
    alignItems: 'center',
    ...(props.startAdornment && { paddingLeft: 4 }),
    ...(props.endAdornment && { paddingRight: 4 }),
}));

type PillTypes = {
    hoverColor?: string;
    label: React.ReactNode;
    size?: 'small' | 'medium';
    noShadow?: boolean;
    centerText?: boolean;
    squared?: boolean;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    onClick?: () => void;
};

const Pill = React.forwardRef<HTMLDivElement, PillTypes>((props, ref) => {
    const {
        hoverColor,
        label,
        size = 'medium',
        noShadow,
        centerText,
        squared,
        onClick,
        startAdornment,
        endAdornment,
        ...rest
    } = props;

    return (
        <MuiStyledPill
            ref={ref}
            onClick={onClick}
            role={onClick ? 'button' : undefined}
            tabIndex={onClick ? 0 : undefined}
            onKeyDown={(e: React.KeyboardEvent) => {
                if (onClick && (e.key === 'Enter' || e.key === ' ')) {
                    onClick();
                }
            }}
            {...rest}
            size={size}
            noShadow={noShadow}
            centerText={centerText}
            squared={squared}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
        >
            <InlineRow itemSpacing="xsmall">
                {startAdornment}
                <div
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {label}
                </div>
                {endAdornment}
            </InlineRow>
        </MuiStyledPill>
    );
});

export default Pill;
