import Dialog from '@mui/material/Dialog';
import { styled as muiStyled } from '@mui/material/styles';

export const StyledDialog = muiStyled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: theme.spacing(3),
        flexDirection: 'row',
        justifyContent: 'space-between',
        minWidth: '562px',
        minHeight: '700px',
        overflow: 'hidden',
        margin: '0 auto',
    },

    '& .MuiDialogContent-root': {
        padding: `${theme.spacing(3.75)} ${theme.spacing(5)}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: '1 1 auto',
        width: '32.5rem',
    },
}));
