import { alpha, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { logOutUser } from 'api';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useUser } from 'providers/user';
import React, { useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BizlyOsPostHogFeatureFlags } from 'shared';
import { removeCookie } from 'utils/tokens';
import { LOGOUT_PATH, PATHS, SETTINGS_PATH } from '../Routes/routes';
import { LogOutModal } from './LogOutModal/LogOutModal';
import { RouteButton } from './RouteButton/RouteButton';
export const SIDE_NAV_WIDTH = 200;
export const SIDE_NAV_COLLAPSED_WIDTH = 68;
export const HEADER_HEIGHT = 60;

const Side = styled(Box, {
    shouldForwardProp: prop => prop !== 'isCollapsed',
})<{ isCollapsed: boolean }>(({ theme: { getColor, EColors }, isCollapsed }) => ({
    width: isCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH,
    minWidth: isCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH,
    boxSizing: 'border-box',
    backgroundColor: getColor(EColors.drWhite),
    zIndex: 200,
    display: 'flex',
    flexDirection: 'column',
    transition: 'width 0.3s ease, min-width 0.3s ease',
    position: 'fixed',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    top: HEADER_HEIGHT,
}));

const SideBarContainer = styled(Box)({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
});

const SideBarLinks = styled(Box)({
    padding: '1.25rem 0.9375rem',
});

const SidebarLink = styled('div', {
    shouldForwardProp: prop => prop !== 'active',
})<{ active?: boolean }>(({ theme: { getColor, EColors }, active }) => ({
    color: getColor(EColors.pureBlack),
    cursor: 'pointer',
    padding: '0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: active ? alpha(getColor(EColors.bizlyOSPrimary), 0.2) : 'transparent',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    '&:hover': {
        backgroundColor: active
            ? alpha(getColor(EColors.bizlyOSPrimary), 0.2)
            : alpha(getColor(EColors.bizlyOSPrimary), 0.1),
    },
}));

const SettingsLink = styled(SideBarLinks)({
    marginTop: 'auto',
});

const LinkContent = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

const IconContainer = styled(Box, {
    shouldForwardProp: prop => prop !== 'isCollapsed',
})<{ isCollapsed: boolean }>(() => ({
    height: '1.25rem',
    width: '1.25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '1rem',
}));

const NavTitle = styled('span', {
    shouldForwardProp: prop => prop !== 'isCollapsed',
})<{ isCollapsed: boolean }>(({ isCollapsed }) => ({
    opacity: isCollapsed ? 0 : 1,
    transition: 'opacity 0.3s ease',
    fontSize: '.875rem',
}));

export const BizlyOSSideNav = ({
    children,
    isCollapsed,
}: Readonly<{
    children?: React.ReactNode;
    isCollapsed: boolean;
}>) => {
    const { user, clearUser } = useUser();
    const location = useLocation();
    const navigate = useNavigate();

    const canViewSettingsFlag = useFeatureFlagEnabled(BizlyOsPostHogFeatureFlags.toggleSettings);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = React.useState(false);

    const highlightedPath = useMemo(() => {
        const match = PATHS.find(path => path.matches.some(pattern => RegExp(pattern).exec(location.pathname)));
        return match?.path;
    }, [location.pathname]);

    const handleLogoutClick = () => {
        setIsLogoutModalOpen(true);
    };

    const handleLogoutConfirm = () => {
        setIsLogoutModalOpen(false);
        if (user?.id) {
            logOutUser(user.id);
        }
        removeCookie();
        clearUser();
        navigate('/sign-in');
    };

    return (
        <>
            <Side isCollapsed={isCollapsed}>
                <SideBarContainer>
                    <SideBarLinks>
                        <Box display="flex" flexDirection="column" gap={1.25} sx={{ width: '100%' }}>
                            {PATHS.map(route => (
                                <RouteButton
                                    key={route.path}
                                    route={route}
                                    isActive={highlightedPath === route.path}
                                    isCollapsed={isCollapsed}
                                />
                            ))}
                        </Box>
                    </SideBarLinks>

                    {canViewSettingsFlag && (
                        <SettingsLink>
                            <Link to={SETTINGS_PATH.path}>
                                <SidebarLink active={location.pathname.startsWith(SETTINGS_PATH.path)}>
                                    <LinkContent>
                                        <IconContainer isCollapsed={isCollapsed}>
                                            <SETTINGS_PATH.icon />
                                        </IconContainer>
                                        <NavTitle isCollapsed={isCollapsed}>{SETTINGS_PATH.title}</NavTitle>
                                    </LinkContent>
                                </SidebarLink>
                            </Link>

                            <SidebarLink
                                active={false}
                                onClick={handleLogoutClick}
                                role="button"
                                tabIndex={0}
                                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                                    e.key === 'Enter' && handleLogoutClick()
                                }
                                sx={{ cursor: 'pointer', marginTop: '10px' }}
                            >
                                <LinkContent>
                                    <IconContainer isCollapsed={isCollapsed}>
                                        <LOGOUT_PATH.icon />
                                    </IconContainer>
                                    <NavTitle isCollapsed={isCollapsed}>{LOGOUT_PATH.title}</NavTitle>
                                </LinkContent>
                            </SidebarLink>
                        </SettingsLink>
                    )}
                </SideBarContainer>
            </Side>

            {children && (
                <Box
                    sx={{
                        marginLeft: isCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH,
                        transition: 'margin-left 0.3s ease',
                    }}
                >
                    {children}
                </Box>
            )}

            <LogOutModal
                isLogoutModalOpen={isLogoutModalOpen}
                setIsLogoutModalOpen={setIsLogoutModalOpen}
                handleLogoutConfirm={handleLogoutConfirm}
            />
        </>
    );
};
