import { useSnackbar } from 'notistack';
import React, { useRef } from 'react';
import { i18n } from 'translation';
import { meetingsActions, selectMeeting, useMeetings } from './store';

export default function LoadMeeting({ id }: { id?: string | number }) {
    const { enqueueSnackbar } = useSnackbar();
    const meeting = useMeetings(selectMeeting(id));

    const loading = useRef(false);

    React.useEffect(() => {
        const load = async () => {
            if (id) {
                try {
                    loading.current = true;
                    await meetingsActions.loadSingle(id);
                } catch (e) {
                    enqueueSnackbar(i18n.error.default, { variant: 'error' });
                } finally {
                    loading.current = false;
                }
            }
        };
        if (!meeting && !loading.current) {
            load();
        }
    }, [id, meeting]);

    return null;
}
